import _ from "lodash";

export namespace array {

    export function moveElement<T>(array: T[], from: number, to: number): T[] {
        array.splice(to, 0, array.splice(from, 1)[0]);
        return array;
    }

    export function getRandomElement<T>(array: T[]) {
        return array[Math.floor(Math.random() * array.length)];
    }

    export function nudgeElement<T>(array: T[], element: T, up: boolean): T[] {
        const from = _.indexOf(array, element);
        if (from < 0) return array;

        const to = from + (up ? 1 : -1);
        if (to < 0) return array;
        if (to > array.length - 1) return array;
        return moveElement(array, from, to);
    }

    export function removeItem<T>(array: T[], item: T): boolean {

        if (!array)
            return false;

        if (array.length === 0)
            return false;

        const idx = array.indexOf(item);
        if (idx >= 0) {
            array.splice(idx, 1);
            return true;
        }
        return false;
    }

    export function removeAllItems<T>(array: T[], item: T): boolean {
        let haveRemovedAny = false;
        while (removeItem(array, item))
            haveRemovedAny = true;
        return haveRemovedAny;
    }

    export function shuffle<T>(array: T[]) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }


}
