import {
    ICompileService,
    IHttpService,
    IIntervalService,
    ILocationService,
    IQService,
    IRootScopeService,
    ISCEService,
    IScope,
    ITimeoutService
} from "angular";
import {NGC} from "../const";
import {NGX} from "../utility/ng";
import {OverlayService} from "../services/overlay";
import {IGlobalState} from "../interfaces";
import IInjectorService = angular.auto.IInjectorService;

export abstract class AppComponentBase {

    q: IQService;
    location: ILocationService;
    root: IRootScopeService;
    http: IHttpService;
    timeout: ITimeoutService;
    interval: IIntervalService;
    compile: ICompileService;
    overlay: OverlayService;
    state: IGlobalState;

    protected injectFields(injector: IInjectorService) {
        this.location = injector.get(NGC.location);
        this.root = injector.get(NGC.rootScope);
        this.q = injector.get(NGC.q);
        this.http = injector.get(NGC.http);
        this.timeout = injector.get(NGC.timeout);
        this.interval = injector.get(NGC.interval);
        this.compile = injector.get(NGC.compile);
        this.overlay = injector.get(OverlayService.injectAs);
        this.state = injector.get(NGC.GlobalState);
    }

    protected postInit() {

    }

    private initInjectedProperties(): boolean {

        const injector = NGX.getInjector()
        if (!injector) {
            return false;
        }

        this.injectFields(injector)
        return true
    }

    protected constructor() {
        if (!this.initInjectedProperties()) {
            const initializer = () => {
                if (!this.initInjectedProperties()) {
                    setTimeout(initializer, 1);
                } else this.postInit()
            }
            initializer();
        } else this.postInit();
    }
}

export abstract class AppDirectiveBase<TScope extends IScope = IScope> extends AppComponentBase {
    scope: TScope;
    overlay: OverlayService;

    private _initialized = false;

    get ready() {
        return this._initialized
    }

    static $inject = [NGC.scope]
    constructor(scope: TScope) {
        super();
        this.scope = scope;
    }

    protected injectFields(injector: IInjectorService) {
        super.injectFields(injector);
        this.overlay = injector.get(OverlayService.injectAs)
    }

    protected postInit() {
        this.setTimeout(async () => {
            await this.onInit()
            this._initialized = true;
            NGX.safeDigest(this.scope)
        })
    }

    async onInit(): Promise<void> {

    }

    setTimeout(fn: () => void, delay: number = 0) {
        this.timeout(fn, delay)
    }


}
