import _ from "lodash";

import {
    IStatusTag,
    ITerminalLayout,
    ITerminalSettings,
    RequestApiBindingKind,
    TerminalDirection,
    TerminalType
} from "./common";
import {uuid} from "../utility/utility";
import {Severity, ValidationGroup, ValidationProblem, Validator} from "../common/Validation";
import {Audio} from "./audio";
import {url} from "../utility/url";
import {NGX} from "../utility/ng";
import {IScope} from "angular";
import {CanvasTemplate, VoiceTemplate} from "./CanvasTemplate";
import {IPoint} from "../utility/common";
import {array} from "../utility/array";
import isValidUrl = url.isValidUrl;
import clearNgHashes = NGX.clearNgHashes;

export interface IBinding {
    parameterName: string;
    parameterFixed?: boolean;
    locked?: boolean;
    undeletable?: boolean;
}

export interface IApiBinding extends IBinding {
    variableId: string;
}

export interface ITokenStep<TToken> {
    token: TToken;
    value?: number;
}

export interface IRequestApiBinding extends IBinding {
    kind: RequestApiBindingKind;
    value?: string;
}

export interface IEndpointConfiguration {
    endpoint: string;
    bindings: IRequestApiBinding[];
    method: string;
    username: string;
    password: string;
}

export interface ITerminal {

    ident: string;
    node: Transaction;

    type: TerminalType;

    target: ITerminal | null;
    incoming: ITerminal[];

    ghost: boolean;
    active: boolean;
    text: string;
    meta?: any;
    direction?: TerminalDirection;

    color?: string;
    layout?: ITerminalLayout;
}

export interface ITerminalConnection {
    from: ITerminal;
    to: ITerminal;
}

export interface ITransactionSettingsScope<TNode extends Transaction> extends IScope {
    transaction: TNode;
    template: CanvasTemplate;
    point: IPoint;
    mode: string;
    currentAudio: Audio;
}

export interface ITransactionLayout {
    sqn: number;

    x: number;
    y: number;
    zindex: number;
    active: boolean;
    dragging: boolean;

    elem: JQuery;

    width?: number;
    height?: number;

    originalWidth?: number;
    originalHeight?: number;

    viewWidth?: number;
    viewHeight?: number;
    viewFontSize?: string;

    lsX?: number;
    lsY?: number;
    lsZ?: number;

    lsH?: string;
    lsW?: string;
    lsF?: string;

    lsA?: boolean;
}

export interface ITransactionScope<TTransaction extends Transaction> extends IScope {
    transaction: TTransaction;
    template: CanvasTemplate;
    tag: IStatusTag;
    problem: ValidationProblem | null;

    defaultIcon: string;
    hasIconSection: boolean;
    hasExtraSection: boolean;
    hasCustomTerminals: boolean;
    hasSettingsSection: boolean;
    hasCustomBody: boolean;

    showTagPicker();
}

export interface IVoiceTransactionScope<TTransaction extends Transaction> extends IScope {
    transaction: TTransaction;
    template: VoiceTemplate;

    hasExtra: boolean;
    hasCustomOutbound: boolean;
    hasSettings: boolean;
}

// Exported Enums

export enum CreditCardTransactionMerchantFacility {
    // noinspection SpellCheckingInspection
    Custom = 0
}

export enum InputTransactionSubtype {
    Any = 0,
    Number = 1,
    Date = 2
}

export enum NumberLogicToken {
    If = 0,
    Else = 9,
    ComparisonGT = 1,
    ComparisonGTE = 2,
    ComparisonLT = 3,
    ComparisonLTE = 4,
    ComparisonEQ = 5,
    Value = 6,
    And = 7,
    End = 8
}

export enum DateInputFormat {
    // noinspection SpellCheckingInspection
    MMDD = 1,
    DDMM = 2,
    MMDDYY = 3,
    DDMMYY = 4
}

export enum DateLogicToken {
    End = 1,
    From = 2,
    Value = 3,
    Days = 4,
    Weeks = 5,
    Months = 6,
    Future = 7,
    Past = 8
}

const Texts = {
    validationMissingInboundConnection: "Missing Inbound Connection",
    validationMissingOutboundConnection: "Missing Outbound Connection",
    validationMissingTtsText: "TTS text is missing",
    validationKeyValuesMissing: "Key values are undefined",
    validationNotAllKeysHaveValues: "Not all keys have values",
};

export abstract class Transaction {

    referenceId: string = uuid();
    canBeReferenced: boolean = false;

    tagId: string; // tags used to have an id, but now their id is their name.

    // Object Fields
    x: number = 0;
    y: number = 0;
    ident: any;
    tranName: string;

    isSystemTransaction: boolean = true;
    layout: ITransactionLayout;

    validation = new ValidationGroup();

    // Utility Fields
    protected static sqnGenerator: number = 0;

    protected constructor() {
        const sqn = Transaction.sqnGenerator++;
        this.layout = {
            sqn: sqn,
            x: 0,
            y: 0,
            zindex: sqn,
            active: false,
            elem: null,
            dragging: false
        };
    }

    clone(into: Transaction | any, serialize: boolean) {

        into.x = this.x;
        into.y = this.y;
        into.tranName = this.tranName;

        if (serialize) {
            into.referenceId = this.referenceId;
        }
    }

    move(x: number, y: number): Transaction {
        this.x = x;
        this.y = y;
        return this;
    }

    get canGoto(): boolean {
        return false;
    }

    get terminals(): ITerminal[] {
        return this.getTerminals();
    }

    get audios(): Audio[] {
        return this.getAudios();
    }

    protected getAudios(): Audio[] {
        return [];
    }

    protected getTerminals(): ITerminal[] {
        return [];
    }

    protected makeTerminal(ident: string, type: TerminalType, ext: ITerminalSettings = null): ITerminal {

        const terminal: ITerminal = {
            node: this,
            ident: ident,
            type: type,
            target: null,
            incoming: [],

            ghost: false,
            active: true,
            text: null,
            meta: {},
            direction: TerminalDirection.Down
        };

        if (ext) {
            terminal.ghost = ext.ghost || false;
            terminal.active = ext.active || true;
            terminal.text = ext.text || null;
            terminal.meta = ext.meta || {};
            terminal.direction = ext.direction || TerminalDirection.Down;
        }

        return terminal;
    }

    get isStartingTransaction(): boolean {
        return false;
    }

    get type(): string {
        if (typenameSymbol in this)
            return this[typenameSymbol];
        return Transaction.typename(this.constructor);
    }

    get baseHeight(): number {
        return 200;
    }

    addValidator(validator: Validator) {
        this.validation.add(validator);
    }

    update() {

    }

    static typename(that: any = null) {
        const tran = that ?? this;
        if (typenameSymbol in tran)
            return tran[typenameSymbol];
        return getTransactionTypename(tran);
    }

    static templateName(): string {
        return this.typename();
    }

}

export abstract class CommonTransaction extends Transaction {

    inbound: ITerminal;

    protected constructor(public ident: number) {
        super();
        this.isSystemTransaction = false;

        this.inbound = this.makeTerminal("inbound", TerminalType.Inbound);
        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => Texts.validationMissingInboundConnection,
            () => {
                return this.inbound.incoming.length > 0;
            }));
    }

    protected getTerminals(): ITerminal[] {
        return [this.inbound];
    }

    get outboundTerminals(): ITerminal[] {
        return this.getOutboundTerminals();
    }

    get outboundTerminalsReady(): boolean {

        for (let ot of this.outboundTerminals) {
            const ready = !!ot.target || ot.ghost;
            if (!ready)
                return false;
        }

        return true;
    }

    get hasIncoming(): boolean {
        return this.inbound.incoming.length > 0;
    }

    clone(into: CommonTransaction, serialize: boolean) {
        super.clone(into, serialize);
        into.tagId = this.tagId;

        if (!serialize) {
            into.inbound = cloneTerminal(this.inbound, into);
        }
    }

    protected abstract getOutboundTerminals(): ITerminal[];

    get bodyText(): string {
        return "";
    }

    getTerminalText(terminal: ITerminal, index: number): string {
        return terminal.ident;
    }

}

export abstract class SmsBaseTransaction extends CommonTransaction {

    branches: ITerminal[] = [];

    protected constructor(ident: number) {
        super(ident);

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => {
                for (let terminal of this.branches) {
                    if (terminal.target === null)
                        return `Missing outbound connection for ${terminal.ident}`;
                }
                return "";
            },
            () => {
                for (let terminal of this.branches) {
                    if (terminal.target === null)
                        return false;
                }
                return true;
            }));
    }

    protected getOutboundTerminals() {
        return this.branches;
    }

    protected getTerminals() {
        return [this.inbound, ...this.branches];
    }

    private findBranch(root: string) {
        const terminal = _.find(this.branches, (branch: ITerminal) => branch.ident === root);
        return terminal ? terminal : null;
    }

    addBranch(root: string) {
        root = root.toLowerCase();
        let terminal = this.findBranch(root);
        if (!terminal) {
            terminal = this.makeTerminal(root,
                TerminalType.Outbound,
                {
                    meta: [],
                    text: root,
                    direction: TerminalDirection.Right
                });
            this.branches.push(terminal);
        }
        return terminal;
    }

    addBranchAlias(root: string, alias: string) {
        root = root.toLowerCase();
        alias = alias.toLowerCase();

        const terminal = this.findBranch(root);
        if (!terminal) return false;

        const meta = terminal.meta as string[];
        if (!_.includes(meta, alias)) {
            meta.push(alias);
            return true;
        }

        return false;
    }

    deleteBranch(root: string) {
        root = root.toLowerCase();

        const terminal = this.findBranch(root);
        if (terminal)
            return array.removeItem(this.branches, terminal);
        return false;
    }

    deleteBranchAlias(root: string, alias: string) {

        root = root.toLowerCase();
        alias = alias.toLowerCase();

        const terminal = this.findBranch(root);
        if (!terminal) return false;

        const meta = terminal.meta as string[];
        const idx = _.indexOf(meta, alias);
        if (idx > -1) {
            meta.splice(idx, 1);
            return true;
        }

        return false;
    }

    get baseHeight(): number {
        return 280;
    }
}

export abstract class VoiceBaseTransaction extends CommonTransaction {

    audio: Audio;

    protected constructor(ident: number) {
        super(ident);

        this.audio = new Audio();

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => Texts.validationMissingTtsText,
            () => {
                if (this.audio.useTts && this.audio.play)
                    return this.audio.tts && this.audio.tts.length > 0;
                return true;
            }));

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => "TTS Text missing on pre prompt",
            () => {
                if (this.audio.useMixedMode && this.audio.prePromptSegments.length) {
                    for (let i = 0; i < this.audio.prePromptSegments.length; ++i) {
                        const segment = this.audio.prePromptSegments[i];
                        if (segment.useTts && segment.play) {
                            const isValid = segment.tts && segment.tts.length > 0;
                            if (!isValid)
                                return false;
                        }
                    }
                    return true;
                }
                return true;
            }));

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => "Invalid WAV file in pre prompt",
            () => {
                if (this.audio.useMixedMode && this.audio.prePromptSegments.length) {
                    for (let i = 0; i < this.audio.prePromptSegments.length; ++i) {
                        const segment = this.audio.prePromptSegments[i];
                        if (!segment.useTts && segment.play) {
                            const isValid = segment.wav && segment.wav.length > 0;
                            if (!isValid)
                                return false;
                        }
                    }
                    return true;
                }
                return true;
            }));

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => "Invalid Wave File",
            () => {
                if (!this.audio.useTts && this.audio.play)
                    return this.audio.wav && this.audio.wav.length > 0;
                return true;
            }));

    }

    protected getAudios(): Audio[] {
        const audios = super.getAudios();
        audios.push(this.audio);
        return audios;
    }

    protected getOutboundTerminals(): ITerminal[] {
        return _.without(this.getTerminals(), this.inbound);
    }

    clone(into: VoiceBaseTransaction, serialize: boolean) {
        super.clone(into, serialize);
        into.audio = this.audio.clone();
    }

    get bodyText(): string {
        let tts = this.audio.tts ?? "";

        if (!tts)
            return "";

        const max = 200;
        tts = `${tts}`;
        if (tts.length > max)
            tts = tts.substr(0, max) + "...";
        return tts;
    }

    get baseHeight(): number {
        return 280;
    }

    get audioRequired(): boolean {
        return true;
    }
}

export abstract class VoiceInputTransaction extends VoiceBaseTransaction {

    // On Error
    maxTransactionAttempts: number | null = null;
    errorRetryCount: number | null = null;
    errorHandlerIdent: number = 0;

    // On No Response
    longPauseRetryCount: number = 2;
    noResponseHandlerIdent: number = 0;
    dtmfTimeout: number | null = null;

    clone(into: VoiceInputTransaction, serialize: boolean) {
        super.clone(into, serialize);

        into.errorRetryCount = this.errorRetryCount;
        into.longPauseRetryCount = this.longPauseRetryCount;
        into.errorHandlerIdent = this.errorHandlerIdent;
        into.noResponseHandlerIdent = this.noResponseHandlerIdent;
        into.dtmfTimeout = this.dtmfTimeout;
        into.maxTransactionAttempts = this.maxTransactionAttempts;
    }
}

function makeDefaultOkTerminal(transaction: Transaction): ITerminal {
    return {
        node: transaction,
        ident: "ok",
        type: TerminalType.Outbound,
        target: null,
        incoming: [],
        ghost: true,
        active: true,
        text: "",
        color: "#4CAF50"
    };
}

function makeDefaultFailTerminal(transaction: Transaction): ITerminal {
    return {
        node: transaction,
        ident: "fail",
        type: TerminalType.Outbound,
        target: null,
        incoming: [],
        ghost: true,
        active: true,
        text: "",
        color: "#D32F2F"
    };
}


class FailingVoiceInputTransaction extends VoiceInputTransaction {
    okTerminal: ITerminal;
    failTerminal: ITerminal;

    constructor(ident: number) {
        super(ident);

        this.okTerminal = makeDefaultOkTerminal(this);
        this.failTerminal = makeDefaultFailTerminal(this);

        this.addValidator(new Validator(Severity.Error, () => this.tranName,
            () => Texts.validationMissingOutboundConnection, () => {
                return !!this.okTerminal.target;
            }));

        this.addValidator(new Validator(Severity.Error, () => this.tranName,
            () => Texts.validationMissingOutboundConnection, () => {
                return !!this.failTerminal.target;
            }));
    }
}

// Exported Inner Types

export class TransactionGroup {

    readonly transactions: Transaction[] = [];

    constructor(transactions: Transaction[]) {
        this.transactions = transactions;
    }

    serialize() {

        const product: any[] = [];
        for (const transaction of this.transactions) {

            const proto = {
                properties: {},
                ident: transaction.ident,
                typename: transaction.type
            };

            transaction.clone(proto.properties, true);
            product.push(proto);
        }

        return product;
    }

    static deserialize(obj: any): TransactionGroup {

        const transactions: Transaction[] = [];
        const product = obj as { properties: any[]; typename: string, ident: any }[];

        for (let idx = 0; idx < product.length; ++idx) {
            const proto = product[idx];
            const transaction = initTransaction(proto.typename, proto.ident);
            materializeTransaction(proto.properties, transaction);
            transactions.push(transaction);
        }
        return new TransactionGroup(transactions);
    }
}

export class NumberLogicBranch {

    ident: string;
    steps: ITokenStep<NumberLogicToken>[] = [];
    terminal: ITerminal;

    constructor(transaction: InputTransaction) {

        const ident = this.ident = uuid();

        this.steps.push({token: NumberLogicToken.If});
        this.steps.push({token: NumberLogicToken.ComparisonGT});
        this.steps.push({token: NumberLogicToken.Value, value: 1});
        this.steps.push({token: NumberLogicToken.End});

        this.terminal = {
            node: transaction,
            ident: ident,
            type: TerminalType.Outbound,
            target: null,
            incoming: [],
            ghost: false,
            active: true,
            text: ""
        };
    }

    change(step: number, token: NumberLogicToken) {

        const that = this;

        const current = this.steps[step];
        const cmps = [
            NumberLogicToken.ComparisonGT,
            NumberLogicToken.ComparisonLT,
            NumberLogicToken.ComparisonGTE,
            NumberLogicToken.ComparisonLTE,
            NumberLogicToken.ComparisonEQ
        ];

        function addContinue() {
            const used = _.intersection(_.map(that.steps, (s: any) => s.token), cmps);
            const unused = _.difference(cmps, used);

            let cmp = NumberLogicToken.ComparisonEQ;

            if (unused.length)
                cmp = (unused[0] as any);

            that.steps.push({token: cmp});
            that.steps.push({token: NumberLogicToken.Value, value: 1});

            if (!_.some(that.steps, s => s.token === NumberLogicToken.And))
                that.steps.push({token: NumberLogicToken.End});
        }

        if (_.includes(cmps, current.token) && _.includes(cmps, token)) {
            this.steps[step] = {token: token};
        }

        if (token === NumberLogicToken.And && current.token === NumberLogicToken.End) {
            this.steps[step] = {token: token};
            addContinue();
        }

        if (token === NumberLogicToken.End) {
            this.steps[step] = {token: token};
            this.steps.length = step + 1;
        }

        if (token === NumberLogicToken.If && current.token === NumberLogicToken.Else) {
            this.steps[step] = {token: token};
            addContinue();
        }

        if (token === NumberLogicToken.Else) {
            this.steps[0] = {token: token};
            this.steps.length = 1;
        }

    }

    script() {

        function decode(step: ITokenStep<NumberLogicToken>) {
            const variable = "[x]";
            const value = step.value;

            switch (step.token) {
                case NumberLogicToken.If:
                    return variable;
                case NumberLogicToken.Else:
                    return "else";
                case NumberLogicToken.And:
                    return `and ${variable}`;
                case NumberLogicToken.ComparisonGT:
                    return ">";
                case NumberLogicToken.ComparisonGTE:
                    return ">=";
                case NumberLogicToken.ComparisonLT:
                    return "<";
                case NumberLogicToken.ComparisonLTE:
                    return "<=";
                case NumberLogicToken.ComparisonEQ:
                    return "==";
                case NumberLogicToken.Value:
                    return value.toString();
            }

            return null;
        }

        return _.map(this.steps, decode).join(" ").trim();
    }

    static deserialize(transaction: InputTransaction, obj: any): NumberLogicBranch {
        const branch = new NumberLogicBranch(transaction);
        branch.terminal.ident = branch.ident = obj.ident;
        branch.steps = obj.steps;
        return branch;
    }
}

export class DateLogicBranch {

    ident: string;
    steps: ITokenStep<DateLogicToken>[] = [];

    validTerminal: ITerminal;
    invalidTerminal: ITerminal;

    constructor(transaction: InputTransaction, ident: string = null) {

        this.ident = ident;
        if (!this.ident) {
            this.ident = uuid();
        }

        this.steps.push({token: DateLogicToken.End});

        this.validTerminal = {
            node: transaction,
            ident: this.ident + "-valid",
            type: TerminalType.Outbound,
            target: null,
            incoming: [],
            ghost: false,
            active: false,
            text: "valid",
            color: "#4CAF50"
        };

        this.invalidTerminal = {
            node: transaction,
            ident: this.ident + "-invalid",
            type: TerminalType.Outbound,
            target: null,
            incoming: [],
            ghost: false,
            active: false,
            text: "invalid",
            color: "#D32F2F"
        };
    }

    change(step: number, token: DateLogicToken, value: number = null) {

        const current = this.steps[step];
        if (current.token === token) return;

        if (step === 0) {
            this.steps.length = 0;

            if (token === DateLogicToken.From) {
                this.steps.push({token: DateLogicToken.From});
                this.steps.push({token: DateLogicToken.Value, value: 10});
                this.steps.push({token: DateLogicToken.Days});
                this.steps.push({token: DateLogicToken.Future});
            } else {
                this.steps.push({token: DateLogicToken.End});
            }
        } else {
            this.steps[step].token = token;
        }
    }

}


// Internal State

type TransactionType = typeof Transaction | typeof VoiceBaseTransaction | typeof CommonTransaction;

interface IRegisteredTransaction {
    hasIdent: boolean;
    type: TransactionType;
}

function cloneTerminal(terminal: ITerminal, transaction: Transaction): ITerminal {

    return {
        ident: terminal.ident,
        node: transaction,

        type: terminal.type,

        target: null,
        incoming: [],

        ghost: terminal.ghost,
        active: terminal.active,
        text: terminal.text,
        color: terminal.color,
        meta: _.cloneDeep(terminal.meta || {}),
        direction: terminal.direction
    };
}

function emptyEndpoint(): IEndpointConfiguration {
    return {
        endpoint: "",
        bindings: [],
        method: "POST",
        username: "",
        password: ""
    };
}

function cloneEndpoint(endpoint: IEndpointConfiguration) {
    const target: any = {};

    target.endpoint = endpoint.endpoint;
    target.bindings = _.clone(endpoint.bindings);
    target.method = endpoint.method;
    target.username = endpoint.username;
    target.password = endpoint.password;

    return target;
}

// Base Transaction Types


export abstract class CompositeTransaction extends VoiceBaseTransaction {

    transactionGroup: TransactionGroup = new TransactionGroup([]);

    clone(into: any, serialize: boolean) {
        super.clone(into, serialize);

        const product = this.transactionGroup.serialize();
        if (serialize) into.transactionGroup = product;
        else into.transactionGroup = TransactionGroup.deserialize(product);
    }

    forward(idx: number): Transaction {
        return this.transactionGroup.transactions[idx];
    }
}


// Common Transactions

export class FlowTerminatingTransaction extends Transaction {
    get baseHeight(): number {
        return 122;
    }
}

export class EndTransaction extends FlowTerminatingTransaction {

    inbound: ITerminal;

    constructor() {
        super();

        this.ident = "end";
        this.inbound = this.makeTerminal("end", TerminalType.Inbound);
    }

    protected getTerminals() {
        const terms = super.getTerminals();
        terms.push(this.inbound);
        return terms;
    }

    clone(into: EndTransaction, serialize: boolean) {
        super.clone(into, serialize);

        if (!serialize) {
            into.inbound = cloneTerminal(this.inbound, into);
        }
    }

}


// Voice Transactions


export class StartTransaction extends FlowTerminatingTransaction {

    outbound: ITerminal;
    useAmd: boolean;
    machine: ITerminal;
    unknown: ITerminal;

    defaultsilenceThreshold: number = 256;
    defaultinitialSilence: number = 2500;
    defaultgreeting: number = 3500;
    defaultafterGreetingSilence: number = 1000;
    defaulttotalAnalysisTime: number = 6000;
    defaultminimumWordLength: number = 120;
    defaultbetweenWordsSilence: number = 50;
    defaultmaximumNumberOfWords: number = 5;
    defaultmaximumWordLength: number = 512;
    defaultavmdDuration: number = 3000;

    silenceThreshold: number;
    initialSilence: number;
    greeting: number;
    afterGreetingSilence: number;
    totalAnalysisTime: number;
    minimumWordLength: number;
    betweenWordsSilence: number;
    maximumNumberOfWords: number;
    maximumWordLength: number;
    avmdDuration: number;

    private initOutboundTerminal(ident: string): ITerminal {
        return {
            node: this,
            ident: ident,
            type: TerminalType.Outbound,
            target: null,
            incoming: [],
            ghost: false,
            active: true,
            text: null
        };
    }

    private initAmdTerminals(): ITerminal[] {
        return [
            this.initOutboundTerminal("machine"),
            this.initOutboundTerminal("unknown")
        ];
    }

    constructor() {
        super();

        this.silenceThreshold = this.defaultsilenceThreshold;
        this.initialSilence = this.defaultinitialSilence;
        this.greeting = this.defaultgreeting;
        this.afterGreetingSilence = this.defaultafterGreetingSilence;
        this.totalAnalysisTime = this.defaulttotalAnalysisTime;
        this.minimumWordLength = this.defaultminimumWordLength;
        this.betweenWordsSilence = this.defaultbetweenWordsSilence;
        this.maximumNumberOfWords = this.defaultmaximumNumberOfWords;
        this.maximumWordLength = this.defaultmaximumWordLength;
        this.avmdDuration = this.defaultavmdDuration;

        this.ident = "start";
        this.useAmd = false;

        this.outbound = this.initOutboundTerminal(this.ident);
        [this.machine, this.unknown] = this.initAmdTerminals();

        this.addValidator(new Validator(Severity.Error,
            () => "Call Start Module",
            () => Texts.validationMissingOutboundConnection,
            () => {
                let ok = true;
                if (this.useAmd) {
                    ok = this.machine.target !== null;
                    ok = ok && this.unknown.target !== null;
                }
                return ok && this.outbound.target !== null;
            }));
    }

    protected getTerminals() {
        const terms = super.getTerminals();
        terms.push(this.outbound);
        terms.push(this.machine);
        terms.push(this.unknown);
        return terms;
    }

    clone(into: StartTransaction, serialize: boolean) {
        super.clone(into, serialize);
        const amd = into.useAmd = !!this.useAmd;


        // Code-dedupe plugin gets confused by the following assignments too being so similar.

        // noinspection DuplicatedCode
        into.silenceThreshold = this.silenceThreshold || this.defaultsilenceThreshold;
        into.initialSilence = this.initialSilence || this.defaultinitialSilence;
        into.greeting = this.greeting || this.defaultgreeting;
        into.afterGreetingSilence = this.afterGreetingSilence || this.defaultafterGreetingSilence;
        into.totalAnalysisTime = this.totalAnalysisTime || this.defaulttotalAnalysisTime;

        // noinspection DuplicatedCode
        into.minimumWordLength = this.minimumWordLength || this.defaultminimumWordLength;
        into.betweenWordsSilence = this.betweenWordsSilence || this.defaultbetweenWordsSilence;
        into.maximumNumberOfWords = this.maximumNumberOfWords || this.defaultmaximumNumberOfWords;
        into.maximumWordLength = this.maximumWordLength || this.defaultmaximumWordLength;
        into.avmdDuration = this.avmdDuration || this.defaultavmdDuration;

        if (!serialize) {
            into.outbound = cloneTerminal(this.outbound, into);
            if (amd) {
                into.machine = cloneTerminal(this.machine, into);
                into.unknown = cloneTerminal(this.unknown, into);
            } else {
                [into.machine, into.unknown] = this.initAmdTerminals();
            }
        }
    }

    get isStartingTransaction(): boolean {
        return true;
    }
}


export class InformationalTransaction extends VoiceBaseTransaction {

    outbound:
        ITerminal;

    constructor(ident: number) {
        super(ident);

        this.tranName = `Info ${ident}`;
        this.outbound = {
            node: this,
            ident: "out",
            type: TerminalType.Outbound,
            target: null,
            incoming: [],
            ghost: true,
            active: true,
            text: ""
        };

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => Texts.validationMissingOutboundConnection,
            () => {
                return !!this.outbound.target;
            }));
    }

    protected getTerminals():
        ITerminal[] {
        const terminals = super.getTerminals();
        terminals.push(this.outbound);
        return terminals;
    }

    clone(into: InformationalTransaction, serialize: boolean) {
        super.clone(into, serialize);

        if (!serialize) {
            into.outbound = cloneTerminal(this.outbound, into);
        }
    }

    getTerminalText(terminal: ITerminal, index: number): string {
        return "";
    }

    get canGoto() {
        return true;
    }

}

export abstract class HelperVoiceInputTransaction extends VoiceInputTransaction {
    // Help and fast input options
    helperButton: string | null = null;

    clone(into: HelperVoiceInputTransaction, serialize: boolean) {
        super.clone(into, serialize);
        into.helperButton = this.helperButton;
    }
}

export class MenuTransaction extends HelperVoiceInputTransaction {

    outbound: ITerminal[] = [];

    constructor(ident: number) {
        super(ident);

        this.canBeReferenced = true;
        this.tranName = `Menu ${ident}`;

        const validkeys: string[] = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "*", "#"];

        for (const key of validkeys) {
            this.outbound.push({
                node: this,
                ident: key,
                type: TerminalType.Outbound,
                target: null,
                incoming: [],
                ghost: true,
                active: false,
                text: ""
            });
        }

        const first = this.outbound[0];
        first.active = true;
        first.ghost = false;

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => Texts.validationMissingOutboundConnection,
            () => {
                return _.every(_.filter(this.outbound,
                        (n: ITerminal) => !n.ghost),
                    (n: ITerminal) => n.target !== null);
            }));


        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => Texts.validationNotAllKeysHaveValues,
            () => {
                const active = _.filter(this.outbound, (n:
                                                            ITerminal) => !n.ghost);
                const some = _.some(active, (t: ITerminal) => !!t.text);
                const all = _.every(active, (t: ITerminal) => !!t.text);
                return !(some && !all);

            }));

        this.addValidator(new Validator(Severity.Warning,
            () => this.tranName,
            () => Texts.validationKeyValuesMissing,
            () => {
                const active = _.filter(this.outbound, (n:
                                                            ITerminal) => !n.ghost);
                return !_.some(active, (t: ITerminal) => !t.text);
            }));
    }

    get outboundTerminals() {
        const list: ITerminal[] = [];

        for (let i = 0; i < this.outbound.length; ++i) {
            const terminal: ITerminal = this.outbound[i];
            if (terminal.active) {
                list.push(terminal);
            }
        }

        for (let i = 0; i < this.outbound.length; ++i) {
            const terminal = this.outbound[i];
            if (!terminal.active && terminal.ghost && terminal.ident !== this.helperButton) {
                list.push(terminal);
                break;
            }
        }
        return list;
    }

    get canGoto() {
        return true;
    }

    protected getTerminals(): ITerminal[] {
        return super.getTerminals().concat(this.outbound);
    }

    clone(into: MenuTransaction, serialize: boolean) {
        super.clone(into, serialize);

        if (serialize)
            return;

        into.outbound = _.map(this.outbound, (t: ITerminal) => cloneTerminal(t, into));
    }

}
export class InputTransaction extends HelperVoiceInputTransaction {

    subtype: InputTransactionSubtype;

    numberLogicBranches: NumberLogicBranch[] = [];
    dateLogicBranch: DateLogicBranch;
    outTerminal: ITerminal;

    dateInputFormat: DateInputFormat;

    minDigits: number = 1;
    maxDigits: number = 10;
    endButton: string = null;

    constructor(ident: number) {
        super(ident);

        this.canBeReferenced = true;
        this.tranName = `Input ${ident}`;
        this.subtype = InputTransactionSubtype.Number;
        this.dateLogicBranch = new DateLogicBranch(this);

        this.outTerminal = {
            node: this,
            ident: "output",
            type: TerminalType.Outbound,
            target: null,
            incoming: [],
            ghost: false,
            active: false,
            text: "OUT"
        };

        const defaultBranch = this.addNumberLogicBranch();
        defaultBranch.change(0, NumberLogicToken.Else);

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => "Inputs must define at least one branch",
            () => {
                if (this.subtype === InputTransactionSubtype.Number) {
                    return this.numberLogicBranches.length > 0;
                }
                return true;
            }));

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => "Branch definitions must be unique",
            () => {
                const scripts = _.map(this.numberLogicBranches, (b) => b.script());
                return scripts.length === _.uniq(scripts).length;
            }));

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => "Not all outbound branches are connected",
            () => {
                return _.every(this.outbound(), t => t.target);
            }));

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => "Digit count configuration invalid",
            () => {
                return this.minDigits <= this.maxDigits && this.minDigits >= 0;
            }));

    }

    clone(into: InputTransaction, serialize: boolean) {
        super.clone(into, serialize);

        into.subtype = this.subtype;
        into.dateInputFormat = this.dateInputFormat;
        into.minDigits = this.minDigits;
        into.maxDigits = this.maxDigits;
        into.maxTransactionAttempts = this.maxTransactionAttempts;
        into.endButton = this.endButton;

        function cloneBranch(branch: NumberLogicBranch) {
            const sbranch: any = serialize ? {} : new NumberLogicBranch(into);
            const ident = serialize ? branch.ident : uuid();

            sbranch.ident = ident;
            sbranch.steps = clearNgHashes(_.cloneDeep(branch.steps));

            if (!serialize) {
                sbranch.terminal = cloneTerminal(branch.terminal, into);
                sbranch.terminal.ident = ident;
            }

            return sbranch;
        }

        into.numberLogicBranches = [];
        if (this.subtype === InputTransactionSubtype.Number) {
            into.numberLogicBranches = _.map(this.numberLogicBranches, b => cloneBranch(b));
        }

        if (this.subtype === InputTransactionSubtype.Date) {

            const ident = serialize ? this.dateLogicBranch.ident : uuid();
            const temp: any = serialize ? {} : new DateLogicBranch(into, ident);

            temp.ident = ident;
            temp.steps = clearNgHashes(_.cloneDeep(this.dateLogicBranch.steps));

            into.dateLogicBranch = temp;
        }

        if (this.subtype === InputTransactionSubtype.Any) {
            if (!serialize) {
                into.outTerminal = cloneTerminal(this.outTerminal, into);
            }
        }

    }

    addNumberLogicBranch() {
        const branch = new NumberLogicBranch(this);
        this.numberLogicBranches.push(branch);
        return branch;
    }

    removeNumberLogicBranch(branch: NumberLogicBranch) {
        const idx = this.numberLogicBranches.indexOf(branch);
        if (idx > -1) {
            this.numberLogicBranches.splice(idx, 1);
        }
    }

    clear() {
        this.numberLogicBranches = [];
    }

    outbound(): ITerminal[] {

        if (this.subtype === InputTransactionSubtype.Number) {
            return _.map(this.numberLogicBranches, (b) => b.terminal);
        }

        if (this.subtype === InputTransactionSubtype.Date) {
            return [this.dateLogicBranch.validTerminal, this.dateLogicBranch.invalidTerminal];
        }

        if (this.subtype === InputTransactionSubtype.Any) {
            return [this.outTerminal];
        }

        return [];
    }

    protected getTerminals(): ITerminal[] {
        return super.getTerminals().concat(this.outbound());
    }

    getTerminalText(terminal: ITerminal, index: number): string {
        if (this.subtype === InputTransactionSubtype.Number) return (index + 1).toString();
        return "";
    }
}
export class TransferTransaction extends VoiceBaseTransaction {

    number: string = "";
    agentAudio: Audio;

    constructor(ident: number) {
        super(ident);

        this.tranName = `Transfer ${ident}`;
        this.agentAudio = new Audio();
        this.agentAudio.play = false;

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => "Missing Agent TTS message",
            () => {
                if (this.agentAudio.useTts && this.agentAudio.play)
                    return this.agentAudio.tts && this.agentAudio.tts.length > 0;
                return true;
            }));

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => "You must assign a phone number for a transfer",
            () => {
                return !(!this.number || this.number.length === 0);
            }));

    }

    protected getAudios(): Audio[] {
        const audios = super.getAudios();
        audios.push(this.agentAudio);
        return audios;
    }

    clone(into: TransferTransaction, serialize: boolean) {
        super.clone(into, serialize);

        into.agentAudio = this.agentAudio.clone();
        into.number = this.number;
    }

    get bodyText() {
        return this.number;
    }

    get canGoto() {
        return true;
    }

    get audioRequired(): boolean {
        return false;
    }
}
export class RecordTransaction extends VoiceInputTransaction {

    outbound: ITerminal;
    maxDuration: number = 30;
    maxSilence: number = 5;
    terminationKey: string = "any";
    playBeep: boolean = false;

    constructor(ident: number) {
        super(ident);

        this.tranName = `Record ${ident}`;

        this.outbound = {
            node: this,
            ident: "out",
            type: TerminalType.Outbound,
            target: null,
            incoming: [],
            ghost: true,
            active: true,
            text: ""
        };

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => Texts.validationMissingOutboundConnection,
            () => {
                return !!this.outbound.target;
            }));

    }

    protected getTerminals(): ITerminal[] {
        const terminals = super.getTerminals();
        terminals.push(this.outbound);
        return terminals;
    }

    clone(into: any, serialize: boolean) {
        super.clone(into, serialize);

        into.maxDuration = this.maxDuration;
        into.maxSilence = this.maxSilence;
        into.terminationKey = this.terminationKey;
        into.playBeep = !!this.playBeep;

        if (serialize)
            return;

        into.outbound = cloneTerminal(this.outbound, into);
    }

    getTerminalText(terminal: ITerminal, index: number): string {
        return "";
    }
}

function addApiTransactionValidators(transaction: ApiTransaction | SmsApiTransaction) {
    transaction.addValidator(new Validator(Severity.Error,
        () => transaction.tranName,
        () => "Endpoint URL is not specified",
        () => {
            return transaction.url && transaction.url.length > 0;
        }));

    transaction.addValidator(new Validator(Severity.Error,
        () => transaction.tranName,
        () => "Endpoint URL Invalid Format",
        () => {
            if (transaction.url && transaction.url.length > 0) {
                return isValidUrl(transaction.url);
            }
            return false;
        }));

    transaction.addValidator(new Validator(Severity.Error,
        () => transaction.tranName,
        () => "A response binding parameter is not set",
        () => {
            return _.every(transaction.bindings,
                binding => {
                    if (!binding.parameterName) return false;
                    return binding.parameterName.length !== 0;

                });
        }));

    transaction.addValidator(new Validator(Severity.Error,
        () => transaction.tranName,
        () => "A request binding parameter is not set",
        () => {
            return _.every(transaction.requestBindings,
                binding => {
                    if (!binding.parameterName) return false;
                    return binding.parameterName.length !== 0;

                });
        }));

    transaction.addValidator(new Validator(Severity.Warning,
        () => transaction.tranName,
        () => "Variable set multiple times",
        () => {
            const ids = _.map(transaction.bindings, b => b.variableId);
            return ids.length === _.uniq(ids).length;
        }));
}

export class ApiTransaction extends FailingVoiceInputTransaction {

    url: string = "";
    method: string = "GET";

    username: string = null;
    password: string = null;

    bindings: IApiBinding[] = [];
    requestBindings: IRequestApiBinding[] = [];

    constructor(ident: number) {
        super(ident);

        this.tranName = `API ${ident}`;
        this.audio.play = false;

        addApiTransactionValidators(this);
    }

    protected getTerminals(): ITerminal[] {
        const terminals = super.getTerminals();
        terminals.push(this.okTerminal);
        terminals.push(this.failTerminal);
        return terminals;
    }

    clone(into: any, serialize: boolean) {
        super.clone(into, serialize);

        into.url = this.url;
        into.method = this.method;
        into.username = this.username;
        into.password = this.password;

        into.bindings = _.cloneDeep(this.bindings);
        into.requestBindings = _.cloneDeep(this.requestBindings || []);

        if (!serialize) {
            into.okTerminal = cloneTerminal(this.okTerminal, into);
            into.failTerminal = cloneTerminal(this.failTerminal, into);
        }
    }

    getTerminalText(terminal: ITerminal, index: number): string {
        return "";
    }
}
export class CreditcardTransaction extends FailingVoiceInputTransaction {

    readBackInput: boolean = false;
    expireAudio: Audio;
    cvvAudio: Audio;

    anyCard: boolean = true;
    amex: boolean = true;
    visa: boolean = true;
    mastercard: boolean = true;
    discovery: boolean = true;
    diners: boolean = true;
    jcb: boolean = true;

    url: string = "";
    method: string = "POST";
    username: string = null;
    password: string = null;

    merchant: CreditCardTransactionMerchantFacility = null;

    bindings: IApiBinding[] = [];
    requestBindings: IRequestApiBinding[] = [];

    constructor(ident: number) {
        super(ident);

        this.canBeReferenced = true;
        this.tranName = `Credit Card ${ident}`;
        this.dtmfTimeout = 12;

        this.audio.tts = "Please enter your credit card number followed by the hash key.";

        this.expireAudio = new Audio();
        this.expireAudio.tts = "Please enter expiration date as month month year year on your key pad followed " +
            "by the hash key. For example, January 2019 would be, zero, one, one, nine, hash.";

        this.cvvAudio = new Audio();
        this.cvvAudio.tts = "Please enter your three digit CVV number or four digits for American Express" +
            " on your key pad, followed by the hash key.";

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => "Select at least one accepted card type.",
            () => {

                if (this.anyCard)
                    return true;

                return this.amex || this.visa || this.mastercard || this.discovery || this.diners || this.jcb;
            })
        );


        this.changeMerchant(CreditCardTransactionMerchantFacility.Custom);
    }

    changeMerchant(merchant: CreditCardTransactionMerchantFacility) {

        if (this.merchant === merchant)
            return;
        this.merchant = merchant;
        if (merchant === CreditCardTransactionMerchantFacility.Custom)
            this.requestBindings.length = 0;
    }

    clone(into: CreditcardTransaction, serialize: boolean) {
        super.clone(into, serialize);

        into.readBackInput = this.readBackInput;
        into.expireAudio = this.expireAudio.clone();
        into.cvvAudio = this.cvvAudio.clone();

        into.anyCard = this.anyCard;
        into.amex = this.amex;
        into.visa = this.visa;
        into.mastercard = this.mastercard;
        into.discovery = this.discovery;
        into.diners = this.diners;
        into.jcb = this.jcb;

        into.url = this.url;
        into.method = this.method;
        into.username = this.username;
        into.password = this.password;

        into.bindings = _.cloneDeep(this.bindings);
        into.requestBindings = _.cloneDeep(this.requestBindings || []);
        into.merchant = this.merchant;

        if (!serialize) {
            into.okTerminal = cloneTerminal(this.okTerminal, into);
            into.failTerminal = cloneTerminal(this.failTerminal, into);
        }
    }

    protected getTerminals():
        ITerminal[] {
        return super.getTerminals().concat([this.okTerminal, this.failTerminal]);
    }

    get baseHeight(): number {
        return 200;
    }
}

export class BiometricsTransaction extends FailingVoiceInputTransaction {

    authenticationMode: boolean = true;

    constructor(ident: number) {
        super(ident);
        this.tranName = `BIOMETRICS ${ident}`;
        this.audio.tts = "After the beep, please say, My voice is my password";
    }

    protected getTerminals(): ITerminal[] {
        const terminals = super.getTerminals();
        terminals.push(this.okTerminal);
        terminals.push(this.failTerminal);
        return terminals;
    }

    clone(into: any, serialize: boolean) {
        super.clone(into, serialize);

        into.authenticationMode = this.authenticationMode;

        if (!serialize) {
            into.okTerminal = cloneTerminal(this.okTerminal, into);
            into.failTerminal = cloneTerminal(this.failTerminal, into);
        }
    }

}

export class BotTransaction extends CompositeTransaction {

    fail: ITerminal;
    brokenLoop: ITerminal;
    outbound: ITerminal[] = [];

    repromptAudio: Audio = new Audio();
    breakLoop: boolean = false;
    languageCode: string = "en-US";
    context: string = "";

    constructor(ident: number) {
        super(ident);

        this.tranName = `Bot ${ident}`;

        this.audio.tts = "Hello, ask me anything.";
        this.repromptAudio.tts = "Go ahead, ask me anything";

        this.fail = {
            node: this,
            ident: "fail",
            type: TerminalType.Outbound,
            target: null,
            incoming: [],
            ghost: true,
            active: true,
            text: "",
            color: "#D32F2F"
        };

        this.brokenLoop = {
            node: this,
            ident: "ok",
            type: TerminalType.Outbound,
            target: null,
            incoming: [],
            ghost: false,
            active: true,
            text: "",
            color: "#4CAF50"
        };

        for (let i = 0; i < 10; ++i) {
            this.outbound.push({
                node: this,
                ident: (i + 1).toString(),
                type: TerminalType.Outbound,
                target: null,
                incoming: [],
                ghost: false,
                active: false,
                text: "",
                color: ""
            });
        }

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => Texts.validationMissingOutboundConnection,
            () => {
                return !!this.fail.target;
            }));

        this.transactionGroup = this.initTransactionGroup();
    }

    private initTransactionGroup() {

        const record = new RecordTransaction(1);
        record.maxSilence = 3;
        record.maxDuration = 10;

        const api = new ApiTransaction(2);
        const info = new InformationalTransaction(3);
        info.audio.tts = "{{speechReceived}}";

        return new TransactionGroup([record, api, info]);
    }

    protected getTerminals(): ITerminal[] {
        const terminals = super.getTerminals();

        const other: ITerminal[] = [];
        if (this.breakLoop)
            other.push(this.brokenLoop);
        other.push(this.fail);

        return terminals.concat(other, this.outbound);
    }

    get outboundTerminals() {

        const list: ITerminal[] = [];

        if (this.breakLoop)
            list.push(this.brokenLoop);

        list.push(this.fail);

        for (let i = 0; i < this.outbound.length; ++i) {
            const terminal: ITerminal = this.outbound[i];
            if (terminal.active) {
                list.push(terminal);
            }
        }

        return list;
    }

    clone(into: any, serialize: boolean) {
        super.clone(into, serialize);

        into.repromptAudio = this.repromptAudio.clone();
        into.breakLoop = this.breakLoop;
        into.languageCode = this.languageCode || "en-US";
        into.context = this.context || "";

        if (!serialize) {
            into.fail = cloneTerminal(this.fail, into);
            into.brokenLoop = cloneTerminal(this.brokenLoop, into);
            into.outbound = _.map(this.outbound, (t: ITerminal) => cloneTerminal(t, into));
        }

    }

}

export class VoiceItTransaction extends FailingVoiceInputTransaction {

    trainingMode: boolean = false;
    customerNumberToken: string = "mobile number";

    getCustomerEndpoint = emptyEndpoint();
    createCustomerEndpoint = emptyEndpoint();

    endpointCustomerIdBinding: string;
    endpointCustomerVoiceItBinding: string;

    voiceItApiKey: string;
    voiceItApiToken: string;
    voiceItSaying: string;
    voiceItContentLanguage: string = "en-US";

    beeps: boolean = true;

    customerNumberRetries: number = 3;
    verificationRetries: number = 3;
    enrollments: number = 3;
    dtmfTimeoutInSeconds: number = 5;

    constructor(ident: number) {
        super(ident);

        this.tranName = `VoiceIt ${ident}`;
        this.audio.tts = "(VoiceIt)";
    }

    protected getTerminals(): ITerminal[] {
        const terminals = super.getTerminals();
        terminals.push(this.okTerminal);
        terminals.push(this.failTerminal);
        return terminals;
    }

    clone(into: any, serialize: boolean) {
        super.clone(into, serialize);

        into.trainingMode = this.trainingMode;
        into.customerNumberToken = this.customerNumberToken;
        into.getCustomerEndpoint = cloneEndpoint(this.getCustomerEndpoint);
        into.createCustomerEndpoint = cloneEndpoint(this.createCustomerEndpoint);

        into.endpointCustomerIdBinding = this.endpointCustomerIdBinding;
        into.endpointCustomerVoiceItBinding = this.endpointCustomerVoiceItBinding;

        into.voiceItApiKey = this.voiceItApiKey;
        into.voiceItApiToken = this.voiceItApiToken;
        into.voiceItSaying = this.voiceItSaying;
        into.voiceItContentLanguage = this.voiceItContentLanguage;

        into.beeps = this.beeps;

        let that: VoiceItTransaction = this;
        if (!this.hasOwnProperty("enrollments")) {
            that = new VoiceItTransaction(0);
        }
        into.customerNumberRetries = that.customerNumberRetries;
        into.verificationRetries = that.verificationRetries;
        into.enrollments = that.enrollments;
        into.dtmfTimeoutInSeconds = that.dtmfTimeoutInSeconds;

        if (!serialize) {
            into.okTerminal = cloneTerminal(this.okTerminal, into);
            into.failTerminal = cloneTerminal(this.failTerminal, into);
        }
    }

}





// SMS Transactions


export class SmsStartTransaction extends FlowTerminatingTransaction {

    outbound: ITerminal;

    constructor() {
        super();

        this.ident = "sms-start";

        this.outbound = {
            node: this,
            ident: this.ident,
            type: TerminalType.Outbound,
            target: null,
            incoming: [],
            ghost: false,
            active: true,
            text: null
        };

        this.addValidator(new Validator(Severity.Error,
            () => "SMS Start node",
            () => "The start node must be connected",
            () => !!this.outbound.target
        ));
    }

    protected getTerminals() {
        const terminals = super.getTerminals();
        terminals.push(this.outbound);
        return terminals;
    }

    clone(into: SmsStartTransaction, serialize: boolean) {
        super.clone(into, serialize);

        if (!serialize) {
            into.outbound = cloneTerminal(this.outbound, into);
        }
    }

    get isStartingTransaction(): boolean {
        return true;
    }

}


export class SmsMessageTransaction extends SmsBaseTransaction {

    text: string = "Enter your message here";
    useRawInputMode: boolean = false;
    delay: number = null;

    constructor(ident: number) {
        super(ident);

        this.tranName = `SMS ${ident.toString()}`;
        this.canBeReferenced = true;
        this.isSystemTransaction = false;

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => "The SMS message must not be empty",
            () => {
                return this.text && this.text.length > 0;
            }));
    }

    clone(into: SmsMessageTransaction, serialize: boolean) {

        super.clone(into, serialize);

        into.text = this.text;
        into.delay = this.delay;
        into.useRawInputMode = this.useRawInputMode;

        if (!serialize) {
            const branches: ITerminal[] = into.branches = [];
            for (let branch of this.branches)
                branches.push(cloneTerminal(branch, into));
        }
    }

}


export class SmsApiTransaction extends CommonTransaction {

    inbound: ITerminal;

    okTerminal: ITerminal;
    failTerminal: ITerminal;

    url: string = "";
    method: string = "GET";

    username: string = null;
    password: string = null;

    bindings: IApiBinding[] = [];
    requestBindings: IRequestApiBinding[] = [];

    constructor(ident: number) {
        super(ident);

        this.tranName = `API ${ident}`;
        this.canBeReferenced = true;

        this.inbound = this.makeTerminal("inbound", TerminalType.Inbound);
        this.okTerminal = makeDefaultOkTerminal(this);
        this.failTerminal = makeDefaultFailTerminal(this);

        this.addValidator(new Validator(Severity.Error,
            () => this.tranName,
            () => Texts.validationMissingOutboundConnection,
            () => {
                return !!this.okTerminal.target;
            }));

        addApiTransactionValidators(this);
    }

    getOutboundTerminals(): ITerminal[] {
        return [
            this.okTerminal,
            this.failTerminal
        ];
    }

    protected getTerminals():
        ITerminal[] {
        const terminals = super.getTerminals();
        terminals.push(this.inbound);
        terminals.push(this.okTerminal);
        terminals.push(this.failTerminal);
        return terminals;
    }

    clone(into: SmsApiTransaction, serialize: boolean) {
        super.clone(into, serialize);

        into.url = this.url;
        into.method = this.method;
        into.bindings = _.cloneDeep(this.bindings);
        into.requestBindings = _.cloneDeep(this.requestBindings || []);
        into.username = this.username;
        into.password = this.password;

        if (!serialize) {
            into.okTerminal = cloneTerminal(this.okTerminal, into);
            into.failTerminal = cloneTerminal(this.failTerminal, into);
        }
    }

    static templateName(): string {
        return "api";
    }
}


export class SmsBranchTransaction extends SmsBaseTransaction {

    constructor(ident: number) {
        super(ident);

        this.tranName = `Branch ${ident.toString()}`;
        this.canBeReferenced = true;
        this.isSystemTransaction = false;
    }

    clone(into: SmsMessageTransaction, serialize: boolean) {

        super.clone(into, serialize);

        if (serialize)
            return;

        into.branches = [];
        for (let branch of this.branches)
            into.branches.push(cloneTerminal(branch, into));
    }
}


// Exported Utility


export function materializeTransaction(properties: any, tran: Transaction): void {

    for (let key in properties) {

        if (!properties.hasOwnProperty(key))
            continue;

        const value = properties[key];
        const type = tran.type;

        if (tran[key] && tran[key].constructor && tran[key].constructor.deserialize) {
            tran[key] = tran[key].constructor.deserialize(value);

        } else if (type === InputTransaction.typename() && key === "numberLogicBranches") {
            tran[key] = _.map(value, (sb: any) => NumberLogicBranch.deserialize(tran as InputTransaction, sb));

        } else if (type === InputTransaction.typename() && key === "dateLogicBranch") {

            const oident = value.ident;
            const branch = new DateLogicBranch(tran as InputTransaction, oident);
            branch.steps = value.steps;
            tran[key] = branch;
        } else {
            tran[key] = value;
        }
    }
}

export interface ITransactionConstructor {
    new(...args): Transaction;

    typename(): string;
}

const transactionRegistry = {
    "end": EndTransaction,
    "start": StartTransaction,
    "informational": InformationalTransaction,
    "menu": MenuTransaction,
    "transfer": TransferTransaction,
    "record": RecordTransaction,
    "api": ApiTransaction,
    "biometrics": BiometricsTransaction,
    "voiceit": VoiceItTransaction,
    "creditcard": CreditcardTransaction,
    "bot": BotTransaction,
    "input": InputTransaction,
    "sms-start": SmsStartTransaction,
    "sms-message": SmsMessageTransaction,
    "sms-api": SmsApiTransaction,
    "sms-branch": SmsBranchTransaction
};

export type ModuleKind = keyof typeof transactionRegistry;


const typenameSymbol = Symbol("typename");

export function getTransactionTypename(tran: Transaction | ITransactionConstructor): string {

    if (typenameSymbol in tran)
        return tran[typenameSymbol];

    if (typeof tran === "object")
        tran = tran.constructor as ITransactionConstructor;

    for (let typename in transactionRegistry) {
        const builder = transactionRegistry[typename];
        if (builder === tran) {
            tran[typenameSymbol] = typename;
            return typename;
        }
    }

    throw new Error("Unknown transaction");
}

export function initTransaction(type: string, ident: number): Transaction {
    const transactionType = transactionRegistry[type];
    if (transactionType)
        return new transactionType(ident);
    throw new Error(`Unknown transaction type: ${type}`);
}
