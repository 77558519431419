import _ from "lodash";
import {VoiceEditorApi} from "../../../apis/VoiceEditorApi";
import {IReferenceGroup, TransactionReferenceGroup, VariableReferenceGroup} from "../../../common/References";
import {Variable} from "../../../common/Variable";
import {GlobalEvents} from "../../../const";
import {ScriptEditorWizardDirective} from "../../../directives/scripteditor";
import {array} from "../../../utility/array";
import {NGX} from "../../../utility/ng";
import {Audio} from "../../audio";
import {ITtsEngine, ITtsVoice, VoiceTemplate} from "../../CanvasTemplate";
import {CanvasEvent, ReferenceType, RequestApiBindingKind} from "../../common";
import {
    ApiTransaction,
    BiometricsTransaction,
    BotTransaction,
    CreditcardTransaction,
    DateInputFormat,
    EndTransaction,
    HelperVoiceInputTransaction,
    IApiBinding,
    InformationalTransaction,
    InputTransaction,
    InputTransactionSubtype,
    IRequestApiBinding,
    MenuTransaction,
    NumberLogicBranch,
    RecordTransaction,
    StartTransaction,
    Transaction,
    TransferTransaction,
    VoiceBaseTransaction
} from "../../Transactions";
import {AllLanguages, getLanguageNameForCode} from "./allLanguages";
import {CommonTransactionController, makeTransactionDirective, TransactionDirective} from "./common";
import {CommonStartTransactionController} from "./dual";

export class VoiceTemplateSettings {

    private _ttsEngines: ITtsEngine[] = [];

    get engines() {
        return this._ttsEngines;
    }

    get engine() {
        const template = this.template as any as VoiceTemplate;
        for (let engine of this.engines) {
            if (template.ttsEngine === engine.id) return engine;
        }
        return null;
    }

    set engine(engine: ITtsEngine) {
        const template = this.template as any as VoiceTemplate;
        template.ttsEngine = engine.id;
        template.setTtsVoice(engine);
    }

    get voice() {
        const template = this.template as any as VoiceTemplate;
        for (let voice of this.engine.voices) {
            if (template.ttsVoice === voice.id) return voice;
        }
        return null;
    }

    set voice(voice: ITtsVoice) {
        const template = this.template as any as VoiceTemplate;
        template.setTtsVoice(this.engine, voice);
    }

    constructor(private readonly api: VoiceEditorApi, private readonly template: VoiceTemplate) {
    }

    async init() {
        const {engines, modules} = await this.api.initialize();
        this._ttsEngines = engines;
    }

    renderVoiceName(voice: ITtsVoice) {
        const lang = getLanguageNameForCode(voice.language);
        return `${voice.name} - ${lang}`;
    }

    isMaleVoice(voice: ITtsVoice) {
        return voice.gender === "male";
    }

    isFemaleVoice(voice: ITtsVoice) {
        return voice.gender === "female";
    }
}

class StartController extends CommonStartTransactionController<StartTransaction> {

    private _settings: VoiceTemplateSettings;

    get maxCallDuration() {
        return 120 * 60;
    }

    async onInit(): Promise<void> {
        await super.onInit();
        const injector = NGX.getInjector();
        this._settings = new VoiceTemplateSettings(injector.get(VoiceEditorApi.injectAs), this.template as any as VoiceTemplate);
        await this._settings.init();
    }

    get settings() {
        return this._settings;
    }
}

const MAX_TRANSACTION_ATTEMPTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const MAX_RETRIES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const DTMF_TIMEOUTS = [3, 5, 10, 12, 15, 20, 30, 60, 120, 240];
const DateFormats = {
    "DDMM": DateInputFormat.DDMM,
    "MMDD": DateInputFormat.MMDD,
    "DDMMYY": DateInputFormat.DDMMYY,
    "MMDDYY": DateInputFormat.MMDDYY
};
const DateFormatsInverse = _.invert(DateFormats);

class AudioTransactionController<TTransaction extends VoiceBaseTransaction> extends CommonTransactionController<TTransaction> {

    private _refs: IReferenceGroup[] = [];

    maxTransactionAttempts = MAX_TRANSACTION_ATTEMPTS;
    maxRetries = MAX_RETRIES;
    timeouts = DTMF_TIMEOUTS;

    private updateReferences() {
        this._refs = [
            new VariableReferenceGroup(this.template.variables),
            new TransactionReferenceGroup(this.template.transactions)
        ];
    }

    async openTtsEditor(audio: Audio | null) {

        if (!audio)
            audio = this.transaction.audio;

        await this.overlay.show(ScriptEditorWizardDirective, {

            get text() {
                return audio.tts;
            },

            set text(value: string) {
                audio.tts = value;
            },

            pageTitle: `Generate Speech`,
            showRawControls: true,
            showCounter: false,
            references: this._refs
        });
    }

    get references(): IReferenceGroup[] {
        return this._refs;
    }

    async onInit(): Promise<void> {
        await super.onInit();
        this.updateReferences();

        this.scope.$on(GlobalEvents.referencesUpdated, () => {
            this.updateReferences();
        });
    }

    get hasAudio(): boolean {
        return true;
    }

    get ttsText(): string {
        return this.transaction.audio.tts;
    }

}

class VoiceApiTransactionController extends AudioTransactionController<ApiTransaction> {

    variableMap: any;
    localVariableMap: any;
    variableNameMap: any;

    requestBindingKindMap: any;
    requestBindingKindNameMap: any;

    ccRequestBindingKindMap: any;
    ccRequestBindingKindNameMap: any;

    referencableTransactionsMap: any;
    referencableTransactionsNameMap: any;

    merchant: any;

    async onInit(): Promise<void> {
        await super.onInit();

        this.requestBindingKindMap = {
            "variable": RequestApiBindingKind.Variable,
            "transaction": RequestApiBindingKind.Transaction,
            "static value": RequestApiBindingKind.Static
        };
        this.ccRequestBindingKindMap = {
            "variable": RequestApiBindingKind.Variable,
            "transaction": RequestApiBindingKind.Transaction,
            "static value": RequestApiBindingKind.Static,
            "callee number": RequestApiBindingKind.CalleeNumber,
            "credit card number": RequestApiBindingKind.CreditCardNumber,
            "credit card expiration date": RequestApiBindingKind.CreditCardExpirationDate,
            "credit card cvv code": RequestApiBindingKind.CreditCardCVVCode
        };

        this.requestBindingKindNameMap = _.invert(this.requestBindingKindMap);
        this.ccRequestBindingKindNameMap = _.invert(this.ccRequestBindingKindMap);

        const template = this.template;
        const transaction = this.transaction;

        function createVariableMap(variables: Variable[]) {
            const keys = _.map(variables, (v: Variable) => v.name);
            const names = _.map(variables, (v: Variable) => v.ident);
            return {
                map: _.zipObject(keys, keys),
                nameMap: _.zipObject(keys, names)
            };
        }

        this.scope.$watch(() => template.variables, (vars) => {

            const {map, nameMap} = createVariableMap(vars);
            this.variableMap = map;
            this.variableNameMap = nameMap;

            const {map: localMap} = createVariableMap(vars.filter((v: Variable) => v.temporary));
            this.localVariableMap = localMap;


        }, true);

        const rfid = _.map(template.referencableTransactions, t => t.referenceId);
        this.referencableTransactionsMap = _.zipObject(rfid, rfid);
        this.referencableTransactionsNameMap = _.zipObject(rfid, _.map(template.referencableTransactions, t => t.tranName));

        _.each(transaction.requestBindings, (binding: IRequestApiBinding) => this.registerRequestBinding(binding));

        if (transaction instanceof CreditcardTransaction) {
            this.merchant = transaction.merchant;
            this.scope.$watch(() => this.merchant, m => {
                transaction.changeMerchant(m);
            });
        }
    }

    addBinding() {
        const locals = this.template.variables.filter((v: Variable) => v.temporary);

        if (locals.length) {
            const variable = locals[0];
            this.transaction.bindings.push({variableId: variable.name, parameterName: ""});
        } else {
            this.setTimeout(async () => {
                await this.overlay.showMessageBox("No local variables", "There are no local variables defined. Define a local variable to bind to in the side panel.");
            });
        }
    }

    private makeDefaultBindingValue(kind: RequestApiBindingKind) {

        switch (kind) {

            case RequestApiBindingKind.Variable:
                const variables = this.template.variables;
                if (variables.length)
                    return variables[0].name;
                break;

            case RequestApiBindingKind.Transaction:
                const transactions = this.template.referencableTransactions;
                if (transactions.length)
                    return transactions[0].referenceId;
                break;

            case RequestApiBindingKind.Static:
                return "";
        }

        return null;
    }

    registerRequestBinding(binding: IRequestApiBinding) {
        this.scope.$watch(() => binding.kind, (kind: RequestApiBindingKind, old: RequestApiBindingKind) => {
            if (kind === old)
                return;
            binding.value = this.makeDefaultBindingValue(kind);
        });
    }

    addRequestBinding() {

        const transaction = this.transaction;
        let binding: IRequestApiBinding;


        if (this.template.variables.length) {
            const variable = this.template.variables[0];
            binding = {value: variable.name, kind: RequestApiBindingKind.Variable, parameterName: ""};
        } else if (this.template.referencableTransactions.length) {
            const referencedTransaction = this.template.referencableTransactions[0] as Transaction;
            binding = {
                value: referencedTransaction.referenceId,
                kind: RequestApiBindingKind.Transaction,
                parameterName: ""
            };

        } else {
            binding = {value: "", kind: RequestApiBindingKind.Static, parameterName: ""};
        }

        transaction.requestBindings.push(binding);
        this.registerRequestBinding(binding);
    }

    getName(variableId: string) {

        const variable = this.template.findByReference(variableId);

        if (!variable)
            return variableId;

        if (variable.type === ReferenceType.Variable)
            return variable.value.name;

        if (variable.type === ReferenceType.Transaction)
            return variable.value.tranName;

        return variableId;
    }

    deleteBinding(binding: IApiBinding) {
        array.removeItem(this.transaction.bindings, binding);
    }

    deleteRequestBinding(binding: IRequestApiBinding) {
        array.removeItem(this.transaction.requestBindings, binding);
    }

    get hasAudio() {
        return true;
    }

}

class RecordTransactionController extends AudioTransactionController<RecordTransaction> {

    keys = ["any", "*", "#"];

    get key() {
        return this.transaction.terminationKey;
    }

    set key(value: string) {
        this.transaction.terminationKey = value;
    }

    renderKey(key: string) {
        switch (key) {
            case "any":
                return "any key";
            case "#":
                return "#️⃣";
            default:
                return key;
        }
    }
}

class CreditCardTransactionController extends AudioTransactionController<CreditcardTransaction> {
    get hasAudio() {
        return false;
    }
}

class HelperTransactionController<TTransaction extends HelperVoiceInputTransaction> extends AudioTransactionController<TTransaction> {

    private _helperValues = [];
    static nonNumericKeys = ["unbound", "*", "#"];

    get helperValues() {

        if (this.transaction instanceof MenuTransaction) {
            for (let terminal of this.transaction.outbound) {
                if (!terminal.active) {
                    if (this._helperValues.indexOf(terminal.ident) === -1) {
                        this._helperValues.push(terminal.ident);
                        this._helperValues.sort();
                    }
                }
            }
            return this._helperValues;
        }

        return HelperTransactionController.nonNumericKeys;
    }

    get endInputValues() {
        return HelperTransactionController.nonNumericKeys;
    }

    private syncHelperWithTerminalKeys() {

        if (this.transaction.helperButton === null)
            return;

        if (this.transaction instanceof MenuTransaction)
            for (let terminal of this.transaction.outbound)
                if (terminal.active && this.transaction.helperButton === terminal.ident) {
                    this.transaction.helperButton = null;
                    return;
                }
    }

    async onInit(): Promise<void> {
        await super.onInit();

        const update = () => {
            this.syncHelperWithTerminalKeys();
        };

        const events = [
            CanvasEvent.terminalStateChange,
            CanvasEvent.terminalUpdated,
            CanvasEvent.terminalLink,
            CanvasEvent.terminalEstablishConnection
        ];

        for (let event of events)
            this.scope.$on(event, update);

        this.syncHelperWithTerminalKeys();
    }

    renderKey(v: string) {
        switch (v) {
            case "unbound":
                return "Do not use a helper key";
            case "#":
                return "#️⃣";
            default:
                return v;
        }
    }

    onKeyChange(keep: "help" | "end") {

        if (!(this.transaction instanceof InputTransaction))
            return;

        const help = this.transaction.helperButton;
        const end = this.transaction.endButton;

        if (help == end && help !== "unbound") {
            if (keep === "help") {
                this.transaction.endButton = null;
            } else {
                this.transaction.helperButton = null;
            }
        }
    }
}

class InputTransactionController extends HelperTransactionController<InputTransaction> {

    private _subtypes = [
        {"name": "Any Input", "type": InputTransactionSubtype.Any},
        {"name": "Number", "type": InputTransactionSubtype.Number},
        {"name": "Date", "type": InputTransactionSubtype.Date},
    ];

    get subtypes() {
        return this._subtypes;
    }

    get dateFormats() {
        return DateFormats;
    }

    get dateFormatsNameMap() {
        return DateFormatsInverse;
    }

    removeNumberLogicBranch(branch: NumberLogicBranch) {
        this.transaction.removeNumberLogicBranch(branch);
        this.template.disconnectTerminal(branch.terminal);
    }

    async onInit(): Promise<void> {
        await super.onInit();


    }
}

class BotTransactionController extends AudioTransactionController<BotTransaction> {

    langvals: any = {};
    apiSettings: VoiceApiTransactionController;

    buttons = {"any": "any", "*": "*", "#": "#"};

    renderKey(key: string) {
        switch (key) {
            case "any":
                return "any key";
            case "#":
                return "#️⃣";
            default:
                return key;
        }
    }


    async onInit(): Promise<void> {
        await super.onInit();

        for (let lang of AllLanguages)
            this.langvals[lang.text] = lang.code;

        let watcher = null;
        const scope = this.scope;
        scope.$watch(() => scope.transaction, transaction => {

            if (!transaction)
                return;

            const apiSettingsScope = scope.$new();
            apiSettingsScope["template"] = scope.template;
            apiSettingsScope["transaction"] = transaction.forward(1);
            this.apiSettings = new VoiceApiTransactionController(apiSettingsScope as any);

            if (watcher) {
                watcher();
                watcher = null;
            }

            watcher = scope.$watch(() => scope.transaction.breakLoop, (broken: boolean) => {
                if (!broken) {
                    scope.template.disconnectTerminal(transaction.brokenLoop);
                    transaction.brokenLoop.active = broken;
                }
            });
        });

    }
}

const start = makeTransactionDirective(StartTransaction, "", "", StartController);
const api = makeTransactionDirective(ApiTransaction, "zync-icon-basic-elaboration-cloud-download", "API Call", VoiceApiTransactionController);
const info = makeTransactionDirective(InformationalTransaction, "zync-icon-basic-elaboration-message-check", "Information", AudioTransactionController);
const menu = makeTransactionDirective(MenuTransaction, "zync-icon-basic-share", "Menu", HelperTransactionController);
const input = makeTransactionDirective(InputTransaction, "pe-7s-keypad", "Input", InputTransactionController);
const transfer = makeTransactionDirective(TransferTransaction, "zync-icon-basic-headset", "Transfer", AudioTransactionController);
const record = makeTransactionDirective(RecordTransaction, "zync-icon-music-microphone-old", "Record", RecordTransactionController);
const creditcard = makeTransactionDirective(CreditcardTransaction, "zync-icon-ecommerce-creditcard", "Credit Card", CreditCardTransactionController);
const biometrics = makeTransactionDirective(BiometricsTransaction, "fa fa-id-card", "Biometric Id", AudioTransactionController);
const bot = makeTransactionDirective(BotTransaction, "fa fa-android", "Bot", BotTransactionController);
const end = makeTransactionDirective(EndTransaction, "fa-stop-circle", "End Flow");

const restrictedModules = [api, transfer, record, creditcard, biometrics, bot];

export function* getVoiceTransactionDirectives(skipStart: boolean = false, modules: string[] = null): IterableIterator<TransactionDirective> {

    function check(directive: TransactionDirective) {
        if (!modules)
            return true;

        return modules.indexOf(directive.typename) !== -1;
    }

    if (!skipStart)
        yield start;

    yield info;
    yield menu;
    yield input;

    for (let module of restrictedModules) {
        if (check(module))
            yield module;
    }

    yield end;
}
