import {AngularDirective} from "../../utility/ng";
import {browser} from "../../utility/browser";


const tag = "switcher";
export const SwitcherDirective: AngularDirective = {
    type: "tag",
    name: tag,
    jsName: tag,
    maker: () => {

        return {
            restrict: "E",
            scope: {
                value: "=",
                yes: "@",
                no: "@",
                yesIcon: "@",
                noIcon: "@",
            },
            template: `
            <div class="radio-group">
                <label>
                    <input type="radio" ng-model="value" ng-value="true"/>
                    <span>
                        <i ng-class="yesIcon"></i>
                        <p>{{yes || 'Yes'}}</p>
                    </span>
                </label>
                <label>
                    <input type="radio" ng-model="value" ng-value="false"/>
                    <span>
                        <i ng-class="noIcon"></i>
                        <p>{{no || 'No'}}</p>
                    </span>
                </label>
            </div>
            `,
            link: (scope, elem) => {

                if (!scope["yesIcon"])
                    scope["yesIcon"] = "fa fa-check";

                if (!scope["noIcon"])
                    scope["noIcon"] = "fa fa-times";

                const id = browser.uniqueDomId();
                elem.find("input").attr("name", id);
            }
        };
    }
};
