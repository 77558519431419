import {ITerminal} from "../Transactions";
import {IDirective, IScope} from "angular";
import {CanvasEvent, TerminalType} from "../common";
import {browser} from "../../utility/browser";
import {NamespaceName} from "../../const";
import {AngularDirective, NGX} from "../../utility/ng";

interface ITerminalScope extends IScope {
    terminal: ITerminal;
    text: string;
    icon: string;
}

const tagName = `${NamespaceName}-canvas-terminal`;

function maker(): IDirective {

    return {
        restrict: "E",
        template: "<div class='indicator'><div class='value' ng-bind='text'></div></div>",
        require: ["^upwireCanvas"],
        scope: {terminal: "=", text: "=?", icon: "@?"},
        link(scope: ITerminalScope, elem: JQuery) {

            const terminal = scope.terminal;
            const indicator = elem.find(".indicator");

            let host: JQuery = null;
            const getHost = () => {
                if (!host) {
                    host = elem.closest(".transaction");
                }
                return host;
            };

            elem.addClass(terminal.type === TerminalType.Outbound ? "outbound" : "inbound");

            scope.$watch(() => scope.icon, (icon) => {
                if (!icon) return;
                indicator.empty().html("<i class='" + icon + "'/>");
            });

            scope.$watchGroup([() => !!terminal.target, () => !!terminal.incoming.length], ([a, b]) => {
                if (terminal.ident == "8")
                    console.warn("9", a, b);

                elem.toggleClass("connected", a || b);
            });

            if (terminal.color)
                elem.css("border-color", terminal.color);

            function dragStart() {
                elem.toggleClass("active", true);
                scope.$emit(CanvasEvent.terminalDragStart, scope.terminal);
                setTimeout(() => {
                    NGX.safeDigest(scope.$root);
                });
            }

            function dragMove(evt: JQueryEventObject, x: number, y: number) {
                scope.$emit(CanvasEvent.terminalDragMove, x, y);
            }

            function dragEnd(evt: JQueryEventObject, x: number, y: number) {

                let target = $(document.elementFromPoint(x, y))
                    .closest(tagName);

                if (scope.terminal.type === TerminalType.Outbound && target.length === 0) {
                    const potential = $(document.elementFromPoint(x, y)).closest(".transaction").find(".inbound");
                    if (potential.length === 1) {
                        target = potential;
                    }
                }

                target.trigger(CanvasEvent.terminalLink, scope.terminal);

                elem.toggleClass("active", false);
                scope.$emit(CanvasEvent.terminalDragEnd);
            }

            elem.on(CanvasEvent.terminalLink, (evt: JQueryEventObject, from: ITerminal) => {
                scope.$emit(CanvasEvent.terminalEstablishConnection, from, scope.terminal);
            });

            browser.onElementDrag(scope, elem, dragStart, dragMove, dragEnd);

            scope.$on(CanvasEvent.terminalUpdated, () => {

                const host = getHost();
                if (!host.length)
                    return;

                const hostOffset = host.offset();
                const offset = elem.offset();

                scope.terminal.layout = {
                    width: elem.outerWidth(),
                    height: elem.outerHeight(),
                    offsetInHost: {
                        x: offset.left - hostOffset.left,
                        y: offset.top - hostOffset.top
                    }
                };

                elem.toggleClass("ghost", scope.terminal.ghost);
            });

            scope.$watch((scope: ITerminalScope) => scope.terminal.active, () => {
                scope.$emit(CanvasEvent.terminalStateChange, scope.terminal);
            });

            scope.$watch((scope: ITerminalScope) => scope.terminal.ghost, (ghost) => {
                elem.toggleClass("ghost", ghost);
                scope.$emit(CanvasEvent.terminalStateChange, scope.terminal);
            });

            scope.$emit(CanvasEvent.terminalStateChange, scope.terminal);

            scope.$on("$destroy", () => scope.$emit(CanvasEvent.terminalStateChange, scope.terminal));
        }
    };
}

export const CanvasTerminalDirective: AngularDirective = {
    name: tagName,
    jsName: NamespaceName + "CanvasTerminal",
    type: "tag",
    maker: maker,
};
