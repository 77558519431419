import {AngularDirective, NGX} from "../utility/ng";
import {GlobalEvents} from "../const";

import $ from "jquery"
import {IScope} from "angular";

interface ILargeScope extends IScope {
    url: string
}


export const LargeImage = NGX.makeTagDirective("common/large-image", null, {
    url: "=",
    title: "=",
    description: "="
}, (scope: IScope, elem: JQuery) => {
    elem.find(".host").on("click", () => {
        scope["close"] = () => {
            scope.$emit(GlobalEvents.overlayClose);
        }
        scope.$emit(GlobalEvents.overlayClose);
    });
})

export const LargeVideo = NGX.makeTagDirective("common/large-video", null, {
    url: "=",
    title: "=",
    description: "="
}, (scope: ILargeScope, elem: JQuery) => {

    const wind = $(window);

    let fullScreen = false;
    if (wind.height() * wind.width() < 750 * 750) {
        fullScreen = true;
    }

    scope.$watch(() => scope.url, url => {
        if (!fullScreen) {
            scope["loading"] = true;
            const player = elem.find(".player");
            player.on("loadeddata", () => {
                scope["loading"] = false;
                NGX.safeDigest(scope);
            });
            player.attr("src", url);
        } else {
            window.open(scope.url);
            setTimeout(() => scope.$emit(GlobalEvents.overlayClose));
        }
    });

})

export function getLargeDirectives(): AngularDirective[] {
    require("../../styles/large.less")
    return [LargeImage, LargeVideo]
}
