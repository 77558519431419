import {getDragHostAttributes} from "./draghost";
import {getColorPickerDirectives} from "./colorpicker";
import {getMarkdownDirectives} from "./md";
import {getUpwireSelectorDirectives} from "./selector";
import {getVariableAdderDirectives} from "./variableadder";
import {getVTabsDirectives} from "./vtabs";
import {getIFrameDirectives} from "./iframe";
import {getLargeDirectives} from "./large";
import {getValidatorDirectives} from "./validation";
import {getEditableDivDirectives} from "./ediv";
import {AngularDirective} from "../utility/ng";
import {getAllModalDirectives} from "./modals";
import {getBodyDirective} from "./body";
import {getNavDirective} from "./nav";
import {getPageDirective} from "./page";
import {ReferencesMenuDirective, TagsMenuDirective, VariableMenuDirective, VariablePillDirective} from "./menu/menu";
import {RequiredVariableCreation, TagCreation, VariableCreation} from "./dialog/VariableCreation";
import {ScriptEditorDirective, ScriptEditorWizardDirective, ScriptViewerDirective} from "./scripteditor";
import {SliderDirective} from "./slider";

export function* getCommonUIDirectives(): IterableIterator<AngularDirective> {

    yield getBodyDirective();
    yield getPageDirective();
    yield getNavDirective();

    yield* getDragHostAttributes();
    yield* getColorPickerDirectives();
    yield* getMarkdownDirectives();
    yield* getUpwireSelectorDirectives();
    yield* getVariableAdderDirectives();
    yield* getVTabsDirectives();
    yield* getIFrameDirectives();
    yield* getLargeDirectives();
    yield* getValidatorDirectives();
    yield* getEditableDivDirectives();
    yield* getAllModalDirectives();

    yield VariablePillDirective;
    yield VariableMenuDirective;
    yield VariableCreation;
    yield RequiredVariableCreation;
    yield TagsMenuDirective;
    yield ReferencesMenuDirective;
    yield TagCreation;
    yield ScriptEditorDirective;
    yield ScriptEditorWizardDirective;
    yield ScriptViewerDirective;
    yield SliderDirective;
}
