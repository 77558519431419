import $ from "jquery";
import _, {isNaN} from "lodash";
import {AppComponentBase} from "../common/AppComponentBase";
import {Template} from "../common/Template";
import {Severity, Validator} from "../common/Validation";
import {TemplateTypename} from "../const";
import {array} from "../utility/array";
import {color} from "../utility/color";

import {geometry, IBoundingBox} from "../utility/geometry";
import {imaging} from "../utility/imaging";
import {strings} from "../utility/strings";
import {uuid} from "../utility/utility";


function makeTable(width: number): JQuery {
    return $("<table>").attr({
        cellspacing: 0,
        cellpadding: 0,
        border: 0,
        width: width
    }).css({
        "border-spacing": 0,
        "border-collapse": "collapse",
        "mso-table-lspace": "0",
        "mso-table-rspace": "0"
    });
}

function makeSingleCellTable(width: number): [JQuery, JQuery] {

    const table = makeTable(width);

    const row = $("<tr>").appendTo(table);
    const cell = $("<td>").appendTo(row);

    return [table, cell];
}

function applyComponentStyle(style: IComponentStyle, host: JQuery, hostCell: JQuery) {

    style = _.cloneDeep(style);
    const bw = style.borderWidth;
    delete style["borderWidth"];

    if (bw) {
        const color = style.borderColor;
        delete style["borderColor"];
        hostCell.css("border", `${bw}px solid ${color}`);
    }

    host.css(style as any);
}

function createTableLinkElement(url: string) {
    return $("<a>").attr({
        href: url,
        target: "_blank"
    });
}


export type DeliveryDelay = {
    name: string;
    duration: number
}

export const DeliveryDelayOptions: DeliveryDelay[] = [
    {name: "No Delay", duration: 0},
    {name: "1 minute", duration: 60},
    {name: "2 minutes", duration: 60 * 2},
    {name: "5 minutes", duration: 60 * 5},
    {name: "20 minutes", duration: 60 * 20},
    {name: "1 hour", duration: 60 * 60},
    {name: "2 hours", duration: 60 * 60 * 2},
    {name: "5 hours", duration: 60 * 60 * 5},
    {name: "1 day", duration: 60 * 60 * 24},
    {name: "2 days", duration: 60 * 60 * 24 * 2}
];

export class EmailTheme {

    static fonts = [
        "Helvetica",
        "Times New Roman",
        "Arial",
        "Arial Black",
        "Tahoma",
        "Trebuchet MS",
        "Verdana",
        "Courier New",
        "Georgia",
        "Times"
    ];

    id: string;
    colors: string[];
    name: string;
    color: string;
    fontFamily: string;

    textBlockBackgroundColor: string;

    static sandy() {
        const theme = new EmailTheme();

        theme.id = theme.name = "Sandy Beach";
        theme.colors = ["#E6E2AF", "#A7A37E", "#EFECCA", "#046380", "#002F2F"];
        theme.color = "#E6E2AF";
        theme.fontFamily = "Arial";
        theme.textBlockBackgroundColor = "#002F2F";

        return theme;
    }

    static firenze() {
        const theme = new EmailTheme();

        theme.id = theme.name = "Firenze";
        theme.colors = ["#6583FF", "#FFF0A5", "#FFB03B", "#B64926", "#8E2800"];
        theme.color = "#333333";
        theme.fontFamily = "Arial";
        theme.textBlockBackgroundColor = "#FFF0A5";

        return theme;
    }

    static bluca() {
        const theme = new EmailTheme();

        theme.id = theme.name = "Bluca";
        theme.colors = ["#468966", "#FFF0A5", "#FFB03B", "#B64926", "#8E2800"];
        theme.color = "#333333";
        theme.fontFamily = "Arial";
        theme.textBlockBackgroundColor = "#FFFFFF";

        return theme;
    }


    private static sthemes: EmailTheme[];
    private static sthemeById: { [id: string]: EmailTheme } = {};

    static themes() {
        if (!EmailTheme.sthemes) {
            const themes = EmailTheme.sthemes = [this.sandy(), this.firenze(), this.bluca()];
            for (let i = 0; i < themes.length; ++i) {
                const theme = themes[i];
                EmailTheme.sthemeById[theme.id] = theme;
            }
        }
        return EmailTheme.sthemes;
    }

    static theme(id: string) {
        if (id === null)
            return null;
        const theme = EmailTheme.sthemeById[id];
        if (!theme) return null;
        return theme;
    }
}

export interface ISectionStyle {
    backgroundColor: string;
}

export interface IComponentStyle {
    borderWidth: number;
    borderColor: string;
    backgroundColor: string;
}

export interface ITextBlockComponentStyle extends IComponentStyle {
    fontFamily: string;
    padding: number;
    lineHeight: number;
    color: string;
}

export interface IButtonBlockComponentStyle extends ITextBlockComponentStyle {
    fontSize: string;
    fontWeight: string;
    fontStyle: string;
    color: string;
}

export interface IImageBlockComponentStyle extends IComponentStyle {
    backgroundSize: string;
}

export interface ISpacerBlockComponentStyle extends IComponentStyle {

}

export interface IComponentLayoutCache {
    [id: string]: IBoundingBox;
}

export interface IEmailStyle {
    backgroundColor: string;
}


export class OptOutMessage extends AppComponentBase {

    private static MARKUPTARGET = /\*\*(.*)\*\*/;

    id: string;

    constructor(public markup: string) {
        super();
        this.id = strings.hash(markup);
    }

    get html() {
        return this.markup.replace(OptOutMessage.MARKUPTARGET,
            (sub, text) => `<a href="[[OPT-OUT]]">${text}</a>`);
    }

    get text() {
        return this.markup.replace(OptOutMessage.MARKUPTARGET,
            (sub, text) => `<strong>${text}</strong>`);
    }

    static messages: OptOutMessage[] = [
        new OptOutMessage("**Click here** to unsubscribe."),
        new OptOutMessage("**Click here** to not receive these messages any more."),
        new OptOutMessage("To cancel your subscription, **click here**."),
        new OptOutMessage("**Unsubscribe**.")
    ];
}

export abstract class Component {

    id: string = uuid();

    x: number = 0;
    y: number = 0;

    width: number = 350;
    height: number = 150;

    minWidth: number = 25;
    minHeight: number = 25;

    style: IComponentStyle;
    zindex = 0;

    abstract get type(): ComponentType;

    get bbox(): IBoundingBox {
        const point = {x: this.x, y: this.y};
        const size = {width: this.width, height: this.height};

        return geometry.makeBBox(point, size);
    }

    set bbox(box: IBoundingBox) {
        const ext = geometry.extBBox(box);

        this.x = box.xmin;
        this.y = box.ymin;

        this.width = ext.width;
        this.height = ext.height;
    }

    constructor() {
        this.style = {
            borderWidth: 0,
            borderColor: "#000000",
            backgroundColor: "#F9FDFF"
        };
    }

    static materialize(obj: any): Component {
        const component = Component.activate(obj.type);
        component.materialize(obj);
        return component;
    }

    static typename(that: any = null) {
        return strings.camel2snake(that ? that.name : this.name);
    }

    static activate(type: ComponentType): Component {
        return new components[type]();
    }

    materialize(obj: any) {

        this.id = obj.id;

        this.x = obj.x;
        this.y = obj.y;

        this.width = obj.width;
        this.height = obj.height;

        this.minWidth = obj.minWidth;
        this.minHeight = obj.minHeight;

        this.style = _.extend(this.style as any, _.clone(obj.style)) as any;
        this.zindex = obj.zindex;

    }

    clone(into: Component, serialize: boolean) {

        into.x = this.x;
        into.y = this.y;

        into.width = this.width;
        into.height = this.height;

        into.minWidth = this.minWidth;
        into.minHeight = this.minHeight;

        into.style = _.clone(this.style);
        into.zindex = this.zindex;

        if (serialize) {
            into.id = this.id;
            (into as any).type = this.type;
        }
    }

    copy(serialize: boolean): Component | any {
        const copy = serialize ? <any>{} : Component.activate(this.type);
        this.clone(copy, serialize);
        return copy;
    }

    heal() {
        const bw = this.style.borderWidth;
        const dim = Math.min(this.width, this.height);
        if (2 * bw > dim) {
            this.style.borderWidth = Math.min(this.width, this.height) / 2;
        }
    }

    get allLayoutModifiers() {
        return [
            () => this.style.borderWidth,
            () => this.width,
            () => this.height
        ];
    }

    getUsedColors(): string[] {
        return [
            this.style.backgroundColor,
            this.style.borderColor
        ];
    }
}

export class Spacer extends Component {
    style: ISpacerBlockComponentStyle;

    get type(): ComponentType {
        return "spacer";
    }
}

export class ImageBlock extends Component {
    imageUrl: string = null;
    imageTransformedUrl: string = null;
    imageTransformedCacheUrl: string = null;

    style: IImageBlockComponentStyle;
    url: string = "";

    constructor() {
        super();
        _.extend(this.style, {
            backgroundSize: "cover"
        });
    }

    set cover(value: boolean) {
        this.style.backgroundSize = value ? "cover" : "contain";
    }

    get cover() {
        return this.style.backgroundSize === "cover";
    }

    clone(into: ImageBlock, serialize: boolean) {
        super.clone(into, serialize);
        into.imageUrl = this.imageUrl;
        into.imageTransformedUrl = this.imageTransformedUrl || null;
        into.url = this.url || "";
    }

    materialize(obj: any) {
        super.materialize(obj);
        this.imageUrl = obj.imageUrl;
        this.imageTransformedUrl = obj.imageTransformedUrl || null;
        this.url = obj.url;
    }

    get type(): ComponentType {
        return "image-block";
    }
}

export class TextBlock extends Component {

    text: string = null;
    style: ITextBlockComponentStyle;

    constructor() {
        super();
        _.extend(this.style, {
            fontFamily: "Helvetica",
            padding: 10,
            lineHeight: 1,
            color: "#000000"
        });
    }

    clone(into: TextBlock, serialize: boolean) {
        super.clone(into, serialize);
        into.text = this.text;
    }

    materialize(obj: any) {
        super.materialize(obj);
        this.text = obj.text;
    }

    get type(): ComponentType {
        return "text-block";
    }

    getUsedColors() {
        let colors = super.getUsedColors();
        colors.push(this.style.color);
        for (let textColor of color.findColors(this.text))
            colors.push(textColor);
        return colors;
    }
}

export class ButtonBlock extends Component {

    text: string;
    url: string;
    style: IButtonBlockComponentStyle;

    constructor() {
        super();
        this.width = 150;
        this.height = 50;

        this.text = "click me";
        this.url = "http://www.google.com";

        _.extend(this.style, {
            fontFamily: "Helvetica",
            fontSize: "15px",
            padding: 10,
            lineHeight: 1,
            color: "#FFFFFF",
            backgroundColor: "#333333",
            fontStyle: "",
            fontWeight: "",
            borderRadius: 0
        });
    }

    clone(into: ButtonBlock, serialize: boolean) {
        super.clone(into, serialize);

        into.text = this.text;
        into.url = this.url;
    }

    materialize(obj: any) {
        super.materialize(obj);
        this.text = obj.text;
        this.url = obj.url;
    }

    get type(): ComponentType {
        return "button-block";
    }

}

export type ComponentType = "text-block" | "button-block" | "image-block" | "spacer";

interface IComponentConstructor {
    new(): Component;
}

const components: { [C in ComponentType]: IComponentConstructor } = {
    "text-block": TextBlock,
    "button-block": ButtonBlock,
    "image-block": ImageBlock,
    "spacer": Spacer
};


export class EmailSection {

    id: string = uuid();
    name: string = null;

    height: number;
    minHeight: number;
    order: number;

    style: ISectionStyle = {
        backgroundColor: "#FFFFFF"
    };

    components: Component[] = [];

    private layoutCache: IComponentLayoutCache = {};

    constructor(special: string = null, minHeight: number = 200) {
        this.name = special;
        this.minHeight = minHeight;
    }

    static materialize(obj: any): EmailSection {
        const section = new EmailSection();

        section.id = obj.id;
        section.name = obj.name;
        section.height = obj.height;
        section.minHeight = obj.minHeight;
        section.order = obj.order;
        section.style = _.clone(obj.style);

        for (let i = 0; i < obj.components.length; ++i) {
            const component = Component.materialize(obj.components[i]);
            section.add(component);
        }

        return section;
    }

    add(component: Component) {
        this.components.push(component);
        this.touch(component);
    }

    layout(template: EmailTemplate, allowComponentExchange: boolean) {

        const cellSize = template.cellSize;
        const templateWidth = template.width;

        for (let component of this.components) {

            let width = Math.max(this.adjust(Math.min(component.width, templateWidth), cellSize), 2 * cellSize);
            if (width > templateWidth) width = templateWidth;

            let x = component.x = this.adjust(component.x, cellSize);
            if (x + width > templateWidth) {
                // can we move the component a bit to the left?
                if (templateWidth - width >= 0) {
                    x = component.x = this.adjust(templateWidth - width, cellSize);
                } else {
                    width = templateWidth - x;
                }
            }

            if (x > templateWidth - component.minWidth)
                x = component.x = templateWidth - component.minWidth;

            if (width < component.minWidth)
                width = component.minWidth;

            component.width = width;
            component.y = this.adjust(component.y, cellSize);
            component.height = Math.max(this.adjust(component.height, cellSize), 2 * cellSize);
        }

        const sortedComponents = _.sortBy(this.components, c => -c.zindex);

        if (allowComponentExchange) {
            for (let idx = 0; idx < sortedComponents.length; ++idx) {
                const component = sortedComponents[idx];

                component.zindex = sortedComponents.length - idx;

                for (let jdx = idx + 1; jdx < sortedComponents.length; ++jdx) {
                    const obscuredComponent = sortedComponents[jdx];

                    const componentBBox = geometry.extBBox(component.bbox);
                    const underlingBBox = geometry.extBBox(obscuredComponent.bbox);

                    const mArea = Math.max(componentBBox.area, underlingBBox.area);
                    const intersectionArea = geometry.intersectionArea(componentBBox, underlingBBox);

                    if (intersectionArea > 0.25 * mArea) {
                        this.exchange(component, obscuredComponent);
                    }
                }
            }
        }

        for (let idx = 0; idx < sortedComponents.length; ++idx) {
            const component = sortedComponents[idx];

            for (let jdx = idx + 1; jdx < sortedComponents.length; ++jdx) {
                const underlingComponent = sortedComponents[jdx];

                const movee = component.bbox.ymin <= underlingComponent.bbox.ymin ? underlingComponent : component;
                while (geometry.intersectionQ(component.bbox, underlingComponent.bbox)) {
                    movee.y += template.cellSize;
                }

            }
        }

        this.height = this.adjust(this.minHeight, template.cellSize);
        for (let idx = 0; idx < this.components.length; ++idx)
            this.height = Math.max(this.components[idx].bbox.ymax, this.height);

        for (let i = 0; i < this.components.length; ++i)
            this.setLastBBox(this.components[i]);
    }

    touch(component: Component) {
        let zIndex = 0;
        const components = _.sortBy(this.components, c => -c.y);
        for (let comp of components)
            comp.zindex = comp === component ? components.length - 1 : zIndex++;
    }

    resize(component: Component, bbox: IBoundingBox) {
        component.bbox = bbox;
        this.touch(component);
        this.clearLastBBox(component);
    }

    clone(into: EmailSection, serialize: boolean) {

        into.name = this.name;
        into.height = this.height;
        into.minHeight = this.minHeight;
        into.order = this.order;
        into.style = _.clone(this.style);

        const components: Component[] = into.components = [];
        for (let i = 0; i < this.components.length; ++i) {
            const component = this.components[i].copy(serialize);

            if (!serialize) into.add(component);
            else components.push(component);
        }

        if (serialize) {
            into.id = this.id;
        }
    }

    copy(serialize: boolean): EmailSection {
        const section = !serialize ? new EmailSection() : ({} as any);
        this.clone(section, serialize);
        return section;
    }

    private adjust(val: number, cellSize: number) {
        return geometry.snapToGrid(val, cellSize);
    }

    private exchange(a: Component, b: Component) {

        const lastA = this.getLastBBox(a),
            lastB = this.getLastBBox(b);

        if (lastA && lastB) {

            a.bbox = lastB;
            b.bbox = lastA;

            this.clearLastBBox(a);
            this.clearLastBBox(b);

            return true;
        }

        return false;
    }

    private clearLastBBox(component: Component) {
        delete this.layoutCache[component.id];
    }

    private setLastBBox(component: Component) {
        this.layoutCache[component.id] = component.bbox;
    }

    private getLastBBox(component: Component): IBoundingBox {
        const box = this.layoutCache[component.id];
        if (box) return box;
        return null;
    }

}

export interface TemplateLayoutOptions {
    allowComponentExchange?: boolean;
}

export class EmailTemplate extends Template {

    name: string = "New Email Template";
    subject: string = "";

    from_address: string = "";
    reply_address: string = "";
    delay: number = 0;

    legacyText = "";

    themeId: string = null;
    cellSize: number = 25;
    optOutMessageId: string = _.head(OptOutMessage.messages).id;
    width: number = 600;

    rawHtml: string = null;
    useRawHtml: boolean = false;

    style: IEmailStyle = {
        backgroundColor: "#FFFFFF"
    };

    sections: EmailSection[] = [];

    //STATE
    layoutSqn: number = 0;
    private _usedColors: string[] = [];

    createSectionTable(section: EmailSection) {

        const cs = this.cellSize;
        const columnCount = this.width / cs;
        const rowCount = section.height / cs;

        const table = $("<table>").attr({
            width: this.width + 1,
            cellspacing: 0,
            cellpadding: 0,
            border: 0,
            bgcolor: section.style.backgroundColor
        });

        const matrix: JQuery[][] = [];

        for (let r = 0; r < rowCount; ++r) {
            const row = $("<tr>").appendTo(table).attr({height: cs});
            const cells: JQuery[] = [];
            matrix.push(cells);

            /* $("<td>")
                .appendTo(row)
                .css({"background-color": this.style.backgroundColor})
                .attr({width: 1}); */

            for (let c = 0; c < columnCount; ++c) {
                const cell = $("<td>")
                    .appendTo(row)
                    .attr({"width": cs, "align": "center"});
                cells.push(cell);
            }

            /* $("<td>")
                .appendTo(row)
                .css({"background-color": this.style.backgroundColor})
                .attr({width: 1}); */
        }

        for (let component of section.components) {

            const i = component.y / cs,
                j = component.x / cs,
                height = component.height / cs,
                width = component.width / cs,
                bw = component.style.borderWidth;

            const cell = matrix[i][j];

            let hostWidth = width * cs;
            let hostHeight = height * cs;

            const [host, hostCell] = makeSingleCellTable(hostWidth);
            host.addClass("host");

            host.appendTo(cell)
                .attr({height: hostHeight, width: hostWidth});

            if (component.type === Spacer.typename() || component.type === ImageBlock.typename()) {
                const w = hostWidth - 2 * bw;
                const h = hostHeight - 2 * bw;
                hostCell.attr({"width": w, "height": h});
            }

            if (component.type === TextBlock.typename()) {

                const textBlock = component as TextBlock;
                const w = hostWidth - 2 * textBlock.style.padding - 2 * bw;
                const h = hostHeight - 2 * textBlock.style.padding - 2 * bw;

                const [itable, icell] = makeSingleCellTable(w);
                itable.attr({"height": h, "align": "center"});
                icell.attr({"valign": "top"}).html(textBlock.text);

                icell.find("p").css({"font-size": "14px"});

                hostCell.attr({"width": w, "height": h}).append(itable);
            }

            if (component.type === ButtonBlock.typename()) {

                const button = component as ButtonBlock;

                const width = hostWidth - 2 * button.style.padding - 2 * bw;
                const height = hostHeight - 2 * button.style.padding - 2 * bw;

                const a = createTableLinkElement(button.url)
                    .css({
                        "display": "block",
                        "color": button.style.color,
                        "width": `${width}px`,
                        "height": `${height}px`,
                        "line-height": `${height}px`,
                    }).text(button.text);

                const [table, cell] = makeSingleCellTable(width);
                table.attr({"height": height, "align": "center"});
                cell.append(a).css({
                    "text-align": "center",
                });

                hostCell
                    .attr({"width": width, "height": height})
                    .css({"text-align": "center", "overflow": "hidden"})
                    .append(table);
            }

            applyComponentStyle(component.style, host, hostCell);

            cell.attr({
                height: height * cs
            }).css({
                "overflow": "hidden",
            });

            if (component.type === ImageBlock.typename()) {

                const width = hostWidth - 2 * bw;
                const height = hostHeight - 2 * bw;

                const image = component as ImageBlock;

                let src = image.imageTransformedUrl || image.imageUrl;
                if (!src) {
                    src = imaging.BlankImage;
                }

                const img = $("<img>").attr({
                    alt: "",
                    src: src,
                    width: width,
                    height: height,
                    border: 0
                });

                if (image.url) {
                    createTableLinkElement(image.url)
                        .append(img)
                        .appendTo(hostCell);
                } else {
                    hostCell.append(img);
                }
            }

            if (component.type !== ButtonBlock.typename() && component.type !== Spacer.typename()) {
                cell.attr({
                    bgcolor: component.style.backgroundColor
                });
            }

            cell.attr("rowspan", height);
            cell.attr("colspan", width);
            cell.attr("width", component.width);

            for (let ioffset = 0; ioffset < height; ++ioffset) {
                for (let joffset = 0; joffset < width; ++joffset) {
                    if (ioffset === 0 && joffset === 0)
                        continue;
                    const query = matrix[ioffset + i][joffset + j];
                    query.remove();
                }
            }
        }

        return table;
    }

    private createSpacer(height: number) {
        const [spacer, cell] = makeSingleCellTable(this.width);
        spacer.attr({"height": height});
        cell.attr({"height": height, "width": this.width});
        return spacer;
    }

    private crateFooter() {

        const footerColor = color.contrastHexColor(this.style.backgroundColor);

        const [footer, cell] = makeSingleCellTable(this.width);
        cell.html(this.optOutMessage.html);

        cell.attr({"align": "center"})
            .css({
                fontFamily: "Helvetica",
                fontSize: "15px",
                color: footerColor
            });

        cell.find("a")
            .css({
                color: footerColor,
                textDecoration: "underline",
                fontWeight: "bold"
            });

        return footer;
    }

    get height() {
        let height = 0;
        for (let i = 0; i < this.sections.length; ++i)
            height += this.sections[i].height;
        return height;
    }

    get theme() {
        return EmailTheme.theme(this.themeId);
    }

    set theme(theme: EmailTheme) {
        this.themeId = theme.id;
    }

    get themes() {
        return EmailTheme.themes();
    }

    get usedColors(): string[] {

        const colors = [
            this.style.backgroundColor
        ];

        for (let section of this.sections) {
            colors.push(section.style.backgroundColor);
            for (let component of section.components) {
                for (let color of component.getUsedColors())
                    colors.push(color);
            }
        }

        this._usedColors.length = 0;
        for (let color of _.sortBy(_.uniq(colors), c => c))
            this._usedColors.push(color);

        return this._usedColors;
    }

    get json(): string {
        return JSON.stringify(this.copy(true), null, 4);
    }

    private get body(): string {

        const tables: JQuery[] = [];
        const sections = _.sortBy(this.sections, s => s.order);

        for (let section of sections) {
            tables.push(this.createSectionTable(section));
        }

        tables.push(this.createSpacer(4 * this.cellSize));
        tables.push(this.crateFooter());
        tables.push(this.createSpacer(this.cellSize));

        const outer = $("<table>").attr({
            cellspacing: 0,
            cellpadding: 0,
            border: 0,
            width: "100%"
        });

        //centered container of outer table
        const spec = {bgcolor: this.style.backgroundColor, align: "center"};

        const centerRowData = $("<td>").attr(spec);
        const marginRow = $("<td height='25px'>").attr(spec);

        //top margin row
        outer.append($("<tr>").append(marginRow.clone()))
            .append($("<tr>").append(centerRowData))
            .append($("<tr>").append(marginRow.clone()));

        //templates sections
        for (let table of tables) {
            centerRowData.append(table);
        }

        let body = (outer[0] as any).outerHTML;
        body = color.sanitize(body);
        return body;
    }

    get html(): string {

        if (this.useRawHtml)
            return this.rawHtml;

        const style = "<style>body,p{margin:0!important;padding:0!important;}a,span,table,td{border-collapse:collapse}body{-webkit-text-size-adjust:100%!important;-ms-text-size-adjust:100%!important;-webkit-font-smoothing:antialiased!important}img{border:0!important;outline:0!important;display:block}table{mso-table-lspace:0;mso-table-rspace:0}a,span,td{mso-line-height-rule:exactly}</style>";
        const prefix = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><!doctype html><html><head><meta charset="UTF-8">${style}<title>${this.subject}</title></head>`;
        return prefix + "<body>" + this.body + "</body></html>";
    }

    get text(): string {
        return this.legacyText;
    }

    get optOutMessage() {
        return _.find(OptOutMessage.messages, m => m.id === this.optOutMessageId);
    }

    get layoutStyle(): any {
        if (this.rawHtml)
            return {"backgroundColor": "#fafafa"};
        return this.style;
    }

    get typename(): TemplateTypename {
        return "email";
    }

    constructor(id: string) {
        super(id);

        this.themeId = "Bluca";
        this.layout();

        this.addValidator(new Validator(Severity.Error,
            () => "Subject Line",
            () => "The subject line is empty",
            () => {
                return !(!this.subject || this.subject.length === 0);
            }));

        this.addValidator(new Validator(Severity.Error,
            () => "Empty Content Body",
            () => "There are no sections in your template.",
            () => {
                return this.useRawHtml || this.sections.length > 0;
            }));

        this.addValidator(new Validator(Severity.Error,
            () => "Invalid Reply-to Address",
            () => "The address specified in the reply-to field appears to be invalid",
            () => {
                if (this.reply_address.length > 0)
                    return strings.isValidEmail(this.reply_address);
                return true;
            }));

        this.addValidator(new Validator(Severity.Warning,
            () => "No Text Version",
            () => "Consider adding a text version for improved accessibility. " +
                "Click the cog icon next to the template name at the top of the editor.",
            () => {
                return this.legacyText && this.legacyText.length > 0;
            }));
    }

    static materialize(id: string, obj: any): EmailTemplate {

        if (typeof obj === "string")
            return EmailTemplate.materialize(id, JSON.parse(obj));

        const template = new EmailTemplate(id);

        if (Object.keys(obj).length < 3)
            return template;

        template.name = obj.name;
        template.themeId = obj.themeId;
        template.cellSize = obj.cellSize;
        template.width = obj.width;
        template.subject = obj.subject;
        template.style = _.clone(obj.style);

        template.from_address = obj.from_address || "";
        template.reply_address = obj.reply_address || "";
        template.delay = obj.delay || 0;

        template.legacyText = obj.legacyText || "";

        template.rawHtml = obj.rawHtml || null;
        template.useRawHtml = obj.useRawHtml || false;

        template.optOutMessageId = obj.optOutMessageId || _.head(OptOutMessage.messages).id;

        for (let i = 0; i < obj.sections.length; ++i) {
            const section = EmailSection.materialize(obj.sections[i]);
            template.addSection(section);
        }

        if (obj.variables) {
            for (let serializedVariable of obj.variables)
                template.addVariable(serializedVariable.name, !!serializedVariable.temporary);
        }

        return template;
    }

    serialize() {
        return this.json;
    }

    layout(options: TemplateLayoutOptions = {}): void {

        const {
            allowComponentExchange = false
        } = options;

        ++this.layoutSqn;
        for (let i = 0; i < this.sections.length; ++i)
            this.sections[i].layout(this, allowComponentExchange);
    }

    addSection(section: EmailSection): void {
        if (!section.name) {
            section.name = `section ${this.sections.length + 1}`;
        }
        this.orderSection(section);
        this.sections.push(section);
        this.layout();
    }

    removeSection(section: EmailSection) {
        const components = _.clone(section.components);
        for (let i = 0; i < components.length; ++i) {
            const component = components[i];
            this.removeComponent(component);
        }

        array.removeItem(this.sections, section);
    }

    orderSection(section: EmailSection, before: EmailSection = null) {
        if (before === null) {
            section.order = this.sections.length;
        } else {
            let order = 0;
            const sorted = _.sortBy(this.sections, s => s.order);
            for (let i = 0; i < sorted.length; ++i) {
                const psect = sorted[i];

                if (psect === before)
                    section.order = order++;
                if (psect !== section)
                    psect.order = order++;
            }
        }
    }

    findSection(component: Component) {
        for (let i = 0; i < this.sections.length; ++i) {
            const section = this.sections[i];
            for (let j = 0; j < section.components.length; ++j)
                if (section.components[j] === component)
                    return section;
        }
        return null;
    }

    copySection(section: EmailSection) {
        const clone = new EmailSection();
        section.clone(clone, false);

        this.addSection(clone);

        const sorted = _.sortBy(this.sections, s => s.order);
        const sidx = _.indexOf(sorted, section);
        const before = sorted[sidx + 1] ? sorted[sidx + 1] : null;
        this.orderSection(clone, before);
    }

    addComponent(component: Component, section: EmailSection): void {

        const boxes = _.map(section.components, c => c.bbox);
        const expanse = geometry.findExpanse(boxes, component.bbox);

        if (expanse) {
            component.bbox = expanse;
            component.width = Math.min(expanse.width, this.width - component.x);
        }

        section.add(component);

        this.stylize(component);
        this.layout();
    }

    maximizeComponent(component: Component) {
        const bbox = component.bbox;
        const section = this.findSection(component);
        section.resize(component, {xmin: 0, xmax: this.width, ymin: bbox.ymin, ymax: bbox.ymax});

        this.layout();
    }

    countComponents(): number {
        return _.sumBy(this.sections, s => s.components.length);
    }

    touchComponent(component: Component) {
        const section = this.findSection(component);
        if (section)
            section.touch(component);
    }

    removeComponent(component: Component) {

        const section = this.findSection(component);

        if (section != null)
            array.removeItem(section.components, component);

        this.layout();
    }

    changeTheme(theme: EmailTheme, update: boolean = false) {
        this.theme = theme;

        for (let i = 0; i < this.sections.length; ++i) {
            const section = this.sections[i];
            for (let j = 0; j < section.components.length; ++j) {
                const component = section.components[j];
                this.stylize(component);
            }
        }

        this.layout();
    }

    changeWidth(width: number) {
        this.width = width;
        this.layout();
    }

    clone(into: EmailTemplate, serialize: boolean) {

        into.name = this.name;
        into.themeId = this.themeId;
        into.cellSize = this.cellSize;
        into.width = this.width;
        into.subject = this.subject;
        into.style = _.clone(this.style);

        into.optOutMessageId = this.optOutMessageId;
        into.from_address = this.from_address || "";
        into.reply_address = this.reply_address || "";
        into.delay = this.delay || 0;

        into.legacyText = this.legacyText || "";

        into.rawHtml = this.rawHtml || null;
        into.useRawHtml = this.useRawHtml || false;

        if (!serialize) {
            for (let i = 0; i < this.sections.length; ++i)
                this.removeSection(this.sections[i]);
        }

        const sections = into.sections = [] as EmailSection[];
        const oSections = _.sortBy(this.sections, s => s.order);

        for (let i = 0; i < oSections.length; ++i) {
            const section = oSections[i].copy(serialize);
            if (!serialize) into.addSection(section);
            else sections.push(section);
        }

        if (serialize) {
            (into as any)["id"] = this.id;

            const target = into as any;
            target.variables = [];

            for (let variable of this.variables)
                target.variables.push({name: variable.name, temporary: variable.temporary});

        } else {
            into.variables = _.clone(this.variables);
        }
    }

    copy(serialize: boolean) {
        const copy: any = serialize ? {} : new EmailTemplate(this.id);
        this.clone(copy, serialize);
        return copy;
    }

    purgeImages(urls: string[]) {
        this.purgeImage(url => !_.includes(urls, url));
    }

    setRawHtml(html: string | null) {
        this.rawHtml = html ?? "";
        this.useRawHtml = html && html.length > 0;
    }

    * findComponents(typename: string) {
        for (let section of this.sections) {
            for (let component of section.components) {
                if (component.type === typename)
                    yield component;
            }
        }
    }

    * allImages() {
        for (let component of this.findComponents(ImageBlock.typename()))
            yield component as ImageBlock;
    }

    fixBrokenTemplate() {
        let purge = true;
        while (purge) {
            purge = false;
            for (let section of this.sections) {
                for (let component of section.components) {
                    if (isNaN(component.width)) {
                        this.removeComponent(component);
                        purge = true;
                    }
                }
            }
        }

        if (typeof this.width === "undefined")
            this.width = 650;
    }

    private purgeImage(filter: (url: string) => boolean) {
        _.each(this.sections, (section: EmailSection) => {
            _.each(section.components, (component: ImageBlock) => {
                if (component.type === ImageBlock.typename()) {
                    if (filter(component.imageUrl)) {
                        component.imageUrl = null;
                        component.imageTransformedUrl = null;
                    }
                }
            });
        });
    }

    private stylize(component: Component) {
        if (component.type === TextBlock.typename()) {
            this.stylizeTextBlock(component as TextBlock);
        }
    }

    private stylizeTextBlock(textBlock: TextBlock) {
        textBlock.style.backgroundColor = this.theme.textBlockBackgroundColor;
        textBlock.style.color = this.theme.color;
        textBlock.style.fontFamily = this.theme.fontFamily;
    }

    sectionHeightDelta(start: EmailSection, end: EmailSection): number {
        const si = this.sections.indexOf(start);
        const ei = this.sections.indexOf(end);

        if (si === -1 || ei === -1)
            return 0;

        let delta = 0;
        if (si < ei) {
            for (let i = si; i < ei; ++i)
                delta += this.sections[i].height;
        } else {
            for (let i = si - 1; i >= ei; --i) {
                delta -= this.sections[i].height;
            }
        }

        return delta;
    }

    get publicationData() {
        return {
            templateId: this.id,

            "content_html": strings.encodeBase64(this.html),
            "content_text": strings.encodeBase64(this.text),
            "content_web": strings.encodeBase64("TODO"),

            templateName: this.name,
            subject: this.subject,
            layout: this.json,

            from_address: this.from_address,
            reply_address: this.reply_address,
            bounce_address: "",
            domain: "",
            features: {
                delay: this.delay
            }
        };
    }
}
