import {CommonTransactionController, TransactionController} from "./common";
import {
    CreditcardTransaction,
    IApiBinding,
    IRequestApiBinding,
    SmsApiTransaction,
    SmsStartTransaction,
    StartTransaction,
    Transaction
} from "../../Transactions";
import {ReferenceType, RequestApiBindingKind} from "../../common";

import _ from "lodash";
import {Variable} from "../../../common/Variable";
import {array} from "../../../utility/array";
import {browser} from "../../../utility/browser";

export class SmsApiTransactionController extends CommonTransactionController<SmsApiTransaction> {

    variableMap: any;
    localVariableMap: any;
    variableNameMap: any;

    requestBindingKindMap: any;
    requestBindingKindNameMap: any;

    ccRequestBindingKindMap: any;
    ccRequestBindingKindNameMap: any;

    referencableTransactionsMap: any;
    referencableTransactionsNameMap: any;

    merchant: any;

    async onInit(): Promise<void> {
        await super.onInit();

        this.requestBindingKindMap = {
            "variable": RequestApiBindingKind.Variable,
            "transaction": RequestApiBindingKind.Transaction,
            "static value": RequestApiBindingKind.Static
        };
        this.ccRequestBindingKindMap = {
            "variable": RequestApiBindingKind.Variable,
            "transaction": RequestApiBindingKind.Transaction,
            "static value": RequestApiBindingKind.Static,
            "callee number": RequestApiBindingKind.CalleeNumber,
            "credit card number": RequestApiBindingKind.CreditCardNumber,
            "credit card expiration date": RequestApiBindingKind.CreditCardExpirationDate,
            "credit card cvv code": RequestApiBindingKind.CreditCardCVVCode
        };

        this.requestBindingKindNameMap = _.invert(this.requestBindingKindMap);
        this.ccRequestBindingKindNameMap = _.invert(this.ccRequestBindingKindMap);

        const template = this.template;
        const transaction = this.transaction;

        function createVariableMap(variables: Variable[]) {
            const keys = _.map(variables, (v: Variable) => v.name);
            const names = _.map(variables, (v: Variable) => v.ident);
            return {
                map: _.zipObject(keys, keys),
                nameMap: _.zipObject(keys, names)
            };
        }

        this.scope.$watch(() => template.variables, (vars) => {

            const {map, nameMap} = createVariableMap(vars);
            this.variableMap = map;
            this.variableNameMap = nameMap;

            const {map: localMap} = createVariableMap(vars.filter((v: Variable) => v.temporary));
            this.localVariableMap = localMap;


        }, true);

        const rfid = _.map(template.referencableTransactions, t => t.referenceId);
        this.referencableTransactionsMap = _.zipObject(rfid, rfid);
        this.referencableTransactionsNameMap = _.zipObject(rfid, _.map(template.referencableTransactions, t => t.tranName));

        _.each(transaction.requestBindings, (binding: IRequestApiBinding) => this.registerRequestBinding(binding));

        if (transaction instanceof CreditcardTransaction) {
            this.merchant = transaction.merchant;
            this.scope.$watch(() => this.merchant, m => {
                transaction.changeMerchant(m);
            });
        }
    }

    addBinding() {
        const locals = this.template.variables.filter((v: Variable) => v.temporary);

        if (locals.length) {
            const variable = locals[0];
            this.transaction.bindings.push({variableId: variable.name, parameterName: ""});
        } else {
            this.setTimeout(async () => {
                await this.overlay.showMessageBox("No local variables", "There are no local variables defined. Define a local variable to bind to in the side panel.");
            });
        }
    }

    private makeDefaultBindingValue(kind: RequestApiBindingKind) {

        switch (kind) {

            case RequestApiBindingKind.Variable:
                const variables = this.template.variables;
                if (variables.length)
                    return variables[0].name;
                break;

            case RequestApiBindingKind.Transaction:
                const transactions = this.template.referencableTransactions;
                if (transactions.length)
                    return transactions[0].referenceId;
                break;

            case RequestApiBindingKind.Static:
                return "";
        }

        return null;
    }

    registerRequestBinding(binding: IRequestApiBinding) {
        this.scope.$watch(() => binding.kind, (kind: RequestApiBindingKind, old: RequestApiBindingKind) => {
            if (kind === old)
                return;
            binding.value = this.makeDefaultBindingValue(kind);
        });
    }

    addRequestBinding() {

        const transaction = this.transaction;
        let binding: IRequestApiBinding;


        if (this.template.variables.length) {
            const variable = this.template.variables[0];
            binding = {value: variable.name, kind: RequestApiBindingKind.Variable, parameterName: ""};
        } else if (this.template.referencableTransactions.length) {
            const referencedTransaction = this.template.referencableTransactions[0] as Transaction;
            binding = {
                value: referencedTransaction.referenceId,
                kind: RequestApiBindingKind.Transaction,
                parameterName: ""
            };

        } else {
            binding = {value: "", kind: RequestApiBindingKind.Static, parameterName: ""};
        }

        transaction.requestBindings.push(binding);
        this.registerRequestBinding(binding);
    }

    getName(variableId: string) {

        const variable = this.template.findByReference(variableId);

        if (!variable)
            return variableId;

        if (variable.type === ReferenceType.Variable)
            return variable.value.name;

        if (variable.type === ReferenceType.Transaction)
            return variable.value.tranName;

        return variableId;
    }

    deleteBinding(binding: IApiBinding) {
        array.removeItem(this.transaction.bindings, binding);
    }

    deleteRequestBinding(binding: IRequestApiBinding) {
        array.removeItem(this.transaction.requestBindings, binding);
    }

    get hasAudio() {
        return "audio" in this.transaction;
    }

}


export class CommonStartTransactionController<TTransaction extends StartTransaction | SmsStartTransaction> extends TransactionController<TTransaction> {

    async showInfoAboutCallerId() {
        await this.overlay.showModal("How to set the caller Id?",
            "To set or change the caller id, assign a number or an alphanumeric caller id to this template through the playground.");
    }

    async copyToClipboard(text: string) {
        await this.overlay.freeze(browser.copyToClipboard(text), "Copied to clipboard");
    }
}
