import {VoiceEditorApi} from "../apis/VoiceEditorApi";
import {CanvasEditorController} from "../canvas/CanvasEditorController";
import {ITtsEngine, VoiceTemplate} from "../canvas/CanvasTemplate";
import {TransactionDirective} from "../canvas/components/transactions/common";

import {getVoiceTransactionDirectives} from "../canvas/components/transactions/voice";
import {TemplateEditorScope} from "../common/EditorController";
import {TemplateTypename} from "../const";
import {NGX} from "../utility/ng";
import {VoiceSettingsWizard} from "./wizard";
import IInjectorService = angular.auto.IInjectorService;

class VoiceEditorController extends CanvasEditorController<VoiceTemplate> {

    private _ttsEngines: ITtsEngine[] = [];
    private _voiceService: VoiceEditorApi;

    transactions: TransactionDirective[] = [];

    async openSettings(): Promise<void> {
        await this.overlay.show(VoiceSettingsWizard, {
            template: this.template
        });
    }

    protected injectFields(injector: IInjectorService) {
        super.injectFields(injector);
        this._voiceService = injector.get(VoiceEditorApi.injectAs);
    }

    async onInit(): Promise<void> {
        await super.onInit();

    }

    protected async onEditorStart(templateId: string, type: TemplateTypename): Promise<void> {
        const parentTask = super.onEditorStart(templateId, type);
        const initializeTask = this._voiceService.initialize();
        await Promise.all([parentTask, initializeTask]);
        const {engines, modules} = await initializeTask;

        this._ttsEngines = engines;
        this.transactions = Array.from(getVoiceTransactionDirectives(true, modules));
    }

    async onEditorTemplateLoaded(): Promise<void> {
        await super.onEditorTemplateLoaded();
        if (!this.template.syncTts(this._ttsEngines))
            await this.overlay.halt("Unable to sync TTS voices");
    }

    get typename(): TemplateTypename {
        return "voice";
    }

    constructor(scope: TemplateEditorScope<VoiceTemplate>) {
        super(scope);
    }
}

export const VoiceEditor = NGX.makeTagDirective("voice/editor", VoiceEditorController, null, null, {
    controllerAs: "editor"
});
