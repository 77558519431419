import {AppDirectiveBase} from "../../common/AppComponentBase";
import {IScope} from "angular";
import {VoiceTemplate} from "../../canvas/CanvasTemplate";
import {TerminalHandler} from "../../canvas/terminal-handler";
import {NGX} from "../../utility/ng";
import {IReferenceGroup, VariableReferenceGroup} from "../../common/References";
import {strings} from "../../utility/strings";
import {Strategy} from "../../canvas/common";

import _ from "lodash";


interface TerminalListScope extends IScope {
    template: VoiceTemplate;
    type: "error" | "response";
}

interface TerminalHandlerScope extends IScope {
    handler: TerminalHandler;
    type: "error" | "response";
    template: VoiceTemplate;
}

class TerminalHandlerListController extends AppDirectiveBase<TerminalListScope> {

    get type(): "error" | "response" {
        return this.scope.type ?? "error";
    }

    get template(): VoiceTemplate {
        return this.scope.template;
    }

    get handlers() {
        return this.type === "error" ?
            this.scope.template.errorHandlers :
            this.scope.template.noResponseHandlers;
    }

    isDefault(handler: TerminalHandler): boolean {
        return handler.ident === 0;
    }

    async showEditor(handler: TerminalHandler) {
        await this.overlay.show(TerminalHandlerEditorDirective, {
            template: this.template,
            handler: handler,
            type: this.type
        });
    }

    addHandler() {
        if (this.type === "error")
            this.template.addErrorHandler();
        else
            this.template.addNoResponseHandler();
    }

    async deleteHandler(handler: TerminalHandler) {

        if (this.isDefault(handler))
            return;

        const ok = await this.overlay.showModalQuestion({
            showCloseButton: false,
            title: "Delete Handler?",
            style: "red",
            text: "Are you sure you want to delete " + handler.name + "?",
            icon: "warn",
            options: {"yes": "Delete", "no": "Keep"},
        }) === "yes";

        if (!ok)
            return;

        if (this.type === "error")
            this.template.removeErrorHandler(handler.ident);
        else
            this.template.removeNoResponseHandler(handler.ident);
    }

    getName(handler: TerminalHandler) {
        if (handler.name)
            return handler.name;
        else
            return `${strings.uppercaseFirstLetter(this.type)} Handler #${handler.ident}`;
    }

    get newHandlerLabel(): string {
        return this.type === "error" ?
            "Add Error Handler" :
            "Add No Response Handler";
    }

}

class TerminalHandlerEditor extends AppDirectiveBase<TerminalHandlerScope> {

    strategyMap: any;
    strategyNameMap: any;

    tranMap: any;
    tranNameMap: any;

    private readonly _refs: IReferenceGroup[] = [];

    get type(): "error" | "response" {
        return this.scope.type ?? "error";
    }

    get template(): VoiceTemplate {
        return this.scope.template;
    }

    get references() {
        return this._refs;
    }

    get raw() {
        return false;
    }

    close() {
        this.overlay.close(this.scope);
    }

    constructor(scope: TerminalHandlerScope) {
        super(scope);
        this._refs = [
            new VariableReferenceGroup(this.template.variables)
        ];

        this.strategyMap = {"hang up": Strategy.HangUp};
        if (scope.handler.allowGoTo)
            this.strategyMap["go to"] = Strategy.GoTo;

        this.strategyNameMap = _.invert(this.strategyMap);

        this.tranMap = {};
        this.tranNameMap = {};
        for(let tran of this.template.getAllGotoTransactions()){
            this.tranMap[tran.tranName] = tran.ident;
            this.tranNameMap[tran.ident] = tran.tranName;
        }
    }

    get strategy() {
        return this.strategyNameMap[this.scope.handler.strategy];
    }

    get transaction() {
        return this.tranNameMap[this.scope.handler.target];
    }


    async onInit(): Promise<void> {
        await super.onInit();
        console.warn("REFS", this._refs);
    }
}

const lister = NGX.makeTagDirective("voice/terminal-handler-list", TerminalHandlerListController, {
    template: "=",
    type: "@"
}, null, {
    controllerAs: "list",
});

export const TerminalHandlerEditorDirective = NGX.makeTagDirective("voice/terminal-handler-editor", TerminalHandlerEditor, {
    handler: "=",
    type: "@",
    template: "=",
}, null, {
    controllerAs: "editor",
});

export function* getTerminalHandlerDirectives() {
    yield lister;
    yield TerminalHandlerEditorDirective;
}
