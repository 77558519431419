import {AppComponentBase} from "../common/AppComponentBase";
import {TemplateTypename} from "../const";
import {EmailEditorApi} from "./EmailEditorApi";
import {TemplateApi} from "./TemplatesApi";
import {VoiceEditorApi} from "./VoiceEditorApi";


export interface ApiService {
    injectAs: string;

    new(...params: any): AppComponentBase;
}

export function getApiServices(type: TemplateTypename): ApiService[] {

    const apis: ApiService[] = [
        TemplateApi
    ];

    if (type === "voice")
        apis.push(VoiceEditorApi);

    if (type === "email")
        apis.push(EmailEditorApi);

    return apis;
}
