import {
    IAngularEvent,
    ICompileService,
    IDirective,
    IPromise,
    IQService,
    IRootScopeService,
    IScope,
    ITimeoutService
} from "angular";
import $ from "jquery";
import _ from "lodash";
import {reloadSelfOrSignalParent} from "../utility/reload";

import {strings} from "../utility/strings";
import {AngularDirective, NGX} from "../utility/ng";
import {array} from "../utility/array";
import {GlobalEvents, NGC} from "../const";
import {FullPageModal, ModalFrost, ModalQuestion} from "../directives/modals";
import {AppError, ModifiedElsewhereError} from "../common/errors";
import {IDict} from "../utility/common";

export interface IModalDialog {
    title: string;

    text?: string;
    options?: { [key: string]: string };
    showCloseButton?: boolean;
    icon?: "info" | "warn",
    style?: "red" | "blue";
}

type OverlayStackElement = {
    host: JQuery;
    scope: IScope;
}

export class OverlayService {

    static injectAs = "OverlayService";

    private readonly _stack: OverlayStackElement[] = [];
    private readonly _permWarningStack: string[] = [];
    private readonly _classNames = {
        baseHost: "overlay-base-host",
        directiveWindow: "overlay-directive-window",
        container: "overlay-container",
        contained: "overlay-contained"
    };

    private _permissionWarningContainer: JQuery = null;

    // noinspection JSUnusedGlobalSymbols
    static $inject = [NGC.compile, NGC.q, NGC.timeout, NGC.rootScope];

    constructor(
        private readonly compile: ICompileService,
        private readonly q: IQService,
        private readonly timeout: ITimeoutService,
        private readonly root: IRootScopeService) {

        require("../../styles/overlays.less");
    }


    private buildOverlayElement(instance: IDirective, name: string, scope: IScope, params: any) {

        function buildOverlayTag(tag: string, params: IDict): string {
            let element = `<${tag}`;

            for (let key in params)
                if (params.hasOwnProperty(key))
                    element += ` ${strings.camel2snake(key)}="${params[key]}"`;

            return `${element}></${tag}>`;
        }

        function processParams(scope: IScope, params: any) {

            if (params === null) {
                params = {};

                const protoScope = scope.$new(true);
                for (let prop in scope)
                    if (scope.hasOwnProperty(prop) && !protoScope.hasOwnProperty(prop))
                        params[prop] = prop;
            }

            return params;
        }

        params = processParams(scope, params);

        let html = null;
        if (instance.restrict && instance.restrict.indexOf("E") > -1)
            html = buildOverlayTag(name, params);

        if (html === null)
            throw new Error(`Error Processing Directive: ${name}`);

        const container = $("<div>")
            .addClass(this._classNames.container);

        $(html)
            .addClass(this._classNames.contained)
            .appendTo(container);

        return container;

    }

    private getBaseHost(): JQuery {
        const query = () => $(`.${this._classNames.baseHost}`);

        if (!query().length) {
            $("<div>")
                .addClass(this._classNames.baseHost)
                .appendTo(document.body);
        }

        return query();
    }

    private removeBaseHost() {
        this.getBaseHost().remove();
    }

    private setTimeout(fn: () => void, delay: number = 0) {
        this.timeout(fn, delay);
    }

    private showInternal(directive: AngularDirective, scope: IScope, params: any = {}, autoClose: boolean = true): Promise<any> {

        const deferred = this.q.defer<any>();

        const zIndex = 1000 * (this._stack.length + 1);

        const isModal = [FullPageModal, ModalFrost, ModalQuestion].indexOf(directive) != -1;

        const item: OverlayStackElement = {
            host: $("<div>")
                .addClass("active")
                .addClass(this._classNames.directiveWindow)
                .addClass(isModal ? "modal" : "")
                .css({"z-index": zIndex})
                .appendTo(this.getBaseHost()),
            scope: scope
        };

        this._stack.push(item);

        const instance = directive.maker();
        const element = this.buildOverlayElement(instance, directive.name, scope, params)
            .appendTo(item.host);

        const de = this.compile(element)(item.scope);

        element.children().on("click", (evt: JQueryEventObject) => {
            evt.stopPropagation();
        });

        const close = (evt: IAngularEvent | null = null, value: any | null = null) => {

            NGX.safeDigest(scope);

            item.scope.$broadcast("$destroy");
            item.scope.$destroy();

            item.host
                .removeClass("active")
                .addClass("disposed");

            item.host.off();
            de.off();

            deferred.resolve(value);

            if (evt) evt.stopPropagation();

            array.removeItem(this._stack, item);

            this.setTimeout(() => {
                item.host.remove();
                if (this._stack.length === 0)
                    this.removeBaseHost();
            }, 100);
        };

        if (autoClose) {
            item.host.on("click", () => {
                close();
            });
        }

        item.scope.$on(GlobalEvents.overlayClose, close);

        NGX.safeDigest(scope);

        return NGX.getPromise(deferred);
    }

    private getPermissionsWarningContainer() {
        if (!this._permissionWarningContainer)
            this._permissionWarningContainer = $("<div>")
                .addClass("permissions-warning-container")
                .appendTo(document.body);
        return this._permissionWarningContainer;
    }

    async show(directive: AngularDirective, properties: any = {}, autoClose: boolean = false): Promise<any> {

        this.root.$broadcast(GlobalEvents.closeToolWindows);

        const scope = this.root.$new(true);

        for (let key in properties)
            if (properties.hasOwnProperty(key))
                scope[key] = properties[key];

        try {
            return await this.showInternal(directive, scope, null, autoClose);
        } finally {
            NGX.safeDigest(this.root);

            for (let key in properties)
                if (properties.hasOwnProperty(key))
                    properties[key] = scope[key];
        }
    }

    async showModal(title: string, text: string, negation: string = null): Promise<boolean> {

        const ok = await this.show(ModalQuestion, {
            text: text,
            title: title,
            negation: negation
        }, true);

        return ok === true;
    }

    async showMessageBox(title: string, text: string): Promise<boolean> {
        return this.showModal(title, text);
    }

    async showModalQuestion(spec: IModalDialog): Promise<string | null> {

        let modal = this.show(FullPageModal, {
            spec: spec
        });

        const item = _.last(this._stack);

        try {
            return new Promise((resolve) => {
                item.scope.$on(GlobalEvents.overlayClose, (evt, value: string) => {
                    resolve(value);
                });
            });
        } finally {
            await modal;
        }
    }

    async handleGlobalException(e) {
        console.warn("Handle Global Exception", e);

        if (e instanceof ModifiedElsewhereError) {
            console.error("Modified elsewhere");

            await this.showModalQuestion({
                title: "Data modified elsewhere",
                text: "Your data has been modified elsewhere (another computer, browser window or tab), " +
                    "or you've had this window open for too long. You need to reload this page to continue.",
                options: {
                    "reload": "Reload this page to continue"
                },
                icon: "warn",
                style: "red"
            });

            reloadSelfOrSignalParent();

        } else if (e instanceof AppError) {
            console.error(e.userMessage, e.message);
            await this.halt(e.userMessage);
        } else throw e;
    }

    async freeze<T>(promise: Promise<T> | IPromise<T> | (() => Promise<T>), text: string = null): Promise<T> {

        let modal = this.show(ModalFrost, {"text": text});
        const item = _.last(this._stack);

        try {
            const task = promise instanceof Function ? promise() : promise;
            return await task;
        } catch (e) {
            await this.handleGlobalException(e);
        } finally {
            item.scope.$emit(GlobalEvents.overlayClose);
            await modal;
        }
    }

    async halt(message: string): Promise<never> {
        console.error("HALT", message);
        await this.showModalQuestion({
            title: "An Error has occurred",
            text: message,
            options: {
                "reload": "Reload this page to continue"
            },
            icon: "warn",
            style: "red"
        });

        reloadSelfOrSignalParent();
        return await new Promise<never>(() => {
        });
    }

    showPermissionsWarning(text: string) {

        if (_.indexOf(this._permWarningStack, text) !== -1)
            return;

        this._permWarningStack.push(text);

        const pwc = this.getPermissionsWarningContainer();
        const element = $("<upwire-permission-warning>").appendTo(pwc);
        element.attr("message", text);

        let scope = this.root.$new(true);
        scope.$on(GlobalEvents.permissionsWarningClose, () => {
            const idx = _.indexOf(this._permWarningStack, text);
            this._permWarningStack.splice(idx, 1);

            scope.$broadcast("$destroy");
            scope.$destroy();
            element.remove();
        });

        this.compile(element)(scope);
        NGX.safeDigest(scope);
    }

    close(scope: IScope, value: any = undefined) {
        scope.$emit(GlobalEvents.overlayClose, value);
        setTimeout(() => {
            NGX.safeDigest(scope.$root);
        });
    }
}
