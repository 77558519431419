import {CanvasConst, ICanvasViewport, sys2view, TerminalDirection, TerminalType} from "./common";
import {ITerminal} from "./Transactions";
import {IPoint} from "../utility/common";
import {CanvasTemplate} from "./CanvasTemplate";
import {geometry} from "../utility/geometry";

class CanvasPainter {

    protected canvas: HTMLCanvasElement;
    protected ctx: CanvasRenderingContext2D;

    private width: number = null;
    private height: number = null;

    constructor(protected elem: JQuery) {
        //
    }

    protected init(width: number, height: number) {

        if (this.width === width && this.height === height) {
            return;
        }

        this.elem.empty();

        const canvas = $("<canvas>")
            .attr({width: width, height: height})
            .appendTo(this.elem);

        this.canvas = <HTMLCanvasElement>canvas[0];
        this.ctx = this.canvas.getContext("2d");

        this.height = height;
        this.width = width;
    }

    protected drawLine(xs: number, xe: number, ys: number, ye: number) {

        this.ctx.beginPath();
        this.ctx.moveTo(Math.round(xs), Math.round(ys) + 0.5);
        this.ctx.lineTo(Math.round(xe), Math.round(ye) + 0.5);
        this.ctx.stroke();
    }

    protected drawDisc(xs: number, ys: number, r: number) {

        this.ctx.beginPath();
        this.ctx.arc(xs, ys, r, 0, 2 * Math.PI, false);
        this.ctx.fill();
    }
}

export class GridLinePainter extends CanvasPainter {

    draw(viewport: ICanvasViewport) {

        const color = "#f6f6f6";

        const w = viewport.width;
        const h = viewport.height;

        this.init(w, h);

        this.ctx.clearRect(0, 0, w, h);
        this.ctx.lineWidth = 1;

        this.ctx.strokeStyle = color;

        const bound = CanvasConst.CanvasSysExtent;

        const lpa = sys2view(viewport, -bound, -bound);
        const lpb = sys2view(viewport, -bound, bound);
        const lpc = sys2view(viewport, bound, bound);
        const lpd = sys2view(viewport, bound, -bound);

        this.ctx.strokeStyle = color;

        const dm = CanvasConst.CellSize * viewport.zoom;

        const yOffset = ((viewport.yoffset - viewport.yf) * viewport.zoom) % dm;
        for (let i = 0; i < viewport.height / dm; ++i) {
            const hp = i * dm + yOffset;
            if (hp > lpa.y && hp < lpc.y) {
                this.drawLine(Math.max(0, lpa.x), Math.min(viewport.width, lpc.x), hp, hp);
            }
        }

        const xOffset = ((viewport.xoffset - viewport.xf) * viewport.zoom) % dm;
        for (let j = 0; j < viewport.width / dm; ++j) {
            const vp = j * dm + xOffset;
            if (vp > lpa.x && vp < lpc.x) {
                this.drawLine(vp, vp, Math.max(0, lpa.y), Math.min(viewport.height, lpc.y));
            }
        }

        const cp = sys2view(viewport, 0, 0);
        this.ctx.beginPath();
        this.ctx.arc(cp.x, cp.y, 6 * viewport.zoom, 0, 2 * Math.PI, false);
        this.ctx.fillStyle = color;
        this.ctx.fill();

        this.ctx.strokeStyle = color;
        this.drawLine(lpa.x, lpb.x, lpa.y, lpb.y);
        this.drawLine(lpb.x, lpc.x, lpb.y, lpc.y);
        this.drawLine(lpc.x, lpd.x, lpc.y, lpd.y);
        this.drawLine(lpd.x, lpa.x, lpd.y, lpa.y);
    }

}

export class ConnectorPainter extends CanvasPainter {

    private danglingStartTerminal: ITerminal;
    private danglingEndPoint: IPoint;

    private readonly color = "#3f51b5";
    private readonly thickness = 4;

    beginDanglingConnection(template: CanvasTemplate, terminal: ITerminal) {
        this.danglingStartTerminal = terminal;
    }

    moveDanglingConnection(viewport: ICanvasViewport, xSys: number, ySys: number) {
        this.danglingEndPoint = {x: xSys, y: ySys};
    }

    endDanglingConnection() {
        this.danglingStartTerminal = null;
        this.danglingEndPoint = null;
    }

    draw(viewport: ICanvasViewport, template: CanvasTemplate) {
        const w = viewport.width,
            h = viewport.height;

        this.init(w, h);
        this.ctx.clearRect(0, 0, w, h);

        if (this.danglingStartTerminal && this.danglingEndPoint) {

            const start = this.getTerminalSystemPoint(viewport, this.danglingStartTerminal);
            const lps = sys2view(viewport, start.x, start.y);
            const lpe = sys2view(viewport, this.danglingEndPoint.x, this.danglingEndPoint.y);

            this.ctx.strokeStyle = this.color;
            this.ctx.lineWidth = this.thickness * viewport.zoom;

            this.drawLine(lps.x, lpe.x, lps.y, lpe.y);

            this.ctx.fillStyle = this.color;
            this.drawDisc(lps.x, lps.y, 4 * viewport.zoom);
        }

        for (let i = 0; i < template.transactions.length; ++i) {
            const terminals = template.transactions[i].terminals;
            for (let j = 0; j < terminals.length; ++j) {
                const terminal = terminals[j];
                if (terminal.type === TerminalType.Outbound && terminal.target) {
                    if (terminal.layout && terminal.target.layout) {
                        this.drawConnection(viewport, terminal, terminal.target);
                    }
                }
            }
        }

    }

    private getTerminalSystemPoint(viewport: ICanvasViewport, terminal: ITerminal): IPoint {

        const tl = terminal.layout;
        const nl = terminal.node.layout;

        const x = terminal.node.x + (tl.offsetInHost.x + tl.width / 2 - nl.viewWidth / 2) / viewport.zoom;
        const y = terminal.node.y + (tl.offsetInHost.y + tl.height / 2 - nl.viewHeight / 2) / viewport.zoom;

        return {x: x, y: y};
    }

    private drawConnection(viewport: ICanvasViewport, outbound: ITerminal, inbound: ITerminal) {

        const inPoint = this.getTerminalSystemPoint(viewport, inbound);
        const outPoint = this.getTerminalSystemPoint(viewport, outbound);

        const dst = geometry.distance(outPoint, inPoint);

        const fac = 0.5;
        const mh = 150;
        const minOffset = 1000;

        let aFactor = 0;
        if (inPoint.y < outPoint.y + mh) {
            const diff = mh + outPoint.y - inPoint.y;
            aFactor = Math.min(diff * 0.005, 1);
        }

        const cpyOffset = Math.min(dst * (fac + aFactor), minOffset);
        let cpxOffset = 0;
        let right = false;

        if (outbound.direction === TerminalDirection.Right) {
            cpxOffset = Math.min(dst * fac, minOffset);
            right = true;
        }

        const lPointCenterStart = sys2view(viewport, inPoint.x, inPoint.y - cpyOffset);
        const lPointCenterEnd = sys2view(viewport, outPoint.x + cpxOffset, outPoint.y + (right ? 0 : cpyOffset));
        const lPointStart = sys2view(viewport, inPoint.x, inPoint.y);
        const lPointEnd = sys2view(viewport, outPoint.x, outPoint.y);


        // The disk points are not visible.
        // this.ctx.fillStyle = this.color;
        // this.drawDisc(lPointStart.x, lPointStart.y, 4 * viewport.zoom);
        // this.drawDisc(lPointEnd.x, lPointEnd.y, 4 * viewport.zoom);

        // Stroke the connection line.
        this.ctx.strokeStyle = this.color;
        this.ctx.lineWidth = this.thickness * viewport.zoom;
        this.ctx.beginPath();
        this.ctx.moveTo(lPointStart.x, lPointStart.y);
        this.ctx.bezierCurveTo(lPointCenterStart.x, lPointCenterStart.y, lPointCenterEnd.x, lPointCenterEnd.y, lPointEnd.x, lPointEnd.y);
        this.ctx.stroke();
    }

}
