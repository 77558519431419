import {NGX} from "../utility/ng";
import {CanvasEditorController} from "../canvas/CanvasEditorController";
import {SmsTemplate} from "../canvas/CanvasTemplate";
import {TemplateEditorScope} from "../common/EditorController";
import {TemplateTypename} from "../const";
import {SmsSettingsWizard} from "./wizard";
import {getSmsTransactions} from "../canvas/components/transactions/sms";
import {TransactionDirective} from "../canvas/components/transactions/common";


class SmsEditorController extends CanvasEditorController<SmsTemplate> {

    readonly transactions: TransactionDirective[] = Array.from(getSmsTransactions(true));

    async openSettings(): Promise<void> {
        await this.overlay.show(SmsSettingsWizard, {
            template: this.template
        });
    }

    get typename(): TemplateTypename {
        return "sms";
    }

    constructor(scope: TemplateEditorScope<SmsTemplate>) {
        super(scope);
    }

}

export const SmsEditor = NGX.makeTagDirective("sms/editor", SmsEditorController, null, null, {
    controllerAs: "editor"
});
