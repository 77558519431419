import _ from "lodash";
import {Variable} from "../common/Variable";
import {strings} from "../utility/strings";

import {Audio} from "./audio";

import {CanvasTemplate, SmsTemplate, VoiceTemplate} from "./CanvasTemplate";

import {
    CallType,
    IStatusTag,
    IVoiceTemplateDefaults,
    ReferenceType,
    RequestApiBindingKind,
    SmsDeliveryTimezone,
    Strategy,
    TerminalDirection
} from "./common";
import {TerminalHandler} from "./terminal-handler";

import {
    ApiTransaction,
    BiometricsTransaction,
    BotTransaction,
    CommonTransaction,
    CreditcardTransaction,
    CreditCardTransactionMerchantFacility,
    DateInputFormat,
    DateLogicToken,
    EndTransaction,
    IEndpointConfiguration,
    InformationalTransaction,
    initTransaction,
    InputTransaction,
    InputTransactionSubtype,
    IRequestApiBinding,
    ITerminal,
    materializeTransaction,
    MenuTransaction,
    NumberLogicBranch,
    RecordTransaction,
    SmsApiTransaction,
    SmsBaseTransaction,
    SmsBranchTransaction,
    SmsMessageTransaction,
    SmsStartTransaction,
    StartTransaction,
    Transaction,
    TransferTransaction,
    VoiceBaseTransaction,
    VoiceItTransaction
} from "./Transactions";

const MISSING: string = "";
const voiceModule: string = "voice_out_Test_rmq_v3_AMD_NX";
const defaultMaxCallDuration: number = 300;

interface ISerializedTerminal {
    ident: string;

    targetId?: number;
    targetIdent?: string;

    ghost: boolean;
    active: boolean;
    text?: string;

    meta?: any;
    direction?: TerminalDirection;
}

interface ISerializedHandler {
    ident: number;
    name: string;
    strategy: Strategy;
    allowGoTo: boolean;

    audio: any;
    targetTransactionId: number;
}

interface ISerializedVariable {
    name: string;
    required: boolean;
}

interface ISerializedTransaction {
    id: number;
    type: string;
    properties: any;
    terminals: ISerializedTerminal[];
}

interface ICanvasTemplate {
    name: string;
    sync: number;
    callerId: string;
    tags: IStatusTag[];
    version: number;
}

export interface IVoiceTemplate extends ICanvasTemplate {
    callMode: CallType;
    maxErrorRetries: number;
    maxNoResponseRetries: number;
    maxDuration: number;
    recordWholeCall: boolean;
    ttsEngine: string;
    ttsVoice: string;
    defaults: IVoiceTemplateDefaults;
}

export interface ISmsTemplate extends ICanvasTemplate {
    sendLarge: boolean;
    twoWay: boolean;
    noMatchReferenceId: string;
    optouts: string[];
    timezone: SmsDeliveryTimezone,
    callMode: CallType;
    useShortLinkUrls: boolean;
    delay: number;
    stateMode: boolean;
    stateModeAllowReset: boolean;
}

// The templateName property is called name internally, and templateName externally
type Settings<TSettingsBase extends ICanvasTemplate> = Omit<TSettingsBase, "name"> & { templateName: string; }

interface ISerializedTemplate<TSettings extends ICanvasTemplate> {
    settings: Settings<TSettings>;
    transactions: ISerializedTransaction[];
    variables: ISerializedVariable[];
}

type ISerializedVoiceTemplate = ISerializedTemplate<IVoiceTemplate> & {
    errorHandlers: ISerializedHandler[];
    noResponseHandlers: ISerializedHandler[];
}

interface ISerializedSmsTemplate extends ISerializedTemplate<ISmsTemplate> {

}

function cloneDeep(obj: any) {

    function walkObjectRecursivelyAndRemoveProperty(obj: any): void {
        const property = "$$hashKey";

        if (obj === null || obj === undefined) {
            return;
        }

        if (_.isArray(obj)) {
            for (let element of obj) {
                walkObjectRecursivelyAndRemoveProperty(element);
            }
            return;
        }

        if (typeof obj != "object") {
            return;
        }

        for (let key in obj) {

            if (key === property) {
                delete obj[property];
            }

            const value = obj[key];
            if (typeof value === "object") {
                walkObjectRecursivelyAndRemoveProperty(value);
            }
        }
    }

    const clone = _.cloneDeep(obj);
    walkObjectRecursivelyAndRemoveProperty(clone);
    return clone;
}


function flag(value: boolean): string {
    return value ? "True" : "False";
}

function onFlag(value: boolean): string {
    return value ? "on" : "off";
}

function yesFlag(value: boolean): string {
    return value ? "yes" : "no";
}

function none(value: string = null): string {
    if (value) return value;
    return "null";
}

function branch(terminal: ITerminal) {
    if (terminal.target) {
        if (terminal.target.ident === "end") {
            return "end";
        }
        return terminal.target.node.ident.toString();

    }
    return "end";
}

function def(value: any, defaultValue: any, none: any = "", ...not: any[]) {
    if (value) {
        if (not) {
            if (!_.includes(not, value))
                return value;
        } else {
            return value;
        }
    }
    if (defaultValue) return defaultValue;
    return none;
}

function extendInformational(obj: any, node: InformationalTransaction) {
    _.extend(obj, {
        branchingLogic: "all",
        branching: {all: branch(node.outbound)}
    });
}

function extendMenu(obj: any, tran: MenuTransaction, template: VoiceTemplate) {

    const branching = {};
    const keys: string[] = [];
    const tranValue = {};

    let tranValueMap = false;

    for (let i = 0; i < tran.outbound.length; ++i) {
        const terminal = tran.outbound[i];
        const key = terminal.ident;

        if (terminal.text) {
            tranValue[key] = terminal.text;
            tranValueMap = true;
        }

        if (terminal.active) {
            branching[key] = branch(terminal);
            keys.push(key);
        }
    }

    if (tranValueMap) {
        for (let i = 0; i < keys.length; ++i) {
            const key = keys[i];
            if (!tranValue[key]) {
                tranValue[key] = "";
            }
        }
    }

    _.extend(obj, {
        "branchingLogic": "digits",
        "branching": branching,
        "tranValueMap": tranValueMap ? "yes" : "no",
        "tranValue": tranValue,
        "keys": keys.join(", "),

        "scriptVariablesData": MISSING,

        "dtmfTimeout": def(tran.dtmfTimeout, template.defaults.dtmfTimeout),
        "helperKey": def(tran.helperButton, template.defaults.dtmfHelpButton, "", "unbound"),
        "errorRetries": def(tran.errorRetryCount, template.defaults.errorRetryCount),
        "errorHandler": (tran.errorHandlerIdent + 1).toString(),
        "noResponseRetries": tran.longPauseRetryCount,
        "noResponseHandler": (tran.noResponseHandlerIdent + 1).toString()
    });
}

function extendWithAudio(obj: any, audio: Audio, template: VoiceTemplate, prefix: string = "", showPlayStatus: boolean = false) {

    audio = audio.nest(template);

    const exo = {};

    if (showPlayStatus) {
        exo[strings.snake2camel(prefix + "-play-message")] = audio.play ? "yes" : "no";
    }

    const wav = audio.wav || "";

    exo[strings.snake2camel(prefix + "-audio")] = audio.useTts ? "tts" : "wav";
    exo[strings.snake2camel(prefix + "-tts")] = audio.useTts ? (audio.formatTts(template)) : "";
    exo[strings.snake2camel(prefix + "-wav")] = audio.useTts ? "" : wav;

    const mixed = audio.useMixedMode && audio.prePromptSegments.length > 0;

    exo[strings.snake2camel(prefix + "-pre-prompt")] = mixed ? "yes" : "no";
    if (mixed) {
        const ppd = exo[strings.snake2camel(prefix + "-pre-prompt-data")] = ({} as any);
        let idx = 1;
        _.each(audio.prePromptSegments, (seg: Audio) => {
            const item: any = {} as any;

            item.audio = seg.useTts ? "tts" : "wav";
            item.tts = seg.useTts ? (seg.formatTts(template)) : "";
            item.wav = seg.useTts ? "" : (seg.wav || "");
            item.variables = "true";

            ppd[idx++] = item;
        });
    }

    _.extend(obj, exo);
}

function extendTransfer(obj: any, tran: TransferTransaction, template: VoiceTemplate) {
    extendWithAudio(obj, tran.audio, template, "", true);
    extendWithAudio(obj, tran.agentAudio, template, "agent", true);
    _.extend(obj, {
        "number": tran.number,
        "branchingLogic": "all",
        "branching": {
            "all": "end"
        }
    });
}

function extendInput(obj: any, tran: InputTransaction, template: VoiceTemplate) {

    const branching: any = {};

    let branchingLogic: string;
    let minDigits = tran.minDigits;
    let maxDigits = tran.maxDigits;

    if (InputTransactionSubtype.Number === tran.subtype) {
        branchingLogic = "evalint";
        _.each(tran.numberLogicBranches, (b: NumberLogicBranch) => {
            branching[b.script()] = branch(b.terminal);
        });

    } else if (tran.subtype === InputTransactionSubtype.Date) {

        branchingLogic = "validation";
        const validationData: any = {
            "type": "date",
            "outputFormat": "d MM"
        };

        const format = DateInputFormat[def(tran.dateInputFormat, template.defaults.dateFormat, DateInputFormat.DDMM)];
        validationData["format"] = format;
        minDigits = maxDigits = format.length;

        const br = tran.dateLogicBranch;
        if (br.steps[0].token === DateLogicToken.From) {

            validationData["valid"] = br.steps[1].value;

            const unit = br.steps[2].token;
            if (unit === DateLogicToken.Days)
                validationData["validType"] = "days";
            else if (unit === DateLogicToken.Weeks)
                validationData["validType"] = "weeks";
            else if (unit === DateLogicToken.Months)
                validationData["validType"] = "months";

            validationData["inpast"] = (br.steps[3].token === DateLogicToken.Past) ? "true" : "false";
        }

        _.extend(obj, {"validationData": validationData});

        branching["True"] = branch(br.validTerminal);
        branching["False"] = branch(br.invalidTerminal);
    } else {
        branchingLogic = "all";
        branching["all"] = branch(tran.outTerminal);
    }

    _.extend(obj, {
        "branchingLogic": branchingLogic,
        "branching": branching,
        "minDigits": minDigits,
        "maxDigits": maxDigits,
        "scriptVariablesData": MISSING,
        "dtmfTimeout": def(tran.dtmfTimeout, template.defaults.dtmfTimeout),
        "dtmfEndKey": def(tran.endButton, template.defaults.dtmfEndInputButton, MISSING, "unbound"),
        "helperKey": def(tran.helperButton, template.defaults.dtmfHelpButton, MISSING, "unbound"),
        "maxTransactionAttempts": tran.maxTransactionAttempts || MISSING,
        "errorRetries": def(tran.errorRetryCount, template.defaults.errorRetryCount),
        "errorHandler": (tran.errorHandlerIdent + 1).toString(),
        "noResponseRetries": tran.longPauseRetryCount,
        "noResponseHandler": (tran.noResponseHandlerIdent + 1).toString()
    });
}

function extendRecord(obj: any, tran: RecordTransaction) {

    _.extend(obj, {
        "branchingLogic": "all",
        "branching": {all: branch(tran.outbound)},
        "maxDuration": tran.maxDuration,
        "maxSilence": tran.maxSilence,
        "terminationKey": tran.terminationKey,
        "maxTransactionAttempts": tran.maxTransactionAttempts || "",
        "errorRetries": tran.errorRetryCount,
        "errorHandler": (tran.errorHandlerIdent + 1).toString(),
        "noResponseRetries": tran.longPauseRetryCount,
        "noResponseHandler": (tran.noResponseHandlerIdent + 1).toString(),
        "playBeep": onFlag(tran.playBeep)
    });
}

function extendCreditCard(obj: any, tran: CreditcardTransaction, template: VoiceTemplate) {

    obj["type"] = "credit";
    const fakeAudio = new Audio();
    fakeAudio.play = false;

    extendWithAudio(obj, fakeAudio, template as VoiceTemplate, "", true);
    extendVoiceApi(obj, tran, template);
    extendApi(obj, tran, template);

    delete obj["url"];
    delete obj["method"];
    delete obj["username"];
    delete obj["password"];

    const merch: any = obj["merchantFacility"] = {};

    if (tran.merchant === CreditCardTransactionMerchantFacility.Custom) {
        merch["type"] = "custom";
        merch["url"] = tran.url || "";
        merch["method"] = tran.method || "";
        merch["username"] = tran.username || "";
        merch["password"] = tran.password || "";
    }

    obj["cardOptions"] = {
        "anyCard": onFlag(tran.anyCard),
        "visa": onFlag(tran.visa),
        "amex": onFlag(tran.amex),
        "masterCard": onFlag(tran.mastercard),
        "discovery": onFlag(tran.discovery),
        "diners": onFlag(tran.diners),
        "jcb": onFlag(tran.jcb),
        "encryption": onFlag(false)
    };
}

function extendBot(obj: any, tran: BotTransaction) {
    const record = tran.forward(0) as RecordTransaction;
    extendRecord(obj, record);
    obj["type"] = "record";
}

function extendVoiceApi(obj: any, tran: ApiTransaction | CreditcardTransaction, template: VoiceTemplate) {
    _.extend(obj, {
        "errorRetries": def(tran.errorRetryCount, template.defaults.errorRetryCount),
        "errorHandler": (tran.errorHandlerIdent + 1).toString(),
        "noResponseRetries": tran.longPauseRetryCount,
        "noResponseHandler": (tran.noResponseHandlerIdent + 1).toString(),
        "dtmfTimeout": def(tran.dtmfTimeout, template.defaults.errorRetryCount)
    });
}

function extendApi(obj: any, tran: ApiTransaction | SmsApiTransaction | CreditcardTransaction, template: CanvasTemplate) {

    function hasBindings(tran: any): tran is ApiTransaction | SmsApiTransaction {
        return tran.hasOwnProperty("bindings");
    }

    const responseBindings = {};
    if (hasBindings(tran)) {
        for (let i = 0; i < tran.bindings.length; ++i) {
            const binding = tran.bindings[i];
            const variable = template.findByReference(binding.variableId);
            if (variable && variable.type === ReferenceType.Variable) {
                const cvar = variable.value as Variable;
                responseBindings[binding.parameterName] = cvar.name;
            }
        }
    }

    _.extend(obj, {
        "url": tran.url,
        "method": tran.method,
        "username": tran.username || "",
        "password": tran.password || "",
        "branchingLogic": "api",
        "branching": {"True": branch(tran.okTerminal), "False": branch(tran.failTerminal)},
        "requestBindings": mapRequestBindings(tran.requestBindings, template),
        "responseBindings": responseBindings
    });
}

function isVoiceTransaction(tran: CommonTransaction): tran is VoiceBaseTransaction {

    if (tran instanceof VoiceBaseTransaction)
        return true;

    if (tran instanceof StartTransaction)
        return true;

    if (tran instanceof EndTransaction)
        return true;

    return false;
}

function deliverTz(tran: SmsMessageTransaction, template: SmsTemplate) {
    switch (template.timezone) {

        case SmsDeliveryTimezone.Off:
            return false;

        case SmsDeliveryTimezone.AllTransactions:
            return true;

        case SmsDeliveryTimezone.FirstTransaction: {
            for (let nt of template.transactions) {
                if (nt.type === SmsStartTransaction.typename()) {
                    const st = <SmsStartTransaction>nt;
                    if (st.outbound.target)
                        return st.outbound.target.node === tran;
                    return false;
                }
            }
        }
            return false;
    }

    return false;
}

function smsMessageBranching(tran: SmsBaseTransaction): any {
    const branching: any = {};

    for (let terminal of tran.outboundTerminals) {
        const destination = branch(terminal);
        branching[terminal.ident] = destination;

        for (let alias of terminal.meta as string[]) {
            branching[alias] = destination;
        }
    }
    return branching;
}

function extendSmsMessage(obj: any, tran: SmsMessageTransaction, template: SmsTemplate) {

    const audio = new Audio();
    audio.tts = tran.text;
    audio.useRawInputMode = tran.useRawInputMode;
    const message = audio.formatTts(template);

    let delay = tran.delay;
    if (delay === null)
        delay = template.delay;

    _.extend(obj, {
        "message": message,
        "branchingLogic": "keyword",
        "branching": smsMessageBranching(tran),
        "features": {
            "deliverRecipientsTZ": flag(deliverTz(tran, template)),
            "delaySeconds": delay
        }
    });
}

function extendBiometrics(obj: any, tran: BiometricsTransaction, template: VoiceTemplate) {

    //We need an additional Record Object for authentication, and 3 record objects for learning

    _.extend(obj, {
        "errorRetries": def(tran.errorRetryCount, template.defaults.errorRetryCount),
        "errorHandler": (tran.errorHandlerIdent + 1).toString(),
        "noResponseRetries": tran.longPauseRetryCount,
        "noResponseHandler": (tran.noResponseHandlerIdent + 1).toString(),
        "branchingLogic": tran.authenticationMode ? "authenticate" : "train",
        "branching": {"True": branch(tran.okTerminal), "False": branch(tran.failTerminal)}
    });
}

function mapRequestBindings(bindings: IRequestApiBinding[], template: CanvasTemplate): any {

    const getMappedValue = (binding: IRequestApiBinding): string => {

        const isVariable = binding.kind === RequestApiBindingKind.Variable;
        const isTransaction = binding.kind === RequestApiBindingKind.Transaction;

        if (isVariable || isTransaction) {
            const variable = template.findByReference(binding.value);
            if (variable && variable.type === ReferenceType.Variable) {
                return `{{${variable.value.ident}}}`;
            } else if (variable && variable.type === ReferenceType.Transaction) {
                return `[[${variable.value.ident}]]`;
            }
        }

        switch (binding.kind) {
            case RequestApiBindingKind.CreditCardNumber:
                return "[[credit-card-reference]]";
            case RequestApiBindingKind.CreditCardExpirationDate:
                return "[[credit-card-expiration-reference]]";
            case RequestApiBindingKind.CreditCardCVVCode:
                return "[[credit-card-cvv-reference]]";
            case RequestApiBindingKind.CalleeNumber:
                return "{{mobile}}";
            default:
                return binding.value;
        }
    };

    const mapped = {};
    for (const binding of bindings)
        mapped[binding.parameterName] = getMappedValue(binding);
    return mapped;
}

function extendVoiceIT(obj: any, transaction: VoiceItTransaction, template: VoiceTemplate) {

    function exEndpoint(name: string, endpoint: IEndpointConfiguration) {

        _.extend(obj, {
            [name + "Endpoint"]: endpoint.endpoint,
            [name + "RequestBindings"]: mapRequestBindings(endpoint.bindings, template),
            [name + "RequestMethod"]: endpoint.method,
            [name + "RequestUsername"]: endpoint.username,
            [name + "RequestPassword"]: endpoint.password,
        });
    }

    exEndpoint("getCustomer", transaction.getCustomerEndpoint);
    exEndpoint("createCustomer", transaction.createCustomerEndpoint);

    _.extend(obj,
        {
            "trainingMode": flag(transaction.trainingMode),
            "beeps": flag(transaction.beeps),
            "customerNumberToken": transaction.customerNumberToken || "",
            "endpointCustomerIdBinding": transaction.endpointCustomerIdBinding || "",
            "endpointCustomerVoiceItBinding": transaction.endpointCustomerVoiceItBinding || "",

            "voiceITApiKey": transaction.voiceItApiKey || "",
            "voiceITApiToken": transaction.voiceItApiToken || "",
            "voiceITSaying": transaction.voiceItSaying || "",
            "voiceITContentLanguage": transaction.voiceItContentLanguage || "",

            "customerNumberRetries": transaction.customerNumberRetries,
            "verificationRetries": transaction.verificationRetries,
            "enrollments": transaction.enrollments,
            "dtmf": transaction.dtmfTimeoutInSeconds,

            "errorHandler": (transaction.errorHandlerIdent + 1).toString(),
            "noResponseHandler": (transaction.noResponseHandlerIdent + 1).toString(),

            "branchingLogic": "voiceit",
            "branching": {
                "True": branch(transaction.okTerminal),
                "False": branch(transaction.failTerminal)
            },
        });
}

function createNonSystemTransaction(template: CanvasTemplate, tran: CommonTransaction) {

    let typename = tran.type;
    typename = typename.replace("sms-", "");

    const obj = {
        "transactionId": tran.referenceId,
        "tranName": tran.tranName,
        "type": typename,
        "variables": "yes",
        "scriptVariables": "yes",
        "tagDescription": "",
        "tagStatus": ""
    };

    if (tran.tagId) {
        const tag = template.findTag(tran.tagId);
        if (tag) {
            obj["tagStatus"] = tag.name;
            obj["tagDescription"] = tag.description;
        } else {
            tran.tagId = null;
        }
    }

    if (isVoiceTransaction(tran)) {

        const isBiometrics = tran.type === BiometricsTransaction.typename();

        if (!isBiometrics)
            extendWithAudio(obj, tran.audio, template as VoiceTemplate);

        if (tran.type === MenuTransaction.typename()) {
            extendMenu(obj, tran as MenuTransaction, template as VoiceTemplate);
        }

        if (isBiometrics) {
            delete obj["variables"];
            delete obj["scriptVariables"];
            delete obj["tagDescription"];
            extendBiometrics(obj, tran as BiometricsTransaction, template as VoiceTemplate);
        }

        if (tran.type === BotTransaction.typename()) {
            extendBot(obj, tran as BotTransaction);
        }

        if (tran.type === VoiceItTransaction.typename()) {
            extendVoiceIT(obj, tran as VoiceItTransaction, template as VoiceTemplate);
        }

        if (tran.type === CreditcardTransaction.typename()) {
            extendCreditCard(obj, tran as CreditcardTransaction, template as VoiceTemplate);
        }

        if (tran.type === InformationalTransaction.typename()) {
            extendInformational(obj, tran as InformationalTransaction);
        }

        if (tran.type === TransferTransaction.typename()) {
            extendTransfer(obj, tran as TransferTransaction, template as VoiceTemplate);
        }

        if (tran.type === InputTransaction.typename()) {
            extendInput(obj, tran as InputTransaction, template as VoiceTemplate);
        }

        if (tran.type === RecordTransaction.typename()) {
            extendRecord(obj, tran as RecordTransaction);
        }

        if (tran.type === ApiTransaction.typename()) {
            extendWithAudio(obj, tran.audio, template as VoiceTemplate, "", true);
            extendVoiceApi(obj, tran as ApiTransaction, template as VoiceTemplate);
            extendApi(obj, tran as ApiTransaction, template);
        }
    }

    if (tran instanceof SmsMessageTransaction) {
        extendSmsMessage(obj, tran, template as SmsTemplate);
    }

    if (tran.type === SmsApiTransaction.typename()) {
        extendApi(obj, tran as SmsApiTransaction, template);
    }

    return obj;
}

function getStartNodeTargetIdent(target: ITerminal) {

    if (target) {
        if (target.node.type === EndTransaction.typename())
            return target.node.type;

        return target.node.ident.toString();
    }

    return none();
}

function createTransactionList(template: CanvasTemplate) {

    let extraTransactionIdent = template.transactions.length;
    const transactions = {};
    for (let i = 0; i < template.transactions.length; ++i) {
        const tran = template.transactions[i];

        let transactionKey = null;
        let transactionObject = null;

        if (tran.type === StartTransaction.typename()) {
            transactionKey = tran.type;
            transactionObject = {"startTransaction": getStartNodeTargetIdent((tran as StartTransaction).outbound.target)};
        }

        if (tran.type === SmsStartTransaction.typename()) {
            transactionKey = "start";
            transactionObject = {"startTransaction": getStartNodeTargetIdent((tran as SmsStartTransaction).outbound.target)};
        }

        if (!tran.isSystemTransaction) {
            transactionKey = tran.ident.toString();
            transactionObject = createNonSystemTransaction(template, tran as CommonTransaction);

            if (tran.type === BiometricsTransaction.typename()) {

                const bio = tran as BiometricsTransaction;
                const count = bio.authenticationMode ? 1 : 3;
                const replacementIdent = extraTransactionIdent++;

                const records: RecordTransaction[] = [];
                for (let j = 0; j < count; ++j) {
                    const record = new RecordTransaction(j === 0 ? transactionKey : extraTransactionIdent++);
                    record.maxDuration = 5;
                    record.maxSilence = 2;
                    record.audio.tts = bio.audio.tts;
                    records.push(record);

                    if (j > 0)
                        template.connectTerminals(records[j - 1].outbound, record.inbound);
                }

                for (let j = 0; j < count; ++j) {
                    const record = records[j];
                    const obj = createNonSystemTransaction(template, record);

                    if (j === count - 1)
                        obj["branching"]["all"] = replacementIdent;

                    transactions[record.ident.toString()] = obj;
                }

                transactionKey = replacementIdent;
            }

            if (tran.type === BotTransaction.typename()) {

                const bot = tran as BotTransaction;
                const botTransactions = bot.transactionGroup.transactions;

                const components: Transaction[] = [];
                const produce: any[] = [];

                for (let j = 0; j < botTransactions.length; ++j) {
                    const transaction = botTransactions[j];
                    const product = {};
                    transaction.clone(product, true);

                    const proxy = initTransaction(transaction.type, extraTransactionIdent++);
                    materializeTransaction(product, proxy);

                    if (j === 0) {
                        const recordTransactionProxy = proxy as RecordTransaction;
                        recordTransactionProxy.audio = bot.repromptAudio.clone();
                    }

                    components.push(proxy);
                }

                for (let j = 0; j < components.length; ++j) {
                    const component = components[j] as CommonTransaction;

                    if (bot.breakLoop && j === 0) {
                        produce.push(null);
                        continue;
                    }

                    const obj = transactions[component.ident.toString()] = createNonSystemTransaction(template, component);
                    produce.push(obj);
                }

                const [record2, api, info] = produce;

                //Bot Record to API
                transactionObject["branching"]["all"] = components[1].ident.toString();

                //API Success to Info
                const apiBranching = api["branching"];
                apiBranching["True"] = components[2].ident.toString();
                apiBranching["False"] = branch(bot.fail);
                api["type"] = "bot";
                api["branchingLogic"] = "keywordMatch";
                api["keywordMatchType"] = "contains";
                api["responseBindings"] = {"speech": "speechReceived"};
                api["languageCode"] = bot.languageCode;

                api["keywords"] = _.filter(_.map((bot.context || "").split(","), s => s.trim()), s => s.length > 0);

                const match = api["keywordsToMatch"] = {};
                for (const terminal of bot.outbound) {
                    if (terminal.active) {
                        const key = (terminal.ident).toString();
                        match[key] = [terminal.text];
                        apiBranching[key] = branch(terminal);
                    }
                }

                //Info goes back to Record2
                info["tts"] = "{{speechReceived}}";

                if (!bot.breakLoop) {
                    info["branching"]["all"] = components[0].ident.toString();
                    //Record goes back to Api
                    record2["branching"]["all"] = components[1].ident.toString();
                } else {
                    info["branching"]["all"] = branch(bot.brokenLoop);
                }
            }

            if (tran.type === CreditcardTransaction.typename()) {

                const cc = tran as CreditcardTransaction;

                const ccInput = new InputTransaction(transactionKey);
                const expInput = new InputTransaction(extraTransactionIdent++);
                const cvvInput = new InputTransaction(extraTransactionIdent++);
                const replacementIdent = extraTransactionIdent++;

                ccInput.tranName = `Credit Card Input for Transaction #${replacementIdent}`;
                ccInput.audio = cc.audio.clone();
                ccInput.minDigits = 10;
                ccInput.maxDigits = 22;

                expInput.tranName = `Credit Card Expiration Input for Transaction #${replacementIdent}`;
                expInput.audio = cc.expireAudio.clone();
                expInput.minDigits = 4;
                expInput.maxDigits = 6;

                cvvInput.tranName = `Credit Card CVV Input for Transaction #${replacementIdent}`;
                cvvInput.audio = cc.cvvAudio.clone();
                cvvInput.minDigits = 3;
                cvvInput.maxDigits = 6;

                const auxs: InputTransaction[] = [ccInput, expInput, cvvInput];

                for (let j = 0; j < auxs.length; ++j) {
                    const input = auxs[j];

                    const obj = transactions[input.ident.toString()] = createNonSystemTransaction(template, input);

                    obj["creditBranchNumber"] = replacementIdent.toString();
                    obj["readBackInput"] = yesFlag(cc.readBackInput);
                    obj["type"] = "creditInput";

                    if (j === 0) {
                        obj["branchingLogic"] = "creditNumber";
                        obj["branching"] = {"True": expInput.ident.toString()};
                    }

                    if (j === 1) {
                        obj["branchingLogic"] = "creditValidation";
                        obj["branching"] = {"True": cvvInput.ident.toString()};
                        obj["validationData"] = {
                            "type": "date",
                            "outputFormat": "m YY",
                            "format": "MMYY"
                        };
                    }

                    if (j === 2) {
                        obj["branchingLogic"] = "evalint";
                        obj["branching"] = {"[x] > 1": replacementIdent.toString()};
                    }
                }

                const bindings = transactionObject["requestBindings"];
                for (let key in bindings) {
                    if (bindings.hasOwnProperty(key)) {
                        const value = bindings[key];
                        if (value === "[[credit-card-reference]]")
                            bindings[key] = `[[${ccInput.ident}]]`;
                        if (value === "[[credit-card-expiration-reference]]")
                            bindings[key] = `[[${expInput.ident}]]`;
                        if (value === "[[credit-card-cvv-reference]]")
                            bindings[key] = `[[${cvvInput.ident}]]`;
                    }
                }


                transactionKey = replacementIdent;
            }
        }

        if (transactionObject !== null) {
            transactions[transactionKey] = transactionObject;
        }
    }

    return transactions;
}

function getHandlerOperation(strategy: Strategy) {

    if (strategy === Strategy.HangUp) {
        return "end";
    }

    if (strategy === Strategy.FallThrough) {
        return "next";
    }

    if (strategy === Strategy.GoTo) {
        return "goto";
    }

    throw `Unknown strategy: ${strategy}`;
}

function createHandlerList(handlers: TerminalHandler[], template: VoiceTemplate) {

    const obj = {};
    for (let i = 0; i < handlers.length; ++i) {
        const handler = handlers[i];
        const key = (handler.ident + 1).toString();

        obj[key] = {
            name: handler.name,
            operation: getHandlerOperation(handler.strategy)
        };

        if (handler.strategy === Strategy.GoTo) {
            _.extend(obj[key], {
                target: handler.target.toString()
            });
        }

        extendWithAudio(obj[key], handler.audio, template);
    }
    return obj;
}

function createAmdSpec(template: VoiceTemplate): any {

    const disabled = {enabled: "False"};
    if (template.callMode === CallType.Inbound)
        return disabled;

    const start = _.find(template.transactions, tr => tr.ident === StartTransaction.typename()) as StartTransaction;
    if (!start.useAmd) return disabled;

    return {
        enabled: "True",
        silenceThreshold: start.silenceThreshold,
        initialSilence: start.initialSilence,
        greeting: start.greeting,
        afterGreetingSilence: start.afterGreetingSilence,
        totalAnalysisTime: start.totalAnalysisTime,
        minimumWordLength: start.minimumWordLength,
        betweenWordsSilence: start.betweenWordsSilence,
        maximumNumberOfWords: start.maximumNumberOfWords,
        maximumWordLength: start.maximumWordLength,
        avmdDuration: start.avmdDuration,
        branching: {
            "human": branch(start.outbound),
            "machine": branch(start.machine),
            "unknown": branch(start.unknown)
        }
    };

}

function tagStatus(template: CanvasTemplate) {

    const tagNames = _.map(template.tags, (tag: IStatusTag) => tag.name);
    return _.zipObject(tagNames, _.map(template.tags, (tag: IStatusTag) => ({
        name: tag.name,
        description: tag.description,
        icon: tag.icon
    })));
}

function serializeTransactions(template: CanvasTemplate, target: ISerializedTemplate<ICanvasTemplate>): any {

    const transactionMap: any = {};
    const identMap: any = {};

    const transactions = template.transactions;
    for (let id = 0; id < transactions.length; ++id) {

        const tran = transactions[id];
        transactionMap[tran.layout.sqn] = id;
        identMap[tran.ident] = id;

        const serializedTransaction: ISerializedTransaction = {
            id: id,
            type: tran.type,
            properties: {},
            terminals: []
        };

        tran.clone(serializedTransaction.properties, true);
        target.transactions.push(serializedTransaction);
    }

    for (let id = 0; id < transactions.length; ++id) {

        const tran = transactions[id];
        const serializedTransaction = target.transactions[id];
        const terminals = tran.terminals;

        for (let i = 0; i < terminals.length; ++i) {
            const terminal: ITerminal = terminals[i];
            const serializedTerminal: ISerializedTerminal = {
                ident: terminal.ident,
                ghost: terminal.ghost,
                active: terminal.active
            };

            if (terminal.meta) {
                serializedTerminal.meta = terminal.meta;
            }

            if (terminal.direction) {
                serializedTerminal.direction = terminal.direction;
            }

            if (terminal.text) {
                serializedTerminal.text = terminal.text;
            }

            if (terminal.target) {
                serializedTerminal.targetId = transactionMap[terminal.target.node.layout.sqn];
                serializedTerminal.targetIdent = terminal.target.ident;
            }

            serializedTransaction.terminals.push(serializedTerminal);
        }
    }

    return identMap;
}

function serializeVariables(template: CanvasTemplate, target: ISerializedTemplate<ICanvasTemplate>) {
    for (const variable of template.variables)
        target.variables.push({name: variable.name, required: variable.required});
}

function serializeHandlers(template: VoiceTemplate, target: ISerializedVoiceTemplate, identMap: any) {

    function serializeHandler(handler: TerminalHandler): ISerializedHandler {

        let id = null;
        if (handler.target) {
            id = identMap[handler.target];
        }
        return {
            ident: handler.ident,
            name: handler.name,
            strategy: handler.strategy,
            audio: _.extend({}, handler.audio),
            targetTransactionId: id,
            allowGoTo: handler.allowGoTo
        };
    }

    for (let handler of template.errorHandlers)
        target.errorHandlers.push(serializeHandler(handler));

    for (let handler of template.noResponseHandlers)
        target.noResponseHandlers.push(serializeHandler(handler));

}

function deserializeSettings(source: ISerializedTemplate<ICanvasTemplate>, target: CanvasTemplate) {

    for (let key in source.settings) {

        if (key == "id")
            continue;

        if (source.settings.hasOwnProperty(key))
            target[key] = source.settings[key];

        if ("templateName" in source.settings)
            target.name = source.settings.templateName;
    }

    if (target.version === undefined)
        target.version = 0;

}

function deserializeTransactions(source: ISerializedTemplate<ICanvasTemplate>, target: CanvasTemplate): any {

    const map = {};
    let transaction: Transaction;

    for (const sourceTransaction of source.transactions) {
        transaction = map[sourceTransaction.id] = target.addTransaction(sourceTransaction.type, 0, 0);
        materializeTransaction(sourceTransaction.properties, transaction);
    }

    for (const sourceTerminals of source.transactions) {
        transaction = map[sourceTerminals.id];
        for (const sourceTerminal of sourceTerminals.terminals) {

            if (transaction.type === SmsMessageTransaction.typename() && sourceTerminal.direction === TerminalDirection.Right) {
                const msg = transaction as SmsMessageTransaction;
                msg.addBranch(sourceTerminal.ident);
            }

            if (transaction.type === SmsBranchTransaction.typename() && sourceTerminal.direction === TerminalDirection.Right) {
                const msg = transaction as SmsMessageTransaction;
                msg.addBranch(sourceTerminal.ident);
            }

            const term = _.find(transaction.terminals, (t: ITerminal) => t.ident === sourceTerminal.ident);
            if (!term)
                continue;

            term.ghost = sourceTerminal.ghost;
            term.active = sourceTerminal.active;
            term.text = sourceTerminal.text;
            term.meta = sourceTerminal.meta;
            term.direction = sourceTerminal.direction;

            if (sourceTerminal.targetId) {
                const targetTransaction: Transaction = map[sourceTerminal.targetId];
                const targetTerminal = _.find(targetTransaction.terminals, (t: Transaction) => t.ident === sourceTerminal.targetIdent) as ITerminal;
                target.connectTerminals(term, targetTerminal);
            }
        }
    }

    return map;
}

function deserializeHandlers(source: ISerializedVoiceTemplate, target: VoiceTemplate, map: any) {

    const deserializeHandler = (obj: ISerializedHandler) => {

        const handler = new TerminalHandler();

        handler.ident = obj.ident;
        handler.name = obj.name;
        handler.audio = Audio.deserialize(obj.audio);
        handler.strategy = obj.strategy;
        handler.allowGoTo = obj.allowGoTo;
        if (obj.targetTransactionId) {
            handler.target = map[obj.targetTransactionId].ident;
        }

        return handler;
    };

    target.errorHandlers.length = 0;
    for (const handler of source.errorHandlers)
        target.errorHandlers.push(deserializeHandler(handler));

    target.noResponseHandlers.length = 0;
    for (const handler of source.noResponseHandlers)
        target.noResponseHandlers.push(deserializeHandler(handler));
}

function deserializeVariables(serializedTemplate: ISerializedTemplate<ICanvasTemplate>, target: CanvasTemplate) {
    if (!serializedTemplate.variables)
        return;

    for (const serializedVariable of serializedTemplate.variables) {
        target.addVariable(serializedVariable.name, !serializedVariable.required);
    }
}


export function serializeVoiceTemplatePackage(template: VoiceTemplate): any {

    const name = template.name || "untitled template";
    const callerId = template.callerId ? [template.callerId] : [];
    const direction = template.callMode === CallType.Inbound ? "inbound" : "outbound";

    return {
        templateId: template.id,
        templateName: name,
        templateVersion: template.version.toString(),
        templateVoiceModule: voiceModule,
        outboundServiceName: voiceModule,
        callerId: callerId,
        direction: direction,
        variables: _.map(template.variables, (v) => v.name),

        template: {
            "global": {
                templateId: template.id,
                "masterTemplateId": "",
                templateName: name,
                callerId: callerId,
                maxErrorRetries: template.maxErrorRetries,
                maxNoResponseRetries: template.maxNoResponseRetries,
                direction: direction,
                maxDuration: template.maxDuration || defaultMaxCallDuration,
                recordWholeCall: template.canRecordWholeCall && template.recordWholeCall ? "yes" : "no",
                variableName: "custom_reference",
                ttsEngine: template.ttsEngine,
                ttsVoice: template.ttsVoice,
                ttsLanguage: template.ttsLanguage,
                ttsGender: template.ttsGender,
                tagStatus: tagStatus(template),
                amd: createAmdSpec(template)
            },

            error: createHandlerList(template.errorHandlers, template),
            noresponse: createHandlerList(template.noResponseHandlers, template),
            transactions: createTransactionList(template)
        },

        layout: JSON.stringify(serializeVoiceTemplate(template))
    };

}

export function serializeVoiceTemplate(template: VoiceTemplate): ISerializedVoiceTemplate {

    if (!template)
        return null;

    const target: ISerializedVoiceTemplate = {
        settings: {
            templateName: template.name || "",
            callMode: template.callMode,
            maxErrorRetries: template.maxErrorRetries,
            maxNoResponseRetries: template.maxNoResponseRetries,
            maxDuration: template.maxDuration || defaultMaxCallDuration,
            recordWholeCall: template.canRecordWholeCall && template.recordWholeCall,
            ttsEngine: template.ttsEngine,
            ttsVoice: template.ttsVoice,
            callerId: template.callerId,
            tags: cloneDeep(template.tags),
            version: template.version,
            defaults: cloneDeep(template.defaults),
            sync: template.sync
        },
        errorHandlers: [],
        noResponseHandlers: [],
        transactions: [],
        variables: []
    };

    const identMap = serializeTransactions(template, target);
    serializeVariables(template, target);
    serializeHandlers(template, target, identMap);

    return target;
}

export function materializeVoiceTemplate(templateId: string, source: ISerializedVoiceTemplate | string): VoiceTemplate {

    if (typeof source === "string") {
        return materializeVoiceTemplate(templateId, JSON.parse(source));
    } else {

        const template = new VoiceTemplate(templateId);
        if (!("settings" in source))
            return template;

        deserializeSettings(source, template);
        const transactions = deserializeTransactions(source, template);
        deserializeHandlers(source, template, transactions);
        deserializeVariables(source, template);

        return template;
    }
}

export function materializeSmsTemplate(templateId: string, source: ISerializedSmsTemplate | string): SmsTemplate {

    if (typeof source === "string") {
        return materializeSmsTemplate(templateId, source);
    } else {

        const template = new SmsTemplate(templateId);
        if (!("settings" in source))
            return template;

        template.clean();
        deserializeSettings(source, template);
        deserializeTransactions(source, template);
        deserializeVariables(source, template);

        return template;
    }
}

export function serializeSmsTemplate(template: SmsTemplate): ISerializedSmsTemplate {

    if (!template)
        return null;

    const target: ISerializedSmsTemplate = {
        settings: {
            templateName: template.name || "",
            callerId: template.callerId,
            tags: cloneDeep(template.tags),
            sendLarge: template.sendLarge,
            twoWay: template.twoWay,
            noMatchReferenceId: template.noMatchReferenceId,
            optouts: cloneDeep(template.optouts),
            timezone: template.timezone,
            version: template.version,
            sync: template.sync,
            callMode: template.callMode,
            useShortLinkUrls: template.useShortLinkUrls,
            delay: template.delay || 0,
            stateMode: template.stateMode || false,
            stateModeAllowReset: template.stateModeAllowReset || false
        },
        transactions: [],
        variables: []
    };

    serializeTransactions(template, target);
    serializeVariables(template, target);

    return target;
}

export function serializeSmsTemplatePackage(template: SmsTemplate) {

    const callerId = template.callerId ? [template.callerId] : [];
    const direction = template.twoWay ? "2way" : "outbound";

    const nomatch = template.noMatchReferenceId === null ? "end" :
        template.findByReference(template.noMatchReferenceId).value.ident.toString();

    const keywords = {};
    for (let tran of template.allOf<SmsMessageTransaction>(SmsMessageTransaction.typename())) {
        _.extend(keywords, smsMessageBranching(tran));
    }

    for (let tran of template.allOf<SmsBranchTransaction>(SmsBranchTransaction.typename())) {
        _.extend(keywords, smsMessageBranching(tran));
    }

    let delay = template.delay;
    if (!delay) delay = 0;

    const features: any = {
        "shortenurl": flag(template.useShortLinkUrls),
        "delaySeconds": delay
    };

    const templateConfiguration = {
        "global": {
            "templateId": template.id,
            "masterTemplateId": "",
            "templateName": template.name || "",
            "callerId": callerId,
            "templateVersion": template.version.toString(),
            "direction": direction,
            "optOutKeywords": template.optouts,
            "sendLarge": flag(template.sendLarge),
            "tagStatus": tagStatus(template),
            "features": features,
            "stateMode": flag(template.stateMode),
            "stateModeAllowReset": flag(template.stateModeAllowReset)
        },
        "nomatch": nomatch,
        "keywords": keywords,
        "transactions": createTransactionList(template)
    };

    return {
        "templateId": template.id,
        "templateName": template.name || "",
        "callerId": callerId,
        "direction": direction,
        "sendLarge": flag(template.sendLarge),
        "templateVersion": template.version.toString(),
        "variables": _.map(template.variables, (v) => v.name),
        "template": templateConfiguration,
        "layout": JSON.stringify(serializeSmsTemplate(template))
    };
}
