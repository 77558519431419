import _ from "lodash";

import {url} from "../utility/url";
import {ReferenceType, TtsFormatSettings} from "./common";
import {CanvasTemplate} from "./CanvasTemplate";
import {Transaction} from "./Transactions";
import {Variable} from "../common/Variable";

enum AudioFragmentKind {
    Text = 1,
    Reference = 2,
    Wave = 3
}

interface IAudioFragment {
    kind: AudioFragmentKind;
    value: string;
}

function formatFragment(fragment: IAudioFragment): string {
    return fragment.kind === AudioFragmentKind.Text ? fragment.value : `{{${fragment.value}}}`;
}

function cleanFancyFormat(str: string) {
    if (!str) return str;

    return str
        .replace(/[\u2018\u2019]/g, "'")
        .replace(/[\u201C\u201D]/g, "\"")
        .replace(/[\u2013\u2014]/g, "-")
        .replace(/[\u2026]/g, "...");
}

export interface IWaveDescriptor {
    file: string;
    size: number;
}

export class Audio {
    play: boolean = true;
    useTts: boolean = true;
    useMixedMode: boolean = false;
    useRawInputMode: boolean = false;

    tts: string = "";
    wav: string = null;
    prePromptSegments: Audio[] = [];

    static waveMetaPrefix = "wave:";

    clone(): Audio {
        return Audio.deserialize(this);
    }

    static deserialize(obj: any): Audio {
        const audio = new Audio();

        audio.play = obj.play;

        audio.tts = obj.tts;
        audio.wav = obj.wav;

        audio.useTts = !!obj.useTts;
        audio.useMixedMode = !!obj.useMixedMode;
        audio.useRawInputMode = !!obj.useRawInputMode;

        if (obj.prePromptSegments && obj.prePromptSegments.length) {
            for (let idx = 0; idx < obj.prePromptSegments.length; ++idx) {
                const segment = obj.prePromptSegments[idx];
                audio.prePromptSegments.push(Audio.deserialize(segment));
            }
        }

        return audio;
    }

    addSegment(): Audio {
        const audio = new Audio();
        this.prePromptSegments.push(audio);
        return audio;
    }

    deleteSegment(a: Audio) {
        const idx = this.prePromptSegments.indexOf(a);
        if (idx > -1)
            this.prePromptSegments.splice(idx, 1);
    }

    get short() {
        if (!this.wav) return "";
        return url.getFileName(this.wav);
    }

    private getFragments(template: CanvasTemplate): IAudioFragment[] {

        const fragments: IAudioFragment[] = [];

        let tts = this.tts;
        if (!tts) return fragments;

        tts = cleanFancyFormat(tts).trim();

        const capture = /{{(.*?)}}/g;

        let lastIndex = 0, m: RegExpExecArray;
        while ((m = capture.exec(tts))) {

            const a = m.index, b = capture.lastIndex;
            const pSection = tts.substr(lastIndex, a - lastIndex);
            if (pSection.length)
                fragments.push({
                    kind: AudioFragmentKind.Text,
                    value: pSection
                });

            const match = m[1];
            if (match.indexOf(Audio.waveMetaPrefix) === 0) {
                fragments.push({kind: AudioFragmentKind.Wave, value: match});
            } else if (template.findByReference(match)) {
                fragments.push({kind: AudioFragmentKind.Reference, value: match});
            }

            lastIndex = b;
        }
        const trailer = tts.substr(lastIndex, tts.length - lastIndex);
        if (trailer)
            fragments.push({kind: AudioFragmentKind.Text, value: trailer});

        return fragments;
    }

    nest(template: CanvasTemplate): Audio {

        const fragments = this.getFragments(template);
        fragments.reverse();

        if (_.every(fragments, f => f.kind === AudioFragmentKind.Text)) {
            return this;
        }

        const clone = this.clone();

        clone.useMixedMode = true;
        clone.tts = "";

        const fragGroup: IAudioFragment[] = [];

        while (fragments.length) {
            const item = fragments.pop();
            if (item.kind === AudioFragmentKind.Wave) {

                if (fragGroup.length) {
                    const fragGroupSegment = clone.addSegment();
                    fragGroupSegment.tts = _.map(fragGroup, formatFragment).join(" ").trim();
                    fragGroup.length = 0;
                }

                let target = clone;

                if (fragments.length !== 0)
                    target = clone.addSegment();

                target.useTts = false;
                target.wav = item.value.substr(Audio.waveMetaPrefix.length);

            } else {
                fragGroup.push(item);
            }
        }

        if (fragGroup.length) {
            clone.tts = _.map(fragGroup, formatFragment).join(" ").trim();
        }

        return clone;
    }

    formatTts(template: CanvasTemplate, settings: TtsFormatSettings = null): string {

        if (this.useRawInputMode)
            return this.tts;

        settings = settings || ({} as any as TtsFormatSettings);

        settings.variablePrefix = settings.variablePrefix || "{{";
        settings.variableSuffix = settings.variableSuffix || "}}";
        settings.transactionPrefix = settings.transactionPrefix || "[[";
        settings.transactionSuffix = settings.transactionSuffix || "]]";

        const fragments = this.getFragments(template);

        let formattedText = "";

        _.each(fragments, fragment => {

            let text = fragment.value;
            if (fragment.kind === AudioFragmentKind.Reference) {
                const reference = template.findByReference(fragment.value);

                if (reference.type === ReferenceType.Variable) {
                    const variable = reference.value as Variable;
                    text = settings.variablePrefix + variable.ident + settings.variableSuffix;
                } else if (reference.type === ReferenceType.Transaction) {
                    const transaction = reference.value as Transaction;
                    text = settings.transactionPrefix + transaction.ident + settings.transactionSuffix;
                }
            } else if (fragment.kind === AudioFragmentKind.Wave) {
                text = ` ${url.getFileName(fragment.value)} `;
            }

            formattedText += text;
        });

        let text = formattedText.substr(0, 1000);

        // gets generated sometimes when two variables smash together
        text = text.replace(/\u00A0/g, " ");

        return text;
    }

}

