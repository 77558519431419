import {TemplateTypename} from "../const";
import {array} from "../utility/array";
import {Severity, ValidationGroup, ValidationProblem, Validator} from "./Validation";
import {Variable} from "./Variable";

export interface ITemplateDescriptor {
    published: boolean;
    publishedOn: number;
    publishedSettings: any;
    publishedLatest: boolean;

    data: any;
    history: number[];

    kind: TemplateTypename;
    modified: number;
    name: string;
}

export abstract class Template {

    name: string;

    variables: Variable[] = [];
    descriptor: ITemplateDescriptor = null;
    showProblems: boolean = false;

    private validation = new ValidationGroup();

    protected constructor(readonly id: string) {

    }

    abstract serialize(): string;

    abstract get publicationData(): any;

    protected addValidator(validator: Validator) {
        this.validation.add(validator);
    }

    protected* getValidationGroups(): IterableIterator<ValidationGroup> {
        yield this.validation;
    }


    get valid(): boolean {

        for (let group of this.getValidationGroups())
            if (!group.valid)
                return false;

        return true;
    }

    validate(): boolean {
        for (let group of this.getValidationGroups())
            group.validate();
        return this.valid;
    }


    get errorCount(): number {
        let count = 0;
        for (let group of this.getValidationGroups())
            count += group.errorCount;
        return count;
    }

    get problem(): ValidationProblem | null {
        let warning: ValidationProblem = null;

        for (let group of this.getValidationGroups()) {
            const problem = group.problem;
            if (problem) {
                if (problem.severity === Severity.Error)
                    return problem;
                warning = problem;
            }
        }

        return warning;
    }

    get publicationStatus(): "draft" | "latest" | "modified" {

        if (!this.descriptor || !this.descriptor.published)
            return "draft";

        if (this.descriptor.publishedLatest)
            return "latest";

        return "modified";
    }

    abstract get typename(): TemplateTypename;

    addVariable(name: string, temporary: boolean = true): boolean {
        const variable = new Variable(name, temporary);

        for (let v of this.variables)
            if (v.referenceId === variable.referenceId)
                return false;

        this.variables.push(variable);
        return true;
    }

    removeVariable(variable: Variable) {
        if (this.publicationStatus !== "draft" && !variable.temporary)
            return;
        array.removeItem(this.variables, variable);
    }

    setDescriptor(descriptor: ITemplateDescriptor) {
        this.descriptor = descriptor;
    }

    addHistorySnapshot(snapshotId: number) {

        const descriptor = this.descriptor;
        if (!descriptor) return;
        descriptor.history.unshift(snapshotId);
        descriptor.publishedLatest = false;
    }

    get settings() {

        const variables: string[] = this.variables
            .filter(v => v.required)
            .map(v => v.name)
            .sort();

        return {
            "variables": variables
        };
    }

}
