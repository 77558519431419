import _ from "lodash";

import {Template} from "../common/Template";
import {Severity, ValidationGroup, Validator} from "../common/Validation";
import {Variable} from "../common/Variable";
import {TemplateTypename} from "../const";
import {array} from "../utility/array";

import {Audio} from "./audio";

import {
    CallType,
    IStatusTag,
    IVoiceTemplateDefaults,
    ReferenceMap,
    ReferenceType,
    SmsDeliveryTimezone,
    Strategy,
    TerminalType
} from "./common";

import {
    ISmsTemplate,
    IVoiceTemplate,
    serializeSmsTemplate,
    serializeSmsTemplatePackage,
    serializeVoiceTemplate,
    serializeVoiceTemplatePackage
} from "./Serialization";

import {TerminalHandler} from "./terminal-handler";
import {
    ApiTransaction,
    CreditcardTransaction,
    DateInputFormat,
    IApiBinding,
    initTransaction,
    IRequestApiBinding,
    ITerminal,
    MenuTransaction,
    SmsBranchTransaction,
    SmsMessageTransaction,
    SmsStartTransaction,
    StartTransaction,
    Transaction,
    VoiceBaseTransaction
} from "./Transactions";

/* interface IReference {
    type: ReferenceType;
    value: Variable | Transaction;
}*
 */

type IReference =
    { value: Variable, type: ReferenceType.Variable }
    | { value: Transaction, type: ReferenceType.Transaction };

//Classes

const CommonTags = ["Success", "Failure", "Incomplete"];

export function isCommonTag(tagName: string): boolean {
    for (let tag of CommonTags) {
        if (tag === tagName) {
            return true;
        }
    }
    return false;
}

function findPreferredOrFirst<T extends IIdentifiable>(items: T[]): T {

    for (const item of items) {
        if (item.preferred)
            return item;
    }

    return items[0];
}

function findDanglingHash(json: string) {

    const prop = "$$hashKey";
    let idx = json.indexOf(prop);
    if (idx === -1) {
        return null;
    }

    idx += prop.length;

    // lookback for the beginning of the property
    let start = idx;
    while (start > 0 && json[start] !== "{") {
        start--;
    }

    console.error("FOUND DANDLING HASH", json.substring(start, idx));
}


export abstract class CanvasTemplate extends Template {

    sync: number = null;
    callerId: string = ""; // Do not use

    transactions: Transaction[] = [];
    referencableTransactions: Transaction[] = [];

    tags: IStatusTag[] = [];

    version = 0;

    protected abstract initTransaction(type: string): Transaction;

    protected abstract cleanTransaction(transaction: Transaction): void;

    protected nextIdent() {

        let count = 0;
        for (let i = 0; i < this.transactions.length; ++i) {
            const transaction = this.transactions[i];
            if (!transaction.isSystemTransaction)
                ++count;
        }

        return count + 1;
    }

    protected forAll<TTransaction extends Transaction>(iterator: (t: TTransaction) => any, typename: string = null) {

        for (const transaction of this.transactions) {
            if (typename === null || transaction.type === typename)
                iterator(transaction as any);
        }
    }

    protected constructor(id: string) {
        super(id);

        this.addValidator(new Validator(Severity.Error, () => "Conflicting variable name", () => {
            const variable = this.getConflictingVariable();
            return `Variable: ${variable.name} is defined more than once.`;
        }, () => {
            return this.getConflictingVariable() === null;
        }));
    }

    private getConflictingVariable(): Variable {

        const hash: { [name: string]: boolean } = {};
        for (let variable of this.variables) {
            if (hash[variable.name])
                return variable;
            hash[variable.name] = true;
        }

        return null;
    }

    getReferenceMap(): ReferenceMap {

        const variableKeys = _.map(this.variables, (v: Variable) => v.name);
        const variableValues = _.map(this.variables, (v: Variable) => ({type: ReferenceType.Variable, value: v}));

        const referenceKeys = _.map(this.transactions, (t: Transaction) => t.referenceId);
        const referenceValues = _.map(this.transactions, (v: Transaction) => ({
            type: ReferenceType.Transaction,
            value: v
        }));

        const mapVariables = _.zipObject(variableKeys, variableValues);
        const mapReferences = _.zipObject(referenceKeys, referenceValues);

        return _.extend(mapVariables, mapReferences);
    }

    get hasTagsSet(): boolean {

        for (const transaction of this.transactions)
            if (transaction.tagId)
                return true;

        return false;
    }

    addTransaction(type: string, x: number, y: number): Transaction {

        const transaction = this.initTransaction(type);

        if (transaction) {
            transaction.move(x, y);
            this.transactions.push(transaction);
            if (transaction.canBeReferenced)
                this.referencableTransactions.push(transaction);
        }

        return transaction;
    }

    removeTransaction(transaction: Transaction) {

        this.cleanTransaction(transaction);

        for (let i = 0; i < transaction.terminals.length; ++i) {
            this.disconnectTerminal(transaction.terminals[i]);
        }

        const index = this.transactions.indexOf(transaction);
        this.transactions.splice(index, 1);

        for (let i = 0; i < this.transactions.length; ++i) {
            const cmp = this.transactions[i];
            if (typeof (cmp.ident) === "number" && cmp.ident > transaction.ident)
                cmp.ident -= 1;
        }


        const jdx = this.referencableTransactions.indexOf(transaction);
        if (jdx > -1) this.referencableTransactions.splice(jdx, 1);
    }

    disconnectTerminal(terminal: ITerminal): void {

        if (terminal.type === TerminalType.Outbound && terminal.target) {
            const incoming = terminal.target.incoming;
            incoming.splice(incoming.indexOf(terminal), 1);
            terminal.target = null;
        }

        if (terminal.type === TerminalType.Inbound) {
            const foreign = terminal.incoming.slice();
            for (let i = 0; i < foreign.length; ++i) {
                this.disconnectTerminal(foreign[i]);
            }
            terminal.incoming.length = 0;
        }
    }

    connectTerminals(a: ITerminal, b: ITerminal) {

        if (a.type === b.type) {
            return false;
        }

        if (a.node.ident === b.node.ident) {
            return false;
        }

        const outgoing = (a.type === TerminalType.Outbound ? a : b);
        const target = outgoing === a ? b : a;

        if (outgoing.target) {
            return false;
        }

        outgoing.active = true;
        outgoing.target = target;
        target.incoming.push(outgoing);

        return true;
    }

    removeVariable(variable: Variable) {

        const idx = this.variables.indexOf(variable);

        if (idx !== -1) {
            this.variables.splice(idx, 1);

            // Delete all api bindings to this variable as well.
            const variableId = variable.name;
            this.forAll<ApiTransaction>(tran => {
                const bindings = tran.bindings;
                let binding: IApiBinding;
                do {
                    binding = _.find(bindings, b => b.variableId === variableId);
                    if (binding) bindings.splice(bindings.indexOf(binding), 1);
                } while (binding);

                const requestBindings = tran.requestBindings;
                let requestBinding: IRequestApiBinding;
                do {
                    requestBinding = _.find(requestBindings, b => b.value === variableId);
                    if (requestBinding) requestBindings.splice(requestBindings.indexOf(requestBinding), 1);
                } while (requestBinding);

            }, ApiTransaction.typename());
        }
    }

    findByReference(name: string): IReference {

        const variable = _.find(this.variables, (v: Variable) => v.name === name);
        if (variable) return {type: ReferenceType.Variable, value: variable};

        const transaction = _.find(this.transactions, (t: Transaction) => t.referenceId === name);
        if (transaction) return {type: ReferenceType.Transaction, value: transaction};

        return null;
    }

    allOf<TTransaction extends Transaction>(typename: string): TTransaction[] {
        const list = [];
        this.forAll<TTransaction>((t) => list.push(t), typename);
        return list;
    }

    getAllGotoTransactions(): Transaction[] {
        return this.transactions.filter(it => it.canGoto);
    }

    addCustomTag(name: string): boolean {

        for (const tag of this.tags) {
            if (tag.name === name)
                return false;
        }

        const tag: IStatusTag = {
            name: name,
            description: "",
            icon: "fa fa-tag",
            "class": "custom-tag"
        };

        this.tags.push(tag);
        return true;
    }

    removeCustomTag(tag: IStatusTag): boolean {

        if (isCommonTag(tag.name))
            return false;

        const idx = this.tags.indexOf(tag);

        if (idx == -1)
            return false;

        const removed = array.removeItem(this.tags, tag);

        this.forAll((transaction: Transaction) => {
            if (!transaction.isSystemTransaction) {
                const userTransaction = transaction as VoiceBaseTransaction;
                if (userTransaction.tagId === tag.name)
                    userTransaction.tagId = null;
            }
        });

        return removed;
    }

    findTag(name: string): IStatusTag | null {
        for (let tag of this.tags)
            if (tag.name === name)
                return tag;
        return null;
    }

    protected* getValidationGroups(): IterableIterator<ValidationGroup> {
        yield* super.getValidationGroups();
        for (let transaction of this.transactions)
            yield transaction.validation;
    }

}

export class SmsTemplate extends CanvasTemplate implements ISmsTemplate {

    sendLarge: boolean = true;
    twoWay: boolean = true;
    noMatchReferenceId: string = null;
    optouts: string[] = [];
    timezone = SmsDeliveryTimezone.Off;
    callMode = CallType.Outbound;
    useShortLinkUrls: boolean = true;
    delay: number = 0;
    stateMode: boolean = true;
    stateModeAllowReset: boolean = false;

    get typename(): TemplateTypename {
        return "sms";
    }

    constructor(id: string) {
        super(id);

        this.name = "New Sms Template";
        this.setupInitialComponents();
        this.setupValidators();
    }

    clean() {
        for (let tran of _.clone(this.transactions))
            this.removeTransaction(tran);
    }

    private setupValidators() {

        this.addValidator(new Validator(Severity.Error,
            () => this.name || "Template",
            () => "Branch names must be unique, unless survey mode is set",
            () => {
                if (this.stateMode)
                    return true;

                const account: string[] = [];
                for (let transaction of this.transactions) {
                    if (transaction.type === SmsMessageTransaction.typename()) {
                        const smsMessageTransaction = transaction as SmsMessageTransaction;
                        const collection: string[] = [];
                        for (let branch of smsMessageTransaction.branches) {
                            collection.push(branch.text);
                            for (let alias of branch.meta) {
                                collection.push(alias);
                            }
                        }

                        if (_.intersection(collection, account).length)
                            return false;

                        for (let item of collection) {
                            account.push(item);
                        }
                    }
                }

                return true;
            }));
    }

    private setupInitialComponents() {
        const start = this.addTransaction(SmsStartTransaction.typename(), 0, -360) as SmsStartTransaction;
        if (this.callMode === CallType.Outbound) {
            const msg = this.addTransaction(SmsMessageTransaction.typename(), 0, 0) as SmsMessageTransaction;
            this.connectTerminals(start.outbound, msg.inbound);
        } else {
            const branch = this.addTransaction(SmsBranchTransaction.typename(), 0, 0) as SmsBranchTransaction;
            const term = branch.addBranch("[all]");
            branch.tranName = "Dispatcher";

            const message = this.addTransaction(SmsMessageTransaction.typename(), 280, 240) as SmsMessageTransaction;
            message.tranName = "Reply Message";

            this.connectTerminals(term, message.inbound);
            this.connectTerminals(start.outbound, branch.inbound);
        }

        this.addOptOut("stop");

        this.tags = [
            {
                name: "Success",
                description: "Successfully Completed Objective",
                icon: "fa fa-thumbs-up",
                "class": "green"
            },
            {
                name: "Failure",
                description: "Unsuccessfully Completed Objective",
                icon: "fa fa-exclamation-triangle",
                "class": "red"
            },
            {
                name: "Incomplete",
                description: "Incomplete Objective",
                icon: "fa fa-star-half-o",
                "class": "yellow"
            }
        ];
    }

    protected initTransaction(type: string): Transaction {
        return initTransaction(type, this.nextIdent());
    }

    protected cleanTransaction(transaction: Transaction): void {
        if (this.noMatchReferenceId === transaction.referenceId) {
            this.noMatchReferenceId = null;
        }
    }

    serialize(): string {
        return JSON.stringify(serializeSmsTemplate(this));
    }

    get publicationData() {
        return serializeSmsTemplatePackage(this);
    }

    addOptOut(text: string) {
        if (this.optouts.indexOf(text) === -1 && text && text.length > 0) {
            this.optouts.push(text);
        }
    }

    delOptOut(text: string) {
        const idx = this.optouts.indexOf(text);
        if (idx > -1)
            this.optouts.splice(idx, 1);
    }

}

export interface IIdentifiable {
    preferred?: boolean;
    name: string;
    id: string;
}

export interface ITtsVoice extends IIdentifiable {
    gender: "male" | "female" | "unspecified";
    language: string;
}

export interface ITtsEngine extends IIdentifiable {
    name: string;
    url: string;
    id: string;
    voices: ITtsVoice [];
}

export class VoiceTemplate extends CanvasTemplate implements IVoiceTemplate {

    callMode: CallType = CallType.Inbound;
    maxErrorRetries: number = 8;
    maxNoResponseRetries: number = 8;
    maxDuration: number = 300;
    recordWholeCall: boolean = false;

    ttsEngine: string;
    ttsVoice: string;
    ttsLanguage: string;
    ttsGender: string;

    errorHandlers: TerminalHandler[] = [];
    noResponseHandlers: TerminalHandler[] = [];

    defaults: IVoiceTemplateDefaults = {
        dtmfHelpButton: "*",
        dtmfEndInputButton: "#",
        dtmfTimeout: 12,
        errorRetryCount: 2,
        dateFormat: DateInputFormat.DDMM
    };

    get canRecordWholeCall(): boolean {
        for (let transaction of this.transactions)
            if (transaction.type === CreditcardTransaction.typename())
                return false;
        return true;
    }

    get typename(): TemplateTypename {
        return "voice";
    }

    constructor(id: string) {
        super(id);

        this.setupInitialComponents();
        this.setupValidators();
    }

    protected initTransaction(type: string): Transaction {

        if (type === StartTransaction.typename())
            this.forAll((tran) => this.removeTransaction(tran), type);

        return initTransaction(type, this.nextIdent());
    }

    protected cleanTransaction(transaction: Transaction): void {
        _.each(this.errorHandlers.concat(this.noResponseHandlers), handler => {
            if (handler.target === transaction.ident) {
                handler.target = null;
                handler.strategy = Strategy.HangUp;
            }
        });
    }

    serialize(): string {
        return JSON.stringify(serializeVoiceTemplate(this));
    }

    get publicationData() {
        return serializeVoiceTemplatePackage(this);
    }

    private setupInitialComponents() {

        const errorHandler = this.addErrorHandler();
        errorHandler.audio.tts = "Sorry, that is not a valid response.";

        const noResponseHandler = this.addNoResponseHandler();
        noResponseHandler.audio.tts = "Sorry, I did not get a response.";

        this.addTransaction(StartTransaction.typename(), 0, -350);

        this.tags = [{
            name: "Success",
            description: "Successfully Completed Objective",
            icon: "fa fa-thumbs-up",
            "class": "green"
        }, {
            name: "Failure",
            description: "Unsuccessfully Completed Objective",
            icon: "fa fa-exclamation-triangle",
            "class": "red"
        }, {
            name: "Incomplete",
            description: "Incomplete Objective",
            icon: "fa fa-star-half-o",
            "class": "yellow"
        }];
    }

    private setupValidators() {
        this.addValidator(new Validator(Severity.Error,
            () => "Template",
            () => "Name not set",
            () => {
                return !!this.name;
            }));
    }

    private findHandler(ident: number, list: TerminalHandler[]) {

        for (let i = 0; i < list.length; ++i) {
            const handler = list[i];
            if (handler.ident === ident)
                return handler;
        }
        return null;
    }

    private addHandler(list: TerminalHandler[], name: string, allowGoTo: boolean): TerminalHandler {

        const handler = new TerminalHandler();
        handler.allowGoTo = allowGoTo;

        handler.ident = list.length;

        if (list.length === 0) {
            handler.name = `Default ${name}`;
        } else {
            handler.name = name + " " + handler.ident;
        }

        list.push(handler);
        return handler;
    }

    private removeHandler(list: TerminalHandler[], ident: number) {

        if (ident === 0)
            return 0;

        const handler = this.findHandler(ident, list);

        const index = list.indexOf(handler);
        list.splice(index, 1);

        for (let i = index; i < list.length; ++i)
            list[i].ident--;

        return index;
    }

    private allAudio(): Audio[] {
        const list: Audio[] = [];
        _.each(this.transactions, (t: Transaction) => {
            const transactionAudios = t.audios;
            for (let i = 0; i < transactionAudios.length; ++i) {
                const audio = transactionAudios[i];
                list.push(audio);
            }
        });

        _.each(this.errorHandlers, (h: TerminalHandler) => {
            list.push(h.audio);
        });
        _.each(this.noResponseHandlers, (h: TerminalHandler) => {
            list.push(h.audio);
        });

        return list;
    }

    addErrorHandler(): TerminalHandler {

        return this.addHandler(this.errorHandlers, "Error Handler", true);
    }

    removeErrorHandler(ident: number) {

        const index = this.removeHandler(this.errorHandlers, ident);
        if (index === 0) return;

        this.forAll<MenuTransaction>((menu) => {
            if (menu.errorHandlerIdent >= index) {
                menu.errorHandlerIdent = 0;
            }
        }, MenuTransaction.typename());
    }

    addNoResponseHandler(): TerminalHandler {
        return this.addHandler(this.noResponseHandlers, "No Response Handler", true);
    }

    removeNoResponseHandler(ident: number) {

        const index = this.removeHandler(this.noResponseHandlers, ident);
        if (index === 0) return;

        this.forAll<MenuTransaction>((menu) => {
            if (menu.noResponseHandlerIdent >= index) {
                menu.noResponseHandlerIdent = 0;
            }
        }, MenuTransaction.typename());

    }

    purgeWaveFile(filename: string) {

        function purge(audio: Audio) {

            if (audio.wav === filename)
                audio.wav = null;

            for (let j = 0; j < audio.prePromptSegments.length; ++j)
                purge(audio.prePromptSegments[j]);
        }

        _.forEach(this.allAudio(), purge);
    }

    syncTts(engines: ITtsEngine[]): boolean {

        if (engines.length === 0)
            return false;

        for (let engine of engines) {
            if (this.ttsEngine !== engine.id)
                continue;

            for (let voice of engine.voices)
                if (this.ttsVoice === voice.id) {
                    this.setTtsVoice(engine, voice);
                    return true;
                }
        }

        // find a preferred engine
        const engine = findPreferredOrFirst(engines);
        this.setTtsVoice(engine);
        return true;
    }

    setTtsVoice(engine: ITtsEngine, voice: ITtsVoice = null) {
        this.ttsEngine = engine.id;

        if (voice)
            voice = engine.voices.find((v) => v.id === voice.id);

        if (!voice)
            voice = findPreferredOrFirst(engine.voices);

        this.ttsVoice = voice.id;
        this.ttsGender = voice.gender;
        this.ttsLanguage = voice.language;
    }

}
