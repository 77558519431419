import {IHttpResponse} from "angular";
import _ from "lodash";
import {AppComponentBase} from "../../common/AppComponentBase";
import {AppError, ModifiedElsewhereError, NetworkError} from "../../common/errors";
import {utility} from "../../utility/utility";

type MultipartData<T> = T | T & { $$multipart: { action: string, payload: any, skip: number, take: number } }


export abstract class DispatchApi extends AppComponentBase {

    private static leaseInit: number = new Date().getTime();

    static getLease() {
        return this.leaseInit;
    }

    static updateLease() {
        this.leaseInit = new Date().getTime();
    }

    private erroredOut: boolean = false;
    private logResponseData: any = null;

    get templateId(): string {
        return this.state.templateId;
    }

    protected constructor(private readonly dispatchGroup: string) {
        super();
    }

    private getEndpoint() {
        return `${this.state.environment.backendUrl}/api/${this.dispatchGroup}`;
    }

    private async resolveResponse(fn: () => Promise<IHttpResponse<any>>, continuation: any = {}) {

        let response: IHttpResponse<any>;

        try {
            response = await fn();
        } catch (e) {

            if (typeof e === "object") {
                if ("data" in e && e.data.lease_expired) {
                    throw new ModifiedElsewhereError();
                }
            }

            console.error("RESPONSE => ", e.data);
            throw new NetworkError(e.toString(), "Network Error");
        }

        try {

            const responseData = response.data as any;

            if ("data" in responseData && responseData["data"]["lease_expired"])
                throw new ModifiedElsewhereError();
            DispatchApi.updateLease();

            if (responseData.ok) {

                this.logResponseData = responseData;

                if ("params" in responseData) {
                    const params = responseData["params"];
                    if ("skip" in params) {
                        return _.extend(responseData.data, {
                            $$multipart: {
                                skip: params["skip"],
                                take: params["take"],
                                ...continuation
                            }
                        });
                    }
                }

                return responseData.data;

            } else {
                this.erroredOut = true;
                throw new AppError(responseData.message ?? "Unknown API Error");
            }
        } finally {

        }
    }

    async dispatch<T = any>(action: string, payload: any = {}): Promise<MultipartData<T>> {

        const start = utility.perf();
        const stack = utility.getStackTrace();

        const payloadCredentials = {
            action: action,
            lease: DispatchApi.getLease()
        };

        payload = _.extend(payloadCredentials, payload);

        try {
            return await this.resolveResponse(async () => {
                return this.http.post(this.getEndpoint(), payload, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${DispatchApi.token}`,
                    },
                });
            }, {
                action: action,
                payload: {...payload},
            });
        } finally {
            const complete = utility.perf();
            if (complete) {
                const pre = _.padEnd(`${this.dispatchGroup}(${action})`, 40);
                const time = _.padStart((complete - start).toFixed(0), 10);
                const groupTitle = `${pre} -> ${time} (ms)`;
                console.groupCollapsed((this.erroredOut ? "⚠ " : "") + groupTitle);

                console.groupCollapsed("JSON Request");
                console.log(JSON.stringify(payload, null, 2));
                console.groupEnd();

                if (this.logResponseData && (location.hostname === "localhost")) {
                    console.groupCollapsed("JSON Response");
                    console.log(JSON.stringify(this.logResponseData, null, 2));
                    console.groupEnd();
                }

                console.groupCollapsed("Python Request");
                console.log(utility.toPythonDict(payload));
                console.groupEnd();

                console.groupCollapsed("Stack Trace");
                for (let item of stack)
                    console.log(item);
                console.groupEnd();

                console.dir(payload);
                console.groupEnd();
            }
        }
    }

    async dispatchFormData<T = any>(action: string, data: FormData): Promise<T> {

        data.append("action", action);
        return await this.resolveResponse(async () => this.http({
            url: this.getEndpoint(),
            method: "POST",
            headers: {
                "Content-Type": undefined,
                "Authorization": `Bearer ${DispatchApi.token}`,
            },
            data: data
        }));
    }

    async next<T>(task: MultipartData<T>): Promise<MultipartData<T>> {

        if (!("$$multipart" in task))
            return null;

        const mp = task.$$multipart;

        const skip = mp.skip + mp.take;
        const payload = _.extend({}, mp.payload, {skip: skip});
        const action = mp.action;

        return await this.dispatch<T>(action, payload);
    }

    private static token: string;

    static setAuthToken(token: string) {
        this.token = token;
    }

}
