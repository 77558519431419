import {AngularDirective, NGX} from "../utility/ng";
import {IScope} from "angular";
import $ from "jquery"

interface IHtmlIFrameScope extends IScope {
    html: string;
}

const HtmlIFrame = NGX.makeTagDirective("common/html-iframe", null, {html: "="},
    (scope: IHtmlIFrameScope, elem: JQuery) => {
        scope.$watch(() => scope.html, (html) => {
            elem.empty();

            const iframe = $("<iframe>").attr("frameBorder", 0);

            // clear iframe border style
            iframe.css("border", "none");
            iframe.css("border-width", "0px");
            iframe.css("border-style", "none");
            iframe.css("border-color", "transparent");
            iframe.css("border-image", "none");

            const iframeElement = iframe[0] as HTMLIFrameElement;
            iframeElement.onload = () => {
                elem.height(iframe.contents().find("body").height() * 1.1);
            }

            iframe.attr("srcdoc", html);
            elem.append(iframe);
        });
    });

export function getIFrameDirectives(): AngularDirective[] {
    return [HtmlIFrame]
}
