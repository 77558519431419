export class AppError extends Error {
    constructor(message: string, readonly userMessage: string = null) {
        super(message);
        if (userMessage == null)
            this.userMessage = message

        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "AppError"
    }
}

export class NetworkError extends AppError {

}

export class ProtectedError extends AppError {

}

export class ModifiedElsewhereError {
    constructor() {
    }
}
