import {AppDirectiveBase} from "../common/AppComponentBase";
import {AngularDirective, NGX} from "../utility/ng";
import {browser} from "../utility/browser";
import {GlobalEvents, NamespaceName} from "../const";
import {IScope} from "angular";
import $ from "jquery";

interface IVTabScope extends IScope {
    id: number;
    icon: string;
    label: string;
    active: boolean;
}

class TabController extends AppDirectiveBase<IVTabScope> {
    private _tabs: TabsController;

    activate() {
        this._tabs?.activateTab(this.scope.id);
    }

    link(tabs: TabsController) {
        this._tabs = tabs;
    }
}

class TabsController extends AppDirectiveBase {

    tabs: TabController[] = [];
    currentTabId: number;

    registerTab(tab: TabController, disableAutoOpen: boolean) {
        this.tabs.push(tab);
        tab.link(this);

        if (this.tabs.length === 1 && !disableAutoOpen)
            this.activateTab(0);

        return this.tabs.length - 1;
    }

    activateTab(id: number) {
        if (this.currentTabId === id) {
            for (const item of this.tabs)
                item.scope.active = false;
            this.currentTabId = null;
        } else {
            for (let i = 0; i < this.tabs.length; ++i)
                this.tabs[i].scope.active = i === id;
            this.currentTabId = id;
        }
    }
}

const VTabs = NGX.makeTagDirective({
    tag: `${NamespaceName}-v-tabs`,
    template: null
}, TabsController);

const VTab = NGX.makeTagDirective({tag: `${NamespaceName}-v-tab`, template: "common/vtab"}, TabController, {
    label: "@",
    icon: "@",
    hint: "@?",
}, (scope, elem, attr, ctrl: [TabController, TabsController]) => {

    const disableAutoOpen = attr.hasOwnProperty("disableAutoOpen");
    const [tab, tabs] = ctrl;
    scope.id = tabs.registerTab(tab, disableAutoOpen);
    browser.applyIconSpec(elem.find(".icon-container").children(), scope.icon);

    const $body = $(document.body);

    if (scope.hint) {

        let hintBox: JQuery;
        elem.find(".hint").on("mouseenter", (event) => {

            if (hintBox)
                hintBox.remove();
            const x = event.pageX;
            const y = event.pageY;

            hintBox = $("<div>").addClass("floating-hint-box").text(scope.hint).appendTo($body).css({
                left: `${x}px`,
                top: `${y}px`
            });
        }).on("mouseleave", () => {
            if (hintBox)
                hintBox.remove();
        });

        scope.$on(GlobalEvents.closeToolWindows, () => {
            if (hintBox)
                hintBox.remove();
        });
    }

}, {require: ["upwireVTab", "^upwireVTabs"], transclude: true});

const DragBox = NGX.makeTagDirective({tag: "upwire-dragbox", template: "common/dragbox"}, null,
    {icon: "@", text: "@", action: "@"}, ((scope, elem) => {
        if (scope.icon.endsWith(".svg")) {
            elem.find(".icon").css({
                "background-image": `url('/pub/assets/${scope.icon}')`,
                "background-position": "center",
                "height": "56px",
                "position": "relative",
                "top": "22px"
            });
        } else {
            browser.applyIconSpec(elem.find(".icon"), scope.icon);
        }
    }));

export function getVTabsDirectives(): AngularDirective[] {
    require("../../styles/vtabs.less");
    return [VTab, VTabs, DragBox];
}
