import {IScope} from "angular";
import {CanvasTemplate, isCommonTag} from "../../canvas/CanvasTemplate";
import {IStatusTag} from "../../canvas/common";
import {AppDirectiveBase} from "../../common/AppComponentBase";
import {IReferenceGroup, IReferenceItem} from "../../common/References";
import {Template} from "../../common/Template";
import {Variable} from "../../common/Variable";
import {NGX} from "../../utility/ng";

export const VariablePillDirective = NGX.makeTagDirective("common/variable-pill", null,
    {variable: "="}, ((scope, elem) => {
        scope.$watch(() => scope.variable, (variable: Variable) => {
            if (variable)
                elem.toggleClass("local", variable.temporary);
        });
    })
);

interface IVariableMenuScope extends IScope {
    template: Template;
    variables: Variable[];
    selectedVariable: Variable | null;

    selectVariable(variable: Variable): void;

    addNewVariable(): void;

    addNewRequiredVariable(): void;

    removeVariable(variable: Variable): void;

    locals: Variable[];
    globals: Variable[];

    allowLocalVariables: boolean;
    allowGlobalVariables: boolean;

    selectedLocalVariable: boolean;
    selectedRequiredVariable: boolean;

    canModifyRequiredVariables: boolean;
}

export const VariableEvents = {
    selectVariable: "selectVariable",
    addNewVariable: "addVariable",
    addRequiredVariable: "addRequiredVariable",
    removeVariable: "removeVariable",
};

export const TagEvents = {
    selectTag: "selectTag",
    addNewTag: "addTag",
    removeTag: "removeTag",
};

export const ReferenceEvents = {
    select: "selectReference"
};


class VariableMenuController extends AppDirectiveBase<IVariableMenuScope> {
    public static $inject = ["$scope"];

    constructor(scope: IVariableMenuScope) {
        super(scope);

        scope.allowGlobalVariables = !(scope.allowGlobalVariables === false);
        scope.selectedRequiredVariable = false;
        scope.selectedLocalVariable = false;
    }

    async onInit(): Promise<void> {
        await super.onInit();

        const scope = this.scope;

        scope.variables = scope.template.variables;
        scope.selectedVariable = null;

        scope.selectVariable = (variable: Variable) => {
            if (variable && scope.selectedVariable !== variable) {
                scope.selectedVariable = variable;

                if (variable.temporary) scope.selectedLocalVariable = true;
                else scope.selectedRequiredVariable = true;

                scope.$emit(VariableEvents.selectVariable, variable);
            } else {
                scope.selectedVariable = null;
                scope.selectedLocalVariable = false;
                scope.selectedRequiredVariable = false;
            }
        };

        scope.addNewVariable = () => {
            scope.$emit(VariableEvents.addNewVariable);
        };

        scope.addNewRequiredVariable = () => {
            scope.$emit(VariableEvents.addRequiredVariable);
        };

        scope.removeVariable = (variable: Variable) => {
            scope.$emit(VariableEvents.removeVariable, variable);

            scope.selectedVariable = null;
            scope.selectedLocalVariable = false;
            scope.selectedRequiredVariable = false;
        };

        const update = () => {
            scope.locals = scope.variables.filter(variable => variable.temporary);
            scope.globals = scope.variables.filter(variable => variable.required);
        };

        scope.$watchGroup([() => scope.variables, () => scope.variables.length], () => {
            update();
        });

        this.scope.$watch(() => this.scope.template.publicationStatus, (status) => {
            this.scope.canModifyRequiredVariables = status === "draft";
        });

        update();
    }

    get empty(): boolean {
        if (this.scope.canModifyRequiredVariables)
            return false;

        if (!this.scope.template)
            return true;

        return !this.scope.allowLocalVariables && this.scope.template.variables.length == 0;
    }
}

export const VariableMenuDirective = NGX.makeTagDirective("common/menu/variables", VariableMenuController,
    {template: "=", allowLocalVariables: "="}
);

interface ITagMenuScope extends IScope {

    template: CanvasTemplate;
    selectedTag: IStatusTag | null;


    selectTag(tag: IStatusTag): void;

    addNewTag(): void;

    removeTag(tag: IStatusTag): void;

    commonTags: IStatusTag[];
    customTags: IStatusTag[];

    selectedCustomTag: boolean;
}

class TagMenuController extends AppDirectiveBase<ITagMenuScope> {
    async onInit(): Promise<void> {
        this.scope.selectedTag = null;

        this.scope.selectTag = (tag: IStatusTag) => {
            if (this.scope.selectedTag !== tag) {
                this.scope.selectedTag = tag;
                this.scope.$emit(TagEvents.selectTag, tag);
            } else {
                this.scope.selectedTag = null;
            }
        };

        this.scope.addNewTag = () => {
            this.scope.$emit(TagEvents.addNewTag);
        };

        this.scope.removeTag = (tag: IStatusTag) => {
            this.scope.$emit(TagEvents.removeTag, tag);
            this.scope.selectedTag = null;
        };

        this.scope.$watchGroup([() => this.scope.template.tags, () => this.scope.template.tags.length], ([tags]) => {

            const common: IStatusTag[] = this.scope.commonTags = [];
            const custom: IStatusTag[] = this.scope.customTags = [];

            for (let tag of tags) {
                if (isCommonTag(tag.name))
                    common.push(tag);
                else
                    custom.push(tag);
            }
        });

        this.scope.$watch(() => this.scope.selectedTag, (tag) => {
            this.scope.selectedCustomTag = tag && !isCommonTag(tag.name);
        });


    }
}

export const TagsMenuDirective = NGX.makeTagDirective("common/menu/tags", TagMenuController,
    {template: "="}
);

interface IReferencesMenuScope extends IScope {
    references: IReferenceGroup[];

    isGroupEmpty(group: IReferenceGroup): boolean;

    select(reference: IReferenceItem): void;
}

export const ReferencesMenuDirective = NGX.makeTagDirective("common/menu/references",
    null, {references: "="}, (scope: IReferencesMenuScope) => {

        scope.isGroupEmpty = (group: IReferenceGroup) => {
            return group.items.length == 0;
        };

        scope.select = (reference: IReferenceItem) => {
            scope.$emit(ReferenceEvents.select, reference);
        };
    }
);
