import {IScope} from "angular";
import {AppDirectiveBase} from "./AppComponentBase";
import {NGX} from "../utility/ng";

import _ from "lodash"
import {GlobalEvents} from "../const";
import {uuid} from "../utility/utility";

const beginSentinel = uuid()

export interface IFullPageWizardScope extends IScope {
    phase: string
}

export abstract class FullPageWizardController<TState, TScope extends IFullPageWizardScope>
    extends AppDirectiveBase<TScope> {

    wizardState: TState = null;
    forwards: boolean = true;

    private stateDepth = 0;

    protected injectFields(injector: angular.auto.IInjectorService) {
        super.injectFields(injector);
    }

    async onInit() {
        await super.onInit();

        history.pushState(beginSentinel, null, null);

        let travellingBackward = false;
        const handler = (evt: PopStateEvent) => {
            if (evt.state === beginSentinel) {
                this.close(null);
            } else {

                const state = evt.state ? evt.state : {depth: 0};
                _.extend(this.wizardState, state.state);

                travellingBackward = true;
                this.forwards = this.stateDepth <= state.depth;
                this.stateDepth = state.depth;

                NGX.safeDigest(this.scope);
            }
        };

        window.addEventListener("popstate", handler);
        this.scope.$on("$destroy", () => {
            window.removeEventListener("popstate", handler);
        });

        this.scope.$watch(() => this.wizardState, (state) => {
            if (state !== null) {
                if (!travellingBackward) {
                    history.pushState({
                        state: this.wizardState,
                        depth: ++this.stateDepth
                    }, null, null);
                }
                this.open(state);
            }
            travellingBackward = false;
        }, true);
    }

    open(state: TState) {

    };

    prev() {
        this.forwards = false;
        history.back();
    }

    next() {
        this.scope.$broadcast(GlobalEvents.wizardMoveNextEvent);
        this.forwards = true;
    }

    close(param: any = null) {
        this.scope.$emit(GlobalEvents.overlayClose, param);
    }
}
