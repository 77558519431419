import _ from "lodash";

export interface IRGBColor {
    r: number;
    g: number;
    b: number;
}

export interface IHSLColor {
    h: number;
    s: number;
    l: number;
}

const upwireTheme: string[] = [
    "#FEC009",
    "#536DFF",
    "#D32F2F",
    "#4CAF50",
    "#f9cdd2",
    "#3f51b5",
    "#417505",
    "#9b2222",
    "#fc5768",
    "#2196f3",
    "#ff9900",
    "#5b75fb"
];

const themes: { [key: string]: string[] } = {
    "upwire": [
        "#FEC009", "#536DFF", "#D32F2F", "#4CAF50", "#f9cdd2", "#3f51b5",
        "#417505", "#9b2222", "#fc5768", "#2196f3", "#ff9900", "#5b75fb"
    ],
    "bracelet candy": ["#F0BFB6", "#F1EC93", "#F0B87C", "#81C6E0", "#9EE196", "#F0BFB6", "#F1EC93", "#F0B87C"],
    "orange drink": ["#779d89", "#bcd5d1", "#fedda1", "#ffde52", "#ffb300"]
};

// ReSharper disable InconsistentNaming
const hex2rgbRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i,
    rgb2hexRegex = /rgb\(([\d]+), ([\d]+), ([\d]+)\)/,
    sanitizeRegex = /rgb\(([\d]+), ([\d]+), ([\d]+)\)/gm;


function make_rgb(r, g, b): IRGBColor {
    return {r: Math.round(r), g: Math.round(g), b: Math.round(b)};
}

function copy_rgb(color: IRGBColor): IRGBColor {
    return {r: color.r, g: color.g, b: color.b};
}

function rgb2str(color: IRGBColor): string {
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
}

const white = make_rgb(255, 255, 255);
const black = make_rgb(0, 0, 0);

function num2hex(c: number): string {
    const hex = c.toString(16);
    return (hex.length === 1 ? `0${hex}` : hex).toUpperCase();
}

function hex2num(hex: string): number {
    return parseInt(hex, 16);
}

function hue2rgb(p: number, q: number, t: number) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
}

export namespace color {

    export function sanitize(html: string): string {
        return html.replace(sanitizeRegex, (rgbs: string) => {
            return rgbs2hex(rgbs);
        });
    }

    export function hex2rgb(hex: string): IRGBColor {

        try {
            const result = hex2rgbRegex.exec(hex);
            if (result.length < 4)
                return black;
            return {
                r: hex2num(result[1]),
                g: hex2num(result[2]),
                b: hex2num(result[3])
            };
        } catch (e) {
            return black;
        }
    }

    export function hex2rgbs(hex: string): string {
        return rgb2str(hex2rgb(hex));
    }

    export function rgb2hex(color: IRGBColor): string {
        return `#${num2hex(color.r)}${num2hex(color.g)}${num2hex(color.b)}`;
    }

    export function rgbs2rgb(color: string): IRGBColor {

        if (color === null)
            return copy_rgb(black);

        const result = rgb2hexRegex.exec(color);

        if (!result) return copy_rgb(black);

        const r = parseInt(result[1]),
            g = parseInt(result[2]),
            b = parseInt(result[3]);

        return make_rgb(r, g, b);
    }

    export function rgbs2hex(color: string): string {
        return rgb2hex(rgbs2rgb(color));
    }

    export function isLight(color: IRGBColor) {
        return Math.sqrt(0.299 * color.r * color.r + 0.587 * color.g * color.g + 0.114 * color.b * color.b) > 128;
    }

    export function contrastHexColor(hex: string) {
        return isLight(hex2rgb(hex)) ? rgb2hex(black) : rgb2hex(white);
    }

    export function hsl2rgb(color: IHSLColor): IRGBColor {

        const h = color.h, s = color.s, l = color.l;

        if (s === 0)
            return copy_rgb(white);

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;

        const r = 255 * hue2rgb(p, q, h + 1 / 3),
            g = 255 * hue2rgb(p, q, h),
            b = 255 * hue2rgb(p, q, h - 1 / 3);

        return make_rgb(r, g, b);
    }

    export function rgb2hsl(color: IRGBColor): IHSLColor {

        const r = color.r / 255,
            g = color.g / 255,
            b = color.b / 255;

        const max = Math.max(r, g, b),
            min = Math.min(r, g, b);

        const l = (max + min) / 2;

        let h: number, s: number;
        if (max === min) {
            h = s = 0;
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }

            // ReSharper disable once UsageOfPossiblyUnassignedValue
            h /= 6;
        }

        return {h: h, s: s, l: l};
    }

    export function findColors(html: string): string[] {
        const colors = [];
        const re = /color: (rgb\(.*?\));/gm;

        let m: RegExpExecArray;

        while ((m = re.exec(html)) !== null) {
            if (m) {
                if (m.index === re.lastIndex)
                    re.lastIndex++;
                colors.push(rgbs2hex(m[1]));
            }
        }

        return _.uniq(colors);
    }

    export function luminance(hex, lum): string {

        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, "");
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        lum = lum || 0;

        // convert to decimal and change luminosity
        let rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += (`00${c}`).substr(c.length);
        }

        return rgb;
    }

    export function colorize(value: string, context: any, theme: any = "upwire") {
        if (!context.hasOwnProperty(value))
            context[value] = {};

        const cvalue = context[value];

        if (!cvalue.hasOwnProperty("theme"))
            cvalue["theme"] = {};

        const tvalue = cvalue["theme"];

        const themeColors = themes[theme];

        if (!tvalue.hasOwnProperty(theme)) {
            const idx = _.indexOf(_.keys(context), value) % themeColors.length;
            tvalue[theme] = themeColors[idx];
        }

        return tvalue[theme];
    }

}
