import {CommonTransactionController, makeTransactionDirective, TransactionDirective} from "./common";
import {ScriptEditorWizardDirective} from "../../../directives/scripteditor";
import {IReferenceGroup, TransactionReferenceGroup, VariableReferenceGroup} from "../../../common/References";
import {
    EndTransaction,
    ITerminal,
    SmsApiTransaction,
    SmsMessageTransaction,
    SmsStartTransaction
} from "../../Transactions";
import {strings} from "../../../utility/strings";

import _ from "lodash";
import {getDeliveryTicks} from "../../../sms/common";
import {SmsApiTransactionController, CommonStartTransactionController} from "./dual";
import {SmsTemplate} from "../../CanvasTemplate";
import {GlobalEvents} from "../../../const";


class SmsMessageController extends CommonTransactionController<SmsMessageTransaction> {
    private _refs: IReferenceGroup[] = [];
    tics: any[] = getDeliveryTicks(true);
    tick: any;
    currentRoot: string = null;
    aliases: any = {};

    private updateReferences() {
        this._refs = [
            new VariableReferenceGroup(this.template.variables)
        ];
    }


    readonly catchall = "[all]";

    get length(): number {
        return this.transaction.text.length;
    }

    get message(): string {
        return this.transaction.text;
    }

    get hasCatchAllBranch() {
        for (let terminal of this.scope.transaction.branches)
            if (terminal.ident === this.catchall)
                return true;
        return false;
    }

    get hasEditor(): boolean {
        return true;
    }

    get references(): IReferenceGroup[] {
        return this._refs;
    }

    async edit() {
        const transaction = this.transaction;
        await this.overlay.show(ScriptEditorWizardDirective, {

            get text() {
                return transaction.text;
            },

            set text(value: string) {
                transaction.text = value;
            },

            pageTitle: `Edit ${transaction.tranName}`,
            showRawControls: true,
            showCounter: true,
            references: [
                new VariableReferenceGroup(this.template.variables)
            ]
        });
    }

    shorten(terminal: ITerminal): string {
        return strings.shorten(terminal.ident, 15);
    }

    async onInit(): Promise<void> {

        const tran = this.scope.transaction;
        this.tick = _.find(this.tics, tic => tic.duration === tran.delay);
        if (!this.tick) {
            const custom = this.tick = {
                name: `Custom: ${tran.delay}(s)`,
                duration: tran.delay
            };
            this.tics.push(custom);
        }

        this.scope.$watch(() => this.tick, (tic) => {
            tran.delay = tic.duration;
        });

        this.updateReferences();
        this.scope.$on(GlobalEvents.referencesUpdated, () => {
            this.updateReferences();
        });
    }

    toggleCatchAllBranch() {
        if (this.hasCatchAllBranch)
            this.removeBranch(this.catchall);
        else
            this.addBranch(this.catchall);
    }

    addAlias(root: string) {
        const alias = this.aliases[root];
        if (alias && root) {
            this.scope.transaction.addBranchAlias(root, alias);
            delete this.aliases[root];
        }
    }

    addBranch(name: string = null) {
        const root = name || this.currentRoot;

        if (root) {
            this.scope.transaction.addBranch(root);
            this.currentRoot = null;
        }
    }

    removeBranch(root: string) {
        this.scope.transaction.deleteBranch(root);
    }
}

class SmsStartController extends CommonStartTransactionController<SmsStartTransaction> {

    get template(): SmsTemplate {
        return this.scope.template as SmsTemplate;
    }

    get scopeEx() {
        return this.scope as any;
    }

    addCurrentOptOut() {
        this.template.addOptOut(this.scopeEx.currentOptOut);
        this.scopeEx.currentOptOut = null;
    }

    removeOptOut(text: string) {
        this.template.delOptOut(text);
    }
}

const start = makeTransactionDirective(SmsStartTransaction, "", "", SmsStartController);
const message = makeTransactionDirective(SmsMessageTransaction, "zync-icon-basic-elaboration-message-plus", "Send SMS", SmsMessageController);
const api = makeTransactionDirective(SmsApiTransaction, "zync-icon-basic-elaboration-cloud-download", "API Call", SmsApiTransactionController);
const end = makeTransactionDirective(EndTransaction, "fa-stop-circle", "End Flow");

export function* getSmsTransactions(skipStart: boolean = false): IterableIterator<TransactionDirective> {
    if (!skipStart)
        yield start;

    yield message;
    yield api;
    yield end;
}
