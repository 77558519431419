import {ITemplateDescriptor, Template} from "../common/Template";
import {EmailTemplate} from "../email/EmailTemplate";
import {materializeSmsTemplate, materializeVoiceTemplate} from "./Serialization";

export function materializeTemplate(templateId: string, descriptor: ITemplateDescriptor): Template {

    let template: Template = null;

    if (descriptor.kind === "sms") {
        template = materializeSmsTemplate(templateId, descriptor.data);
    }

    if (descriptor.kind === "voice") {
        template = materializeVoiceTemplate(templateId, descriptor.data);
    }

    if (descriptor.kind === "email") {
        template = EmailTemplate.materialize(templateId, descriptor.data);
    }

    if (!template)
        throw new Error(`Unknown template kind: ${descriptor.kind}`);

    template.setDescriptor(descriptor);
    return template;
}
