import {AngularDirective} from "../utility/ng";
import {VoiceEditor} from "./editor";
import {VoiceSettingsWizard} from "./wizard";
import {getVoiceTransactionDirectives} from "../canvas/components/transactions/voice";
import {getTerminalHandlerDirectives} from "./directives/handers";
import {DateLogicBuilderDirective, NumberLogicBuilderDirective} from "./directives/logicbuilders";


export function* getVoiceDirectives(): IterableIterator<AngularDirective> {
    yield VoiceEditor;
    yield VoiceSettingsWizard;
    yield* getVoiceTransactionDirectives();
    yield* getTerminalHandlerDirectives();
    yield NumberLogicBuilderDirective;
    yield DateLogicBuilderDirective;
}
