import {materializeTemplate} from "../canvas/materialization";
import {NetworkError} from "../common/errors";
import {Template} from "../common/Template";
import {DispatchApi} from "./base/DispatchApi";

export class TemplateApi extends DispatchApi {

    static injectAs = "TemplateApiService";

    constructor() {
        super("templates");
    }

    // SUB TEMPLATE ACTIONS ACTIONS

    async saveTemplate(template: Template): Promise<boolean> {

        const snapshotId = await this.dispatch("set", {
            "templateId": template.id,
            "data": JSON.parse(template.serialize()),
        });

        if (snapshotId)
            template.addHistorySnapshot(snapshotId);

        return true;
    }

    async undo<TTemplate extends Template>(template: TTemplate): Promise<TTemplate> {

        const snapshotId = template.descriptor?.history[0];
        if (!snapshotId)
            return template;

        console.log("undo", template.id, snapshotId);

        const model = await this.dispatch("revert", {
            "templateId": template.id,
            "snapshotId": snapshotId,
        });

        return materializeTemplate(template.id, model) as TTemplate;
    }

    async loadTemplate(templateId: string): Promise<Template | null> {

        try {
            const template = await this.dispatch("get", {
                templateId: templateId
            });
            return materializeTemplate(templateId, template);

        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async publishTemplate(template: Template): Promise<boolean> {

        const publication = await this.dispatch("publish", {
            templateId: template.id,
            settings: template.settings,
            data: template.publicationData,
        }) as { "published": boolean, "conflict": boolean, "error": string };

        if (publication.error) {
            throw new NetworkError(publication.error, publication.error);
        }


        const descriptor = await this.dispatch("get", {
            templateId: template.id
        });

        template.setDescriptor(descriptor);
        return false;
    }
}
