import {ITtsEngine} from "../canvas/CanvasTemplate";
import {ModuleKind} from "../canvas/Transactions";
import {DispatchApi} from "./base/DispatchApi";

export type VoiceEditorConfiguration = {
    engines: ITtsEngine[];
    modules: ModuleKind[]
}

export class VoiceEditorApi extends DispatchApi {
    static injectAs = "VoiceEditorApiService";
    private _task: Promise<VoiceEditorConfiguration>;

    constructor() {
        super("editor/voice");
    }

    async initialize(): Promise<VoiceEditorConfiguration> {
        if (!this._task)
            this._task = this.dispatch("init", {});
        try {
            return await this._task;
        } catch (e) {
            await this.overlay.halt("Unable to get initial settings");
        }
    }
}
