import {IScope} from "angular";
import {DateLogicBranch, DateLogicToken, NumberLogicBranch, NumberLogicToken} from "../../canvas/Transactions";
import {NGX} from "../../utility/ng";
import {AppDirectiveBase} from "../../common/AppComponentBase";

interface INumberLogicBuilderScope extends IScope {
    branch: NumberLogicBranch;
    remove: (any) => void;
}

class BuilderController extends AppDirectiveBase<INumberLogicBuilderScope> {

    comparisons: any;
    actions: any;
    operations: any;

    constructor(scope: INumberLogicBuilderScope) {
        super(scope);

        this.operations = {
            "if": 0,
            "else": 9
        };

        this.comparisons = {
            "greater than": 1,
            "greater than or equal to": 2,
            "less than": 3,
            "less than or equal to": 4,
            "equal to": 5
        };

        this.actions = {
            "and": 7,
            "end": 8
        };

    }

    changeComp(idx: number, token: NumberLogicToken) {
        this.scope.branch.change(idx, token);
    }

    remove() {
        this.scope.remove({branch: this.scope.branch});
    }
}

interface IDateLogicBuilderScope extends IScope {
    branch: DateLogicBranch;
    ident: string;
}

class DateBuilderController extends AppDirectiveBase<IDateLogicBuilderScope> {

    units: any;
    temporals: any;
    operations: any;

    constructor(scope: IDateLogicBuilderScope) {
        super(scope);
        this.operations = {
            ".": DateLogicToken.End,
            "from": DateLogicToken.From
        };

        this.units = {
            "days": DateLogicToken.Days,
            "weeks": DateLogicToken.Weeks,
            "months": DateLogicToken.Months,
        };

        this.temporals = {
            "future": DateLogicToken.Future,
            "past": DateLogicToken.Past
        };

    }

    changeComp(idx: number, token: DateLogicToken) {
        this.scope.branch.change(idx, token);
    }
}

export const NumberLogicBuilderDirective = NGX.makeTagDirective("voice/number-logic-builder", BuilderController, {
    branch: "=",
    remove: "&",
    ident: "="
}, null, {controllerAs: "builder"});

export const DateLogicBuilderDirective = NGX.makeTagDirective("voice/date-logic-builder", DateBuilderController, {
    branch: "=",
    ident: "@"
}, null, {controllerAs: "builder"});
