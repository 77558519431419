import {url} from "./url";
import {browser} from "./browser";

export enum ImageTransform { Cover = 0, Contain = 1}

export interface IImageSource {
    data: ImageData;
    compressed: boolean;
    filename: string;
}

export interface ITransformResult {
    dataUrl: string;
    signature: string;
    blob: Blob;
}

export interface ITransformDescriptor {
    width: number;
    height: number;
    transform: ImageTransform;
    background: string;
}

export interface IImageTransformRequest extends ITransformDescriptor {
    source: IImageSource;
}

export namespace imaging {

    function getDataUrlBuffer(dataUrl: string): string {
        const marker = ";base64,";
        const idx = dataUrl.indexOf(marker) + marker.length;
        return window.atob(dataUrl.substring(idx));
    }

    function makeBlob(dataUrl: string, contentType: string) {
        const byteCharacters = getDataUrlBuffer(dataUrl);
        return browser.makeBlob(byteCharacters, contentType);
    }

    function makeCanvas(width: number, height: number): [HTMLCanvasElement, CanvasRenderingContext2D] {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        [canvas.width, canvas.height] = [width, height];
        return [canvas, context];
    }

    export async function getImageSource(url: string): Promise<IImageSource> {

        function isCompressed(): boolean {
            for (const fmt of ["jpeg", "jpg"])
                if (url.endsWith(`.${fmt}`))
                    return true;
            return null;
        }

        const image = new Image();

        const promise = new Promise<IImageSource>((resolve) => {
            image.onload = async () => {

                const [w, h] = [image.width, image.height];
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                [canvas.width, canvas.height] = [w, h];

                context.drawImage(image, 0, 0);
                const data = context.getImageData(0, 0, w, h);

                resolve({
                    data: data,
                    compressed: isCompressed(),
                    filename: url
                });
            };
        });
        image.src = url;
        image.crossOrigin = "Anonymous";

        return promise;
    }

    export function makeTransformSignature(descriptor: ITransformDescriptor): string {

        let signature = "";
        if (descriptor.transform === ImageTransform.Contain)
            signature = `--T-CNT--W${~~descriptor.width}xH${~~descriptor.height}_BG${descriptor.background}_--`;

        if (descriptor.transform === ImageTransform.Cover)
            signature = `--T-COV--W${~~descriptor.width}xH${~~descriptor.height}_--`;

        return url.makeSafeFileNameFromUri(signature);
    }

    export function readTransformSignature(str: string): ITransformDescriptor {

        if (str.includes("--T-CNT--")) {

            const m = /--T-CNT--W(\d+)xH(\d+)_BG(.+)_--/g.exec(str);
            if (!m) return null;

            if (m.length !== 4) return null;

            const descriptor = {
                transform: ImageTransform.Contain,
                width: parseInt(m[1]),
                height: parseInt(m[2]),
                background: m[3]
            };

            if (isNaN(descriptor.width) || isNaN(descriptor.height))
                return null;

            return descriptor;
        }

        if (str.includes("--T-COV--")) {

            const m = /--T-COV--W(\d+)xH(\d+)_--/g.exec(str);
            if (!m) return null;

            if (m.length !== 3) return null;
            const descriptor = {
                transform: ImageTransform.Cover,
                width: parseInt(m[1]),
                height: parseInt(m[2]),
                background: ""
            };

            if (isNaN(descriptor.width) || isNaN(descriptor.height))
                return null;

            return descriptor;
        }

        return null;
    }

    export function stripTransformSignature(str: string): string {
        const match = /--T-(.*?)_--(.*)/g.exec(str);
        if (!match || match.length !== 3)
            return str;
        return match[2];
    }

    export function stripLeadHash(str: string): string {
        const m = str.matchAll(/^[a-f0-9]{32}_(.*)$/gi).next();
        if (m.value && m.value[1])
            return m.value[1];
        return str;
    }

    export function transform(request: IImageTransformRequest): ITransformResult {

        const [dw, dh] = [request.source.data.width, request.source.data.height];
        const [ar, sar] = [request.width / request.height, dw / dh];

        const [dstCanvas, context] = makeCanvas(request.width, request.height);
        const [srcCanvas, srcContext] = makeCanvas(dw, dh);
        srcContext.putImageData(request.source.data, 0, 0);

        if (request.transform === ImageTransform.Cover) {
            const [sw, sh] = ar > sar ? [dw, dw / ar] : [dh * ar, dh];
            const [xs, ys] = [(dw - sw) / 2, (dh - sh) / 2];
            context.drawImage(srcCanvas, xs, ys, sw, sh, 0, 0, request.width, request.height);
        }

        if (request.transform === ImageTransform.Contain) {
            context.fillStyle = request.background;
            context.fillRect(0, 0, request.width, request.height);
            const [tw, th] = ar < sar ? [request.width, request.width / sar] : [request.height * sar, request.height];
            const [tx, ty] = [(request.width - tw) / 2, (request.height - th) / 2];
            context.drawImage(srcCanvas, 0, 0, dw, dh, tx, ty, tw, th);
        }

        const data = context.getImageData(0, 0, request.width, request.height);

        const dataUrl = dstCanvas.toDataURL("image/png");
        const blob = makeBlob(dataUrl, "image/png");

        //const [dataUrl, blob] = renderFinalImage(request.source, data);

        return {
            blob: blob,
            dataUrl: dataUrl,
            signature: makeTransformSignature(request)
        };
    }

    export const BlankImage = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

}


