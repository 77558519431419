import {AngularDirective} from "../utility/ng";
import {SmsEditor} from "./editor";
import {SmsSettingsWizard} from "./wizard";
import {getSmsTransactions} from "../canvas/components/transactions/sms";

export function* getSmsDirectives(): IterableIterator<AngularDirective> {
    yield SmsEditor;
    yield SmsSettingsWizard;
    yield* getSmsTransactions();
}
