import _ from "lodash";

import {EmailEditorApi, IImageDescriptor} from "../apis/EmailEditorApi";
import {TemplateEditorController, TemplateEditorScope} from "../common/EditorController";

import {GlobalEvents, TemplateTypename} from "../const";
import {imaging} from "../utility/imaging";
import {url} from "../utility/url";
import {EmailPreview} from "./components";
import {DeliveryDelay, DeliveryDelayOptions, EmailTemplate, EmailTheme} from "./EmailTemplate";
import {EmailImporter} from "./importer";
import {EmailWizard} from "./wizard";
import IInjectorService = angular.auto.IInjectorService;


export class EmailEditorController extends TemplateEditorController<EmailTemplate> {

    widths = [400, 500, 550, 600, 650];

    themes: EmailTheme[];
    theme: EmailTheme = null;

    allImages: IImageDescriptor[] = [];
    images: IImageDescriptor[] = [];


    private internalUsedColors: string[] = [];

    private api: EmailEditorApi;

    deliveryDelays: DeliveryDelay[] = [];
    delay: DeliveryDelay;

    get width() {
        return this.template?.width ?? 650;
    }

    protected injectFields(injector: IInjectorService) {
        super.injectFields(injector);
        this.api = injector.get(EmailEditorApi.injectAs);
    }

    constructor(public scope: TemplateEditorScope<EmailTemplate>) {
        super(scope);
        this.themes = EmailTheme.themes();

        for (let option of DeliveryDelayOptions)
            this.deliveryDelays.push({...option});
        this.delay = this.deliveryDelays[0];

    }

    async onEditorTemplateLoaded(): Promise<void> {
        await super.onEditorTemplateLoaded();

        this.theme = this.template.theme;
        this.template.fixBrokenTemplate();

        this.scope.$watch(() => this.theme, (theme: EmailTheme) => {
            if (this.template && theme !== this.template.theme)
                this.template.changeTheme(theme);
        });

        this.scope.$watch(() => this.width, (width: number) => {
            if (this.template && width !== this.template.width)
                this.template.changeWidth(width);
        });

        this.scope.$watch(() => this.delay, (dd) => {
            this.template.delay = dd.duration;
        });

        this.scope.$watch(() => this.template.delay, () => {

            this.delay = _.find(this.deliveryDelays, ddd => ddd.duration === this.template.delay);

            if (!this.delay) {
                this.deliveryDelays.push(this.delay = {
                    name: `Custom: ${this.template.delay}(s)`,
                    duration: this.template.delay
                });
            }
        });

        this.scope.$on(GlobalEvents.undo, () => {
            this.undo();
        });

        this.addExtraMenuOption({
            name: "Set Custom HTML",
            icon: "fa-code",
            action: async () => {
                await this.showRawEmailImportWizard();
            }
        });

        this.addExtraMenuOption({
            name: "Show Preview",
            icon: "fa-magic",
            action: async () => {
                await this.showPreview();
            }
        });
    }

    async showRawEmailImportWizard() {
        const html = await this.overlay.show(EmailImporter, {template: this.template});
        if (html && html.length > 0) {
            this.template.setRawHtml(html);
        } else {
            this.template.setRawHtml(null);
        }
    }

    usedColors() {
        this.internalUsedColors.length = 0;
        if (this.template) {
            const colors = this.template.usedColors;
            for (let color of colors)
                this.internalUsedColors.push(color);
        }

        return this.internalUsedColors;
    }

    json() {
        // TODO (X-MIGRATE): JSON TEMPLATE

        /* this.overlay.show(directives.zyncDebug, {
            json: this.email.json
        }); */
    }

    async openSettings() {
        await this.overlay.show(EmailWizard, {
            template: this.template
        });
    }

    private async updateImages(): Promise<void> {

        await this.api.update();
        const images = this.allImages = this.api.getImages();

        if (this.template)
            this.template.purgeImages(_.map(images, img => img.url));

        this.images = [];

        for (let image of images) {
            const filename = url.getFileName(image.url);
            if (imaging.readTransformSignature(filename) === null)
                this.images.push(image);
        }
    }

    private prettyPrintXML(sourceXML: string): string {
        const beautify_html = require("js-beautify").html;
        return beautify_html(sourceXML);
    }

    async showPreview() {
        const task = this.api.prepareTemplateImages(this.template);
        await this.overlay.freeze(task, "Preparing Email Images...");
        const html = this.prettyPrintXML(this.template.html);
        await this.overlay.show(EmailPreview, {
            html: html
        });
    }

    protected async onEditorStart(templateId: string, type: TemplateTypename): Promise<void> {
        require("../../styles/email.less");
        await super.onEditorStart(templateId, type);
        await this.updateImages();
    }

    async onImageUploaded() {
        await this.overlay.freeze(this.updateImages(), "Syncing images");
    }

    get rawMode(): boolean {
        return this.template.useRawHtml;
    }

    get builderMode(): boolean {
        return !this.rawMode;
    }

    short(image: IImageDescriptor) {
        return imaging.stripLeadHash(url.getFileName(image.url));
    }

    selectImage(image: IImageDescriptor) {
        this.scope.$broadcast("select-image", image);
    }

    async deleteImage(image: IImageDescriptor) {

        const confirm = await this.overlay.showModalQuestion({
            title: "Delete image?",
            text: "Are you sure you want to remove this image?",
            icon: "info",
            options: {"yes": "Delete it", "no": "Keep it"},
            showCloseButton: true,
            style: "red",
        }) == "yes";

        if (confirm) {
            await this.overlay.freeze(this.api.delete(image), "Deleting image...");
            await this.overlay.freeze(this.updateImages(), "Syncing images");
        }

    }

    get typename(): TemplateTypename {
        return "email";
    }

    protected async onTemplatePublication(): Promise<void> {
        await super.onTemplatePublication();
        await this.api.prepareTemplateImages(this.template);
    }
}
