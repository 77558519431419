import angular from "angular";
import {capitalize} from "lodash";
import {DispatchApi} from "./app/apis/base/DispatchApi";
import {getApiServices} from "./app/apis/services";
import {getCanvasDirectives} from "./app/canvas/components/components";
import {AppDirectiveBase} from "./app/common/AppComponentBase";
import {BrandName, NamespaceName, NGC, TemplateTypename} from "./app/const";

import {getCommonUIDirectives} from "./app/directives/directives";
import {getAllEmailTemplateDirectives} from "./app/email/components";
import {Environment, IGlobalState} from "./app/interfaces";
import {OverlayService} from "./app/services/overlay";
import {getSmsDirectives} from "./app/sms/components";
import {browser} from "./app/utility/browser";
import {AngularDirective, NGX} from "./app/utility/ng";
import {reloadSelfOrSignalParent} from "./app/utility/reload";
import {getVoiceDirectives} from "./app/voice/components";

async function begin(state: IGlobalState) {
    require("./styles.less");

    function initApp() {

        const angular = require("angular");
        require("angular-animate");
        require("angular-messages");
        require("angular-material");
        require("angular-route");

        function setupTheme(provider: any) {

            provider.definePalette(NamespaceName, {
                "50": "ffebee",
                "100": "ffcdd2",
                "200": "ef9a9a",
                "300": "e57373",
                "400": "ef5350",
                "500": "FEC009",
                "600": "e53935",
                "700": "d32f2f",
                "800": "c62828",
                "900": "b71c1c",
                "A100": "ff8a80",
                "A200": "ff5252",
                "A400": "ff1744",
                "A700": "d50000",
                "contrastDefaultColor": "light",
                "contrastDarkColors": [
                    "50", "100",
                    "200", "300", "400", "A100"
                ],
                "contrastLightColors": undefined
            });

            provider.theme("default")
                .primaryPalette(NamespaceName)
                .accentPalette("deep-purple");
        }

        class AppController extends AppDirectiveBase {
            public editor: TemplateTypename | null = null;
            public loading: boolean = true;

            async onInit(): Promise<void> {
                this.loading = false;
                this.openEditor(state.editor);
            }

            private openEditor(editor: TemplateTypename) {
                this.editor = editor;
                document.title = `${BrandName} ${capitalize(editor)} Template Editor`;
            }
        }

        const appDirective = NGX.makeTagDirective("app", AppController);

        function* getDirectives(type: TemplateTypename): IterableIterator<AngularDirective> {

            yield appDirective;

            yield* getCommonUIDirectives();

            if (type === "email") {
                yield* getAllEmailTemplateDirectives();
            }

            if (type === "sms") {
                yield* getCanvasDirectives();
                yield* getSmsDirectives();
            }

            if (type === "voice") {
                yield* getCanvasDirectives();
                yield* getVoiceDirectives();
            }
        }

        const module = angular
            .module(NamespaceName, ["ngMaterial", "ngAnimate", "ngMessages", "ngRoute"])
            .config(["$sceProvider", provider => {
                provider.enabled(false);
            }])
            .config(["$mdThemingProvider", provider => setupTheme(provider)])
            .config(["$animateProvider", $animateProvider => {
                $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
            }])
            .config(["$ariaProvider", provider => provider.config({
                bindRoleForClick: false,
                tabindex: false
            })])
            .config(["$mdAriaProvider", provider => {
                provider.disableWarnings();
            }])
            .service(OverlayService.injectAs, OverlayService);

        module.value(NGC.GlobalState, state);

        for (let api of getApiServices(state.editor))
            module.service(api.injectAs, api);

        for (let directive of getDirectives(state.editor))
            module.directive(directive.jsName, directive.maker);


        return module;
    }

    initApp();
    angular.bootstrap(document.body, ["upwire"]);

    setTimeout(() => {
        const editorId = window[globalEditorIdReference];
        if (editorId)
            window.parent.postMessage({type: "loaded", editorId: editorId}, "*");
    });

    setTimeout(async () => {
        require("./styles/icons/pe-icon-7-stroke.css");
        require("./styles/icons/pe-icon-line.css");
        require("./styles/icons/pe-icon-set-communication.css");
        require("./styles/icons/pe-icon-set-edition.css");
        require("./styles/icons/pe-icon-set-interface.css");
        require("./styles/icons/zync.css");
    });
}

function getQueryString(name: string) {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    const r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}

interface IEditorTokenPacket {
    token: string;
}

interface IEditorInitPacket extends IEditorTokenPacket {
    templateId: string;
    editor: TemplateTypename;
}

const globalStateReference = Symbol();
const globalEditorIdReference = Symbol();

declare var ___ENVIRONMENT___: Environment;

async function makeGlobalState(init: IEditorInitPacket): Promise<IGlobalState> {

    const {token, templateId, editor} = init;
    const modules = [];

    DispatchApi.setAuthToken(token);

    return {
        editor: editor,
        modules: modules,
        templateId: templateId,
        environment: ___ENVIRONMENT___,
        variables: {},
    };
}

async function initializeGlobalState(): Promise<IGlobalState> {

    if (window[globalStateReference])
        return window[globalStateReference];

    return await new Promise<IGlobalState>((resolve, reject) => {
        window.addEventListener("message", async event => {
            const data = event.data;
            const type = data.type;
            if (type === "init") {
                try {
                    const init = JSON.parse(data.data) as IEditorInitPacket;
                    const state = await makeGlobalState(init);
                    window[globalStateReference] = state;
                    resolve(state);
                } catch (e) {
                    reject(e);
                }
            } else if (type === "token") {
                const {token} = JSON.parse(data.data) as IEditorTokenPacket;
                DispatchApi.setAuthToken(token);
            }
        });

        // get id from query string
        const editorId = getQueryString("editorId");
        window.parent.postMessage({type: "ready", editorId: editorId}, "*");
        window[globalEditorIdReference] = editorId;
    });
}

async function bootstrap() {

    try {
        const state = await initializeGlobalState();
        await begin(state);
    } catch (e) {
        require("./styles/globalfail.less");
        document.body.innerHTML = `
            <div class="global-init-error">
                <h1>Editor Error</h1>
                <p>${e.message}</p>
            </div>`;

        // TODO (X-MIGRATE): Catch error with Sentry.
        throw e;
    }
}

if (module) {
    const hot = (module)["hot"];
    if (hot) {
        hot.addStatusHandler(status => {
            if (status === "abort")
                reloadSelfOrSignalParent();
        });
    }
}

if (!browser.isBrowserSupported()) {
    document.location.href = "/unsupported";
} else {
    document.title = BrandName;
    setTimeout(async () => {
        await bootstrap();
    });
}


