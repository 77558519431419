import {AngularDirective} from "../../utility/ng";
import {NamespaceName} from "../../const";
import {IDirective, IScope} from "angular";
import {strings} from "../../utility/strings";
import {AppDirectiveBase} from "../../common/AppComponentBase";
import {browser, Draggable} from "../../utility/browser";
import {geometry} from "../../utility/geometry";

const menuTag = NamespaceName + "-canvas-settings-menu";
const menuJsTag = strings.snake2camel(menuTag);

const tabTag = NamespaceName + "-canvas-settings-tab";
const tabJsTag = strings.snake2camel(tabTag);


interface IMenuScope extends IScope {
    currentTab: TabController;
}

interface ITabScope extends IScope {
    icon: string;
    hint: string;
    active: boolean;
}

class MenuController extends AppDirectiveBase<IMenuScope> {

    readonly tabs: TabController[] = [];

    registerTab(tab: TabController) {
        this.tabs.push(tab);
    }

    open(tab: TabController) {
        this.scope.currentTab = tab;
        for (let tab of this.tabs)
            tab.open = false;
        tab.open = true;
    }
}

class TabController extends AppDirectiveBase<ITabScope> {

    open: boolean = false;

    get active() {
        // undefined and true are 'active'
        return this.scope.active !== false;
    }

    get hint() {
        return this.scope.hint;
    }
}

export const SettingsMenuDirective: AngularDirective = {
    type: "tag",
    name: menuTag,
    jsName: menuJsTag,
    maker: function (): IDirective {
        return {
            restrict: "E",
            template: `
                <ul class="menu">
                    <li ng-class="{current: currentTab === tab}"
                        ng-if="tab.active" 
                        ng-click="menu.open(tab)"
                        ng-repeat="tab in menu.tabs">
                        <i ng-class="tab.scope.icon"></i>
                        <div class="hint">
                            <div class="hint-text">{{tab.hint}}</div>
                        </div>
                    </li>
                </ul>
                <ng-transclude class="offset-menu"></ng-transclude>`,
            controller: MenuController,
            controllerAs: "menu",
            transclude: true,
            scope: {},
            link: (scope, elem) => {
                elem.addClass("canvas-settings-menu");
            }
        };
    }
};

function moveTabBodyToFitIntoViewport(elem: JQuery) {
    const elements = [elem.children(`form`), elem.parents("upwire-canvas").children(".item.area")];
    const [menu, canvas] = elements.map(it => geometry.extBBox(it));

    // check if menu already fits inside canvas
    if (geometry.fits(menu, canvas))
        return;

    // check if menu overflows in the horizontal dimension
    if (menu.xmax > canvas.xmax)
        return;

    // bail if menu couldn't fit anyway
    if (!geometry.couldFit(menu, canvas))
        return;

    // calculate the top offset.
    const top = canvas.ymin + canvas.height - menu.ymin - menu.height;
    elem.css({
        "position": "relative",
        "top": `${top}px`,
        "left": "0px"
    });
}

export const SettingsMenuTabDirective: AngularDirective = {
    type: "tag",
    name: tabTag,
    jsName: tabJsTag,
    maker: function (): IDirective {
        return {
            restrict: "E",
            template: `
                <form class="tab-body" ng-if="tab.open">
                    <div class="title">{{hint}}</div>
                    <div ng-transclude class="body-content"></div>
                </form>`,
            require: [`^${menuJsTag}`, `^${tabJsTag}`],
            controller: TabController,
            controllerAs: "tab",
            scope: {hint: "@", icon: "@", active: "="},
            transclude: true,
            link: (scope, elem, attrs, controllers: [MenuController, TabController]) => {
                const [menu, tab] = controllers;
                menu.registerTab(tab);

                let draggable: Draggable = null;
                scope.$watch(() => tab.open, () => {

                    draggable?.dispose();
                    if (!tab.open)
                        return;

                    draggable = browser.makeDraggable(elem.find(".title"), elem);

                    setTimeout(() => {

                        if (!tab.open)
                            return;

                        try {
                            moveTabBodyToFitIntoViewport(elem);
                        } catch (e) {
                            // TODO: Report error
                        }
                    });
                });
            }
        };
    }
};
