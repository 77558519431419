import {IPoint, ISize} from "./common";

export interface IBoundingBox {
    xmin: number;
    xmax: number;
    ymin: number;
    ymax: number;
}

export interface IExtendedBoundingBox extends IBoundingBox {
    center: IPoint;
    width: number;
    height: number;
    area: number;
}

export namespace geometry {

    export function calcPointerRelativePosition(x: number, y: number, ox: number, oy: number, w: number, h: number): IPoint {

        const xrel = (x - ox) / w,
            yrel = (y - oy) / h;

        return {x: xrel, y: yrel};
    }

    export function makeBBox(point: IPoint, size: ISize): IBoundingBox {
        return {
            xmin: point.x,
            xmax: point.x + size.width,
            ymin: point.y,
            ymax: point.y + size.height
        };
    }

    export function extBBox(box: IBoundingBox | DOMRect | JQuery): IExtendedBoundingBox {

        if (box instanceof jQuery) {
            const jquery = box as JQuery;
            if (jquery.length > 0) {
                box = jquery[0].getBoundingClientRect();
            } else if (jquery.length === 0) {
                throw new Error("Can't get bounding box for an empty jQuery object");
            } else {
                throw new Error("Can't get bounding box for a jQuery object with more than one element");
            }
        }

        box = box as DOMRect | IBoundingBox;

        if (box instanceof DOMRect)
            box = getBBFromDomRect(box);

        const width = box.xmax - box.xmin;
        const height = box.ymax - box.ymin;

        return {
            ...box,
            width: width,
            height: height,
            center: {
                x: width / 2 + box.xmin,
                y: height / 2 + box.ymin
            },
            area: width * height
        };
    }

    export function sameBBox(a: IBoundingBox, b: IBoundingBox) {
        return a.xmin === b.xmin && a.xmax === b.xmax && a.ymin === b.ymin && a.ymax === b.ymax;
    }

    export function intersectionArea(a: IBoundingBox, b: IBoundingBox): number {

        const xo = Math.max(0, Math.min(a.xmax, b.xmax) - Math.max(a.xmin, b.xmin));
        const yo = Math.max(0, Math.min(a.ymax, b.ymax) - Math.max(a.ymin, b.ymin));

        return xo * yo;
    }

    export function intersectionQ(a: IBoundingBox, b: IBoundingBox): boolean {
        return intersectionArea(a, b) > 0;
    }

    export function inQ(a: IBoundingBox, p: IPoint): boolean {
        return p.y < a.ymax && p.y > a.ymin && p.x < a.xmax && p.x > a.xmin;
    }

    export function fits(box: IBoundingBox, container: IBoundingBox): boolean {
        return box.xmin >= container.xmin && box.xmax <= container.xmax && box.ymin >= container.ymin && box.ymax <= container.ymax;
    }

    export function couldFit(box: IBoundingBox, container: IBoundingBox): boolean {
        const extBox = extBBox(box);
        const extContainer = extBBox(container);
        return extBox.width <= extContainer.width && extBox.height <= extContainer.height;
    }

    export function getBBFromDomRect(rect: DOMRect): IBoundingBox {
        return {
            xmin: rect.left,
            xmax: rect.left + rect.width,
            ymin: rect.top,
            ymax: rect.top + rect.height
        };
    }

    export function distance(a: IPoint, b: IPoint): number {
        return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
    }

    export function findExpanse(boxes: IBoundingBox[], orig: IBoundingBox): IExtendedBoundingBox {

        const exto = extBBox(orig);
        const point = exto.center;

        function pointFree(): boolean {
            for (let box of boxes) {
                if (inQ(box, point)) {
                    return false;
                }
            }
            return true;
        }

        function findLayout(v: number, min: number, max: number, maxv: number): { start: number, end: number } {
            if (max - min <= maxv)
                return {start: min, end: max};

            const layout = {start: min, end: min + maxv};
            const layoutCenter = layout.start + (layout.end - layout.start) / 2;

            if (v > layoutCenter) {
                const shift = Math.min(v - layoutCenter, max - layout.end);
                layout.start += shift;
                layout.end += shift;
            }

            return layout;
        }

        if (!pointFree())
            return null;

        let xmin = 0,
            ymin = 0,
            xmax = Infinity,
            ymax = Infinity;

        for (let box of boxes) {

            if (point.y < box.ymax && point.y > box.ymin) {

                if (point.x < box.xmin)
                    xmax = Math.min(xmax, box.xmin);

                if (point.x > box.xmax)
                    xmin = Math.max(xmin, box.xmax);

            }

            if (point.x < box.xmax && point.x > box.xmin) {
                if (point.y < box.ymin)
                    ymax = Math.max(ymax, box.ymin);

                if (point.y > box.ymax)
                    ymin = Math.max(ymin, box.ymax);
            }
        }

        const xdim = findLayout(point.x, xmin, xmax, exto.width);
        const ydim = findLayout(point.y, ymin, ymax, exto.height);

        return extBBox({
            xmin: xdim.start,
            ymin: ydim.start,
            xmax: xdim.end,
            ymax: ydim.end
        });
    }

    export function clamp(value: number, min: number, max: number): number {
        if (value < min) return min;
        if (value > max) return max;
        return value;
    }

    export function snapToGrid(val: number, gridSize: number) {

        if (val < 0) return 0;

        const cellSpill = val % gridSize;
        let cellCount = Math.floor(val / gridSize);

        if (cellSpill > gridSize / 2)
            ++cellCount;

        return cellCount * gridSize;
    }

    export function snapPointToGrid(p: IPoint, gridSize: number) {
        p.x = snapToGrid(p.x, gridSize);
        p.y = snapToGrid(p.y, gridSize);
    }

    export function distancePointToLine(l1: IPoint, l2: IPoint, p: IPoint): number {

        const x1 = l1.x, y1 = l1.y, x2 = l2.x, y2 = l2.y, x0 = p.x, y0 = p.y;
        const dy = y2 - y1, dx = x2 - x1;

        const numerator = Math.abs(dy * x0 - (dx) * y0 + x2 * y1 - y2 * x1);
        const divisor = Math.sqrt(Math.pow(dy, 2) + Math.pow(dx, 2));

        return numerator / divisor;

    }

    export function normalizePixelSize(point: IPoint) {
        point.x = Math.round(point.x);
        point.y = Math.round(point.y);
        return point;
    }
}
