import {AngularDirective, NGX} from "../utility/ng";
import {GlobalEvents, NGC} from "../const";
import {IScope} from "angular";
import {IModalDialog} from "../services/overlay";
import {browser} from "../utility/browser";


interface IFullPageModalScope extends IScope {
    spec: IModalDialog;
    phase: string;
}

interface IOption {
    key: string;
    description: string;
}

class CommonModalQuestionController {

    static $inject = [NGC.scope];

    constructor(private scope: IScope) {

    }

    answer(value) {
        this.scope.$emit(GlobalEvents.overlayClose, value);
    }

    close() {
        this.answer(false);
    }

}

class CommonFullPageModalController {

    options: IOption[] = [];

    static $inject = [NGC.scope];

    constructor(readonly scope: IFullPageModalScope) {

        const spec = this.scope.spec;

        spec.text = spec.text || "";
        spec.options = spec.options || {"ok": "OK"};
        spec.style = spec.style || "blue";
        spec.showCloseButton = spec.showCloseButton || false;

        for (let key in spec.options)
            if (spec.options.hasOwnProperty(key))
                this.options.push({key: key, description: spec.options[key]});
    }

    close(value: any) {
        this.scope.$emit(GlobalEvents.overlayClose, value);
    }

}

export const ModalFrost = NGX.makeTagDirective("common/modal-frost", null, {
    text: "="
});
export const ModalQuestion = NGX.makeTagDirective("common/modal-question", CommonModalQuestionController, {
    "title": "=",
    "text": "=",
    "negation": "="
});
export const FullPageModal = NGX.makeTagDirective("common/modal-full-page", CommonFullPageModalController, {
    "spec": "=",
}, (scope, elem) => {
    elem.addClass("full-page-modal");

    if (scope.spec.style) elem.addClass(scope.spec.style);

    const target = elem.find(".icon-target");

    switch (scope.spec.icon2) {
        case "warn":
            browser.applyIconSpec(target, "pe-7s-flag");
            break
        case "info":
        default:
            browser.applyIconSpec(target, "pe-7s-info");
    }
});

export function getAllModalDirectives(): AngularDirective[] {
    return [ModalFrost, ModalQuestion, FullPageModal];
}
