export const AllLanguages = [
    {"code": "af-ZA", "text": "Afrikaans (South Africa)"},
    {"code": "am-ET", "text": "Amharic (Ethiopia)"},
    {"code": "hy-AM", "text": "Armenian (Armenia)"},
    {"code": "az-AZ", "text": "Azerbaijani (Azerbaijan)"},
    {"code": "id-ID", "text": "Indonesian (Indonesia)"}, {
        "code": "ms-MY",
        "text": "Malay (Malaysia)"
    }, {"code": "bn-BD", "text": "Bengali (Bangladesh)"}, {
        "code": "bn-IN",
        "text": "Bengali (India)"
    }, {"code": "ca-ES", "text": "Catalan (Spain)"}, {
        "code": "cs-CZ",
        "text": "Czech (Czech Republic)"
    }, {"code": "da-DK", "text": "Danish (Denmark)"}, {"code": "de-DE", "text": "German (Germany)"}, {
        "code": "en-AU",
        "text": "English (Australia)"
    }, {"code": "en-CA", "text": "English (Canada)"}, {"code": "en-GH", "text": "English (Ghana)"}, {
        "code": "en-GB",
        "text": "English (United Kingdom)"
    }, {"code": "en-IN", "text": "English (India)"}, {"code": "en-IE", "text": "English (Ireland)"}, {
        "code": "en-KE",
        "text": "English (Kenya)"
    }, {"code": "en-NZ", "text": "English (New Zealand)"}, {
        "code": "en-NG",
        "text": "English (Nigeria)"
    }, {"code": "en-PH", "text": "English (Philippines)"}, {
        "code": "en-SG",
        "text": "English (Singapore)"
    }, {"code": "en-ZA", "text": "English (South Africa)"}, {
        "code": "en-TZ",
        "text": "English (Tanzania)"
    }, {"code": "en-US", "text": "English (United States)"}, {
        "code": "es-AR",
        "text": "Spanish (Argentina)"
    }, {"code": "es-BO", "text": "Spanish (Bolivia)"}, {"code": "es-CL", "text": "Spanish (Chile)"}, {
        "code": "es-CO",
        "text": "Spanish (Colombia)"
    }, {"code": "es-CR", "text": "Spanish (Costa Rica)"}, {
        "code": "es-EC",
        "text": "Spanish (Ecuador)"
    }, {"code": "es-SV", "text": "Spanish (El Salvador)"}, {
        "code": "es-ES",
        "text": "Spanish (Spain)"
    }, {"code": "es-US", "text": "Spanish (United States)"}, {
        "code": "es-GT",
        "text": "Spanish (Guatemala)"
    }, {"code": "es-HN", "text": "Spanish (Honduras)"}, {"code": "es-MX", "text": "Spanish (Mexico)"}, {
        "code": "es-NI",
        "text": "Spanish (Nicaragua)"
    }, {"code": "es-PA", "text": "Spanish (Panama)"}, {"code": "es-PY", "text": "Spanish (Paraguay)"}, {
        "code": "es-PE",
        "text": "Spanish (Peru)"
    }, {"code": "es-PR", "text": "Spanish (Puerto Rico)"}, {
        "code": "es-DO",
        "text": "Spanish (Dominican Republic)"
    }, {"code": "es-UY", "text": "Spanish (Uruguay)"}, {
        "code": "es-VE",
        "text": "Spanish (Venezuela)"
    }, {"code": "eu-ES", "text": "Basque (Spain)"}, {
        "code": "fil-PH",
        "text": "Filipino (Philippines)"
    }, {"code": "fr-CA", "text": "French (Canada)"}, {"code": "fr-FR", "text": "French (France)"}, {
        "code": "gl-ES",
        "text": "Galician (Spain)"
    }, {"code": "ka-GE", "text": "Georgian (Georgia)"}, {"code": "gu-IN", "text": "Gujarati (India)"}, {
        "code": "hr-HR",
        "text": "Croatian (Croatia)"
    }, {"code": "zu-ZA", "text": "Zulu (South Africa)"}, {
        "code": "is-IS",
        "text": "Icelandic (Iceland)"
    }, {"code": "it-IT", "text": "Italian (Italy)"}, {
        "code": "jv-ID",
        "text": "Javanese (Indonesia)"
    }, {"code": "kn-IN", "text": "Kannada (India)"}, {"code": "km-KH", "text": "Khmer (Cambodia)"}, {
        "code": "lo-LA",
        "text": "Lao (Laos)"
    }, {"code": "lv-LV", "text": "Latvian (Latvia)"}, {
        "code": "lt-LT",
        "text": "Lithuanian (Lithuania)"
    }, {"code": "hu-HU", "text": "Hungarian (Hungary)"}, {
        "code": "ml-IN",
        "text": "Malayalam (India)"
    }, {"code": "mr-IN", "text": "Marathi (India)"}, {"code": "nl-NL", "text": "Dutch (Netherlands)"}, {
        "code": "ne-NP",
        "text": "Nepali (Nepal)"
    }, {"code": "nb-NO", "text": "Norwegian Bokmål (Norway)"}, {
        "code": "pl-PL",
        "text": "Polish (Poland)"
    }, {"code": "pt-BR", "text": "Portuguese (Brazil)"}, {
        "code": "pt-PT",
        "text": "Portuguese (Portugal)"
    }, {"code": "ro-RO", "text": "Romanian (Romania)"}, {
        "code": "si-LK",
        "text": "Sinhala (Sri Lanka)"
    }, {"code": "sk-SK", "text": "Slovak (Slovakia)"}, {
        "code": "sl-SI",
        "text": "Slovenian (Slovenia)"
    }, {"code": "su-ID", "text": "Sundanese (Indonesia)"}, {
        "code": "sw-TZ",
        "text": "Swahili (Tanzania)"
    }, {"code": "sw-KE", "text": "Swahili (Kenya)"}, {"code": "fi-FI", "text": "Finnish (Finland)"}, {
        "code": "sv-SE",
        "text": "Swedish (Sweden)"
    }, {"code": "ta-IN", "text": "Tamil (India)"}, {"code": "ta-SG", "text": "Tamil (Singapore)"}, {
        "code": "ta-LK",
        "text": "Tamil (Sri Lanka)"
    }, {"code": "ta-MY", "text": "Tamil (Malaysia)"}, {"code": "te-IN", "text": "Telugu (India)"}, {
        "code": "vi-VN",
        "text": "Vietnamese (Vietnam)"
    }, {"code": "tr-TR", "text": "Turkish (Turkey)"}, {"code": "ur-PK", "text": "Urdu (Pakistan)"}, {
        "code": "ur-IN",
        "text": "Urdu (India)"
    }, {"code": "el-GR", "text": "Greek (Greece)"}, {"code": "bg-BG", "text": "Bulgarian (Bulgaria)"}, {
        "code": "ru-RU",
        "text": "Russian (Russia)"
    }, {"code": "sr-RS", "text": "Serbian (Serbia)"}, {
        "code": "uk-UA",
        "text": "Ukrainian (Ukraine)"
    }, {"code": "he-IL", "text": "Hebrew (Israel)"}, {"code": "ar-IL", "text": "Arabic (Israel)"}, {
        "code": "ar-JO",
        "text": "Arabic (Jordan)"
    }, {"code": "ar-AE", "text": "Arabic (United Arab Emirates)"}, {
        "code": "ar-BH",
        "text": "Arabic (Bahrain)"
    }, {"code": "ar-DZ", "text": "Arabic (Algeria)"}, {
        "code": "ar-SA",
        "text": "Arabic (Saudi Arabia)"
    }, {"code": "ar-IQ", "text": "Arabic (Iraq)"}, {"code": "ar-KW", "text": "Arabic (Kuwait)"}, {
        "code": "ar-MA",
        "text": "Arabic (Morocco)"
    }, {"code": "ar-TN", "text": "Arabic (Tunisia)"}, {"code": "ar-OM", "text": "Arabic (Oman)"}, {
        "code": "ar-PS",
        "text": "Arabic (State of Palestine)"
    }, {"code": "ar-QA", "text": "Arabic (Qatar)"}, {"code": "ar-LB", "text": "Arabic (Lebanon)"}, {
        "code": "ar-EG",
        "text": "Arabic (Egypt)"
    }, {"code": "fa-IR", "text": "Persian (Iran)"}, {"code": "hi-IN", "text": "Hindi (India)"}, {
        "code": "th-TH",
        "text": "Thai (Thailand)"
    }, {"code": "ko-KR", "text": "Korean (South Korea)"}, {
        "code": "zh-TW",
        "text": "Chinese, Mandarin (Traditional, Taiwan)"
    }, {"code": "yue-Hant-HK", "text": "Chinese, Cantonese (Traditional, Hong Kong)"}, {
        "code": "ja-JP",
        "text": "Japanese (Japan)"
    }, {"code": "zh-HK", "text": "Chinese, Mandarin (Simplified, Hong Kong)"}, {
        "code": "zh",
        "text": "Chinese, Mandarin (Simplified, China)"
    }];

export function getLanguageNameForCode(code: string): string {
    const language = AllLanguages.find(language => language.code === code);
    return language ? language.text : code;
}
