import {IDirective, IScope} from "angular";
import $ from "jquery";
import {Template} from "../common/Template";
import {Variable} from "../common/Variable";
import {browser} from "../utility/browser";
import {AngularDirective, NGX} from "../utility/ng";
import {makeVariableMenu, VariableMenu} from "./varmenu";

interface IVariableAdderScope extends IScope {
    template: Template;
    variableadder: string;
}


function maker(): IDirective {

    return {
        restrict: "A",
        scope: {
            variables: "=",
            variableadder: "=",
            template: "="
        },

        link: (scope: IVariableAdderScope, elem: JQuery) => {

            const body = $(document.body);
            const input = elem[0] as HTMLInputElement;

            let adderButton: JQuery = null;
            let selection: any = null;
            let menu: VariableMenu = null;

            function clearAdderButton() {
                if (adderButton != null)
                    adderButton.remove();
                adderButton = null;
            }

            function showMenu(parent: JQuery): VariableMenu {

                if (menu != null)
                    menu.close();

                menu = makeVariableMenu(parent, scope.template);
                menu.onAddVariable((variable: Variable) => {
                    if (selection && selection.start === selection.end) {
                        const idx = selection.start;
                        const text = elem.val() as string;
                        const endString = text.substr(idx);
                        const startString = text.substr(0, idx);
                        scope.variableadder = startString + " {{" + variable.name + "}}" + (endString.length ? ` ${endString}` : "");
                    }
                    NGX.safeDigest(scope);
                });

                return menu;
            }

            function showAdderButton() {
                clearAdderButton();
                adderButton = $("<div>")
                    .addClass("variable-adder-button")
                    .css({display: "none"})
                    .append($("<i>").addClass("fa fa-plus"))
                    .appendTo(elem)
                    .on("mousedown", () => {
                        showMenu(adderButton);
                        return false;
                    }).appendTo(body);
            }

            function followAdderButton() {

                if (!adderButton)
                    showAdderButton();

                setTimeout(() => {

                    if (!adderButton)
                        return;

                    selection = browser.getInputSelection(input);
                    const position = browser.getCaretPosition(input);
                    if (position) {
                        const bb = browser.getElementBoundingBox(elem);
                        const left = Math.min(bb.xmax, position.x) + 20;
                        adderButton.css({top: position.y + 10, left: left, display: ""});
                    } else {
                        clearAdderButton();
                    }
                });
            }

            elem.on("focus", () => {
                showAdderButton();
            }).on("blur", () => {
                clearAdderButton();
            }).on("input keydown click focus", () => {
                followAdderButton();
            });
        }
    };
}

export function getVariableAdderDirectives(): AngularDirective[] {
    return [
        {
            type: "attr",
            jsName: "variableadder",
            name: "variableadder",
            maker: maker
        }
    ];
}

