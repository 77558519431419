import {IScope} from "angular";
import {CanvasTemplate} from "../../canvas/CanvasTemplate";
import {IStatusTag} from "../../canvas/common";
import {AppDirectiveBase} from "../../common/AppComponentBase";
import {Template} from "../../common/Template";
import {Variable} from "../../common/Variable";
import {GlobalEvents} from "../../const";
import {NGX} from "../../utility/ng";

interface VariableCreationScope extends IScope {
    template: Template;
    variable: string;
    vform: any;
}

interface TagCreationScope extends IScope {
    template: CanvasTemplate;
    tagname: string;
    vform: any;
}

// checks if a string is a valid javascript identifier
function isValidIdentifier(str: string): boolean {
    if (!str)
        return false;

    return /^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(str);
}

// checks if string starts with an underscore
function startsWithUnderscore(str: string): boolean {
    if (!str)
        return false;

    return str.charAt(0) === "_";
}

function doesNotStartWithUnderscore(str: string): boolean {
    return !startsWithUnderscore(str);
}

function checkVariableNameAlreadyTaken(variableName: string, variables: Variable[]): boolean {
    if (!variableName)
        return true;

    for (const item of variables)
        if (item.name === variableName)
            return false;

    return true;
}

function checkTagNameAlreadyTaken(tagName: string, tags: IStatusTag[]) {
    if (!tagName)
        return true;

    for (const tag of tags)
        if (tag.name.toLowerCase() === tagName.toLowerCase())
            return false;
    return true;
}

class VariableController extends AppDirectiveBase<VariableCreationScope> {

    async onInit(): Promise<void> {
        await super.onInit();

        const scope = this.scope;
        scope.variable = "_";
        scope.$watch(() => scope.variable, (variable) => {
            scope.vform.varname.$setValidity("invalid-identifier", isValidIdentifier(variable));
            scope.vform.varname.$setValidity("underscore", startsWithUnderscore(variable));
            scope.vform.varname.$setValidity("dupe", checkVariableNameAlreadyTaken(variable, this.scope.template.variables));
        });
    }

    create() {
        if (this.scope.vform.$valid) {
            this.scope.template.addVariable(this.scope.variable, true);
            this.close();
        }
    }

    close() {
        this.scope.$emit(GlobalEvents.overlayClose);
    }
}

class RequiredVariableController extends AppDirectiveBase<VariableCreationScope> {

    async onInit(): Promise<void> {
        await super.onInit();

        const scope = this.scope;
        scope.variable = "";
        scope.$watch(() => scope.variable, (variable) => {
            scope.vform.varname.$setValidity("invalid-identifier", isValidIdentifier(variable));
            scope.vform.varname.$setValidity("underscore", doesNotStartWithUnderscore(variable));
            scope.vform.varname.$setValidity("dupe", checkVariableNameAlreadyTaken(variable, this.scope.template.variables));
        });
    }

    create() {
        if (this.scope.vform.$valid) {
            this.scope.template.addVariable(this.scope.variable, false);
            this.close();
        }
    }

    close() {
        this.scope.$emit(GlobalEvents.overlayClose);
    }
}

class TagController extends AppDirectiveBase<TagCreationScope> {

    async onInit(): Promise<void> {
        await super.onInit();

        const scope = this.scope;
        scope.tagname = "";
        scope.$watch(() => scope.tagname, (tagName) => {
            scope.vform.tagname.$setValidity("dupe", checkTagNameAlreadyTaken(tagName, this.scope.template.tags));
        });

        this.scope.vform.varname;
    }

    create() {
        if (this.scope.vform.$valid) {
            this.scope.template.addCustomTag(this.scope.tagname);
            this.close();
        }
    }

    close() {
        this.scope.$emit(GlobalEvents.overlayClose);
    }
}

export const VariableCreation = NGX.makeTagDirective("common/dialog/variable-creation", VariableController, {
    template: "="
}, (scope, elem) => {
    elem.find("input").focus();
});

export const RequiredVariableCreation = NGX.makeTagDirective("common/dialog/required-variable-creation", RequiredVariableController, {
    template: "="
}, (scope, elem) => {
    elem.find("input").focus();
}, {
    controllerAs: "controller"
});

export const TagCreation = NGX.makeTagDirective("common/dialog/tag-creation", TagController, {
    template: "="
}, (scope, elem) => {
    elem.find("input").focus();
});
