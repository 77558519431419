import {ICompileService, IDirective, IRootScopeService, IScope} from "angular";
import $ from "jquery"
import {browser} from "../utility/browser";
import {strings} from "../utility/strings";
import {color as Color, IHSLColor, IRGBColor} from "../utility/color";
import {GlobalEvents, NamespaceName, NGC} from "../const";
import {AngularDirective, NGX} from "../utility/ng";
import makeRandomDomId = browser.makeRandomDomId;

const pickerTag = `${NamespaceName}-color-picker`
const pickerJsName = strings.snake2camel(pickerTag)

interface IColorPickerScope extends IScope {
    color: string;
    theme: string[];
    used: string[];
}

interface IDrawingCanvas {
    context: CanvasRenderingContext2D
    width: number;
    height: number;
}

interface IColorPickerScope extends IScope {
    color: string;
    hsl: IHSLColor;
    rgb: IRGBColor;

    theme: string[];
    used: string[];
}

function colorPicker(root: IRootScopeService): IDirective {

    class ColorPicker {

        hsl: IHSLColor;
        rgb: IRGBColor;

        input: string;

        private setHsl(hsl: IHSLColor) {
            this.hsl.h = hsl.h;
            this.hsl.s = hsl.s;
            this.hsl.l = hsl.l;
        }

        private setRgb(rgb: IRGBColor) {
            this.rgb.r = rgb.r;
            this.rgb.g = rgb.g;
            this.rgb.b = rgb.b;
        }

        static $inject = [NGC.scope];

        constructor(public scope: IColorPickerScope) {

            let init = false;

            this.scope.hsl = this.hsl = {h: 0, s: 0, l: 0};
            this.rgb = this.rgb = {r: 0, g: 0, b: 0};

            let colorMagic = false;
            scope.$watch(() => scope.color, (color) => {
                init = true;

                if (!color || !color.startsWith("#"))
                    return

                const rgb = Color.hex2rgb(color);
                this.setRgb(rgb);
                this.setHsl(Color.rgb2hsl(rgb));

                if (!colorMagic) this.input = color;
                colorMagic = false;
            });

            scope.$watchGroup([() => this.hsl.h, () => this.hsl.s, () => this.hsl.l], () => {
                if (!init) return;

                const rgb = Color.hsl2rgb(this.hsl);
                this.scope.color = Color.rgb2hex(rgb);
            });

            scope.$watchGroup([() => this.rgb.r, () => this.rgb.g, () => this.rgb.b], () => {
                if (!init) return;

                this.scope.color = Color.rgb2hex(this.rgb);
            });

            const dc = $("<canvas>").attr({width: 1, height: 1});
            const dcc = (dc[0] as HTMLCanvasElement).getContext("2d");

            scope.$watch(() => this.input, (value) => {

                dcc.clearRect(0, 0, 1, 1);
                dcc.fillStyle = "rgba(0, 0, 0, 0)";
                dcc.fillRect(0, 0, 1, 1);
                dcc.fillStyle = value;
                dcc.fillRect(0, 0, 1, 1);
                const imageData = dcc.getImageData(0, 0, 1, 1);
                const buffer = imageData.data;

                if (buffer[3] === 255) {
                    colorMagic = true;
                    const [r, g, b] = [buffer[0], buffer[1], buffer[2]];
                    scope.color = Color.rgb2hex({r: r, g: g, b: b});
                }
            });
        }

        contrast(color: string) {
            return Color.contrastHexColor(color);
        }

        pick(color: string) {
            this.scope.color = color;
        }

        close() {
            this.scope.$destroy();
        }

    }

    function getDrawingCanvas(elem: JQuery): IDrawingCanvas {
        const canvas = <HTMLCanvasElement>elem[0];

        const width = elem.width();
        const height = elem.height();

        const ctx = canvas.getContext("2d");
        const [w, h] = browser.scaleCanvas(ctx, width, height)

        return {
            context: ctx,
            width: w,
            height: h
        }
    }

    function setupHuePicker(scope: IColorPickerScope, elem: JQuery, indicator: JQuery) {

        const {context: ctx, height, width} = getDrawingCanvas(elem);
        const {context: indicatorContext, height: ih, width: iw} = getDrawingCanvas(indicator);

        for (let i = 0; i < height; ++i) {
            const h = i / (height - 1);

            const color = Color.hsl2rgb({h: h, s: 1, l: 0.5});

            const imageData = ctx.createImageData(width, 1);
            const imageDataBuffer = imageData.data;

            for (let j = 0; j < imageDataBuffer.length; j += 4) {
                imageDataBuffer[j] = color.r;
                imageDataBuffer[j + 1] = color.g;
                imageDataBuffer[j + 2] = color.b;
                imageDataBuffer[j + 3] = 255;
            }

            ctx.putImageData(imageData, 0, height - i - 1);
        }

        function drawIndicator() {
            const yRef = Math.round((1 - scope.hsl.h) * height);

            indicatorContext.clearRect(0, 0, iw, ih);
            indicatorContext.beginPath();
            indicatorContext.moveTo(0, 0);
            indicatorContext.lineTo(iw * 0.5, ih / 2);
            indicatorContext.lineTo(0, ih);
            indicatorContext.fill();

            indicator.css({top: yRef - ih / 2});
        }

        browser.onMouseHold(scope, elem, (evt, x, y) => {
            scope.hsl.h = 1 - (y - elem.offset().top) / (height - 1);
            NGX.safeDigest(root);
        });

        scope.$watch(() => scope.hsl.h, () => {
            drawIndicator();
        });

    }

    function setupSLPicker(scope: IColorPickerScope, elem: JQuery, indicator: JQuery) {

        const {context: ctx, height, width} = getDrawingCanvas(elem);
        const {context: indicatorContext, height: ih, width: iw} = getDrawingCanvas(indicator);

        function clamp(v: number) {
            return Math.min(1, Math.max(0, v));
        }

        function calc_s(i: number) {
            return clamp((height - i) / (height - 1));
        }

        function calc_l(j: number) {
            return clamp((width - j) / (width - 1));
        }

        let xHold: number = null
        let yHold: number = null;

        function drawIndicator(hex: string, hsl: IHSLColor) {

            indicatorContext.clearRect(0, 0, iw, ih);

            indicatorContext.beginPath();
            indicatorContext.arc(iw / 2, ih / 2, iw / 3, 0, 2 * Math.PI, false);

            indicatorContext.lineWidth = 2;
            indicatorContext.strokeStyle = Color.contrastHexColor(hex);
            indicatorContext.stroke();

            const y = yHold || Math.round(height + hsl.s - height * hsl.s - ih / 2);
            const x = xHold || Math.round(width + hsl.l - width * hsl.l - iw / 2);

            indicator.css({top: y, left: x});
        }

        function draw(h: number) {

            ctx.clearRect(0, 0, width, height);

            const image = ctx.getImageData(0, 0, width, height);
            const buffer = image.data;

            for (let i = 0; i < height; ++i) {
                const stride = i * width * 4;
                for (let j = 0; j < width; j++) {

                    const color = Color.hsl2rgb({
                        h: h,
                        s: calc_s(i),
                        l: calc_l(j)
                    });

                    const p = stride + j * 4;

                    buffer[p] = color.r;
                    buffer[p + 1] = color.g;
                    buffer[p + 2] = color.b;
                    buffer[p + 3] = 255;
                }
            }

            ctx.putImageData(image, 0, 0);
        }

        if (width * height > 1) {
            scope.$watch(() => scope.hsl.h, (h) => draw(h));
            scope.$watch(() => scope.color, (hex) => drawIndicator(hex, scope.hsl));

            browser.onMouseHold(scope, elem, (evt, x, y) => {

                const offset = elem.offset();

                yHold = y - offset.top - iw / 2;
                xHold = x - offset.left - iw / 2;

                scope.hsl.s = calc_s(yHold);
                scope.hsl.l = calc_l(xHold);
                NGX.safeDigest(root);
            });
        }
    }

    return {
        template: require("../../templates/common/colorpicker.html"),
        restrict: "E",
        scope: {color: "=", theme: "=", used: "="},
        controller: ColorPicker,
        controllerAs: "picker",
        link(scope: IColorPickerScope, elem) {

            elem.on("click mousedown", (evt) => {
                evt.stopPropagation();

                if ($(evt.target).closest(".free-input").length === 0)
                    return false;

                return void (0);
            });

            setupHuePicker(scope, elem.find(".hue-picker"), elem.find(".hue-indicator"));
            setupSLPicker(scope, elem.find(".sl-picker"), elem.find(".sl-indicator"));


            scope.$on("$destroy", () => {
                elem.remove();
            });

            function close() {
                setTimeout(async () => {
                    scope.$destroy();
                    $(window).off("resize", close);
                })
            }

            const events = [GlobalEvents.reload, GlobalEvents.undo, GlobalEvents.closeToolWindows];
            for (let event of events) {
                scope.$on(event, () => {
                    close()
                });
            }

            $(window).on("resize", close);

            setTimeout(() => {

                const offset = elem.offset();
                let x = offset.left,
                    y = offset.top;
                const ww = $(window).width(),
                    wh = $(window).height();

                if (x + elem.width() > ww)
                    x = ww - elem.width() - 20;

                if (y + elem.height() > wh)
                    y = wh - elem.height() - 20;

                browser.move(elem[0], x, y);
            });
        }
    };
}

colorPicker.$inject = [NGC.rootScope];

function colorWidget(compile: ICompileService): IDirective {

    const hostId = makeRandomDomId()

    let pickerScope: IScope = null;

    const clear = () => {

        $(`#${hostId}`).remove();
        pickerScope?.$destroy();
        pickerScope = null;

        $(document.body).off("click", clear)
    };

    $(document.body).on("click", clear);

    const picker = `<${pickerTag}>`;
    return {
        restrict: "E",
        scope: {color: "=", theme: "=", used: "="},
        link(scope: IColorPickerScope, elem: JQuery) {

            scope.$watch(() => scope.color, (colorSpec) => {
                if (colorSpec) {
                    elem.css("background-color", colorSpec)
                        .css("color", Color.contrastHexColor(colorSpec));
                }
            });

            browser.onMouseClick(scope, elem, (evt, x: number, y: number) => {

                const colorPickerHost = $(picker)
                    .attr("id", hostId)
                    .attr("color", "color")
                    .attr("theme", "theme")
                    .attr("used", "used");

                x -= 5;
                y -= 5;

                browser.move(colorPickerHost[0], x, y);

                compile(colorPickerHost)(scope, (clone) => {
                    clear();
                    $(document.body).append(clone);
                });

                evt.stopPropagation();
            });
        }
    };
}

colorWidget.$inject = [NGC.compile];

export function getColorPickerDirectives(): AngularDirective[] {
    require("../../styles/colorpicker.less")
    return [
        {type: "tag", name: pickerTag, jsName: pickerJsName, maker: colorPicker},
        {type: "tag", name: "upwire-color-widget", jsName: "upwireColorWidget", maker: colorWidget}
    ]
}
