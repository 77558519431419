import {IAngularEvent, ICompileService, IScope} from "angular";
import $ from "jquery";

import _ from "lodash";
import {EmailEditorApi, IImageDescriptor} from "../apis/EmailEditorApi";
import {AppComponentBase, AppDirectiveBase} from "../common/AppComponentBase";

import {GlobalEvents, NamespaceName, NGC} from "../const";
import {getUpwireRteDirective} from "../directives/rte";
import {browser} from "../utility/browser";
import {color} from "../utility/color";
import {IPoint, ISize} from "../utility/common";
import {imaging} from "../utility/imaging";
import {AngularDirective, NGX} from "../utility/ng";
import {strings} from "../utility/strings";

import {url} from "../utility/url";
import {utility} from "../utility/utility";
import {EmailEditorController} from "./editor";
import {
    ButtonBlock,
    Component,
    ComponentType,
    EmailSection,
    EmailTemplate,
    EmailTheme,
    ImageBlock,
    OptOutMessage,
    Spacer,
    TextBlock
} from "./EmailTemplate";
import {EmailImporter} from "./importer";
import {EmailWizard} from "./wizard";
import setDimension = browser.setDimension;
import setPosition = browser.setPosition;
import generateLoremIpsum = strings.generateLoremIpsum;

const EmailEvents = {
    addEmailComponentEvent: utility.makeUniqueSymbol(),
    addedEmailComponentEvent: utility.makeUniqueSymbol(),
    componentSelectedEvent: utility.makeUniqueSymbol(),
    clearSelectionEvent: utility.makeUniqueSymbol(),
    selectSectionEvent: utility.makeUniqueSymbol(),
    componentRemovedEvent: utility.makeUniqueSymbol(),
    focusEvent: utility.makeUniqueSymbol(),
};

interface IEmailTemplateScope extends IScope {
    template: EmailTemplate;
}

interface IEmailTemplateBuilderScope extends IEmailTemplateScope {
    showSectionTemplates: boolean;
}

interface IEmailSectionScope extends IEmailTemplateScope {
    section: EmailSection;
    active: boolean;
    activate: () => void;
}

interface IEmailComponentScope extends IEmailSectionScope {
    component: Component;
}

interface IEmailComponentSettingsScope extends IEmailTemplateScope {
    component: Component;
}

interface IEmailComponentSettingsScope extends IEmailComponentScope {
    mode: string;
}

interface IImageThumbnailScope extends IScope {
    image: IImageDescriptor;
}


function calculateOffset(x, y, start: IPoint, size: ISize): IPoint {
    return {x: x - start.x + size.width, y: y - start.y + size.height};
}


class EmailController extends AppComponentBase {
    template: EmailTemplate;

    constructor(public scope: IEmailTemplateScope) {
        super();
        this.template = scope.template;
    }
}

class BuilderController extends AppDirectiveBase<IEmailTemplateBuilderScope> {

    template: EmailTemplate;
    currentComponent: Component = null;
    currentSection: EmailSection;

    static $inject = [NGC.scope];

    async onInit(): Promise<void> {
        await super.onInit();

        const scope = this.scope;
        scope.showSectionTemplates = false;

        scope.$watch(() => scope.template, (template) => {

            this.template = template;
            if (template.sections.length)
                this.activate(_.head(template.sections));
        });

        scope.$on("select-image", (evt, image: IImageDescriptor) => {
            if (this.currentComponent && this.currentComponent.type === ImageBlock.typename()) {
                const imageBlock = this.currentComponent as ImageBlock;
                imageBlock.imageUrl = image.url;
            }
        });

        scope.$on(EmailEvents.addedEmailComponentEvent, (evt: IAngularEvent, component: Component) => {
            this.activate(scope.template.findSection(component));
        });

        scope.$on(EmailEvents.selectSectionEvent, (event, section) => {
            this.activate(section);
        });
    }

    private addSection(section: EmailSection) {
        this.template.addSection(section);
        this.activate(section);
        this.scope.showSectionTemplates = false;
    }

    private mkTextBlock(words: number) {
        const block = new TextBlock();
        block.text = `<p style='text-align:justify;'>${generateLoremIpsum(words)}</p>`;
        block.style.lineHeight = 1.2;
        block.style.backgroundColor = "#FFFFFF";
        return block;
    }

    private mkTitleBlock(title: string, align: string = "center") {
        const block = new TextBlock();
        block.height = this.template.cellSize * 2;
        block.text = `<p style='text-align:${align};font-family: Arial,Helvetica,sans-serif;font-size:18px'><strong>${title}</strong></p>`;
        block.style.lineHeight = 1.2;
        block.style.backgroundColor = "#FFFFFF";
        return block;
    }

    private mkImageBlock() {
        const block = new ImageBlock();
        block.style.backgroundColor = this.template.theme.color;
        return block;
    }

    activate(section: EmailSection | null) {
        this.currentSection = section;
    }

    toggleShowSectionTemplates() {
        this.scope.showSectionTemplates = !this.scope.showSectionTemplates;
        this.scope.$root.$broadcast(GlobalEvents.closeToolWindows);
    }

    addBlankSection() {
        const section = new EmailSection();
        this.addSection(section);
    }

    addSectionType1() {
        const section = new EmailSection();

        const cs = this.template.cellSize;
        const w = this.template.width;
        const dim = 6 * cs;

        const img1 = this.mkImageBlock();
        const img2 = this.mkImageBlock();
        const text1 = this.mkTextBlock(40);
        const text2 = this.mkTextBlock(40);

        text1.y = img1.y = img1.x = img2.x = cs;

        img2.width = img2.height = text1.height = text2.height = img1.height = img1.width = dim;

        text2.x = text1.x = img1.x + img1.width + cs;
        text2.width = text1.width = w - text1.x - cs;
        img2.y = text2.y = text1.y + text1.height + cs;

        section.add(img1);
        section.add(text1);
        section.add(img2);
        section.add(text2);

        section.minHeight = 2 * dim + 3 * cs;

        this.addSection(section);
    }

    addSectionType2() {

        const cs = this.template.cellSize;
        const w = this.template.width;

        let wc = Math.floor(w / 3);
        const rem = wc % cs;
        wc -= rem;
        const ww = w - 2 * wc;

        const section = new EmailSection();

        const that = this;

        function makeColumn(xo: number, n: number, w: number) {

            const img = that.mkImageBlock();
            const title = that.mkTitleBlock(`Title ${n}`);
            const text = that.mkTextBlock(30);

            text.x = title.x = img.x = xo;
            text.width = title.width = img.width = w;

            img.y = cs;
            title.y = img.y + img.height;
            text.y = title.y + title.height;

            text.height = 10 * cs;

            section.add(img);
            section.add(title);
            section.add(text);
            return [img, title, text];
        }

        let xo = 0;
        for (let i = 0; i < 3; ++i) {
            const cw = i === 1 ? ww : wc;
            const [img, ,] = makeColumn(xo, i + 1, cw);
            xo += cw;
            if (i === 1) {
                img.style.borderColor = "#FFFFFF";
                img.style.borderWidth = 10;
            }
        }

        this.addSection(section);
    }

    addSectionType3() {
        const cs = this.template.cellSize;
        const w = this.template.width - 2 * cs;
        const section = new EmailSection();


        const title1 = this.mkTitleBlock("Title 1", "left");
        const title2 = this.mkTitleBlock("Title 2", "left");
        const text1 = this.mkTextBlock(70);
        const text2 = this.mkTextBlock(70);

        text1.x = text2.x = title2.x = title1.x = cs;
        text1.width = text2.width = title2.width = title1.width = w;

        title1.y = cs;
        text1.y = title1.y + title1.height;
        title2.y = text1.y + text1.height + cs;
        text2.y = title2.y + title2.height;

        section.add(title1);
        section.add(text1);
        section.add(title2);
        section.add(text2);


        this.addSection(section);
    }

    async clearRawTemplate() {

        if (!this.template.useRawHtml)
            return;

        const ok = await this.overlay.showModalQuestion({
            title: "Do you want to clear the custom HTML template?",
            text: "Clearing the custom HTML template reverts to block builder mode, and removes the custom HTML template",
            style: undefined,
            icon: "info",
            showCloseButton: true,
            options: {"yes": "Clear", "no": "Keep Using Custom HTML Template"}
        }) === "yes";

        if (ok) {
            this.template.setRawHtml(null);
        }
    }

    get optouts(): OptOutMessage[] {
        return OptOutMessage.messages;
    }
}

class EmailSectionController extends EmailController {

    static $inject = [NGC.scope];

    constructor(public scope: IEmailSectionScope) {
        super(scope);
    }

    add(action: string, xrel: number, yrel: number) {
        if (action) {
            const [type, param] = action.split(",");
            this.scope.$broadcast(EmailEvents.addEmailComponentEvent, type, param, xrel, yrel);
        }
    }

    remove() {
        this.template.removeSection(this.scope.section);
    }

    move(up: boolean) {
        const os = _.sortBy(this.template.sections, s => s.order);
        const idx = _.indexOf(os, this.scope.section);
        const before = os[idx + (up ? -1 : 2)] || null;
        this.template.orderSection(this.scope.section, before);
    }

    copy() {
        this.template.copySection(this.scope.section);
    }
}

class ComponentHostController extends AppComponentBase {

    editable: boolean = false;
    edit: boolean = false;
    active: boolean = false;

    template: EmailTemplate;
    component: Component;

    static $inject = [NGC.scope];

    constructor(public scope: IEmailComponentScope) {
        super();

        this.template = scope.template;
        this.component = scope.component;

        const type = this.scope.component.type;

        if (type === TextBlock.typename())
            this.editable = true;

        if (type === ButtonBlock.typename())
            this.editable = true;

        scope.$watchGroup(this.component.allLayoutModifiers, () => {
            this.component.heal();
        });
    }

    switch() {
        if (!this.editable) return;
        this.edit = !this.edit;
        if (this.edit) {
            this.scope.$broadcast(EmailEvents.focusEvent);
        }
    }

    canMaximize() {
        const template = this.scope.template;
        const component = this.scope.component;
        return template.width !== component.width;
    }

    maximize() {
        this.template.maximizeComponent(this.component);
    }

    remove() {
        this.scope.template.removeComponent(this.scope.component);
        this.scope.$emit(EmailEvents.componentRemovedEvent);
    }

    activate() {
        this.active = true;
    }

    deactivate(force: boolean = false) {
        if (force || !this.edit)
            this.active = false;

        if (this.edit && force)
            this.edit = false;
    }
}

const EmailBuilderTag = NGX.makeTagDirective("email/builder", BuilderController, {"template": "="},
    (scope: IEmailTemplateBuilderScope, elem: JQuery, attr, builder: BuilderController) => {

        const content = elem.find(".content");

        scope.$watch(() => scope.template, () => {
            content.toggleClass("no-animation", true);
            setTimeout(() => content.toggleClass("no-animation", false), 500);
        });

        scope.$watchGroup([() => scope.template.width, () => scope.template.layoutSqn],
            () => {
                content.width(scope.template.width);
            });

        scope.$watch(() => scope.template.width, () => {
            scope.template.layout();
        });

        scope.$on(EmailEvents.componentSelectedEvent, (evt: IAngularEvent, component: Component | null) => {

            if (builder.currentComponent !== component) {
                scope.$root.$broadcast(EmailEvents.clearSelectionEvent, component);
                builder.currentComponent = component;
            }

            const section = builder.template.findSection(component);
            scope.$root.$broadcast(EmailEvents.selectSectionEvent, section);
        });

        scope.$on(EmailEvents.selectSectionEvent, section => {
            if (section == null) {
                builder.currentComponent = null;
            }
        });

        $(document).on("keydown", e => {
            if (!browser.isFocusTarget(e) && e.which === 46) {
                if (builder.currentComponent) {
                    scope.template.removeComponent(builder.currentComponent);
                    builder.currentComponent = null;
                    NGX.safeDigest(scope);
                }
            }
        });

        browser.onMouseDown(scope, elem, (evt) => {

            // builder.currentComponent = null;
            const hit = $(evt.target).closest(".email-section-host");

            let section: EmailSection = null;
            if (hit.length === 1) {
                const scope = hit.scope() as IEmailSectionScope;
                if (scope.section)
                    section = scope.section;
            }


            scope.$root.$broadcast(EmailEvents.componentSelectedEvent, null);
            scope.$root.$broadcast(EmailEvents.selectSectionEvent, section);
            scope.$root.$broadcast(GlobalEvents.closeToolWindows);

        });

        scope.$on(EmailEvents.componentRemovedEvent, (evt) => {
            builder.currentComponent = null;
            evt.stopPropagation();
        });
    });

const EmailSectionTag = NGX.makeTagDirective("email/section", EmailSectionController, {
        template: "=",
        section: "=",
        active: "=",
        activate: "&"
    },
    (scope: IEmailSectionScope, elem) => {

        scope.$watchGroup([
            () => scope.section.height,
            () => scope.template.layoutSqn
        ], () => elem.height(scope.section.height));

        scope.$on(EmailEvents.addEmailComponentEvent, (evt, type: ComponentType, param: string, xrel: number, yrel: number) => {

            const component = Component.activate(type);
            const section = scope.section;

            if (component) {
                component.x = scope.template.width * xrel - component.width / 2;
                component.y = section.height * yrel - component.height / 2;
                scope.template.addComponent(component, section);

                if (param && type === "image-block") {
                    const image = component as ImageBlock;
                    image.imageUrl = param;
                }

                if (param && type === "text-block") {
                    const text = component as TextBlock;
                    text.style.lineHeight = 1.2;
                    text.text = strings.generateLoremIpsum(50);

                    if (param === "text") {
                        text.y = text.x = 0;
                        text.width = scope.template.width;
                        text.height = section.minHeight;
                    }

                    if (param === "paragraph") {
                        text.width = scope.template.width / 2;
                        text.height = section.minHeight;
                    }

                    if (param === "h1") {
                        text.x = 0;
                        text.height = 2 * scope.template.cellSize;
                        text.text = "<p style='font-size:20px;'><strong>Heading 1</strong></p>";
                        text.width = scope.template.width;
                    }

                    if (param === "h2") {
                        text.x = 0;
                        text.height = 2 * scope.template.cellSize;
                        text.text = "<p style='font-size:16px;'><strong>Heading 2</strong></p>";
                        text.width = scope.template.width;
                    }

                    if (param === "h3") {
                        text.x = 0;
                        text.height = 2 * scope.template.cellSize;
                        text.text = "<p style='font-size:14px;'>Heading 3</p>";
                        text.width = scope.template.width;
                    }

                    scope.template.layout();
                }

                scope.$emit(EmailEvents.addedEmailComponentEvent, component);
            }

        });

        const slider = elem.find(".section-slider").find(".dragger");
        scope.$watch(() => scope.section.style.backgroundColor, (c) => {
            const contrast = color.contrastHexColor(c);
            slider.find(".drag-rect").css("backgroundColor", contrast);
        });

        browser.onElementDrag(scope.$root, slider, () => {
            elem.toggleClass("no-animation", true);
            return scope.section.height;
        }, (evt, x, y, start, height) => {
            const dy = y - start.y;
            elem.height(height + dy);
        }, (evt, x, y, start, height) => {
            const dy = y - start.y;
            scope.section.minHeight = Math.max(height + dy, 85);
            elem.toggleClass("no-animation", false);
            scope.template.layout();
        });
    });

const EmailComponentHostTag = NGX.makeTagDirective("email/component-host", ComponentHostController, {
    component: "=",
    template: "="
}, (scope: IEmailComponentScope, elem, attr, ctrl: ComponentHostController) => {

    function setupComponentResizing() {

        const component = scope.component;

        scope.$watchGroup([() => ctrl.active, () => ctrl.edit], () => {

            const widget = elem.find(".resize");

            if (widget.length === 0)
                return;

            browser.onElementDrag<ISize>(scope, widget, () => {

                    scope.template.touchComponent(component);
                    scope.$emit(EmailEvents.clearSelectionEvent, component);
                    scope.$emit(EmailEvents.componentSelectedEvent, component);

                    elem.toggleClass("no-animation", true);
                    return {width: component.width, height: component.height};
                },
                (evt, x, y, start, size) => {
                    const offset = calculateOffset(x, y, start, size);
                    setDimension(elem, offset.x, offset.y);
                },
                (evt, x, y, start, size) => {

                    const offset = calculateOffset(x, y, start, size);
                    [component.width, component.height] = [offset.x, offset.y];

                    elem.toggleClass("no-animation", false);
                    scope.template.layout({
                        allowComponentExchange: true
                    });

                    NGX.safeDigest(ctrl.root);
                });
        });
    }

    function setupComponentMove() {

        const component = scope.component;

        scope.$watchGroup([() => ctrl.edit, () => ctrl.active], () => {
            const widget = elem.find(".hover-overlay");
            if (widget.length === 0) return;

            let startingSection: EmailSection = null;

            function getSection(evt): EmailSection {
                const hit = $(evt.target).closest(".email-section-host");
                if (hit.length) {
                    const scope = hit.scope() as IEmailSectionScope;
                    return scope.section;
                }
                return null;
            }

            let moved = false;
            browser.onElementDrag<ISize>(scope, widget, (evt) => {
                startingSection = getSection(evt);
                scope.template.touchComponent(component);
                scope.$emit(EmailEvents.clearSelectionEvent, component);
                scope.$emit(EmailEvents.componentSelectedEvent, component);

                return {width: component.x, height: component.y};

            }, (evt, x, y, start, size) => {

                if (x !== start.x || y !== start.y)
                    moved = true;

                elem.toggleClass("transit", moved);
                const offset = calculateOffset(x, y, start, size);
                setPosition(elem, offset.x, offset.y);
            }, (evt, x, y, start, size) => {
                const endSection = getSection(evt);

                let heightAdjust = 0;
                if (endSection && endSection !== startingSection) {
                    scope.template.removeComponent(component);
                    scope.template.addComponent(component, endSection);
                    heightAdjust = -1 * scope.template.sectionHeightDelta(startingSection, endSection);
                    scope.$root.$broadcast(EmailEvents.selectSectionEvent, endSection);
                }

                const point = {...start};
                point.y -= heightAdjust;

                const offset = calculateOffset(x, y, point, size);
                [component.x, component.y] = [offset.x, offset.y];
                elem.toggleClass("transit", false);
                scope.template.layout({
                    allowComponentExchange: true
                });
            });
        });
    }

    const component = scope.component;

    scope.$watchGroup([() => component.width, () => component.height, () => scope.template.layoutSqn],
        ([width, height]: number[]) => {
            setDimension(elem, width, height);
        });

    scope.$watchGroup([() => component.x, () => component.y, () => scope.template.layoutSqn],
        ([x, y]: [number, number]) => {
            setPosition(elem, x, y);
        });

    scope.$watch(() => component.zindex, (z) => {
        elem.css("z-index", z);
    });

    setupComponentResizing();
    setupComponentMove();

    browser.onMouseDown(scope, elem.find(".root"), (evt) => {
        evt.stopImmediatePropagation();
    });

    scope.$on(EmailEvents.clearSelectionEvent, (evt, cmp?: Component) => {
        if (!component || component !== cmp)
            ctrl.deactivate(true);
    });
}, {
    transclude: true
});

type ComponentLinker = (scope: IEmailComponentScope, elem: JQuery) => void;

function registerComponent(component: string, linker: ComponentLinker | null = null): AngularDirective {

    class ComponentBodyController extends EmailController {

        static $inject = [NGC.scope];

        constructor(public scope: IEmailComponentScope) {
            super(scope);
        }
    }

    return NGX.makeTagDirective(`email/component/${component}`, ComponentBodyController, {
        component: "=",
        template: "="
    }, (scope, elem) => {
        if (linker)
            linker(scope, elem);
    });
}

const EmailTextBlockComponent = registerComponent("text-block", (scope, elem) => {
    scope.$on(EmailEvents.focusEvent, () => {
        setTimeout(() => elem.find("textarea").focus(), 25);
    });
});
const EmailImageBlockComponent = registerComponent("image-block", (scope, elem) => {
    scope.$watch(() => (<ImageBlock>scope.component).imageUrl, (url) => {
        if (url)
            elem.find(".picture").css("background-image", "url(" + url + ")");
    });
});
const EmailButtonBlockComponent = registerComponent("button-block", (scope, elem) => {
    scope.$on(EmailEvents.focusEvent, () => {
        setTimeout(() => {
            browser.focusOn(elem.find("input"));
        }, 25);
    });
});
const EmailSpacerComponent = registerComponent("spacer");

const ComponentTagNames = {
    "text-block": EmailTextBlockComponent.name,
    "image-block": EmailImageBlockComponent.name,
    "button-block": EmailButtonBlockComponent.name,
    "spacer": EmailSpacerComponent.name,
};

function makeSettingsDirective(tag: string): AngularDirective {

    class SettingsController extends EmailController {

        currentImage: IImageDescriptor = null;
        images: IImageDescriptor[];

        fontFamilies: any = {};
        alignments: any = {};

        static $inject = [NGC.scope, EmailEditorApi.injectAs];

        constructor(public scope: IEmailComponentSettingsScope, public api: EmailEditorApi) {
            super(scope);

            const fonts = EmailTheme.fonts;

            for (let font of fonts)
                this.fontFamilies[font] = font;

            for (let alignment of ["left", "center", "right", "justify"])
                this.alignments[alignment] = alignment;

            let imageWatcher: Function = null;

            scope.$watch(() => scope.template, () => {

                if (imageWatcher !== null)
                    imageWatcher();

                this.images = [];
                const images = api.getImages();
                imageWatcher = scope.$watch(() => images, () => {
                    this.images.length = 0;
                    for (let img of images) {
                        const filename = url.getFileName(img.url);
                        if (imaging.readTransformSignature(filename) === null)
                            this.images.push(img);
                    }
                }, true);
            });

            if (scope.component.type === ImageBlock.typename()) {
                const image = scope.component as ImageBlock;
                this.currentImage = this.api.getDescriptorByUrl(image.imageUrl);
                scope.$watch(() => this.currentImage, (desc: IImageDescriptor) => {
                    image.imageUrl = desc ? desc.url : null;
                });
            }

            scope.$watchGroup(scope.component.allLayoutModifiers, () => {
                this.scope.component.heal();
            });
        }

        short(image: IImageDescriptor) {
            return imaging.stripLeadHash(url.getFileName(image.url));
        }

        setMode(mode: string) {
            this.scope.mode = mode;
        }
    }

    return NGX.makeTagDirective(`email/settings/${tag}`, SettingsController, {template: "=", component: "="},
        (scope: IEmailComponentSettingsScope, elem: JQuery) => {
            elem.addClass("modal-settings");

            browser.onMouseDown(scope, elem, (evt: JQueryEventObject) => {
                if (!browser.isFocusTarget(evt))
                    return false;
                return void (0);
            });

            browser.onWheelScroll(scope, elem, (evt: JQueryEventObject) => {
                evt.stopPropagation();
                return false;
            });

            scope.$watch(() => scope.template.layoutSqn, () => {

                const page = elem.closest(".content");

                const component = scope.component;

                const xPosition = component.x + component.width + 5;
                const xOffset = page.offset().left;

                const overflow = xPosition + xOffset + 300 > $(window).width();

                if (!overflow) {
                    setPosition(elem, xPosition, component.y);
                } else {
                    setPosition(elem, $(window).width() - 300 - xOffset - 20, component.y + component.height + 10);
                }
            });

            scope.$watch(() => scope.mode, (mode: string) => {

                setTimeout(() => {

                    const form = elem.find("form");

                    if (form.length !== 1) {
                        return;
                    }

                    const offset = form.offset().top,
                        height = form.height(),
                        windowHeight = $(window).height();

                    const overflow = offset + height - windowHeight;
                    if (overflow > 0)
                        form.css({"top": `${-(overflow + 10)}px`});

                    NGX.safeDigest(scope);
                });

                elem.toggleClass("expanded", !!mode);
            });
        });
}

const EmailTextBlockSettings = makeSettingsDirective(TextBlock.typename());
const EmailSpacerSettings = makeSettingsDirective(Spacer.typename());
const EmailButtonBlockSettings = makeSettingsDirective(ButtonBlock.typename());
const EmailImageBlockSettings = makeSettingsDirective(ImageBlock.typename());


const EmailImageThumbnail = NGX.makeTagDirective("email/image-thumb", null, {
    image: "=",
    remove: "&"
}, (scope: IImageThumbnailScope, elem) => {

    function thumbnail(width: number, height: number, max: number) {

        if (width <= 0) return [0, 0];
        if (height <= 0) return [0, 0];

        if (width > height)
            return [max, height / width * max];

        if (height > width)
            return [width / height * max, max];

        return [max, max];

    }

    scope.$watch(() => scope.image.url, (url) => {

        const image = new Image();
        image.onload = () => {

            const [dw, dh] = thumbnail(image.width, image.height, 85);
            const img = $("<img>").attr("src", url).attr("alt", "image");

            elem.find(".image-thumbnail")
                .css({width: dw, height: dh})
                .empty()
                .append(img);

        };

        image.src = url;
    });
});

interface EmailImageUploadControllerScope extends IScope {
    uploadComplete: () => void;
    template: EmailTemplate;
    progress: number;
    isAccepting: boolean;
    showProgress: boolean;
    text: string;
}

class EmailImageUploaderController extends AppDirectiveBase<EmailImageUploadControllerScope> {
    private _api: EmailEditorApi;

    protected injectFields(injector: angular.auto.IInjectorService) {
        super.injectFields(injector);
        this._api = injector.get(EmailEditorApi.injectAs);
    }

    reset() {
        this.scope.progress = 0;
        this.scope.isAccepting = false;
        this.scope.showProgress = false;
        this.scope.text = "";
        NGX.safeDigest(this.scope);
    }

    async upload(file: File): Promise<boolean> {


        try {
            const task = this._api.upload(file.name, file);
            const ok = await this.overlay.freeze(task, "Uploading image...");

            if (!ok) {
                await this.overlay.showModal("Could not upload file",
                    "Couldn't upload your file. Perhaps it's not a valid file format?");
            } else {
                this.scope.uploadComplete();
            }

            return ok;

        } catch (e) {
            await this.overlay.showModal("Could not upload file", e.toString());
        } finally {
            this.reset();
        }

        return false;
    }

}

const EmailImageUploader = NGX.makeTagDirective("email/image-uploader", EmailImageUploaderController, {
        uploadComplete: "&",
        template: "="
    },
    (scope: EmailImageUploadControllerScope, elem, attrs, ctrl: EmailImageUploaderController) => {

        const tag = "upwire-email-image-uploader";

        function updateHint() {
            scope.text = scope.isAccepting ? "Drop your image file here" : "Drag image file here";
        }

        elem.on("dragenter dragleave", (evt) => {

            const isAccepting = evt.type === "dragenter";

            evt.stopPropagation();
            evt.preventDefault();

            try {

                if (evt.type === "dragenter" && $(evt.target).closest(tag)[0] !== elem[0])
                    return false;

                if (evt.type === "dragleave" && $(evt.target).parent()[0] !== elem[0])
                    return false;

            } catch (e) {
                console.error(e);
                return false;
            }


            scope.$apply(() => {
                scope.isAccepting = isAccepting;
                updateHint();
            });

            return false;
        });

        elem.on("dragover", (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            return false;
        });

        elem.on("drop", (evt: any) => {

            evt.stopPropagation();
            evt.preventDefault();

            if (!scope.isAccepting)
                return false;

            const files = (evt.dataTransfer || evt.originalEvent.dataTransfer).files;

            function chain(idx: number) {

                if (idx >= files.length) {
                    ctrl.reset();
                    return;
                }

                const file = files[idx];
                ctrl.upload(file).then((ok: boolean) => {
                    if (ok) chain(idx + 1);
                    else {
                        ctrl.reset();
                        updateHint();
                    }
                });
            }

            scope.$apply(() => scope.showProgress = true);

            chain(0);
            return false;
        });

        updateHint();
    });

function specScopeGeneral(elem: JQuery, ...params: string[]) {
    for (let i = 0; i < params.length; ++i)
        elem.attr(params[i], params[i]);
    return elem;
}

function makeComponentActivator(): AngularDirective {

    class ActivatorController extends AppComponentBase {
        constructor() {
            super();
        }
    }

    function specScope(elem: JQuery) {
        return specScopeGeneral(elem, "component", "template");
    }

    return NGX.makeTagDirective("email/component-activator", ActivatorController, {
            "component": "=",
            "template": "="
        },
        (scope, elem, attr, ctrl: ActivatorController) => {

            const componentTag = `<${ComponentTagNames[scope.component.type]}>`;
            const hostTag = `<${EmailComponentHostTag.name}>`;

            const host = specScope($(hostTag))
                .append(specScope($(componentTag)
                    .addClass("component"))
                );

            ctrl.compile(host)(scope, (clone: JQuery) => {
                elem.empty().append(clone);
            });
        });
}

const EmailComponentActivator = makeComponentActivator();

const EmailEditor = NGX.makeTagDirective("email/editor", EmailEditorController);

const EmailSettingsActivator = NGX.makeTagDirective(
    {tag: `${NamespaceName}-email-settings-activator`, template: null}, null,
    {component: "=", template: "="}, ((scope: IEmailComponentSettingsScope, elem: JQuery) => {

        let pScope = null;

        const injector = NGX.getInjector();
        const compile: ICompileService = injector.get(NGC.compile);

        scope.$watch(() => scope.component, (component) => {

            if (pScope)
                pScope.$destroy();

            pScope = NGX.copyScope(scope);

            const tag = `<upwire-email-settings-${component.type}>`;
            const host = specScopeGeneral($(tag), "component", "template");

            compile(host)(pScope, (clone: JQuery) => {
                elem.empty().append(clone);
            });
        });

        browser.onMouseDown(scope, elem, (evt) => {
            evt.stopPropagation();
        });
    }));

interface IEmailPreviewScope extends IScope {
    html: string;
}

class EmailPreviewController {

    static $inject = [NGC.scope];

    constructor(private scope: IEmailPreviewScope) {

    }


    close() {
        this.scope.$emit(GlobalEvents.overlayClose);
    }

}

interface IEmailPreviewScope extends IScope {
    html: string;

    select(event): void;
}

export const EmailPreview = NGX.makeTagDirective("email/preview", EmailPreviewController, {html: "="},
    (scope: IEmailPreviewScope) => {
        scope.select = event => {
            const range = document.createRange();
            range.selectNode(event.target);
            if (window.getSelection) {
                window.getSelection().addRange(range);
            }
        };
    });

export function getAllEmailTemplateDirectives(): AngularDirective[] {

    const richTextEditor = getUpwireRteDirective();

    return [

        EmailBuilderTag,
        EmailSectionTag,
        EmailComponentHostTag,

        EmailTextBlockComponent,
        EmailImageBlockComponent,
        EmailButtonBlockComponent,
        EmailSpacerComponent,

        EmailTextBlockSettings,
        EmailSpacerSettings,
        EmailButtonBlockSettings,
        EmailImageBlockSettings,

        EmailImageThumbnail,
        EmailImageUploader,
        EmailComponentActivator,

        EmailEditor,
        EmailPreview,
        EmailImporter,
        EmailWizard,
        EmailSettingsActivator,
        richTextEditor,
    ];
}
