import {AngularDirective, NGX} from "../utility/ng";

export function getPageDirective(): AngularDirective {
    return NGX.makeTagDirective("common/page", null, {
        controller: "="
    }, (scope, elem, a, b, transclude) => {
        transclude(scope, (clone) => {
            elem.append(clone);
        });
    }, {
        transclude: {
            "tabs": "pageVTabs",
            "canvas": "pageCanvas",
        }
    });
}
