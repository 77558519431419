export const NamespaceName = "upwire";
export const BrandName = "Upwire";

export const Resources = {
    ImageCDN: "https://storage.googleapis.com/cpm-01-webui/img/",
    VideoCDN: "https://storage.googleapis.com/cpm-01-webui/vid/"
};

export const GlobalEvents = {
    wizardMoveNextEvent: "wizard-next", //IMPORTANT
    undo: "global-undo",
    overlayClose: "global-overlay-close",
    permissionsWarningClose: "global-permissions-warning-close",
    reload: "global-reload",
    closeToolWindows: "global-close-tool-windows",
    showProblems: "show-validation-errors",
    referencesUpdated: "references-updated",
    handlersUpdated: "handlers-updated"
};

export const NGC = {
    scope: "$scope",
    compile: "$compile",
    filter: "$filter",
    timeout: "$timeout",
    interval: "$interval",
    q: "$q",
    http: "$http",
    rootScope: "$rootScope",
    parse: "$parse",
    sce: "$sce",
    location: "$location",
    animate: "$animate",
    GlobalState: "GlobalState"
};

export type TemplateTypename = "sms" | "voice" | "email"
