export enum Severity {
    Warning = 1,
    Error = 2
}

export class Validator {
    active: boolean = false;

    constructor(
        public severity: Severity,
        private readonly namefn: () => string,
        private readonly msgfn: () => string,
        private readonly validatefn: () => boolean
    ) {
    }

    get name(): string {
        return this.namefn();
    }

    get message(): string {
        return this.msgfn();
    }

    validate() {
        this.active = !this.validatefn();
    }
}

export interface ValidationProblem {
    name: string;
    message: string;
    severity: Severity;
}

export class ValidationGroup {

    private readonly _validators: Validator[] = [];
    private _problem: ValidationProblem = null;
    private _valid: boolean = true;
    private _errorCount: number = 0;

    add(validator: Validator): void {
        this._validators.push(validator);
    }

    private findActiveValidator(severity: Severity): Validator | undefined {
        return this._validators.find(v => v.severity === severity && v.active);
    }

    validate(): void {

        let count = 0;
        for (let validator of this._validators) {
            validator.validate();
            if (validator.active && validator.severity === Severity.Error) {
                count++;
            }
        }
        this._errorCount = count;

        const erringValidator = this.findActiveValidator(Severity.Error);

        this._problem = null;
        const problemValidator = erringValidator ?? this.findActiveValidator(Severity.Warning);

        if (problemValidator) {
            this._problem = {
                name: problemValidator.name,
                message: problemValidator.message,
                severity: problemValidator.severity
            };
        }

        this._valid = erringValidator === undefined;
    }

    get valid(): boolean {
        return this._valid;
    }

    get problem(): ValidationProblem {
        return this._problem;
    }

    get errorCount(): number {
        return this._errorCount;
    }
}
