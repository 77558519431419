import {IDirective, IScope} from "angular";
import {browser} from "../utility/browser";
import $ from "jquery"
import {AngularDirective} from "../utility/ng";
import {strings} from "../utility/strings";

export interface IDragHost extends IScope {
    dragHost: string;
}

const DragConstants = {
    dragHostName: "drag-host",
    containerName: "drag-host-hoisted-container",
    receiverName: "drag-receiver",
    onTransference: "on-drag-transference"
};

function makeDragHost(): IDirective {
    return {
        restrict: "A",
        scope: {dragHost: "@"},
        link(scope: IDragHost, elem: JQuery) {

            const children = elem.children();
            if (children.length !== 1) {
                throw "drag-host accepts exactly one cloneable child.";
            }

            const child = $(children[0]);

            let width = child.width();
            let height = child.height();

            let hoisted: JQuery = null;
            let hoistedElement: HTMLElement = null;

            function start() {
                width = child.width();
                height = child.height();
                hoisted = $("<div>")
                    .addClass(DragConstants.containerName)
                    .append(child.clone().toggleClass("grabbing", true))
                    .appendTo(document.body);

                hoistedElement = hoisted[0];
            }

            function move(evt: JQueryEventObject, x: number, y: number) {
                browser.move(hoistedElement, x - width / 2, y - height / 2);
            }

            function end(evt: JQueryEventObject, x: number, y: number) {

                const closure = hoisted
                    .addClass("disposed")
                    .on("animationend webkitAnimationEnd", () => {
                        // closure.remove();
                    });

                const receiver = $(document.elementFromPoint(x, y))
                    .closest(`[${DragConstants.receiverName}]`);

                if (receiver.length) {
                    receiver.trigger(DragConstants.onTransference, [scope.dragHost, x, y]);
                }

                setTimeout(async () => {
                    //closure.remove()
                }, 350)

                hoisted = null;
                hoistedElement = null;
            }

            browser.onElementDrag(scope, elem, start, move, end);
        }
    };
}

export interface IDragReceiverScope extends IScope {
    dragReceiver: any
}

function makeDragReceiver(): IDirective {
    return {
        restrict: "A",
        scope: {dragReceiver: "&"},
        link(scope: IDragReceiverScope, elem: JQuery) {
            elem.on(DragConstants.onTransference, (evt: JQueryEventObject, type: string, x: number, y: number) => {
                const rel = browser.getPointerRelativePosition(elem, x, y);
                scope.$apply(function () {
                    scope.dragReceiver({type: type, xrel: rel.x, yrel: rel.y});
                });
            });
        }
    };
}

export function getDragHostAttributes(): AngularDirective[] {
    require("../../styles/draghost.less")
    return [{
        type: "tag",
        jsName: strings.snake2camel(DragConstants.dragHostName),
        name: DragConstants.dragHostName,
        maker: makeDragHost
    }, {
        type: "tag",
        jsName: strings.snake2camel(DragConstants.receiverName),
        name: DragConstants.receiverName,
        maker: makeDragReceiver
    }]
}
