import {IScope} from "angular";
import {FullPageWizardController} from "../common/wizards";
import {NGX} from "../utility/ng";
import {SmsTemplate} from "../canvas/CanvasTemplate";

import _ from "lodash";
import {getDeliveryTicks} from "./common";


interface IWizardScope extends IScope {
    template: SmsTemplate;
    phase: string;
}

interface IWizardState {
    page: number;
}

class Controller extends FullPageWizardController<IWizardState, IWizardScope> {

    tics: any[] = [];
    tick: any;

    constructor(scope: IWizardScope) {
        super(scope);
        this.tics = getDeliveryTicks();
    }

    async onInit(): Promise<void> {
        await super.onInit();
        this.wizardState = {page: 1};

        const template = this.scope.template;
        this.tick = _.find(this.tics, tic => tic.duration === template.delay);
        if (!this.tick) {
            const custom = this.tick = {
                name: `Custom: ${template.delay}(s)`,
                duration: template.delay
            };
            this.tics.push(custom);
        }

        this.scope.$watch(() => this.tick, (tic) => {
            template.delay = tic.duration;
        });
    }
}

export const SmsSettingsWizard = NGX.makeTagDirective("sms/wizard",
    Controller, {template: "="});
