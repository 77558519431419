import {NGC} from "../../const";
import {AngularDirective} from "../../utility/ng";
import {CanvasTemplate} from "../CanvasTemplate";
import {SmsBranchTransaction, SmsMessageTransaction, Transaction} from "../Transactions";
import {ICompileService, IDirective, IScope} from "angular";

interface ITransactionActivatorScope extends IScope {
    transaction: Transaction;
    template: CanvasTemplate;
}

export var maker = (compile: ICompileService): IDirective => {
    return {
        scope: {transaction: "=", template: "="},
        link(scope: ITransactionActivatorScope, elem: JQuery) {

            const transaction = scope.transaction;
            const tag = `<upwire-${transaction.type}-transaction>`;

            const transactionElement = $(tag).attr({
                template: "template",
                transaction: "transaction"
            });

            if (!transaction.isSystemTransaction) {
                transactionElement.addClass("common-transaction");
            }

            compile(transactionElement)(scope, (clone: JQuery) => {
                clone.appendTo(elem);
            });
        }
    };
};

maker.$inject = [NGC.compile];

export const TransactionActivator: AngularDirective = {
    type: "tag",
    name: "upwire-transaction-activator",
    jsName: "upwireTransactionActivator",
    maker: maker
};
