import {NamespaceName, NGC} from "../const";
import {browser} from "../utility/browser";
import {AngularDirective, NGX} from "../utility/ng";
import {ICompileService, IDirective, IScope} from "angular";

import Quill from "quill";
import {strings} from "../utility/strings";
import {uuid} from "../utility/utility";
import {IPoint} from "../utility/common";
import {IReferenceGroup, IReferenceItem} from "../common/References";
import {makeReferenceMenu, ReferenceGroupMenu} from "./varmenu";
import {FullPageWizardController} from "../common/wizards";


interface IVariableEditorScope extends IScope {

    text: string;
    rawInputMode: boolean;
    references: IReferenceGroup[];
    disableVariableAdder: boolean;

    showAdderButton: boolean;
    adderTop: string;
    adderLeft: string;
}

class PillImageBlot extends Quill.import("formats/image") {

    static readonly meta = "data-meta";
    static readonly blotName = "pillImage";
    static readonly tagName = "img";

    static create(value: IReferenceItem) {
        const node = super.create();
        node.setAttribute("src", value.image);
        node.setAttribute(this.meta, value.meta);
        return node;
    }

    static value(node): IReferenceItem {
        return {
            meta: node.getAttribute(this.meta),
            image: node.getAttribute("src")
        };
    }
}

Quill.register(PillImageBlot);

enum RecycleState {
    None,
    CompactLines,
    RenderReference
}

function generateHtmlFromScript(script: string, references: IReferenceGroup[]): string {

    function pack(str: string): string {
        return `|>|${str}|<|`;
    }

    const mapping = {};
    for (let group of references)
        for (let item of group.items) {
            const id = uuid();
            mapping[id] = item;
            script = strings.replaceAll(script, item.meta, pack(id));
        }

    const capture = /\|>\|(.*?)\|<\|/g;
    const minidom = [];

    const addTextSpan = (text: string) => {
        if (text.length > 0)
            minidom.push($("<span>").text(text));
    };

    let lastIndex = 0;
    let m: RegExpExecArray;

    while ((m = capture.exec(script))) {

        const a = m.index;
        const b = capture.lastIndex;

        addTextSpan(script.substr(lastIndex, a - lastIndex));

        const meta = m[1];
        const reference = mapping[meta];
        if (reference)
            minidom.push(PillImageBlot.create(reference));

        lastIndex = b;
    }

    addTextSpan(script.substr(lastIndex, script.length - lastIndex));

    const doc = $("<div>");

    for (const element of minidom)
        doc.append(element);

    return doc.html();
}

class ScriptEditor {

    private editor: Quill;
    private changeListeners: ((point: IPoint) => void)[] = [];

    private initQuillEditor(): Quill {

        const id = browser.uniqueDomId();
        const idString = `#${id}`;
        this.host.attr("id", id);

        return new Quill(idString, {
            formats: ["pillImage"],
            placeholder: "Create a scripted message...",
        });
    }

    private getRecyclingState(): RecycleState {

        const div = document.createElement("div");
        div.innerHTML = this.editor.root.innerHTML;

        const paragraphs = div.getElementsByTagName("p");
        if (paragraphs.length > 1)
            return RecycleState.CompactLines;

        const images = div.getElementsByTagName("img");
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            image.removeAttribute(PillImageBlot.meta);
        }

        const search = div.innerText;
        for (let group of this.groups)
            for (let item of group.items)
                if (search.indexOf(item.meta) !== -1)
                    return RecycleState.RenderReference;

        return RecycleState.None;
    }

    get selectionIndex() {
        return (this.editor.getSelection() ?? {index: -1, length: 0}).index;
    }

    private get length() {
        return this.editor.getLength();
    }

    private recycle() {

        const state = this.getRecyclingState();
        if (state === RecycleState.None)
            return;

        const initialPosition = this.selectionIndex;
        const initialLength = this.length;

        this.script = this.script;

        if (this.focused) {
            setTimeout(() => {

                let position = initialPosition;
                if (state == RecycleState.RenderReference)
                    position = this.length - (initialLength - initialPosition);

                this.editor.setSelection({index: position, length: 0}, "api");
                this.editor.focus();

            }, 16);
        }
    }

    private updateChangeListeners() {

        const rang = this.editor.getSelection(false);

        if (!rang)
            return;

        const position = rang.index + rang.length;
        const bounds = this.editor.getBounds(position);

        for (const listener of this.changeListeners)
            try {
                listener({x: bounds.left, y: bounds.top});
            } catch (e) {
                console.error(e);
            }
    }

    private traverseChildrenExtractingText(node: HTMLElement): string[] {

        const children = node.childNodes;

        if (children.length === 0) {
            const text = node.textContent;
            return text.length > 0 ? [text] : [];
        }

        const fragments = [];

        for (let i = 0; i < children.length; i++) {
            const node = children[i];

            if (node.nodeName === PillImageBlot.tagName) {
                fragments.push(PillImageBlot.value(node).meta);
            } else {
                fragments.push(...this.traverseChildrenExtractingText(node as HTMLElement));
            }
        }

        return fragments.map(it => it.trim());
    }

    constructor(private readonly host: JQuery, readonly groups: IReferenceGroup[]) {
        this.editor = this.initQuillEditor();
        this.editor.on("editor-change", () => {
            this.updateChangeListeners();
        });
    }

    get script(): string {

        const doc = document.createElement("pre");
        doc.innerHTML = this.editor.root.innerHTML;

        let script = this.traverseChildrenExtractingText(doc).join(" ");
        script = strings.removeRepeatedSpaces(script);
        script = strings.removeSpacesBeforePunctuation(script);
        return script;
    }

    set script(script: string) {
        this.editor.setText("");
        const html = generateHtmlFromScript(script, this.groups);
        this.editor.clipboard.dangerouslyPasteHTML(html, "api");
        this.editor.setSelection(this.editor.getLength(), 0, "api");
        setTimeout(() => this.updateChangeListeners(), 100);
    }

    onChange(fn: (script: string) => void) {
        this.editor.on("text-change", (delta, oldDelta, source) => {

            this.updateChangeListeners();

            if (source === "api")
                return;

            this.recycle();

            fn(this.script);
        });
    }

    onFocusOrBlur(fn: (focused: boolean) => void) {
        this.editor.on("selection-change", (range, oldRange, source) => {
            const focused = range != null;
            fn(focused);
        });
    }

    onPositionUpdate(fn: (point: IPoint) => void) {
        this.changeListeners.push(fn);
        setInterval(() => {
            this.updateChangeListeners();
        }, 1000);
    }

    focus() {
        this.editor.focus();
    }

    addReference(reference: IReferenceItem) {
        const selection = this.editor.getSelection(true);
        this.editor.insertText(selection.index, reference.meta, "user");
        setTimeout(() => {
            this.editor.setSelection(this.length, 0, "api");
        }, 16);
    }

    get focused(): boolean {
        const active = document.activeElement;
        if (!active)
            return false;
        return this.editor.root.contains(active);
    }


}

function maker(): IDirective {

    const liker = (scope: IVariableEditorScope, elem: JQuery) => {

        function glue(editor: ScriptEditor) {
            let lastSetScript: string = undefined;

            scope.$watch(() => scope.text, script => {
                if (script === lastSetScript)
                    return;

                if (!scope.rawInputMode)
                    editor.script = script;
            });

            scope.$watch(() => scope.rawInputMode, raw => {
                if (raw)
                    return;
                editor.script = scope.text;
            });

            editor.onChange(script => {
                scope.text = script;
                lastSetScript = script;
                NGX.safeDigest(scope.$root);
            });
        }

        const groups = scope.references ?? [];

        const compiler = NGX.getInjector().get(NGC.compile) as ICompileService;
        const editorPane = elem.find(".editor-pane");
        if (elem.length === 0)
            throw new Error("No editor pane found");

        function initVariableAdder() {

            if (groups.length == 0)
                return;

            scope.showAdderButton = false;
            scope.adderTop = "0px";
            scope.adderLeft = "0px";

            const adder = $("<div>")
                .addClass("variable-adder-button")
                .attr("ng-show", "showAdderButton && !rawInputMode")
                .attr("ng-style", "{'top': adderTop, 'left': adderLeft}")
                .append($("<i>").addClass("fa fa-plus"));

            let menu: ReferenceGroupMenu = null;

            compiler(adder)(scope, (clone) => {
                elem.append(clone);
                clone.on("click", () => {

                    menu?.close();
                    menu = makeReferenceMenu(clone, groups);

                    menu.onAddReference(reference => {
                        editor.addReference(reference);
                    });

                    scope.showAdderButton = true;

                    NGX.safeDigest(scope);
                });
            });

            const update = (focused: boolean) => {
                scope.showAdderButton = focused;
                menu?.close();
                NGX.safeDigest(scope);
            };

            scope.showAdderButton = true;
            editor.onFocusOrBlur(update);

            editor.onPositionUpdate(point => {
                scope.adderLeft = `${point.x + 7}px`;
                scope.adderTop = `${point.y + 7}px`;
                NGX.safeDigest(scope);
            });
        }

        const editor = new ScriptEditor(editorPane, groups);

        editor.script = scope.text;
        glue(editor);

        if (!scope.disableVariableAdder)
            initVariableAdder();

        editorPane.on("click", () => {
            editor.focus();
            NGX.safeDigest(scope);
            return false;
        });
    };

    return {
        restrict: "E",
        template:
            "<div class='editor-pane' ng-show='!rawInputMode'></div>" +
            "<textarea class='editor-text' ng-show='rawInputMode' ng-model='text'></textarea>",
        scope: {
            text: "=",
            rawInputMode: "=?",
            references: "=?",
            disableVariableAdder: "=?"
        },
        link: liker
    };
}

require("quill/dist/quill.snow.css");
export const ScriptEditorDirective: AngularDirective = {
    type: "tag",
    name: NamespaceName + "-script-editor",
    jsName: NamespaceName + "ScriptEditor",
    maker: maker
};

interface IWizardScope extends IScope {

    text: string;
    pageTitle: string;
    references: IReferenceGroup[];
    showRawControls: boolean;
    showCounter: number;

    useRawInputMode: boolean;
    phase: string;
}

interface IScriptViewerScope extends IScope {
    script: string;
    groups: IReferenceGroup[];
}

class Controller extends FullPageWizardController<null, IWizardScope> {

    constructor(public scope: IWizardScope) {
        super(scope);
        this.wizardState = null;
    }

    get counter(): number {
        return this.scope.text.length;
    }

    get raw() {
        return this.scope.useRawInputMode || false;
    }

    set raw(value: boolean) {
        this.scope.useRawInputMode = value;
    }

    close(param: any = null) {
        super.close(param);
    }
}

export const ScriptEditorWizardDirective = NGX.makeTagDirective("common/script-wizard", Controller, {
    text: "=",
    pageTitle: "=",
    references: "=",
    showRawControls: "=?",
    showCounter: "=?"
}, ((scope: IWizardScope, elem) => {
    scope.useRawInputMode = false;
    elem.addClass("full-page-wizard blue");

}), {
    controllerAs: "wizard"
});

export const ScriptViewerDirective: AngularDirective = NGX.makeTagDirective("common/script-viewer", null, {
    "script": "@",
    "groups": "="
}, (scope: IScriptViewerScope, elem) => {

    scope.$watchGroup([() => scope.script, () => scope.groups], ([script, groups]) => {
        elem.empty();

        if (!script)
            return;

        const html = generateHtmlFromScript(script, groups);
        elem.html(html);
    });
});
