import _ from "lodash";

function s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export function uuid(): string {
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}


let uniqueSymbolGeneratorId = 0

export namespace utility {

    export function matches(regexp: RegExp, str: string): RegExpExecArray[] {

        const matches: RegExpExecArray[] = [];

        let match: RegExpExecArray;
        while ((match = regexp.exec(str))) {
            if (match.index === regexp.lastIndex)
                regexp.lastIndex++;
            matches.push(match);
        }

        return matches;
    }

    export function perf(): number {
        if (!!console.group && !!window.performance)
            return performance.now();
        return 0;
    }

    export function getStackTrace() {

        const stacks = new Error().stack;

        let stack = stacks.split("\n").map(line => line.trim());
        stack = _.filter(stack, (line: string) => !_.includes(line, "angular"));
        return stack.splice(stack[0] === "Error" ? 2 : 1);
    }

    export function toPythonDict(payload: any) {

        function symbol(value: any) {
            return `<<<PY${value}>>>PY`;
        }

        const json = JSON.stringify(payload,
            (key, value) => {
                if (typeof value === "boolean")
                    return symbol(value ? "True" : "False");

                if (value === null)
                    return symbol("None");

                return value;
            }, 2);

        return json.replace(/"<<<PY(.*?)>>>PY"/g, (a, b) => b);
    }

    export function isJsonObjectString(str: string) {
        try {
            return typeof JSON.parse(str) == "object";
        } catch (e) {
            return false;
        }
    }

    export function makeUniqueSymbol() {
        // Should use actual JS symbols, but, for all compatibilities, we'll just make unique strings.
        return "__symbol_" + (uniqueSymbolGeneratorId++);
    }
}

