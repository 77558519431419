import {IWaveDescriptor} from "../canvas/audio";
import {Transaction} from "../canvas/Transactions";
import {Variable} from "../common/Variable";
import {url} from "../utility/url";

const PILL_FONT = "Sofia Pro";

function drawPill(text: string, fgColor: string, bgColor: string, openBrace: string, closeBrace: string): string {

    const canvas = document.createElement("canvas");

    const height = 32;


    const hh = Math.floor(height / 2);
    canvas.width = 100;
    canvas.height = height;

    const ctx = canvas.getContext("2d", {alpha: true}) as CanvasRenderingContext2D;

    function roundRect(x, y, width, height, radius): void {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
    }

    const fh = height - 6;
    ctx.font = `${fh}px ${PILL_FONT}`;
    ctx.globalAlpha = 0;

    const pillText = openBrace + " " + text + " " + closeBrace;
    const measure = ctx.measureText(pillText);
    const padding = 9;
    canvas.width = 2 * padding + measure.width;

    ctx.fillStyle = bgColor;
    roundRect(0, 0, canvas.width, height, hh);
    ctx.fill();

    const fragments = [openBrace + " ", text, " " + closeBrace];
    let offset = padding;

    ctx.font = `${fh}px ${PILL_FONT}`;
    ctx.globalAlpha = 1;
    const y = Math.floor(height - (height - fh) - 2);
    for (let i = 0; i < fragments.length; i++) {
        const fragment = fragments[i];

        // if is first or last element
        if (i === 0 || i === fragments.length - 1) {
            ctx.fillStyle = "rgba(255, 255, 255, 0.3)";
        } else {
            ctx.fillStyle = fgColor;
        }

        ctx.fillText(fragment, offset, y);
        offset += ctx.measureText(fragment).width;
    }

    return canvas.toDataURL();
}

export function drawVariablePill(variable: Variable): string {
    if (variable.temporary) {
        return drawPill(variable.name, "#fafafa", "#2b98f0", "{{", "}}");
    }
    return drawPill(variable.name, "#fafafa", "#536DFF", "{{", "}}");
}

export function drawTransactionPill(transaction: Transaction): string {
    const text = `#${transaction.ident}:${transaction.tranName}`;
    return drawPill(text, "#fafafa", "#417505", "[[", "]]");
}

export function drawWavePill(wave: IWaveDescriptor): string {
    const text = url.getFileName(wave.file);
    return drawPill(text, "#fafafa", "#FEC009", "??", "??");
}
