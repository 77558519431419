import {Variable} from "./Variable";
import {drawTransactionPill, drawVariablePill} from "../directives/drawing";
import {Transaction} from "../canvas/Transactions";

export interface IReferenceItem {
    meta: string;
    image: string;
}

export interface IReferenceGroup {
    name: string;
    icon: string;
    items: IReferenceItem[];
}

class VariableReference implements IReferenceItem {

    constructor(private readonly variable: Variable) {

    }

    get meta(): string {
        return `{{${this.variable.name}}}`;
    }

    get image(): string {
        return drawVariablePill(this.variable);
    }
}

class TransactionReference implements IReferenceItem {


    constructor(private readonly transaction: Transaction) {

    }

    get meta(): string {
        return `[[${this.transaction.ident}]]`;
    }

    get image(): string {
        return drawTransactionPill(this.transaction);
    }
}

export class VariableReferenceGroup implements IReferenceGroup {
    icon: string = "fa fa-code";
    name: string = "Variables";

    items: VariableReference[];

    constructor(variables: Variable[]) {
        this.items = variables.map(v => new VariableReference(v));
    }

}

export class TransactionReferenceGroup implements IReferenceGroup {
    icon: string = "fa fa-exchange";
    name: string = "Module Results";

    items: TransactionReference[];

    constructor(transactions: Transaction[]) {
        this.items = transactions
            .filter(t => t.canBeReferenced)
            .map(v => new TransactionReference(v));
    }
}
