import {CanvasConst, CanvasEvent, ICanvasViewport, pointer2sys, rel2sys, Settings, sys2view} from "../common";
import {IAngularEvent, IDirective, IScope, ITimeoutService} from "angular";
import {
    BotTransaction,
    CommonTransaction,
    ITerminal,
    ITransactionLayout,
    MenuTransaction,
    Transaction
} from "../Transactions";
import {CanvasTemplate} from "../CanvasTemplate";
import {IPoint} from "../../utility/common";
import {AppDirectiveBase} from "../../common/AppComponentBase";
import {browser} from "../../utility/browser";
import {ConnectorPainter, GridLinePainter} from "../painter";

import _ from "lodash";
import {AngularDirective, NGX} from "../../utility/ng";
import {OverlayService} from "../../services/overlay";
import {GlobalEvents, NGC} from "../../const";
import getPointerRelativePosition = browser.getPointerRelativePosition;
import schedulePainter = browser.schedulePainter;
import getInjector = NGX.getInjector;

interface ICanvasScope extends IScope {
    template: CanvasTemplate;
    disableScrollZoom: boolean;
    activeTransaction: Transaction;
    showSettings: boolean;
    settingsPoint: IPoint;
    checkErrors: boolean;
}

class CanvasController extends AppDirectiveBase<ICanvasScope> {

    constructor(scope: ICanvasScope) {
        super(scope);

        this.scope.checkErrors = false;
        this.scope.disableScrollZoom = localStorage.getItem(Settings.disableScrollZoom) === "true";

        this.setTimeout(() => this.initFocus(), 100);
    }

    connect(from: ITerminal, to: ITerminal) {
        this.scope.$broadcast(CanvasEvent.terminalEstablishConnection, from, to);
    }

    add(type: string, xrel: number, yrel: number) {
        this.scope.$broadcast(CanvasEvent.transactionAdd, type, xrel, yrel);
    }

    initFocus() {
        const transactions = this.scope.template.transactions;
        for (let transaction of transactions) {
            if (transaction.isStartingTransaction) {
                this.scope.$emit(CanvasEvent.transactionActivated, transaction);
                return;
            }
        }
    }

    zoomIn() {
        this.scope.$broadcast(CanvasEvent.zoomIn);
    }

    zoomOut() {
        this.scope.$broadcast(CanvasEvent.zoomOut);
    }

    resetZoom() {
        this.scope.$broadcast(CanvasEvent.zoomReset);
    }

    reset() {
        this.scope.$broadcast(CanvasEvent.reset);
    }

    toggleScrollZoom() {
        this.scope.disableScrollZoom = !this.scope.disableScrollZoom;
        localStorage.setItem(Settings.disableScrollZoom, this.scope.disableScrollZoom.toString());
    }

    errorCount() {
        return this.scope.template.errorCount;
    }

    get template() {
        return this.scope.template;
    }
}

function adjustToGrid(v: number) {

    if (Math.abs(v) > CanvasConst.CanvasSysExtent - CanvasConst.CanvasSysMargin) {
        const sign = v < 0 ? -1 : 1;
        v = (CanvasConst.CanvasSysExtent - CanvasConst.CanvasSysMargin) * sign;
    }

    const dst = CanvasConst.CellSize;
    const rest = Math.abs(v % dst);
    const diff = Math.min(rest, dst - rest);
    const dir = (Math.abs(v + diff) % dst < Math.abs(v - diff) % dst) ? 1 : -1;

    return v + dir * diff;
}

function clamp(v: number) {

    if (Math.abs(v) > CanvasConst.CanvasSysExtent) {
        const sign = v < 0 ? -1 : 1;
        return sign * CanvasConst.CanvasSysExtent;
    }
    return v;
}

function adjustDimensionalBound(vo: number, vf: number, vdim: number, zoom: number) {

    if (vo - vf > CanvasConst.CanvasSysExtent) {
        vo = CanvasConst.CanvasSysExtent + vf;
    }

    if (vdim / zoom > 2 * CanvasConst.CanvasSysExtent) {
        const diff = vdim / zoom - 2 * CanvasConst.CanvasSysExtent;
        return CanvasConst.CanvasSysExtent + vf + diff / 2;
    } else if (vdim / zoom - vo + vf > CanvasConst.CanvasSysExtent) {
        return -CanvasConst.CanvasSysExtent + vdim / zoom + vf;
    }

    return vo;
}

function adjustBounds(viewport: ICanvasViewport) {

    viewport.xoffset = adjustDimensionalBound(viewport.xoffset, viewport.xf, viewport.width, viewport.zoom);
    viewport.yoffset = adjustDimensionalBound(viewport.yoffset, viewport.yf, viewport.height, viewport.zoom);
}

function setSysFocus(viewport: ICanvasViewport, xsys: number, ysys: number) {

    const x = clamp(xsys),
        y = clamp(ysys);

    viewport.xoffset += (x - viewport.xf);
    viewport.yoffset += (y - viewport.yf);

    viewport.xf = x;
    viewport.yf = y;
}

function setRelativeFocus(viewport: ICanvasViewport, elem: JQuery, evt: JQueryEventObject) {

    const rel = getPointerRelativePosition(elem, evt.clientX, evt.clientY);
    const sys = rel2sys(viewport, rel.x, rel.y);
    setSysFocus(viewport, sys.x, sys.y);
}

function measureTranLayout(layout: ITransactionLayout, viewport: ICanvasViewport) {

    const heightExpander = layout.elem.find(".expand-transaction-height");
    for (let i = 0; i < heightExpander.length; i++) {
        const expanderStaticHeight = heightExpander[i].clientHeight / viewport.zoom;
        layout.height = layout.originalHeight + expanderStaticHeight;
    }
}

function updateElementStyle(layout: ITransactionLayout) {

    const re = layout.elem[0];

    if (layout.x !== layout.lsX) {
        re.style.left = layout.x + "px";
        layout.lsX = layout.x;
    }

    if (layout.y !== layout.lsY) {
        re.style.top = layout.y + "px";
        layout.lsY = layout.y;
    }

    if (layout.zindex !== layout.lsZ) {
        re.style.zIndex = layout.zindex.toString();
        layout.lsZ = layout.zindex;
    }

    const ww = layout.viewWidth.toFixed() + "px";
    if (ww !== layout.lsW) {
        re.style.width = layout.lsW = ww;
    }

    const hh = layout.viewHeight.toFixed() + "px";
    if (hh !== layout.lsH) {
        re.style.height = layout.lsH = hh;
    }

    const ff = layout.viewFontSize;
    if (ff !== layout.lsF) {
        re.style.fontSize = layout.lsF = ff;
    }

    if (layout.active !== layout.lsA) {
        layout.elem.toggleClass("active", layout.active);
        layout.lsA = layout.active;
    }


}

function layout(viewport: ICanvasViewport, transactions: Transaction[]) {

    for (let i = 0; i < transactions.length; ++i) {
        const tran = transactions[i];

        const lp = sys2view(viewport, tran.x, tran.y);

        tran.layout.x = lp.x - tran.layout.width * viewport.zoom / 2;
        tran.layout.y = lp.y - tran.layout.height * viewport.zoom / 2;

        if (tran.layout.elem)
            measureTranLayout(tran.layout, viewport);

        tran.layout.viewWidth = tran.layout.width * viewport.zoom;
        tran.layout.viewHeight = tran.layout.height * viewport.zoom;
        tran.layout.viewFontSize = viewport.zoom.toFixed(5) + "em";

        if (tran.layout.elem)
            updateElementStyle(tran.layout);
    }
}

function getDeepClientRects(elem: HTMLElement): DOMRect[] {

    const rects: DOMRect[] = [elem.getBoundingClientRect()];

    const children = elem.children;
    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child instanceof HTMLElement) {
            const childRects = getDeepClientRects(child);
            rects.push(...childRects);
        }
    }

    return rects;
}

// find a rect covering all rects
function findCoveringRect(rects: DOMRect[]): DOMRect {

    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;
    let maxX = Number.MIN_VALUE;
    let maxY = Number.MIN_VALUE;

    for (let rect of rects) {
        minX = Math.min(minX, rect.left);
        minY = Math.min(minY, rect.top);
        maxX = Math.max(maxX, rect.right);
        maxY = Math.max(maxY, rect.bottom);
    }

    return new DOMRect(minX, minY, maxX - minX, maxY - minY);
}

function maker(): IDirective {

    const overlay = getInjector().get(OverlayService.injectAs) as OverlayService;
    const timeout = getInjector().get(NGC.timeout) as ITimeoutService;

    return {
        restrict: "E",
        template: require("../../../templates/canvas/canvas.html"),
        controller: CanvasController,
        controllerAs: "canvas",
        scope: {template: "="},
        link: (scope: ICanvasScope, elem: JQuery) => {

            function updateComponentLayout2() {
                paint();

                timeout(() => {
                    scope.$broadcast(CanvasEvent.terminalUpdated);
                    scheduler.paint();
                });

                return false;
            }

            function initViewport() {

                const offset = elem.offset();
                const width = elem.width();
                const height = elem.height();

                if (!viewport) {
                    viewport = {
                        xoffset: width / 2,
                        yoffset: height / 2,
                        width: width,
                        height: height,
                        zoom: 1,
                        xf: 0,
                        yf: 0,
                        documentOffset: offset
                    };
                }

                viewport.width = width;
                viewport.height = height;
                viewport.documentOffset = offset;
                scheduler.paint();
            }

            function setZoom(zoom: number) {

                const oz = viewport.zoom;
                zoom = Math.max(Math.min(zoom, CanvasConst.ZoomMax), CanvasConst.ZoomMin);
                viewport.zoom = zoom;

                viewport.xoffset = viewport.xoffset / viewport.zoom * oz;
                viewport.yoffset = viewport.yoffset / viewport.zoom * oz;

                adjustBounds(viewport);
                layout(viewport, scope.template.transactions);
                updateComponentLayout();
                updateSettingsLayout(true);
                paint();
            }

            function paint() {
                adjustBounds(viewport);

                if (scope.template)
                    layout(viewport, scope.template.transactions);

                glp.draw(viewport);
                if (scope.template)
                    clp.draw(viewport, scope.template);
                return false;
            }

            function shiftViewport() {

                const topleftp = rel2sys(viewport, CanvasConst.EdgeScrollRelativeDistance, CanvasConst.EdgeScrollRelativeDistance);
                const botrightp = rel2sys(viewport, 1 - CanvasConst.EdgeScrollRelativeDistance, 1 - CanvasConst.EdgeScrollRelativeDistance);

                let speed = CanvasConst.EdgeScrollSpeed / viewport.zoom;

                if (dragTransaction.x < topleftp.x) {
                    viewportShiftVelocity.x = speed;
                } else if (dragTransaction.x > botrightp.x) {
                    viewportShiftVelocity.x = -speed;
                }

                if (dragTransaction.y < topleftp.y) {
                    viewportShiftVelocity.y = speed;
                } else if (dragTransaction.y > botrightp.y) {
                    viewportShiftVelocity.y = -speed;
                }

                const vxs = viewport.xoffset,
                    vys = viewport.yoffset;

                viewport.xoffset += viewportShiftVelocity.x;
                viewport.yoffset += viewportShiftVelocity.y;

                adjustBounds(viewport);

                tranShift.shiftX += viewport.xoffset - vxs;
                tranShift.shiftY += viewport.yoffset - vys;

                dragTransaction.x = clamp(tranShift.xsys - tranShift.shiftX);
                dragTransaction.y = clamp(tranShift.ysys - tranShift.shiftY);

                paint();

                viewportShiftVelocity.x *= CanvasConst.EdgeScrollSpeedDampener;
                viewportShiftVelocity.y *= CanvasConst.EdgeScrollSpeedDampener;

                speed = Math.sqrt(Math.pow(viewportShiftVelocity.x, 2) + Math.pow(viewportShiftVelocity.y, 2));

                return speed > CanvasConst.EdgeScrollTerminalSpeed;
            }

            function startDrag(evt: JQueryEventObject) {
                scope.$root.$broadcast(GlobalEvents.closeToolWindows);
                draggingCanvas = true;
                setRelativeFocus(viewport, elem, evt);

                for (let i = 0; i < scope.template.transactions.length; ++i) {
                    const transaction = scope.template.transactions[i];
                    transaction.layout.active = false;
                }

                scheduler.paint();
                updateSettingsLayout(false);

                return {xo: viewport.xoffset, yo: viewport.yoffset};
            }

            function moveDrag(evt: JQueryEventObject, x: number, y: number, start: IPoint, sparam: { xo: number; yo: number }) {

                viewport.xoffset = sparam.xo + (x - start.x) / viewport.zoom;
                viewport.yoffset = sparam.yo + (y - start.y) / viewport.zoom;
                scheduler.paint();
            }

            function endDrag() {
                draggingCanvas = false;
                updateSettingsLayout(true);
                scheduler.paint();
            }

            function updateSettingsLayout(show: boolean) {

                if (scope.activeTransaction == null) {
                    show = false;
                } else if (!scope.activeTransaction.layout.active) {
                    show = false;
                }

                let digestPending = false;

                if (scope.showSettings !== show) {
                    scope.showSettings = show;
                    digestPending = true;
                }

                if (scope.activeTransaction && show) {
                    layout(viewport, scope.template.transactions);
                    const nlayout = scope.activeTransaction.layout;
                    scope.settingsPoint = {
                        x: nlayout.x + nlayout.viewWidth + 10,
                        y: nlayout.y
                    };
                    digestPending = true;
                }

                if (digestPending) {
                    NGX.safeDigest(scope);
                }
            }

            function addTransaction(tran: Transaction) {

                if (!tran)
                    return;

                tran.x = adjustToGrid(tran.x);
                tran.y = adjustToGrid(tran.y);

                setSysFocus(viewport, tran.x, tran.y);

                scope.activeTransaction = null;
                paint();
            }

            const glp = new GridLinePainter(elem.find(CanvasConst.GridAreaSelector));
            const viewportShiftVelocity = {x: 0, y: 0};
            const updateComponentLayout = _.debounce(updateComponentLayout2, 8, {leading: true});
            const clp: ConnectorPainter = new ConnectorPainter(elem.find(CanvasConst.ConnectorAreaSelector));

            let viewport: ICanvasViewport = null;
            let dragTransaction: Transaction = null;
            let tranShift = {xsys: 0, ysys: 0, shiftX: 0, shiftY: 0};
            let scheduler = schedulePainter(paint);
            let shifterScheduler = schedulePainter(shiftViewport);
            let draggingCanvas = false;

            // Canvas Dragging
            browser.onElementDrag(scope, elem.find(CanvasConst.ItemAreaSelector), startDrag, moveDrag, endDrag);

            browser.onWindowResize(scope, initViewport);

            browser.onWheelScroll(scope, elem, (evt: JQueryEventObject, direction: boolean) => {

                if (scope.disableScrollZoom) {
                    return;
                }

                setRelativeFocus(viewport, elem, evt);
                const zf = CanvasConst.ZoomFactor;
                const zoom = viewport.zoom * (direction ? zf : 1 / zf);
                setZoom(zoom);
                scheduler.paint();
            });

            scope.$on(CanvasEvent.zoomIn, () => setZoom(viewport.zoom * CanvasConst.ZoomFactor));

            scope.$on(CanvasEvent.zoomOut, () => setZoom(viewport.zoom / CanvasConst.ZoomFactor));

            scope.$on(CanvasEvent.zoomReset, () => setZoom(1));

            scope.$on(CanvasEvent.reset, () => {
                viewport = null;
                initViewport();
                setZoom(1);
            });

            scope.$on(CanvasEvent.transactionAdd, (evt: IAngularEvent, type: string, xrel: number, yrel: number) => {

                const sys = rel2sys(viewport, xrel, yrel);
                const transaction = scope.template.addTransaction(type, sys.x, sys.y);
                addTransaction(transaction);
            });

            scope.$on(CanvasEvent.transactionClone, (evt: IAngularEvent, tran: Transaction) => {

                const clone = scope.template.addTransaction(tran.type, tran.x, tran.y);
                tran.clone(clone, false);
                clone.x += CanvasConst.CellSize;
                clone.y += CanvasConst.CellSize;
                addTransaction(clone);
            });

            scope.$on(CanvasEvent.transactionRemove, async (evt: IAngularEvent, tran: Transaction) => {

                const ok = await overlay.showModalQuestion({
                    title: "Delete module?",
                    text: "Are you sure you want to remove this module from the canvas?",
                    icon: "warn",
                    style: "red",
                    options: {"yes": "Delete", "no": "Keep"},
                    showCloseButton: false
                }) === "yes";

                if (ok) {
                    scope.template.removeTransaction(tran);
                    scope.activeTransaction = null;
                    updateComponentLayout();
                }
            });

            scope.$on(CanvasEvent.transactionActivated, (evt: IAngularEvent, tran: Transaction) => {

                scope.activeTransaction = tran;
                scope.showSettings = true;

                NGX.safeDigest(scope);

                if (!draggingCanvas) {
                    setSysFocus(viewport, tran.x, tran.y);
                }

                let zidx = 0;
                _.each(_.sortBy(scope.template.transactions, (n: Transaction) => n.layout.zindex),
                    (t: Transaction) => {
                        if (t === tran) {
                            t.layout.zindex = scope.template.transactions.length;
                            t.layout.active = true;
                        } else {
                            t.layout.zindex = zidx++;
                            t.layout.active = false;
                        }
                    });

                paint();
                updateSettingsLayout(true);
                evt.stopPropagation();
            });

            scope.$on(CanvasEvent.transactionMoveStart, (evt: IAngularEvent, tran: Transaction) => {

                evt.stopPropagation();
                dragTransaction = tran;

                tranShift = {xsys: tran.x, ysys: tran.y, shiftX: 0, shiftY: 0};
                scope.$digest();
                updateSettingsLayout(false);
            });

            scope.$on(CanvasEvent.transactionMove, (evt: IAngularEvent, tran: Transaction, origin: IPoint, delta: IPoint) => {

                const x = origin.x - delta.x / viewport.zoom;
                const y = origin.y - delta.y / viewport.zoom;

                tranShift.xsys = x;
                tranShift.ysys = y;

                shifterScheduler.paint();
                updateSettingsLayout(false);
                evt.stopPropagation();

            });

            scope.$on(CanvasEvent.transactionMoveEnd, (evt: IAngularEvent, tran: Transaction) => {

                tran.x = adjustToGrid(tran.x);
                tran.y = adjustToGrid(tran.y);

                setSysFocus(viewport, tran.x, tran.y);
                shifterScheduler.abort();
                updateComponentLayout();
                updateSettingsLayout(true);

                evt.stopPropagation();
            });

            scope.$on(CanvasEvent.terminalDragStart, (evt: IAngularEvent, terminal: ITerminal) => {
                initViewport(); // Re-initialize the viewport; because the layout may have shifted
                scope.template.disconnectTerminal(terminal);
                clp.beginDanglingConnection(scope.template, terminal);
            });

            scope.$on(CanvasEvent.terminalDragMove, (evt: IAngularEvent, x: number, y: number) => {

                const sys = pointer2sys(viewport, x, y);
                clp.moveDanglingConnection(viewport, sys.x, sys.y);

                scheduler.paint();
                evt.stopPropagation();
            });

            scope.$on(CanvasEvent.terminalDragEnd, (evt: IAngularEvent) => {

                clp.endDanglingConnection();
                updateComponentLayout();
                evt.stopPropagation();
            });

            scope.$on(CanvasEvent.terminalStateChange, (evt: IAngularEvent, terminal: ITerminal) => {

                if (!terminal.active) {
                    scope.template.disconnectTerminal(terminal);
                }

                let tran: CommonTransaction = null;
                let activeTerminals: number = null;

                if (!terminal.node.isSystemTransaction) {
                    tran = terminal.node as CommonTransaction;
                    activeTerminals = tran.outboundTerminals.length;
                }


                if (tran) {

                    const ntype = terminal.node.type;

                    const menuOrBot = ntype == MenuTransaction.typename() || ntype == BotTransaction.typename();
                    if (menuOrBot) {
                        if (activeTerminals > 4) {
                            const spacers = Math.ceil((activeTerminals - 4) / 2);
                            const nw = tran.layout.originalWidth + 1.5 * spacers * CanvasConst.CellSize;
                            if (tran.layout.width !== nw) {
                                tran.layout.width = nw;
                            }
                        } else {
                            tran.layout.width = tran.layout.originalWidth;
                        }
                    }
                }


                updateComponentLayout();
                updateSettingsLayout(true);
                evt.stopPropagation();
            });

            scope.$on(CanvasEvent.terminalEstablishConnection, (evt: IAngularEvent, from: ITerminal, to: ITerminal) => {

                evt.stopPropagation();
                if (scope.template.connectTerminals(from, to)) {
                    updateComponentLayout();
                }
            });

            scope.$watch(() => scope.template, () => {

                scope.activeTransaction = null;
                scope.showSettings = false;
                scope.settingsPoint = null;

                scheduler.paint();
            });

            scope.$watch(() => {
                scheduler.paint();
            });
        }
    };
}


export const CanvasDirective: AngularDirective = {
    type: "tag",
    name: "upwire-canvas",
    jsName: "upwireCanvas",
    maker: maker
};



