import {IScope} from "angular";
import _ from "lodash";
import {TemplateApi} from "../apis/TemplatesApi";
import {GlobalEvents, TemplateTypename} from "../const";
import {RequiredVariableCreation, VariableCreation} from "../directives/dialog/VariableCreation";
import {VariableEvents} from "../directives/menu/menu";
import {NGX} from "../utility/ng";
import {AppDirectiveBase} from "./AppComponentBase";
import {Template} from "./Template";
import {Variable} from "./Variable";
import IInjectorService = angular.auto.IInjectorService;

function nextInstanceSequence() {
    return Math.floor(Math.random() * 1000000);
}

export interface IExtraOption {
    name: string;
    icon: string;
    action: () => void | Promise<void>;
    check?: () => boolean;
}

interface UnrecoverableError {
    description: string;
}

export interface TemplateEditorScope<TTemplate extends Template> extends IScope {
    template: TTemplate;
}

export abstract class TemplateEditorController<TTemplate extends Template> extends AppDirectiveBase<TemplateEditorScope<TTemplate>> {
    template: TTemplate;

    saving: boolean = false;
    saved: boolean = false;

    skipSaveOnce: boolean = false;
    instanceSequence = nextInstanceSequence();
    systemError: UnrecoverableError | null = null;

    protected templateApi: TemplateApi;

    private _workDepth = 0;
    private _extraOptions: IExtraOption[] = [];

    protected injectFields(injector: IInjectorService) {
        super.injectFields(injector);
        this.templateApi = injector.get(TemplateApi.injectAs);
    }

    protected async work<T>(task: Promise<T> | (() => Promise<T>), description: string = null): Promise<T> {
        try {
            this._workDepth++;
            return await this.overlay.freeze(task, description);
        } finally {
            this._workDepth--;
        }
    }

    private setupAutoSave() {

        let deferSaveHandle = null;

        const save = async () => {
            this.saving = true;
            NGX.safeDigest(this.scope);

            try {
                await this.templateApi.saveTemplate(this.template);
                this.saved = true;
            } catch (e) {
                await this.overlay.handleGlobalException(e);
            } finally {
                this.saving = false;
                NGX.safeDigest(this.scope);
            }
        };

        const deferSave = _.debounce(async () => {

            if (this.saving) {
                if (deferSaveHandle) {
                    clearTimeout(deferSaveHandle);
                    deferSaveHandle = null;
                }
                deferSaveHandle = setTimeout(() => deferSave(), 250);
            } else {
                await save();
            }

        }, 500);

        this.scope.$watch(() => this.template && this.template.serialize(), (n, o) => {

            const template = this.template;
            if (!template)
                return;

            template.validate();

            const edited = n && o && n !== o;

            if (edited) {
                if (this.skipSaveOnce) {
                    this.skipSaveOnce = false;
                    return;
                }

                deferSave();
            }
        });
    }

    private async loadTemplate() {

        const load = async () => {

            const startup = await this.onEditorStart(this.templateId, this.typename);
            const template = await this.templateApi.loadTemplate(this.templateId);

            if (!template || template.typename !== this.typename)
                await this.overlay.halt("Unable to load template");

            this.scope.template = this.template = template as TTemplate;

            for (let name in this.state.variables)
                this.template.addVariable(name, false);

            await startup;
            await this.onEditorTemplateLoaded();

            this.setupAutoSave();

            this.scope.$on(VariableEvents.addNewVariable, async () => {
                await this.overlay.show(VariableCreation, {
                    template: template
                });
                this.scope.$root.$broadcast(GlobalEvents.referencesUpdated);
            });

            this.scope.$on(VariableEvents.addRequiredVariable, async () => {
                await this.overlay.show(RequiredVariableCreation, {
                    template: template
                });
                this.scope.$root.$broadcast(GlobalEvents.referencesUpdated);
            });
        };

        await this.work(load(), "Loading template");
    }

    async onInit(): Promise<void> {
        await super.onInit();

        try {
            await this.loadTemplate();
        } catch (e) {
            this.systemError = {
                description: e
            };
        } finally {
            NGX.safeDigest(this.scope);
        }
    }

    async undo() {

        const task = this.templateApi.undo(this.template);
        this.scope.template = this.template = await this.overlay.freeze(task, "Reverting changes");

        this.skipSaveOnce = true;
        this.instanceSequence = nextInstanceSequence();

        NGX.safeDigest(this.scope);
        this.scope.$broadcast(GlobalEvents.reload);
    }

    constructor(scope: TemplateEditorScope<TTemplate>) {
        super(scope);

        scope.$on(VariableEvents.removeVariable, (e, variable: Variable) => {
            this.template.removeVariable(variable);
            scope.$broadcast(GlobalEvents.referencesUpdated);
            NGX.safeDigest(this.scope);
        });
    }

    get templateId() {
        return this.state.templateId;
    }

    get working() {
        return this._workDepth > 0;
    }

    // This method will be launched concurrently with the template load.
    protected async onEditorStart(templateId: string, type: TemplateTypename) {

    }

    protected async onEditorTemplateLoaded() {
        this.scope.$on(GlobalEvents.showProblems, (e, show: boolean) => {
            this.template.showProblems = show;
            NGX.safeDigest(this.scope);
        });

        this.addExtraMenuOption({
            name: "Publish",
            icon: "fa-cloud-upload",
            action: async () => {

                const isValid = this.template.validate();
                console.log("Template is valid", isValid);

                if (!isValid) {
                    await this.overlay.showMessageBox("Template isn't ready", "There are problems with your template. Please fix the errors and try again. To highlight next error, click the red marker on the bottom of the screen.");
                    return;
                }

                if (!this.template.descriptor.published) {
                    const ok = await this.overlay.showModalQuestion({
                        icon: "warn",
                        title: "Publish Template",
                        text: "Are you sure you want to publish this template?. Once you've published the template you can use it in playgrounds, but you will no longer be able to modify required variables.",
                        options: {
                            "yes": "Yes, publish",
                            "no": "No, don't publish"
                        },
                        style: "blue",
                        showCloseButton: true,

                    }) == "yes";

                    if (!ok)
                        return;
                }

                try {
                    await this.overlay.freeze(this.onTemplatePublication(), "Preparing template for publication...");
                    await this.overlay.freeze(this.templateApi.publishTemplate(this.template), "Publishing...");
                    await this.overlay.showModal("Published", "Template published successfully");
                } catch (e) {
                    console.error(e);
                    await this.overlay.halt("Error publishing template");
                }
            },
            check: () => this.template.publicationStatus !== "latest"
        });
    }

    protected async onTemplatePublication() {

    }

    protected abstract openSettings(): Promise<void>

    abstract get typename(): TemplateTypename

    get extraOptions(): IExtraOption[] {
        return this._extraOptions;
    }

    showExtraOption(option: IExtraOption): boolean {
        if (option.check)
            return option.check();
        return true;
    }

    protected addExtraMenuOption(option: IExtraOption) {
        this._extraOptions.push(option);
    }
}

