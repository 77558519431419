import {IScope} from "angular";

import _ from "lodash";
import $ from "jquery";

import {AngularDirective} from "../utility/ng";

interface ISelectorScope extends IScope {
    upwireSelector: any;
    values: any;
    grid?: number;
    mode: "click" | "contextmenu";
    disable: boolean;
    namemap: any;
    select: (value: any) => void;

    bind?: "key" | undefined;

    currentKey: string;
    currentValue: any;
}

function link(scope: ISelectorScope, elem: JQuery) {

    const mode = scope.mode || "click";

    const grid = scope.grid ?? 1;
    const width = 100 / grid;

    const $body = $(document.body);
    const $window = $(window);

    let selector: JQuery = null;

    function handler(evt: JQueryEventObject) {
        const target = $(evt.target).closest(".selector-item");
        if (target.length && target.closest(selector).length) {
            const key = target.attr("data-item-key");

            let value = scope.values[key];
            if (scope.bind === "key")
                value = key;

            scope.$apply(() => {
                scope.upwireSelector = value;
                if (scope.select)
                    scope.select({value: value});
            });
        }

        if (selector) {
            selector.remove();
        }
        $window.off("click", null, handler);
    }

    function activate() {
        elem.on(mode, (evt: JQueryEventObject) => {

            $body.find(".selector")
                .remove();

            selector = $("<ul>")
                .addClass("selector")
                .css({top: 0, left: 0});

            let group: JQuery = null;

            const pairs = _.toPairs(scope.values);
            const keys = _.map(_.sortBy(pairs, (pair: any) => pair[1]), (pair: any) => pair[0]);

            for (let idx = 0; idx < keys.length; ++idx) {
                const key = keys[idx];
                const value = scope.values[key];

                if (idx % grid === 0) {
                    if (group != null)
                        selector.append(group);
                    group = $("<li>");
                }

                const text = scope.namemap ? scope.namemap[key] : key;

                const item = $("<div>")
                    .addClass("selector-item")
                    .attr("data-item-key", key)
                    .css({width: width + "%"})
                    .text(text)
                    .appendTo(group);

                if (scope.upwireSelector === value)
                    item.addClass("current");
            }

            if (group != null)
                selector.append(group);

            $body.append(selector);

            $window.off("click", null, handler);
            $window.on("click scroll resize", null, null, handler);

            const y = evt.clientY;
            const x = Math.min(evt.clientX, $window.width() - selector.width() - 5);

            selector.css({
                top: `${y}px`,
                left: `${x}px`
            });

            const sh = selector.height();
            if (y + sh > $window.height()) {
                const msh = $window.height() - y;
                selector.css("max-height", msh + "px");
            }

            evt.stopImmediatePropagation();

            if (mode === "contextmenu") {
                evt.preventDefault();
                evt.stopPropagation();
                return false;
            }

            return undefined;
        });
    }

    function deactivate() {
        elem.off(mode);
    }

    scope.$watch(() => scope.disable, (disabled) => {
        if (disabled) deactivate();
        else activate();
    });

}

const UpwireSelector: AngularDirective = {
    type: "attr",
    jsName: "upwireSelector",
    name: "upwire-selector",
    maker: () => ({
        priority: 1,
        restrict: "A",
        scope: {
            upwireSelector: "=?",
            values: "=",
            grid: "=",
            mode: "@",
            disable: "=",
            select: "&?",
            namemap: "=?",
            bind: "@?"
        },
        link: link
    })
};

export function getUpwireSelectorDirectives(): AngularDirective[] {
    return [UpwireSelector];
}
