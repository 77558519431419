import {IPoint} from "../utility/common";
import {geometry} from "../utility/geometry";
import {DateInputFormat, Transaction} from "./Transactions";
import {Variable} from "../common/Variable";


export enum TerminalType {
    Inbound = 1,
    Outbound = 2
}

export enum TerminalDirection {
    Down,
    Right
}

export enum CallType {
    Inbound = 1,
    Outbound = 2
}

export enum Strategy {
    HangUp = 1,
    FallThrough = 2,
    GoTo = 3
}

export enum RequestApiBindingKind {
    Variable = 1,
    Transaction = 2,
    Static = 3,

    CalleeNumber = 4,

    CreditCardNumber = 5,
    CreditCardExpirationDate = 6,
    CreditCardCVVCode = 7
}

export enum ReferenceType {
    Variable,
    Transaction
}

export type Reference =
    { type: ReferenceType.Variable, value: Variable } |
    { type: ReferenceType.Transaction, value: Transaction };

export type ReferenceMap = { [referenceId: string]: Reference }

export enum SmsDeliveryTimezone {
    Off,
    FirstTransaction,
    AllTransactions
}


export interface TtsFormatSettings {
    variablePrefix?: string;
    variableSuffix?: string;
    transactionPrefix?: string;
    transactionSuffix?: string;
}

export interface ITerminalLayout {
    width: number;
    height: number;
    offsetInHost: IPoint;
}

export interface ITerminalSettings {
    ghost?: boolean;
    active?: boolean;
    text?: string;
    meta?: any;
    direction?: TerminalDirection;
}

export interface ICanvasViewport {
    width: number;
    height: number;
    xoffset: number;
    yoffset: number;
    zoom: number;
    xf: number;
    yf: number;
    documentOffset: JQueryCoordinates;
}

export interface IStatusTag {
    name: string;
    description: string;
    icon: string;
    class?: string;
}

export interface IVoiceTemplateDefaults {
    dtmfHelpButton: string;
    dtmfEndInputButton: string;
    dtmfTimeout: number;
    errorRetryCount: number;
    dateFormat: DateInputFormat;
}


// noinspection SpellCheckingInspection
export const Settings = {
    disableScrollZoom: "settings-disable-scrollzoom"
};

export const CanvasConst = {
    GridAreaSelector: ".grid",
    ConnectorAreaSelector: ".connector",
    ItemAreaSelector: ".item",

    CellSize: 40,

    ZoomFactor: 1.1,
    ZoomMin: 0.05,
    ZoomMax: 5,

    CanvasSysExtent: 15000,
    CanvasSysMargin: 150,

    EdgeScrollRelativeDistance: 0.05,
    EdgeScrollSpeed: 10,
    EdgeScrollTerminalSpeed: 1,
    EdgeScrollSpeedDampener: 0.8
};

export const CanvasEvent = {
    zoomIn: "zoom-in",
    zoomOut: "zoom-out",
    zoomReset: "zoom-reset",
    reset: "reset",

    transactionAdd: "transaction-add",
    transactionClone: "transaction-clone",
    transactionRemove: "transaction-remove",
    transactionSettingsOpen: "transaction-settings-open",
    transactionActivated: "transaction-activated",
    transactionMoveStart: "transaction-move-start",
    transactionMove: "transaction-move",
    transactionMoveEnd: "transaction-move-end",

    terminalAdded: "terminal-added",
    terminalUpdated: "terminal-updated",
    terminalDragStart: "terminal-drag-start",
    terminalDragMove: "terminal-drag-move",
    terminalDragEnd: "terminal-drag-end",
    terminalLink: "terminal-link",
    terminalEstablishConnection: "terminal-establish-connection",
    terminalStateChange: "terminal-state-change",

    openSettingsSub: "open-settings-sub"
};

export function pointer2rel(vp: ICanvasViewport, x: number, y: number): IPoint {
    return geometry.calcPointerRelativePosition(x, y,
        vp.documentOffset.left,
        vp.documentOffset.top,
        vp.width,
        vp.height);
}

export function pointer2sys(vp: ICanvasViewport, x: number, y: number) {
    const rel = pointer2rel(vp, x, y);
    return rel2sys(vp, rel.x, rel.y);
}

export function rel2sys(vp: ICanvasViewport, xRel: number, yRel: number): IPoint {

    const x = xRel * vp.width / vp.zoom - (vp.xoffset - vp.xf);
    const y = yRel * vp.height / vp.zoom - (vp.yoffset - vp.yf);
    return {x: x, y: y};
}

export function sys2view(vp: ICanvasViewport, xSys: number, ySys: number): IPoint {

    const x = (xSys + vp.xoffset - vp.xf) * vp.zoom;
    const y = (ySys + vp.yoffset - vp.yf) * vp.zoom;
    return {x: x, y: y};
}
