import {AngularDirective, NGX} from "../utility/ng";
import {IScope} from "angular";


interface IEditableDivScope extends IScope {
    text: string
}

const EditableDiv = NGX.makeTagDirective("common/editable-div", null, {
    text: "=",
}, (scope: IEditableDivScope, element: JQuery) => {

    const div = element.children(".editable-div")

    function getText() {
        return div.text()
    }

    function setText(text: string) {
        div.text(text)
    }

    scope.$watch(() => scope.text, text => {
        if (text !== getText())
            setText(text)
    });

    div.on('blur keyup change', () => {
        scope.$evalAsync(() => {
            scope.text = getText();
        });
    });
})

export function getEditableDivDirectives(): AngularDirective[] {
    return [EditableDiv]
}
