import {AngularDirective} from "../../utility/ng";
import {CanvasDirective} from "./canvas";
import {TransactionActivator} from "./TransactionActivator";
import {
    ErrorViewDirective,
    CommonTransactionDirective,
} from "./transactions/common";
import {CanvasTerminalDirective} from "./terminal";
import {SettingsMenuDirective, SettingsMenuTabDirective} from "./settings";
import {SwitcherDirective} from "./switcher";

export function getCanvasDirectives(): AngularDirective[] {

    require("../../../styles/canvas.less");

    return [
        CanvasDirective,
        TransactionActivator,
        CommonTransactionDirective,
        CanvasTerminalDirective,
        ErrorViewDirective,
        SettingsMenuDirective,
        SettingsMenuTabDirective,
        SwitcherDirective
    ];
}
