import {IPoint} from "./common";
import {IScope} from "angular";
import _ from "lodash";
import $ from "jquery";
import {geometry, IBoundingBox} from "./geometry";
import {NamespaceName} from "../const";
import {NGX} from "./ng";

export interface IFramePainter {
    (t: number, dt: number): boolean;
}

export interface IFramePaintScheduler {
    paint: () => void;
    abort: () => void;
}

export type EventDeregisterFunction = () => void

export type Draggable = {
    dispose(): void;
}

export namespace browser {

    let uniqueDomIdGenerator = 0;

    export function isBrowserSupported() {
        for (let browser of ["Chrome", "Chromium", "Firefox", "Safari", "Edge"])
            if (navigator.userAgent.indexOf(browser) >= 0)
                return true;
        return false;
    }

    import KeyDownEvent = JQuery.KeyDownEvent;

    interface IDraggerStartDelegate<TStart> {
        (evt: JQueryEventObject, x: number, y: number): TStart;
    }

    interface IDraggerMoveDelegate<TStart> {
        (evt: JQueryEventObject, x: number, y: number, start: IPoint, sparam: TStart): void;
    }

    interface IDraggerEndDelegate<TStart> {
        (evt: JQueryEventObject, x: number, y: number, start: IPoint, sparam: TStart): void;
    }

    interface IMouseEventDelegate {
        (evt: JQueryEventObject, x: number, y: number);
    }

    class Dragger<TStart> {

        private startPoint: IPoint;
        private dragging: boolean = false;
        private startParameter: TStart = undefined;

        private moveHandler: IDraggerMoveDelegate<TStart> = null;
        private startHandler: IDraggerStartDelegate<TStart> = null;
        private endHandler: IDraggerEndDelegate<TStart> = null;

        constructor(readonly scope: IScope, elem: JQuery, readonly requireClass: string | null) {

            if (elem.length !== 1) {
                throw "need exactly one child";
            }

            const dragger = this;

            function start(evt: JQueryEventObject) {

                // if element has class, do not activate dragger
                if (requireClass && !elem.hasClass(requireClass)) {
                    evt.stopPropagation();
                    return undefined;
                }

                // do not activate dragger on user-editable elements
                if (isFocusTarget(evt))
                    return undefined;


                // do not activate dragger on right-clicks.
                if (evt.which !== 1)
                    return undefined;

                blur();

                $(document).on("mousemove", move);
                $(document).on("mouseout", leave);
                $(document).on("mouseup", end);

                elem.toggleClass("grabbing", true);

                dragger.dragging = true;
                dragger.startPoint = {x: evt.clientX, y: evt.clientY};

                if (dragger.startHandler) {
                    dragger.startParameter = dragger.startHandler(evt, evt.clientX, evt.clientY);
                }

                move(evt);

                evt.stopPropagation();
                return false;
            }

            function leave(evt: JQueryEventObject) {

                if (!evt.relatedTarget || evt.relatedTarget.nodeName === "HTML") {
                    end(evt);
                }
            }

            function end(evt: JQueryEventObject) {

                $(document).off("mousemove", move);
                $(document).off("mouseout", leave);
                $(document).off("mouseup", end);
                elem.toggleClass("grabbing", false);
                if (dragger.endHandler && dragger.dragging) {
                    dragger.endHandler(evt, evt.clientX, evt.clientY, dragger.startPoint, dragger.startParameter);
                }

                dragger.dragging = false;
                evt.stopPropagation();
                return false;
            }

            function move(evt: JQueryEventObject) {

                if (!dragger.dragging) {
                    return true;
                }

                const x = evt.clientX,
                    y = evt.clientY;

                if (dragger.moveHandler) {
                    dragger.moveHandler(evt, x, y, dragger.startPoint, dragger.startParameter);
                }

                return undefined;
            }

            elem.on("mousedown", start);
            scope.$on("$destroy", () => {
                elem.off("mousedown", start);
            });

        }

        onStart(handler: IDraggerStartDelegate<TStart>) {
            this.startHandler = handler;
            return this;
        }

        onMove(handler: IDraggerMoveDelegate<TStart>) {
            this.moveHandler = handler;
            return this;
        }

        onEnd(handler: IDraggerEndDelegate<TStart>) {
            this.endHandler = (evt: JQueryEventObject, x: number, y: number, start: IPoint, sparam: TStart) => {
                handler(evt, x, y, start, sparam);
                setTimeout(() => {
                    NGX.safeDigest(this.scope.$root);
                });
            };
            return this;
        }

    }

    function regEvent(scope: IScope, elem: JQuery<any>, events: string, handler: (evt: JQueryEventObject) => void): EventDeregisterFunction {
        elem.on(events, handler);

        const deregister = () => {
            elem.off(events, handler);
        };

        scope.$on("$destroy", deregister);
        return deregister;
    }

    export function blur() {
        const id = "global-blur-target";
        let blurElement = $(`#${id}`);
        if (blurElement.length === 0) {
            blurElement = $("<input>")
                .attr("id", id)
                .attr("type", "text")
                .css({position: "fixed", width: 0, height: 0})
                .appendTo($(document.body));
        }

        setTimeout(() => blurElement.focus());
    }

    export function isFocusTarget(evt: (JQueryEventObject | JQueryKeyEventObject | KeyDownEvent)) {
        return !!$(evt.target).closest("input, textarea, upwire-variable-editor, .input").length;
    }

    export function onWheelScroll(scope: IScope, elem: JQuery, callback: (evt: JQueryEventObject, direction: boolean) => void) {
        regEvent(scope, elem, "whell mousewheel DOMMouseScroll", (evt: JQueryEventObject) => {

            const e = evt.originalEvent as any;
            if (!evt.clientX) {
                evt.clientX = e.clientX;
                evt.clientY = e.clientY;
            }
            callback(evt, e.wheelDelta > 0 || e.detail < 0);
        });
    }

    export function onWindowResize(scope: IScope, update: () => void) {
        update();
        regEvent(scope, $(window), "resize", _.debounce(update, 20));
    }

    export function onElementDrag<TStart>(scope: IScope, elem: JQuery,
                                          start: IDraggerStartDelegate<TStart> = null,
                                          move: IDraggerMoveDelegate<TStart> = null,
                                          end: IDraggerEndDelegate<TStart> = null,
                                          requireClass: string = null) {
        return new Dragger(scope, elem, requireClass)
            .onStart(start)
            .onMove(move)
            .onEnd(end);
    }

    export function onContextMenu(scope: IScope, elem: JQuery, menu: JQuery) {

        menu.hide();
        $(document.body).append(menu);

        let hider = null;

        menu.mouseenter(() => {
            if (hider) {
                clearTimeout(hider);
                hider = null;
            }
        });

        menu.mouseleave(() => {
            hider = setTimeout(() => menu.hide(), 250);
        });

        menu.on("click", () => {
            menu.hide();
        });

        const handler = (event: JQueryEventObject) => {
            menu.css({left: event.clientX - 5, top: event.clientY - 5});
            menu.show();
            event.preventDefault();
            return false;
        };

        elem.on("contextmenu", handler);

        scope.$on("$destroy", () => {
            elem.off("contextmenu", handler);
            menu.remove();
        });
    }

    export function onMouseMove(scope: IScope, elem: JQuery, callback: IMouseEventDelegate) {
        return regEvent(scope, elem, "mousemove", (evt: JQueryEventObject) => callback(evt, evt.clientX, evt.clientY));
    }

    export function onMouseDown(scope: IScope, elem: JQuery, callback: IMouseEventDelegate) {
        return regEvent(scope, elem, "mousedown", (evt: JQueryEventObject) => {
                scope.$apply(() => {
                    callback(evt, evt.clientX, evt.clientY);
                });
            }
        );
    }

    export function onMouseUp(scope: IScope, elem: JQuery, callback: IMouseEventDelegate) {
        return regEvent(scope, elem, "mouseup", (evt: JQueryEventObject) => callback(evt, evt.clientX, evt.clientY));
    }

    export function onMouseLeave(scope: IScope, elem: JQuery, callback: IMouseEventDelegate) {
        return regEvent(scope, elem, "mouseleave", (evt: JQueryEventObject) => callback(evt, evt.clientX, evt.clientY));
    }

    export function onMouseEnter(scope: IScope, elem: JQuery, callback: IMouseEventDelegate) {
        return regEvent(scope, elem, "mouseenter", (evt: JQueryEventObject) => callback(evt, evt.clientX, evt.clientY));
    }

    export function onMouseHold(scope: IScope, elem: JQuery, callback: IMouseEventDelegate) {

        let hold = false;

        onMouseDown(scope, elem, (evt, x, y) => {
            hold = true;
            callback(evt, x, y);
        });

        onMouseMove(scope, elem, (evt, x, y) => {
            if (hold) callback(evt, x, y);
        });

        onMouseLeave(scope, elem, () => hold = false);
        onMouseUp(scope, elem, () => hold = false);
    }

    export function onMouseClick(scope: IScope, elem: JQuery, callback: IMouseEventDelegate) {
        return regEvent(scope, elem, "click", function (evt: JQueryEventObject) {
            return callback(evt, evt.clientX, evt.clientY);
        });
    }

    export function elementBBox(elem: HTMLElement | JQuery): IBoundingBox {

        const $el: JQuery = elem instanceof $ ? (elem as JQuery) : $(elem);
        const offset = $el.offset();

        return {
            xmin: offset.left,
            xmax: offset.left + $el.width(),
            ymin: offset.top,
            ymax: offset.top + $el.height()
        };
    }

    export function elementsIntersectQ(a: HTMLElement, b: HTMLElement) {
        return geometry.intersectionQ(elementBBox(a), elementBBox(b));
    }

    export function move<TElement extends HTMLElement | JQuery>(elem: TElement, x: number, y: number): TElement {

        if ("jquery" in elem) {
            elem.css({left: x, top: y});
        } else {
            elem.style.left = `${x}px`;
            elem.style.top = `${y}px`;
        }

        return elem;
    }

    export function elementInViewport(elem: HTMLElement) {

        if (!elem || 1 !== elem.nodeType) {
            return false;
        }

        const html = document.documentElement;
        const r = elem.getBoundingClientRect();
        return (!!r && r.bottom >= 0 && r.right >= 0 && r.top <= html.clientHeight && r.left <= html.clientWidth);
    }

    export function getInputSelection(input: HTMLInputElement) {

        const doc = document as any;

        if (typeof input.selectionStart == "number" && typeof input.selectionEnd == "number") {
            return {start: input.selectionStart, end: input.selectionEnd};
        } else {
            const range = doc.selection.createRange();

            if (range && range.parentElement() === input) {

                const len = input.value.length;
                const normalizedValue = input.value.replace(/\r\n/g, "\n");

                // Create a working TextRange that lives only in the input
                const textInputRange = (input as any).createTextRange();
                textInputRange.moveToBookmark(range.getBookmark());

                // Check if the start and end of the selection are at the very end
                // of the input, since moveStart/moveEnd doesn't return what we want
                // in those cases
                const endRange = (input as any).createTextRange();
                endRange.collapse(false);

                let start: number, end: number;

                if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                    start = end = len;
                } else {
                    start = -textInputRange.moveStart("character", -len);
                    start += normalizedValue.slice(0, start).split("\n").length - 1;

                    if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                        end = len;
                    } else {
                        end = -textInputRange.moveEnd("character", -len);
                        end += normalizedValue.slice(0, end).split("\n").length - 1;
                    }
                }

                return {
                    start: start,
                    end: end
                };
            }


        }

        return null;
    }

    export function getCaretPosition(input: HTMLInputElement) {

        const selection = getInputSelection(input);

        if (selection.start !== selection.end)
            return null;

        const $input = $(input);

        const offset = $input.offset();
        const result = {
            x: offset.left,
            y: offset.top
        };

        const inputValue = $input.val();
        if (typeof inputValue === "string") {
            const text: string = inputValue;
            if (text.length > 0) {
                const inputDummy = $("<div>");
                const css = window.getComputedStyle(input);
                inputDummy[0].style.cssText = css.cssText;
                inputDummy.css({
                    width: "auto",
                    position: "absolute",
                    height: "auto",
                    "white-space": "pre"
                });
                inputDummy.text(text);
                $(document.body).append(inputDummy);

                const width = inputDummy.width();
                const offx = width * selection.end / text.length;

                inputDummy.remove();

                result.x += offx;
            }
        }

        return result;
    }

    export function uniqueDomId() {
        return `${NamespaceName}-${uniqueDomIdGenerator++}`;
    }

    export function applyIconSpec(target: HTMLElement | JQuery, spec: string) {

        if (!spec) return;

        const container = ("jquery" in target) ? target : $(target);
        for (const item of spec.split(" "))
            container.addClass(item);
    }

    export function focusOn(elem: JQuery) {

        try {
            elem.focus();
        } catch (e) {
            // ERR
        }

    }

    export function getPointerRelativePosition(elem: JQuery, x: number, y: number): IPoint {
        const offset = elem.offset();
        return geometry.calcPointerRelativePosition(x, y, offset.left, offset.top, elem.width(), elem.height());
    }

    export function schedulePainter(painter: IFramePainter): IFramePaintScheduler {

        let handle = null;
        let t = window.performance.now();

        function schedulePaint() {
            if (handle !== null)
                window.cancelAnimationFrame(handle);
            handle = window.requestAnimationFrame(paint);
        }

        function paint() {

            handle = null;
            const ct = window.performance.now();
            const repaint = painter(ct, ct - t);

            t = ct;
            if (repaint) schedulePaint();
        }

        function abort() {
            if (handle !== null) {
                window.cancelAnimationFrame(handle);
            }
            handle = null;
        }

        return {
            paint: schedulePaint,
            abort: abort
        };
    }

    export function makeBlob(byteCharacters: string, mime: string = "text/plain") {

        const byteArrays: Uint8Array[] = [];
        const sliceSize = 512;
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++)
                byteNumbers[i] = slice.charCodeAt(i);

            byteArrays.push(new Uint8Array(byteNumbers));
        }

        return new Blob(byteArrays, {type: mime});
    }

    export function saveB64(b64Str: string, filename: string, mime: string = "text/plain"): void {

        const blob = makeBlob(window.atob(b64Str), mime);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link["download"] = filename;
        link.click();
    }

    export function saveBlobAs(blob: Blob, mime: string, filename: string): void {
        const url = window.URL.createObjectURL(blob);
        const element = document.createElement("a");
        element.setAttribute("href", url);
        element.setAttribute("download", filename);
        element.style.display = "none";

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    export function saveDataAs(data: string, filename: string, mime: string): void {
        saveBlobAs(new Blob([data]), mime, filename);
    }

    export function saveTextAs(str: string, filename: string): void {
        saveDataAs(str, filename, "text/csv");
    }

    export function makeRandomDomId() {
        const rand = Math.random().toString(36);
        return `${NamespaceName}-${rand.substr(2)}`;
    }

    export function scaleCanvas(context: CanvasRenderingContext2D, width: number, height: number): [number, number] {

        const canvas = context.canvas;
        canvas.width = width;
        canvas.height = height;
        return [width, height];

        // TODO: Figure out if it's sensible to do a high density transform here

        /*
        const devicePixelRatio = window.devicePixelRatio || 1;
        const backingStoreRatio = 1;

        const ratio = devicePixelRatio / backingStoreRatio;
        if (devicePixelRatio !== backingStoreRatio) {
            canvas.width = width * ratio;
            canvas.height = height * ratio;
            canvas.style.width = `${width}px`;
            canvas.style.height = `${height}px`;
        } else {
            canvas.width = width;
            canvas.height = height;
        }

        // context.scale(ratio, ratio);
        return [width * ratio, height * ratio] */
    }

    export function setDimension(elem: JQuery, w: number, h: number): JQuery {
        return elem.width(w).height(h);
    }

    export function setPosition(elem: JQuery, x: number, y: number): JQuery {
        return elem.css({top: y, left: x});
    }

    export function getElementBoundingBox(elem: JQuery): IBoundingBox {
        const bbox = elem.offset();
        const width = elem.outerWidth();
        const height = elem.outerHeight();
        return {
            xmin: bbox.left,
            xmax: bbox.left + width,
            ymin: bbox.top,
            ymax: bbox.top + height
        };
    }

    export function makeDraggable(handle: JQuery, elem: JQuery): Draggable {

        let disposed = false;
        const $body = $("body");
        handle.css({
            "cursor": "grab"
        });


        const mousedown = (e) => {

            const offset = handle.offset();
            const x = e.pageX - offset.left;
            const y = e.pageY - offset.top;

            handle.css({
                "cursor": "grabbing"
            }).toggleClass("grabbing", true);

            const dispose = () => {
                $body.off("mousemove", move);
                handle.off("mousedown", mousedown);
                handle.css({"cursor": "inherit"})
                    .toggleClass("grabbing", false);
            };

            const move = (e: JQueryMouseEventObject) => {

                if (disposed) {
                    dispose();
                    return;
                }

                elem.offset({
                    left: e.pageX - x,
                    top: e.pageY - y
                });
            };

            $body.on("mousemove", move);

            $body.one("mouseup mousedown", () => {
                if (disposed) {
                    dispose();
                }
                handle.css({"cursor": "grab"}).toggleClass("grabbing", false);
                $body.off("mousemove", move);
            });
        };

        handle.on("mousedown", mousedown);

        return {
            dispose: () => {

                handle.off("mousedown", mousedown);
                handle.css({"cursor": "inherit"})
                    .toggleClass("grabbing", false);
                disposed = true;

                elem.css({
                    "position": "relative",
                    "top": 0,
                    "left": 0,
                });
            }
        };
    }

    export function getElementVisibleRegion(elem: JQuery): IBoundingBox {
        const bbox = elem.offset();
        const width = elem.outerWidth();
        const height = elem.outerHeight();
        const windowWidth = $(window).width();
        const windowHeight = $(window).height();
        return {
            xmin: Math.max(bbox.left, 0),
            xmax: Math.min(bbox.left + width, windowWidth),
            ymin: Math.max(bbox.top, 0),
            ymax: Math.min(bbox.top + height, windowHeight)
        };
    }

    export function isFullyVisibleWithin(elem: JQuery, container: JQuery): boolean {
        const evis = getElementVisibleRegion(elem);
        const econtiner = getElementBoundingBox(container);
        return geometry.fits(evis, econtiner);
    }

    export function fitsFullyIntoContainer(child: JQuery, container: JQuery): boolean {
        const childBbox = geometry.extBBox(getElementBoundingBox(child));
        const containerBbox = geometry.extBBox(getElementBoundingBox(container));
        return containerBbox.width > childBbox.width && containerBbox.height > childBbox.height;
    }


    export async function copyToClipboard(text: string) {
        try {
            await navigator.clipboard.writeText(text);
        } catch (e) {

        }
    }
}


