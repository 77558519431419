import {DeliveryDelay, DeliveryDelayOptions, EmailTemplate} from "./EmailTemplate";
import {IScope} from "angular";
import {FullPageWizardController} from "../common/wizards";
import _ from "lodash";
import {NGX} from "../utility/ng";

interface IWizardScope extends IScope {
    template: EmailTemplate;
    phase: string;
}

interface IWizardState {
    page: number;
}

class Controller extends FullPageWizardController<IWizardState, IWizardScope> {

    deliveryDelays: DeliveryDelay[] = [];
    delay: DeliveryDelay;

    constructor(public scope: IWizardScope) {
        super(scope);

        this.wizardState = {page: 1};

        for (let option of DeliveryDelayOptions)
            this.deliveryDelays.push({...option});
        this.delay = this.deliveryDelays[0];
    }

    async onInit() {
        await super.onInit();

        const template = this.scope.template;

        this.delay = _.find(this.deliveryDelays, tic => tic.duration === template.delay);

        if (!this.delay) {
            this.deliveryDelays.push(this.delay = {
                name: `Custom: ${template.delay}(s)`,
                duration: template.delay
            });
        }

        this.scope.$watch(() => this.delay, (dd) => {
            template.delay = dd.duration;
        });
    }

    goto(page: number) {
        this.forwards = page > this.wizardState.page;
        this.wizardState.page = page;
    }

    prev() {
        if (this.wizardState.page > 1) {
            this.forwards = false;
            --this.wizardState.page;
        } else this.close();
    }

    open(state: IWizardState) {
        super.open(state);
    }
}

export const EmailWizard = NGX.makeTagDirective("email/wizard", Controller, {template: "="});
