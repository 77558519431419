import {ICompileService, IDirective, IScope} from "angular";
import $ from "jquery";

import _ from "lodash";
import {Template} from "../common/Template";
import {Variable} from "../common/Variable";
import {NGC} from "../const";
import {EmailTheme} from "../email/EmailTemplate";
import {EventDeregisterFunction} from "../utility/browser";
import {AngularDirective, NGX} from "../utility/ng";
import {drawVariablePill} from "./drawing";
import {makeVariableMenu} from "./varmenu";


interface IRteScope extends IScope {
    text: string;
    template: Template;
    colors: string[];
}

interface IRichTextEditor {
    getHtml(): string;

    setHtml(html: string);

    onChange(callback: () => void): EventDeregisterFunction;

    destroy();

    insert(text: string);

    onOpenVariableMenu(handler: () => void);
}

const imageVariableIdAttr = "data-variable-id";

function initExternalEditor() {
    require("/node_modules/trumbowyg/dist/trumbowyg");

    require("/node_modules/trumbowyg/dist/ui/trumbowyg.css");
    require("/node_modules/trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily");
    require("/node_modules/trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize");
    require("/node_modules/trumbowyg/dist/plugins/indent/trumbowyg.indent");
    require("/node_modules/trumbowyg/dist/plugins/emoji/trumbowyg.emoji");
    require("/node_modules/trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.css");
    require("/node_modules/trumbowyg/dist/plugins/colors/trumbowyg.colors");

    //$["trumbowyg"].svgPath = '/assets/my-custom-path/icons.svg';

    // Icons must be loaded as a separate file.
    const icons = require("/node_modules/trumbowyg/dist/ui/icons.svg");
    const data = $("<div>").attr("id", "trumbowyg-icons").html(icons);
    $(document.body).append(data);

    // $["trumbowyg"].svgAbsoluteUsePath = true;
    // $["trumbowyg"].svgPath = "https://cdnjs.cloudflare.com/ajax/libs/Trumbowyg/2.25.1/ui/icons.svg";
    // $["trumbowyg"].absoluteUseHref = true //"https://cdnjs.cloudflare.com/ajax/libs/Trumbowyg/2.25.1/ui/icons.svg";

}

function linkEditor(compile: ICompileService, elem: any, haveVariables: boolean): IRichTextEditor {

    let _handler: () => void = null;
    const fontList = [];
    for (let font of EmailTheme.fonts) {
        fontList.push({name: font, family: font});
    }

    // noinspection SpellCheckingInspection,JSUnusedGlobalSymbols
    elem.trumbowyg({
        removeformatPasted: true,
        imgDblClickHandler: () => {
        },
        tagsToRemove: ["script", "link"],
        btns: [["linkvar"], ["strong", "em",], ["fontfamily", "fontsize"], ["foreColor", "backColor"], ["emoji"], ["align", "indent", "outdent"]],
        btnsDef: {
            align: {
                dropdown: ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
                ico: "justifyLeft"
            },
            linkvar: {
                fn: () => {
                    if (_handler) _handler();
                },
                title: "Button tooltip",
                text: "Variables",
                ico: "view-html",
                isSupported: () => haveVariables
            }
        },
        plugins: {
            fontsize: {
                sizeList: [8, 10, 12, 14, 18, 30].map(it => it + "px"),
                allowCustomSize: true
            },
            fontfamily: {
                fontList: fontList
            }
        },
    });

    // Crazy icon fix. Trumbowyg is not using the correct icons, possibly as a side effect of iframe embedding.
    try {
        const buttons = $(".trumbowyg-button-group use");
        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            const attr = button.getAttribute("xlink:href");
            if (attr) {
                const link = attr.split("#");
                if (link.length === 2)
                    button.setAttribute("xlink:href", `#${link[1]}`);
            }
        }
    } catch (e) {
        // TODO: Report Error
    }

    return new class implements IRichTextEditor {

        onOpenVariableMenu(handler: () => void) {
            _handler = handler;
        }

        getHtml(): string {
            return elem.trumbowyg("html");
        }

        setHtml(html: string) {
            return elem.trumbowyg("html", html);
        }

        onChange(callback: () => void): EventDeregisterFunction {

            function handler() {
                callback();
            }

            const changeEvent = "tbwchange";

            // noinspection SpellCheckingInspection
            elem.on(changeEvent, handler);
            return () => {
                elem.off(changeEvent);
            };
        }

        destroy() {
            elem.trumbowyg("destroy");
        }

        insert(html: string) {
            elem.trumbowyg("execCmd", {
                cmd: "insertHTML",
                param: html,
                forceCss: false,
            });
        }
    };
}

function makeVariableRepresentationInHtml(variable: Variable): string {
    const pill = drawVariablePill(variable);
    return `<img ${imageVariableIdAttr}='${variable.referenceId}' src='${pill}' alt="${variable.name} variable"/>`;
}

function scriptToHtml(html: string, variables: Variable[]) {

    const miniDom = $("<div>").css("white-space", "pre").html(html);

    const pills: JQuery = miniDom.find("span");
    for (let i = 0; i < pills.length; ++i) {

        const pill = $(pills[i]);
        const text = pill.text();

        if (_.startsWith(text, "{{") && _.endsWith(text, "}}")) {
            const name = text.substr(2, text.length - 4);
            const variable = _.find(variables, v => v.name === name);
            if (variable) {
                pill.after(makeVariableRepresentationInHtml(variable));
                pill.remove();
            }
        }
    }

    let script = miniDom.html();
    script = script.replace(/\u00a0/g, " ");

    return script;

}

function htmlToScript(html: string, variables: Variable[]) {

    const miniDom = $("<div>").css("white-space", "pre").html(html);

    const pills: JQuery = miniDom.find("img");

    for (let pill of pills) {

        let $pill = $(pill);

        const id = $pill.attr(imageVariableIdAttr);
        const variable = _.find(variables, (v) => v.referenceId === id);

        if (variable)
            $pill.after($("<span>").text(`{{${variable.name}}}`));

        $pill.remove();
    }

    let script = miniDom.html();
    script = script.replace(/\u00a0/g, " ");

    return script;
}


export function maker(compile: ICompileService): IDirective {

    function link(scope: IRteScope, elem: JQuery) {

        const editor = linkEditor(compile, elem.find(".editor"), scope.template.variables.length > 0);

        let skipUpdate = false;

        const update = () => {
            const html = editor.getHtml();
            scope.text = htmlToScript(html, scope.template.variables);
            skipUpdate = true;
            NGX.safeDigest(scope);
        };

        editor.onOpenVariableMenu(() => {
            const menu = makeVariableMenu(elem, scope.template);
            menu.onAddVariable(variable => {
                editor.insert(makeVariableRepresentationInHtml(variable));
                update();
            });
        });

        editor.onChange(() => {
            update();
        });

        scope.$watch(() => scope.text, script => {

            if (skipUpdate) {
                skipUpdate = false;
                return;
            }

            if (script)
                editor.setHtml(scriptToHtml(script, scope.template.variables));
        });

        scope.$on("$destroy", () => {
            editor.destroy();
        });
    }

    return {
        scope: {
            text: "=",
            template: "=",
            colors: "=",
        },
        template: require("../../templates/common/rte.html"),
        link: link
    };
}

maker.$inject = [NGC.compile];

const UpwireRte: AngularDirective = {
    type: "tag",
    jsName: "upwireRte",
    name: "upwire-rte",
    maker: maker
};

export function getUpwireRteDirective(): AngularDirective {
    initExternalEditor();
    return UpwireRte;
}
