import {browser} from "../utility/browser";
import {IDirective, IScope} from "angular";
import {AngularDirective} from "../utility/ng";
import {NamespaceName} from "../const";

interface ISliderScope extends IScope {
    value: boolean;
}

function maker(): IDirective {
    return {
        restrict: "E",
        template: "<div class=\"text\">{{value?'ON':'OFF'}}</div>\n<div class=\"knob\"></div>",
        link: (scope: ISliderScope, elem: JQuery) => {
            function update() {
                elem.toggleClass("off", !scope.value);
            }

            browser.onMouseDown(scope, elem, (evt: JQueryEventObject) => {
                scope.value = !scope.value;
                update();
                evt.stopPropagation();
                return false;
            });

            scope.$watch("value", update);
        },
        scope: {
            value: "="
        }
    };
}

export const SliderDirective: AngularDirective = {
    type: "tag",
    name: NamespaceName + "-slider",
    jsName: NamespaceName + "Slider",
    maker: maker
};
