import {CommonTransaction, ITerminal, ITransactionScope, Transaction, VoiceInputTransaction} from "../../Transactions";
import _ from "lodash";
import {CanvasEvent, IStatusTag} from "../../common";
import {AppDirectiveBase} from "../../../common/AppComponentBase";
import {IPoint} from "../../../utility/common";
import {browser} from "../../../utility/browser";
import {IScope} from "angular";
import {Severity} from "../../../common/Validation";
import {NamespaceName} from "../../../const";
import {strings} from "../../../utility/strings";
import {AngularDirective, NGX} from "../../../utility/ng";
import {CanvasTemplate} from "../../CanvasTemplate";
import {makeTagFloatingMenu, TagsMenu} from "../../../directives/varmenu";

interface ITransactionType {

    typename(): string;

    templateName(): string;

    new(...any): Transaction;
}

interface ITransactionHoldingScope extends IScope {
    transaction: Transaction;
}

export type TransactionDirective = AngularDirective & { icon: string, text: string, typename: string };

const hostControllerName = "nc"; // node controller, historic reasons; but refactoring is not done yet
const defaultIcon = "defaultIcon";
const commonTransactionTag = `${NamespaceName}-common-transaction`;

export class TransactionController<TTransaction extends Transaction> extends AppDirectiveBase<ITransactionScope<TTransaction>> {

    constructor(public scope: ITransactionScope<TTransaction>) {
        super(scope);

        scope.$watch(() => scope.transaction.tagId, (tagId) => {
            scope.tag = scope.template.findTag(tagId);
        });
    }

    get transaction(): TTransaction {
        return this.scope.transaction;
    }

    get template(): CanvasTemplate {
        return this.scope.template;
    }

    get isActive() {
        return this.transaction.layout.active;
    }

    openSettingsTo(sub: string) {
        this.root.$broadcast(CanvasEvent.openSettingsSub, sub);
    }

    close() {
        if (!this.isActive)
            return;
        this.scope.$emit(CanvasEvent.transactionRemove, this.scope.transaction);
    }

    clone() {


        if (!this.isActive)
            return;

        if (this.transaction.layout.dragging)
            return;

        this.scope.$emit(CanvasEvent.transactionClone, this.scope.transaction);
    }

    get templateTypename(): string {
        return this.scope.template.typename;
    }

}


export class CommonTransactionController<TTransaction extends CommonTransaction = CommonTransaction> extends TransactionController<TTransaction> {

    typename: string;
    tagMap: any;
    tagNameMap: any;

    constructor(public scope: ITransactionScope<TTransaction>) {
        super(scope);
        this.typename = scope.transaction.type;

        scope.$watch(() => scope.template.tags, (tags: IStatusTag[]) => {

            const ids = _.map(tags, t => t.name);
            this.tagMap = _.zipObject(ids, ids);
            this.tagMap["null"] = null;
            this.tagNameMap = _.zipObject(ids, _.map(tags, t => t.name));
            this.tagNameMap["null"] = "No Tag";
        }, true);

    }

    get hasEditor(): boolean {
        return false;
    }

    async edit() {

    }

    get hasAudio(): boolean {
        return false;
    }

    get isVoiceInputTransaction(): boolean {
        return this.transaction instanceof VoiceInputTransaction;
    }


    bodyText() {
        return this.transaction.bodyText;
    }

    terminalText(terminal: ITerminal, index: any) {
        if (terminal.ghost)
            return "+";
        return this.transaction.getTerminalText(terminal, index);
    }

    tagClass(): string {
        const tag = this.scope.tag;
        if (!tag) {
            return "";
        }

        if (tag.class) return tag.class;
        return "custom-tag";
    }

    tagHint(): string | null {
        return this.scope.tag?.description || this.scope.tag?.name || "Add tag to this module";
    }

    get hasTag(): boolean {
        return this.scope.tag != null;
    }

    tagIcon(): string {
        return this.scope.tag?.icon || "fa fa-tag";
    }


}

function registerTransaction<TTransaction extends Transaction>(scope: ITransactionScope<TTransaction>, elem: JQuery) {

    function registerTerminal(scope: IScope, terminal: ITerminal) {
        scope.$watch(() => terminal.active, (active: boolean) => {
            terminal.ghost = !active;
            scope.$emit(CanvasEvent.terminalStateChange, terminal);
        });
    }

    elem.addClass("transaction");

    const template = scope.template;
    const transaction = scope.transaction;

    transaction.layout.elem = elem;

    function activate(scope: IScope, transaction: Transaction) {
        if (!transaction.layout.active) {
            scope.$emit(CanvasEvent.transactionActivated, transaction);
        }
    }

    transaction.layout.width = transaction.layout.originalWidth = elem.width();
    transaction.layout.height = transaction.layout.originalHeight = transaction.baseHeight;

    let startedActive = false;

    function dragStart() {
        transaction.layout.dragging = true;
        startedActive = transaction.layout.active;

        activate(scope, transaction);
        const x = transaction.x, y = transaction.y;
        scope.$emit(CanvasEvent.transactionMoveStart, transaction);
        return {x: x, y: y};
    }

    function dragMove(evt: JQueryEventObject, x: number, y: number, start: IPoint, origin: any) {
        scope.$emit(CanvasEvent.transactionMove, transaction, origin, {x: start.x - x, y: start.y - y});
    }

    function dragEnd() {
        scope.$emit(CanvasEvent.transactionMoveEnd, transaction);

        if (startedActive) {
            transaction.layout.dragging = false;
        } else setTimeout(() => {
            transaction.layout.dragging = false;
        });
    }

    function mousedown(evt: JQueryEventObject) {
        activate(scope, transaction);
        evt.stopPropagation();
    }

    browser.onMouseDown(scope, elem, mousedown);

    const tran = elem.find(".transaction-block");
    if (tran.length === 1)
        browser.onElementDrag(scope, tran, dragStart, dragMove, dragEnd);

    for (let term of transaction.terminals)
        registerTerminal(scope, term);

    scope.$watch(() => transaction.isStartingTransaction ? template.problem : transaction.validation.problem, (problem) => {
        scope.problem = problem;

        const hasProblem = !!problem;
        const isWarning = !!(hasProblem && problem.severity === Severity.Warning);
        const isError = !!(hasProblem && problem.severity === Severity.Error);

        elem.toggleClass("problem", hasProblem);
        elem.toggleClass("problem-warning", isWarning);
        elem.toggleClass("problem-error", isError);

    });

    scope.$watch(() => scope.template.showProblems, (show: boolean) => {
        elem.toggleClass("show-problems", show);
    });

    activate(scope, transaction);

}

export function makeTransactionDirective<TTransaction extends Transaction>(
    transactionType: ITransactionType, icon: string, text: string, controller: any = null): TransactionDirective {

    const typename = transactionType.typename();
    const name = `${typename}-transaction`;
    const template = require(`/src/templates/canvas/transactions/${transactionType.templateName()}-transaction.html`);
    const tagName = `${NamespaceName}-${name}`;

    const commonTransaction = transactionType.prototype instanceof CommonTransaction;
    controller = controller ?? (commonTransaction ? CommonTransactionController : TransactionController);

    if (icon.startsWith("fa-"))
        icon = `fa ${icon}`;

    const directive = () => ({
        restrict: "E",
        template: template,
        controller: controller,
        controllerAs: hostControllerName,
        require: ["^upwireCanvas"],
        scope: {transaction: "=", template: "="},
        link(scope: ITransactionScope<TTransaction>, elem: JQuery) {
            scope[defaultIcon] = icon;
            if (!commonTransaction)
                registerTransaction(scope, elem);
        }
    });


    return {
        type: "tag",
        name: tagName,
        jsName: strings.snake2camel(tagName),
        maker: directive,
        icon: icon,
        text: text,
        typename: typename
    };
}

export const CommonTransactionDirective: AngularDirective = {
    type: "tag",
    name: commonTransactionTag,
    jsName: strings.snake2camel(commonTransactionTag),
    maker: () => ({
        restrict: "E",
        template: require(`/src/templates/canvas/transactions/common-transaction.html`),
        require: ["^upwireCanvas"],
        transclude: {
            icon: "?transactionIcon",
            extra: "?transactionExtra",
            settings: "?transactionSettings",
            outbound: "?transactionCustomOutbound"
        },
        scope: {transaction: "=", template: "="},
        link(scope: ITransactionScope<CommonTransaction>, elem: JQuery, attrs, controllers, transclude) {

            scope["common"] = scope.$parent[hostControllerName];
            Object.defineProperty(scope, defaultIcon, {
                get() {
                    return scope.$parent[defaultIcon] || "fa fa-question";
                }
            });

            scope.hasIconSection = transclude.isSlotFilled("icon");
            scope.hasExtraSection = transclude.isSlotFilled("extra");
            scope.hasCustomTerminals = transclude.isSlotFilled("outbound");
            scope.hasSettingsSection = transclude.isSlotFilled("settings");
            scope.hasCustomBody = transclude.isSlotFilled("body");

            elem.on("mousedown", ".settings-menu", (evt: JQueryEventObject) => {
                evt.stopPropagation();
            });

            elem.on("mousedown", ".icon-button", (evt: JQueryEventObject) => {
                evt.stopPropagation();
            });

            elem.addClass("common-transaction");
            registerTransaction(scope, elem);

            let menu: TagsMenu = null;
            scope.showTagPicker = () => {

                if (!scope.transaction.layout.active)
                    return;

                if (scope.transaction.layout.dragging)
                    return;

                menu?.close();
                const btn = elem.find(".tag.icon-button");
                menu = makeTagFloatingMenu(btn, scope.template);
                menu.onAddTag((tag: IStatusTag) => {
                    if (scope.transaction.tagId !== tag.name) {
                        scope.transaction.tagId = tag.name;
                    } else {
                        scope.transaction.tagId = null;
                    }
                    NGX.safeDigest(scope);
                });
            };
        }
    })
};

export const ErrorViewDirective: AngularDirective = {
    name: NamespaceName + "-transaction-error-view",
    jsName: NamespaceName + "TransactionErrorView",
    type: "tag",
    maker: () => {
        return {
            restrict: "E",
            template: require(`/src/templates/canvas/transactions/error-view.html`),
            scope: {transaction: "="},
            link(scope: ITransactionHoldingScope, elem: JQuery) {

            }
        };
    }
};
