import _ from "lodash";
import {EmailTemplate} from "../email/EmailTemplate";
import {IImageTransformRequest, ImageTransform, imaging} from "../utility/imaging";
import {url} from "../utility/url";
import {DispatchApi} from "./base/DispatchApi";


export interface IImageDescriptor {
    url: string;
    mime: string;
    name: string;
    size: number;
}

export class EmailEditorApi extends DispatchApi {
    static injectAs = "EmailEditorApiService";
    private images: IImageDescriptor[] = [];

    constructor() {
        super("editor/email");
    }

    private findImageByName(name: string): IImageDescriptor {
        const images = this.getImages();
        return _.find(images, (img) => url.makeFileNameFromUri(img.name).endsWith(name));
    }

    private preload() {
        for (let i = 0; i < this.images.length; ++i) {
            const img = new Image();
            img.src = this.images[i].url;
        }
    }

    getImages(): IImageDescriptor[] {
        return this.images;
    }

    getDescriptorByUrl(url: string): IImageDescriptor {

        for (let image of this.images)
            if (image.url === url)
                return image;

        return null;
    }

    async update(): Promise<IImageDescriptor[]> {

        const response = await this.dispatch("list", {"templateId": this.state.templateId});

        const list: IImageDescriptor[] = this.getImages();
        list.length = 0;

        for (let image of response)
            list.push(image);

        this.preload();
        return list;
    }

    async upload(filename: string, data: Blob): Promise<boolean> {
        const formData = new FormData();
        formData.append("file", data, filename);
        formData.append("templateId", this.templateId);
        return await this.dispatchFormData("upload", formData);
    }

    async prepareTemplateImages(template: EmailTemplate) {

        await this.update();

        for (let img of template.allImages()) {

            const request: IImageTransformRequest = {
                background: img.style.backgroundColor as string,
                height: img.height - 2 * img.style.borderWidth,
                width: img.width - 2 * img.style.borderWidth,
                transform: img.style.backgroundSize === "cover" ?
                    ImageTransform.Cover :
                    ImageTransform.Contain,
                source: null,
            };

            if (img.imageUrl === null)
                continue;

            if (request.height <= 0 || request.width <= 0) {
                // Inline tiny image
                img.imageTransformedUrl = imaging.BlankImage;
                continue;
            }

            const signature = imaging.makeTransformSignature(request);
            const filename = url.makeSafeFileNameFromUri(img.imageUrl);
            const signed = signature + filename;

            if (this.findImageByName(signed)) {
                img.imageTransformedUrl = this.findImageByName(signed).url;
                continue;
            }

            request.source = await imaging.getImageSource(img.imageUrl);
            const transform = imaging.transform(request);
            await this.upload(signed, transform.blob);
            await this.update();

            const image = await this.findImageByName(signed);
            if (!image) {
                console.error("Unable to find image", template.id, signed);
                await this.overlay.halt("Unable to create preview image");
            }

            img.imageTransformedUrl = image.url;
        }
    }

    async delete(image: IImageDescriptor): Promise<void> {

        const originalFilename = url.getFileName(image.url);
        const descriptors = await this.update();

        const images: IImageDescriptor[] = [];
        for (let image of descriptors) {
            const filename = url.getFileName(image.url);
            if (imaging.stripTransformSignature(filename) === originalFilename || filename == originalFilename)
                images.push(image);
        }

        for (let image of images) {
            await this.dispatch("delete", {
                templateId: this.state.templateId,
                fileName: url.getFileName(image.url)
            });
        }

        await this.update();
    }

}
