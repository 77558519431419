import {TemplateEditorController} from "../common/EditorController";
import {GlobalEvents, TemplateTypename} from "../const";
import {TagCreation} from "../directives/dialog/VariableCreation";
import {TagEvents} from "../directives/menu/menu";
import {NGX} from "../utility/ng";
import {CanvasTemplate} from "./CanvasTemplate";
import {CanvasEvent, IStatusTag} from "./common";

export abstract class CanvasEditorController<TTemplate extends CanvasTemplate> extends TemplateEditorController<TTemplate> {

    protected async onEditorTemplateLoaded() {
        await super.onEditorTemplateLoaded();

        this.scope.$on(TagEvents.addNewTag, () => {
            this.overlay.show(TagCreation, {
                template: this.template
            });
        });

        this.scope.$on(TagEvents.removeTag, (e, tag: IStatusTag) => {
            this.template.removeCustomTag(tag);
            NGX.safeDigest(this.scope);
        });

        this.scope.$on(CanvasEvent.transactionAdd, () => {
            this.scope.$root.$broadcast(GlobalEvents.referencesUpdated);
        });
    }
}

