import {strings} from "../utility/strings";

export class Variable {

    readonly referenceId: string;
    readonly ident: string;

    constructor(readonly name: string, readonly temporary: boolean) {

        if (temporary && !name.startsWith("_"))
            this.name = `_${name}`;

        this.referenceId = `var${strings.hash(this.name)}_${temporary ? "local" : "global"}`;
        this.ident = this.name.replace(/\s\s+/g, " ").replace(/\s/g, "-");
    }

    get required(): boolean {
        return !this.temporary;
    }

    static deserialize(obj: any): Variable {
        return new Variable(obj.name, true);
    }
}
