import {IDirective} from "angular";
import {AngularDirective} from "../utility/ng";

function isDragEvent(event: Event): event is DragEvent {
    return "dataTransfer" in event && event["dataTransfer"] != null
}

function body(): IDirective {
    return {

        link(scope, elem) {

            elem.on("dragover drop", evt => {

                const event = evt.originalEvent
                if (isDragEvent(event)) {
                    const isFileDrop = event.dataTransfer.types[0] === "Files";
                    if (isFileDrop) {
                        evt.stopPropagation();
                        evt.preventDefault();
                        return false;
                    }
                }

                return undefined;
            });

        }
    }
}

export function getBodyDirective(): AngularDirective {
    return {
        type: "tag",
        name: "body",
        jsName: "body",
        maker: body
    }
}
