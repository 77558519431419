import angular, {
    IAttributes,
    IAugmentedJQuery,
    IDeferred,
    IDirective,
    IDirectiveLinkFn,
    IScope,
    ITranscludeFunction
} from "angular";
import {NamespaceName} from "../const";
import {strings} from "./strings";
import IInjectorService = angular.auto.IInjectorService;

const NgConstants = {
    tagPrefix: `${NamespaceName}-`,
    controllerSuffix: "Controller"
};

interface IExtendedDirectiveLinkFn<TScope extends IScope, TCtrl> extends IDirectiveLinkFn {
    (
        scope: TScope,
        instanceElement: IAugmentedJQuery,
        instanceAttributes: IAttributes,
        controller: TCtrl,
        transclude: ITranscludeFunction
    ): void;
}

interface NgConstructableController<T> {
    as?: string;

    new(...args: any[]): T;
}

export type NgController<T> = NgConstructableController<T> | null | undefined

export type TagName = string | { "tag": string, "template": string | null }

function assemble(name: TagName): [string, string | undefined] {
    if (typeof name == "string") {
        const tagName = NgConstants.tagPrefix + name.replace(/\//g, "-");
        const template: string = require(`../../templates/${name}.html`);
        return [tagName, template];
    }

    const template = name.template ? require(`../../templates/${name.template}.html`) : undefined;
    return [name.tag, template];
}

export type AngularTag = { type: "tag" }
export type AngularAttr = { type: "attr" }

export type AngularDirective = (AngularTag | AngularAttr) & {
    name: string,
    jsName: string,
    maker: (...any: any[]) => IDirective
}

export namespace NGX {

    export function makeTagDirective<TScope extends IScope, TCtrlInstance, TCtrl extends NgController<TCtrlInstance>>(
        name: string | { "tag": string, "template": string | null },
        ctrl: TCtrl = null,
        scope: any = {},
        linkFn: IExtendedDirectiveLinkFn<TScope, TCtrlInstance> | null = null, partial: Partial<IDirective> = {}): AngularDirective {

        const [tagName, template] = assemble(name);
        const jsName = strings.snake2camel(tagName);

        const controllerAs = strings.lowercaseFirstLetter(strings.removePrefix(`${jsName}Controller`, NamespaceName));

        const maker: () => IDirective = () => {
            return {
                restrict: "E",
                template: template,
                scope: scope ?? {},
                controller: ctrl,
                controllerAs: controllerAs,
                link(scope: IScope) {
                    if (linkFn) linkFn.apply(this, arguments);
                },
                ...partial
            };
        };

        return {
            type: "tag",
            name: tagName,
            jsName: jsName,
            maker: maker
        };
    }

    export function getInjector(): IInjectorService {
        return angular.element(document.body).injector();
    }

    export function safeDigest(scope: IScope) {
        if (scope.$root.$$phase)
            return;
        scope.$digest();
    }

    export function safeApply(scope: IScope, action: () => void) {
        if (!scope.$root.$$phase) {
            scope.$apply(action);
        } else action();
    }

    export function getPromise<T>(deferred: IDeferred<T>): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            deferred.promise.then(resolve, reject);
        });
    }

    export function copyScope(scope: IScope) {

        const copy = scope.$new(true);

        for (let prop in scope)
            if (scope.hasOwnProperty(prop) && !copy.hasOwnProperty(prop))
                copy[prop] = scope[prop];

        return copy;
    }

    export function clearNgHashes(collection: any[]) {

        for (let item of collection)
            delete item.$$hashKey;

        return collection;
    }

}


