import {Strategy} from "./common";
import {Audio} from "./audio";

export class TerminalHandler {
    ident: number = NaN;
    name: string = "Handler";
    audio: Audio = new Audio();
    strategy: Strategy = Strategy.HangUp;
    allowGoTo: boolean = true;
    target: number;
}
