import {Template} from "../common/Template";
import {AngularDirective, NGX} from "../utility/ng";
import {ValidationProblem} from "../common/Validation";
import {AppDirectiveBase} from "../common/AppComponentBase";
import {IScope} from "angular";
import {GlobalEvents} from "../const";

interface ValidationScope extends IScope {
    template: Template;
    expand: boolean;
    problem: ValidationProblem;
}

class CommonTemplateValidationController extends AppDirectiveBase<ValidationScope> {

    expand: boolean = false;

    get problem(): ValidationProblem | null {
        return this.scope.template.problem;
    }

    async onInit(): Promise<void> {
        await super.onInit();
        this.scope.expand = false;
        this.scope.$watch(() => this.scope.template.problem, problem => {
            this.scope.problem = problem;
        });
    }

    toggle() {
        this.scope.expand = !this.scope.expand;
        this.scope.$root.$broadcast(GlobalEvents.showProblems, this.scope.expand);
    }
}

const Validation = NGX.makeTagDirective("common/template-validation",
    CommonTemplateValidationController, {"template": "="});

export function getValidatorDirectives(): AngularDirective[] {
    return [Validation];
}
