import {IScope} from "angular";

import {AppDirectiveBase} from "../common/AppComponentBase";
import {EmailTemplate} from "./EmailTemplate";
import {GlobalEvents} from "../const";
import {NGX} from "../utility/ng";

interface IHtmlImportWizardScope extends IScope {
    template: EmailTemplate;
}

class EmailImporterController extends AppDirectiveBase<IHtmlImportWizardScope> {

    html: string = "";

    async onInit() {
        await super.onInit();
        if (this.scope.template.useRawHtml)
            this.html = this.scope.template.rawHtml
    }

    async close() {
        this.scope.$emit(GlobalEvents.overlayClose, this.html);
    }
}

export const EmailImporter = NGX.makeTagDirective("email/importer", EmailImporterController, {template: "="})
