import {IScope} from "angular";
import {FullPageWizardController} from "../common/wizards";
import {NGX} from "../utility/ng";
import {VoiceTemplate} from "../canvas/CanvasTemplate";
import {VoiceEditorApi} from "../apis/VoiceEditorApi";
import {VoiceTemplateSettings} from "../canvas/components/transactions/voice";


interface IWizardScope extends IScope {
    template: VoiceTemplate;
    phase: string;
}

interface IWizardState {
    page: number;
}

class Controller extends FullPageWizardController<IWizardState, IWizardScope> {

    private _settings: VoiceTemplateSettings;

    star = "*";
    hash = "#";

    constructor(scope: IWizardScope) {
        super(scope);
    }

    goto(page: number) {
        this.forwards = page > this.wizardState.page;
        this.wizardState.page = page;
    }

    get template(): VoiceTemplate {
        return this.scope.template;
    }

    async onInit(): Promise<void> {
        await super.onInit();
        this.wizardState = {page: 1};

        const injector = NGX.getInjector();
        this._settings = new VoiceTemplateSettings(injector.get(VoiceEditorApi.injectAs), this.template as any as VoiceTemplate);
        await this._settings.init();
    }

    syncHelpAndEndButtons(keep: "help" | "end"): void {
        const help = this.template.defaults.dtmfHelpButton;
        const end = this.template.defaults.dtmfEndInputButton;

        if (help == end && help !== "unbound") {
            if (keep === "help") {
                this.template.defaults.dtmfEndInputButton = "unbound";
            } else {
                this.template.defaults.dtmfHelpButton = "unbound";
            }
        }
    }

    get settings(): VoiceTemplateSettings {
        return this._settings;
    }
}

export const VoiceSettingsWizard = NGX.makeTagDirective("voice/wizard",
    Controller, {template: "="}, null, {controllerAs: "wizard"});
