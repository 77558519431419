import {AngularDirective} from "../utility/ng";
import {NamespaceName, Resources} from "../const";
import {strings} from "../utility/strings";
import $ from "jquery"
import {IScope} from "angular";

interface IMdScope extends IScope {
    markup: string
}

export function getMarkdownDirectives(): AngularDirective[] {
    const tag = `${NamespaceName}-md`
    require("../../styles/markdown.less")
    return [{
        type: "tag",
        jsName: strings.snake2camel(tag),
        name: tag,
        maker: () => {
            return {
                restrict: "E",
                scope: {markup: "="},
                link(scope: IMdScope, elem: JQuery) {
                    scope.$watch(() => scope.markup, async (markup) => {

                        const {default: marked} = await import("marked")

                        let html = "";
                        if (markup)
                            html = marked(markup);

                        const fragment = $("<div>")
                            .css("white-space", "pre").html(html);
                        const images = fragment.find("img");
                        for (let i = 0; i < images.length; ++i) {
                            const image = $(images[i]);
                            image.attr("src", Resources.ImageCDN + image.attr("src"));
                        }

                        elem.html(fragment.html());
                    });
                }
            };
        }
    }]
}
