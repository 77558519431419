import {ICompileService, IScope} from "angular";
import $ from "jquery";
import {CanvasTemplate} from "../canvas/CanvasTemplate";
import {IStatusTag} from "../canvas/common";
import {IReferenceGroup, IReferenceItem} from "../common/References";
import {Template} from "../common/Template";
import {Variable} from "../common/Variable";
import {GlobalEvents, NGC} from "../const";
import {browser} from "../utility/browser";
import {NGX} from "../utility/ng";
import {ReferenceEvents, TagEvents, VariableEvents} from "./menu/menu";

export interface ClosingMenu {
    close(): void;

    scope: IScope;
}

export interface VariableMenu extends ClosingMenu {
    onAddVariable(handler: (variable: Variable) => void): void;
}

export interface TagsMenu extends ClosingMenu {
    onAddTag(handler: (tag: IStatusTag) => void): void;
}

export interface ReferenceGroupMenu extends ClosingMenu {
    onAddReference(handler: (reference: IReferenceItem) => void): void;
}

function makeFloatingMenu(elem: JQuery, builder: (scope: IScope) => JQuery): ClosingMenu {
    const injector = NGX.getInjector();

    const compiler: ICompileService = injector.get(NGC.compile);
    const parentScope = elem.scope();

    const scope = parentScope.$new(false, parentScope);

    let floatingMenu: JQuery = null;

    const $body = $(document.body);

    const closeButton = $("<i>").addClass("pe-7s-close").addClass("close-button");
    const head = $("<div>").addClass("head").append(closeButton);


    const close = () => {
        if (floatingMenu != null) floatingMenu.remove();
        floatingMenu = null;
    };

    const menu = builder(scope);

    const box = $("<div>")
        .addClass("floating-menu")
        .addClass("slide-menu")
        .append(head)
        .append(menu);

    compiler(box)(scope, (menu) => {

        floatingMenu = menu;

        $body.append(menu);
        menu.find(".close-button").on("click", () => {
            close();
        });

        const invokerOffset = elem.offset();
        menu.offset(invokerOffset);

        const header = menu.find(".head");
        browser.makeDraggable(header, menu);

        setTimeout(() => {
            NGX.safeDigest(scope);
        });
    });

    scope.$root.$on(GlobalEvents.closeToolWindows, () => {
        close();
    });

    return {
        close: close,
        scope: scope
    };
}

export function makeVariableMenu(elem: JQuery, template: Template): VariableMenu {

    const menu = makeFloatingMenu(elem, (scope) => {
        const template_key = "template";
        scope[template_key] = template;
        return $("<upwire-common-menu-variables>").attr(template_key, template_key);
    });

    return {
        ...menu,
        onAddVariable: (handler) => {
            menu.scope.$on(VariableEvents.selectVariable, (event, variable) => {
                menu.close();
                handler(variable);
                event.stopPropagation();
            });
        }
    };
}

export function makeTagFloatingMenu(elem: JQuery, template: CanvasTemplate): TagsMenu {
    const menu = makeFloatingMenu(elem, (scope) => {
        const vars = "template";
        scope[vars] = template;
        return $("<upwire-common-menu-tags>").attr(vars, vars);
    });

    return {
        ...menu,
        onAddTag: (handler) => {
            menu.scope.$on(TagEvents.selectTag, (event, variable) => {
                menu.close();
                handler(variable);
                event.stopPropagation();
            });
        }
    };
}

export function makeReferenceMenu(elem: JQuery, references: IReferenceGroup[]): ReferenceGroupMenu {

    const menu = makeFloatingMenu(elem, (scope) => {
        const refs = "references";
        scope[refs] = references;
        return $("<upwire-common-menu-references>").attr(refs, refs);
    });

    return {
        ...menu,
        onAddReference: (handler) => {
            menu.scope.$on(ReferenceEvents.select, (event, reference) => {
                menu.close();
                handler(reference);
                event.stopPropagation();
            });
        }
    };
}

