export function getDeliveryTicks(addGlobalOption: boolean = false): { name: string, duration: number | null }[] {
    const options = [
        {name: "Deliver as soon as possible", duration: 0},
        {name: "1 minute", duration: 60},
        {name: "2 minutes", duration: 60 * 2},
        {name: "5 minutes", duration: 60 * 5},
        {name: "20 minutes", duration: 60 * 20},
        {name: "1 hour", duration: 60 * 60},
        {name: "2 hours", duration: 60 * 60 * 2},
        {name: "5 hours", duration: 60 * 60 * 5},
        {name: "1 day", duration: 60 * 60 * 24},
        {name: "2 days", duration: 60 * 60 * 24 * 2}
    ];

    if (addGlobalOption) {
        options.unshift({name: "User Global Setting", duration: null});
    }

    return options;

}
